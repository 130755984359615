import { VideoPlayer } from '../../../../components/VideoPlayer'
import { iItemIntegracao } from '../../../../interfaces'
import { Body } from '../../../Layouts/Body'

interface iVideoProps {
  item: iItemIntegracao
  isVisible?: boolean
}

export const VideoImersao: React.FC<iVideoProps> = ({ item, isVisible }) => {
  if (!isVisible) {
    return null
  }

  return (
    <Body>
      <VideoPlayer descricao={item.descricao ?? ''} titulo={item.titulo ?? ''} url={item.arquivo?.uri ?? ''}/>
    </Body>
  )
}
