import { Avatar, Box, Flex, Input, InputGroup, InputProps, InputRightElement } from '@chakra-ui/react'
import React, { useState, useEffect } from 'react'
import { MdClose } from 'react-icons/md'
import { iNomeId } from '../../interfaces'
import { Container, Nome, Sobrenome } from './styles'

interface iFindProps extends InputProps {
  lista: iNomeId[]
  onChoice: (id: string) => void
  onChangeInput?: (texto: string) => void
  extrairEmail?: (email: string) => void
  selecionado?: string
  isNotUser?: boolean
  leftIcon?: React.ReactNode
}

export const FindUser: React.FC<iFindProps> = ({ lista, selecionado, isNotUser, placeholder, isInvalid, isDisabled, leftIcon, onChoice, onChangeInput, extrairEmail }) => {
  const [Selecionado, setSelecionado] = useState<string>()
  const [Texto, setTexto] = useState('')
  const [Last, setLast] = useState('')

  function internalSelect(id: string): void {
    onChoice(id)
    setSelecionado(id)

    const colab = lista.find(e => e.id === id)
    if (colab) {
      setTexto(colab.nome)
      setLast(colab.nome)
    }
  }

  useEffect(() => {
    if (selecionado !== undefined && lista !== undefined && lista.length > 0) {
      setSelecionado(selecionado)
      const buscar = lista.find(e => e.id === selecionado)
      if (buscar !== undefined) {
        setTexto(buscar.nome)
        setLast(buscar.nome)
        if (extrairEmail !== undefined) {
          extrairEmail(buscar?.email ?? '')
        }
      }
    } else if (selecionado === undefined) {
      setTexto('')
      setLast('')
      setSelecionado(undefined)
    }
  }, [lista, selecionado])

  const handleKeyDown = (event: any): void => {
    if (event.key === 'Backspace') {
      Clean()
    }
  }

  function Clean(): void {
    setTexto('')
    setSelecionado(undefined)
    onChoice('')
    if (Texto !== Last && Selecionado) {
      setTexto('')
    }
  }

  return (
    <Flex flexDir={'column'} width={'100%'}>
      <Flex flexDir={'column'} width='100%'>
        <InputGroup width={'100%'}>
          {
            (!isDisabled) && (
              <InputRightElement
                cursor={'pointer'}
                children={Selecionado && <MdClose onClick={Clean} color='var(--terc3)' />}
              />
            )
          }
          <Input
            placeholder={placeholder}
            isDisabled={isDisabled}
            onChange={(e) => {
              setTexto(e.target.value)
              if (onChangeInput !== undefined) {
                onChangeInput(e.target.value)
              }
            }}
            onKeyDown={handleKeyDown}
            value={Texto}
            borderColor={'var(--Gray4)'}
            isInvalid={isInvalid}
          />
        </InputGroup>

      </Flex>
      <Flex width={'100%'} flexDir={'column'} gap={'.5rem'}>
        {
          lista.filter((e) => {
            if (isDisabled) {
              return null
            } else if (Texto === '' || Selecionado) {
              return null
            } else if (e.nome.toLocaleLowerCase().includes(Texto.toLocaleLowerCase())) {
              return e
            }
            return null
          }).slice(0, 3).map((e, i) => {
            return (
              <Container onClick={() => internalSelect(e.id)} key={i} background='var(--c3)'>
                {(!leftIcon) && (<Avatar margin={'1rem 0rem 1rem 1rem'} size={'md'} src={e.avatar} />)}

                {(leftIcon) && (
                  <Box margin={'1rem 0rem 1rem 1rem'} minWidth={'2rem'} minHeight={'1.5rem'}>
                    {leftIcon}
                  </Box>)}

                <Flex padding={'0 1rem'} width={'100%'} justifyContent='center' alignItems={'start'} flexDir={'column'}>
                  <Nome>{e.nome}</Nome>
                  {
                    (!isNotUser) && (
                      <Sobrenome>{e.complemento ?? 'Departamento não identificado'}</Sobrenome>
                    )
                  }
                </Flex>
              </Container>
            )
          })
        }
      </Flex>
    </Flex>
  )
}
