import styled from 'styled-components'

export const Container = styled.div`
padding: 1rem;
width: 75rem;
`

export const ModalHeader = styled.div`
display: flex;
margin-bottom:1rem;
justify-content: space-between;
div:first-child{
    display: flex;
    align-items: center;
}

h2{
    color:var(--a3)
}

img{
    margin-right: 1.25rem;
    height: 5rem;
    width: 5rem;
    border-radius: 2.5rem;
}
`

export const ModalBody = styled.div`
display: flex;
flex-direction: column;
border: 0.125rem solid var(--c3);
padding: 0.375rem;
margin-bottom: 1rem;

div{
    display: flex;
    align-items: center;
    padding: 1rem;
    background: var(--c2);
    cursor: pointer;
    color: var(--a4);

    span{
        font-weight: 400;
        font-size: 1.125rem;
        color: var(--a4);
    }

    svg{
        margin-right: 1rem;
        min-height: 1.75rem;
        min-width: 1.75rem;
    }

    &:hover{
        background: #B7F7B1;
        span{
            font-weight: bold;
        }
    }
}

div:not(:last-child){
    margin-bottom:.5rem;
}

overflow: auto;
max-height:60vh;
::-webkit-scrollbar {
  width: .5rem;
}
/* heigth */
::-webkit-scrollbar {
  height: 0.375rem;
}
/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 0.625rem;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
`
