import React from 'react'
import { TreeNode } from 'react-organizational-chart'
import { IEmployee } from '../../../../../../../../../../interfaces'
import { EmployeeCard } from '../EmployeeCard'

interface IChartNodePops {
  isFolded: boolean
  employees: IEmployee[]
}

export const ChartNode: React.FC<IChartNodePops> = ({
  isFolded,
  employees
}) => {
  return (
    <>
      {employees.map((employee) =>
        !employee.subordinados?.length || isFolded
          ? (
          <TreeNode
            key={employee.id}
            label={<EmployeeCard employee={employee} />}
          />
            )
          : (
          <TreeNode
            key={employee.id}
            label={<EmployeeCard employee={employee} />}
          >
            <ChartNode isFolded={isFolded} employees={employee.subordinados} />
          </TreeNode>
            )
      )}
    </>
  )
}
