import styled from 'styled-components'
import { Column, Flex } from '../../../../../styles/styledGlobal'

export const Container = styled(Flex)`
justify-content: space-between;
border-radius: 6px;
width: 100%;
background: var(--c2);
border: 0.125rem solid var(--v3);
`

export const EndIcon = styled(Flex)`
align-items: start;

svg{
    color: var(--terc3);
    cursor: pointer;
    margin: .3rem;
}
`

export const ColumnText = styled(Column)`
margin-left: 12px;
span,h4{
    color: var(--a5);
}

span{
font-weight: 400;
font-size: 0.875rem;
}
`
