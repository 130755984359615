import { TagLabel, TagRightIcon, Tag as ChakraTag } from '@chakra-ui/react'
import { MdClose } from 'react-icons/md'

interface TagProps {
  onclickText?: () => void
  onDelete?: () => void
  color?: string
  hasX?: boolean
  isDisabled?: boolean
}

export const Tag: React.FC<TagProps> = ({ children, color, hasX, isDisabled, onclickText, onDelete }) => {
  return (
    <ChakraTag width={'fit-content'} className={isDisabled ? 'disabled' : ''} size={'sm'} bg={color ?? 'var(--Azul)'}>
      <TagLabel cursor={'pointer'} color={'white'} onClick={onclickText}>{children}</TagLabel>
      {hasX && <TagRightIcon color={'white'} cursor={'pointer'} onClick={onDelete} as={MdClose} />}
    </ChakraTag>
  )
}
