import { Flex, FormControl, FormLabel, Input } from '@chakra-ui/react'
import { useState } from 'react'
import { FaTrash } from 'react-icons/fa'
import { Button } from '../../../../../../components/Button'
import { iConvite } from '../../../../../../interfaces'
import { appApi } from '../../../../../../services/appApi'
import { ModalBase } from '../../../../../../components/ModalBase'
import { TableConvites } from '../Tabela'
import { PropInvalida } from '../../../../../../Utils/Helper'

interface iModalProps {
  isOpen: boolean
  rows: iConvite[]
  paramId: string
  Reload: () => void
  onClose: () => void
}
export const ModalConviteEmail: React.FC<iModalProps> = ({ isOpen, rows, paramId, onClose, Reload }) => {
  const [Selecionados, setSelecionados] = useState<string[]>([])
  const [Email, setEmail] = useState('')

  function onCheck(id: string, all?: boolean): void {
    if (!PropInvalida(all)) {
      if (all) {
        setSelecionados(Selecionados.concat(rows.filter(r => !Selecionados.some(t => t === r.id)).map((e) => { return e.id })))
      } else {
        setSelecionados([])
      }
    } else {
      const copy = [...Selecionados]
      if (copy.find(e => e === id) === undefined) {
        copy.push(id)
        setSelecionados(copy)
      } else {
        setSelecionados([])
        const filtrado = copy.filter(e => e !== id)
        setSelecionados(filtrado)
      }
    }
  }

  function onDeleteConvites(): void {
    appApi.post('PesquisaInternaConvite/DeletarVarios', Selecionados)
      .then(() => {
        Reload()
        setSelecionados([])
      })
      .catch(err => console.log(err))
  }

  function CadastrarPorEmail(): void {
    appApi.post(`PesquisaInternaConvite/${paramId}/Email`, { texto: Email })
      .then(Reload)
      .catch(err => console.log(err))
  }

  return (
    <ModalBase
      Titulo="Convite por e-mail"
      Subtitulo="Utilize os filtros para selecionar apenas os convites que desejar. Você pode filtrar por área ou nome. Poderá também clicar na barra de seleção e adicionar todos os convites que estão aparecendo na lista a partir dos filtros realizados."
      isOpen={isOpen}
      onClose={onClose}
      Width='65rem'
    >
      <Flex padding={'2rem'} flexDir={'column'}>
        <Flex width={'100%'} marginBottom={'1rem'}>
          <FormControl as={Flex} alignItems={'center'}>
            <FormLabel width={'12.2rem'} marginBottom='0rem' fontSize={'xl'}>Email do usuário</FormLabel>
            <Input
              onChange={(e) => setEmail(e.target.value)}
              marginRight={'1rem'}
              borderColor={'var(--Gray4)'}
              placeholder='Colaborador@gmail.com'
            />
          </FormControl>
          <Button VarColor='v2' onClick={CadastrarPorEmail}>Selecionar usuários</Button>
        </Flex>

        {
          (rows !== undefined && rows.filter(e => e.status !== 'Respondido').length > 0) && (
            <>
              <Flex marginBottom={'1rem'} fontWeight={'400'} justifyContent={'space-between'} alignItems={'center'}>
                <h3>Usuários selecionados</h3>
                <Button leftIcon={<FaTrash />} VarColor={'terc3'} onClick={onDeleteConvites}>Excluir convites selecionados</Button>
              </Flex>
              <TableConvites
                isEdit
                rows={rows.filter(e => e.status !== 'Respondido')}
                onCheck={onCheck}
                selecionados={Selecionados}
                onSend={() => { }}
              />
            </>
          )
        }
      </Flex>
      <Flex padding={'0 1rem 1rem 0'} justifyContent={'end'}>
        <Button VarColor='Green1' size={'lg'} onClick={onClose}>Continuar</Button>
      </Flex>
    </ModalBase>
  )
}
