import styled from 'styled-components'

export const Main = styled.span`
input[type=checkbox]{
    height: 1.25rem;
    width: 1.25rem;
    margin-right:.5rem;
}

small{
    font-size: 0.875rem;
    font-weight: bold;
}

h3,small{
    color: var(--a4);
}
`
