import { Button, Flex, FormControl, FormLabel, Input, InputGroup, InputRightElement } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { FaPlus } from 'react-icons/fa'
import { MdClose, MdSave } from 'react-icons/md'
import { ModalBase } from '../../../../../../components/ModalBase'
import { iDemografia, iGrupo } from '../../../../../../interfaces'
import { SigahApi } from '../../../../../../services/appApi'
import { ObterToken } from '../../../../../../Utils/Helper'

interface iModalProps {
  isOpen: boolean
  onClose: () => void
  reload: () => void
  demografiaId?: string
}
export const ModalUpdateDemografia: React.FC<iModalProps> = ({ isOpen, demografiaId, onClose, reload }) => {
  const [Demografias, setDemografias] = useState<iGrupo[]>([])
  const [Nome, setNome] = useState('')

  function UpdateDemografiasArray(index: number, Texto: string): void {
    const array = [...Demografias]
    array[index].nome = Texto
    setDemografias(array)
  }

  function DeleteItemArray(index: number): void {
    const dems: iGrupo[] = []
    Demografias.forEach((e, i) => {
      if (index !== i) { dems.push(e) }
    })
    setDemografias(dems)
  }

  function PutDemografia(form: iDemografia): void {
    SigahApi.put(`api/Onboard/Demografia/${form.id}`, form, {
      headers: {
        authorization: ObterToken()
      }
    })
      .then(() => {
        reload()
        onClose()
      })
      .catch(err => console.log(err))
  }

  function getDemografia(): void {
    SigahApi.get(`api/Onboard/Demografia/${demografiaId as string}`, {
      headers: {
        authorization: ObterToken()
      }
    })
      .then(res => {
        setNome(res.data.nome)
        setDemografias(res.data.grupos)
      })
      .catch(err => console.log(err))
  }

  useEffect(() => {
    getDemografia()
  }, [])

  return (
    <ModalBase
      Titulo="Editando demografia (grupo para análise)"
      Subtitulo="Ao  adicionar ou remover grupos de demografia você pode demorar para obter dados para as análises"
      onClose={onClose}
      isOpen={isOpen}
      Width='50rem'
    >
      <Flex flexDirection={'column'} padding={'1rem'} borderBottom={'1px solid var(--c4)'}>
        <FormControl marginBottom={'1rem'}>
          <FormLabel fontSize={'lg'} marginBottom={'.3rem'}>Nome da demografia</FormLabel>
          <Input
            placeholder='Digite o nome'
            width={'18rem'}
            borderColor={'var(--Gray4)'}
            value={Nome}
            onChange={(e) => setNome(e.target.value)}
          />
        </FormControl>

        <FormControl>
          <FormLabel fontSize={'lg'} marginBottom={'.3rem'}>Grupos</FormLabel>
          <Flex display={'grid'} gap={'1rem 1rem'}>
            {
              Demografias?.map((e, i) => {
                return (
                  <InputGroup key={i}>
                    {
                      (Demografias.length > 1) && (
                        <InputRightElement
                          children={<MdClose cursor={'pointer'}
                            onClick={() => DeleteItemArray(i)}
                            color='gray.300' />}
                        />
                      )
                    }
                    <Input
                      placeholder={`Grupo ${i !== 0 ? i : ''}`}
                      borderColor={'var(--Gray4)'}
                      value={e.nome}
                      onChange={(e) => UpdateDemografiasArray(i, e.target.value)}
                    />
                  </InputGroup>
                )
              })
            }

          </Flex>
        </FormControl>

        <Flex marginTop={'1rem'}>
          <Button
            borderRadius={'12px'}
            fontWeight={'500'}
            bg={'var(--v2)'}
            _hover={{ bg: 'var(--v2)' }}
            leftIcon={<FaPlus />}
            onClick={() => setDemografias(oldArray => [...oldArray, { nome: '' }])}
          >Adicionar</Button>
        </Flex>
      </Flex>
      <Flex justifyContent={'end'} padding={'1rem'}>
        <Button
          borderRadius={'12px'}
          fontWeight={'500'}
          bg={'var(--v2)'}
          _hover={{ bg: 'var(--v2)' }}
          leftIcon={<MdSave />}
          onClick={() => PutDemografia({
            id: demografiaId as string,
            nome: Nome,
            grupos: Demografias,
            area: false
          })}
        >Salvar</Button>
      </Flex>
    </ModalBase>
  )
}
