import React from 'react'
import { FaDoorOpen, FaTrashAlt } from 'react-icons/fa'
import { FiEdit } from 'react-icons/fi'
import { IJobTitle } from '../../../../../../interfaces'

import { Container } from './styles'

interface IJobTitleCardProps {
  jobTitle: IJobTitle
  onEdit: (jobTitle: IJobTitle) => void
  onDelete: (jobTitle: IJobTitle) => void
}

export const JobTitleCard: React.FC<IJobTitleCardProps> = ({
  jobTitle,
  onEdit,
  onDelete
}) => {
  return (
    <Container>
      <header>
        <FaDoorOpen />
        <div>
          <strong>{jobTitle.nome}</strong>
        </div>
      </header>
      <div>
        <button type="button" onClick={() => onEdit(jobTitle)}>
          <FiEdit />
        </button>

        <button type="button" onClick={() => onDelete(jobTitle)}>
          <FaTrashAlt size={20} />
        </button>
      </div>
    </Container>
  )
}
