import { Flex, Image } from '@chakra-ui/react'

export const ImgSah: React.FC<{ entidade?: string, textoPersonalizado?: string }> = ({
  children,
  entidade,
  textoPersonalizado
}) => {
  return (
        <Flex alignItems={'center'} color={'var(--Azul)'} justifyContent={'center'}>
            <Image className='sah_Img' height={'20rem'} src='https://sigah.blob.core.windows.net/onboard/bbb6990f-d313-488a-af99-5383c9bd1c82.png' />
            <Flex flexDir={'column'}>
                {entidade && <h3>Você ainda não criou nenhuma {entidade}<br /> O que acha de criar a primeira, agora?</h3>}
                {textoPersonalizado && <h3>{textoPersonalizado}</h3>}
                <Flex justifyContent={'center'} marginTop={'1rem'}>
                    {children}
                </Flex>
            </Flex>
        </Flex>
  )
}
