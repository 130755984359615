import { Avatar } from '@chakra-ui/react'
import { FaMinusCircle } from 'react-icons/fa'
import { iCardAvaliador } from '../../../../../../interfaces'
import { AvatarTitle, ColumnText, Container, EndIcon } from './styles'

interface iParticipanteReuniao extends iCardAvaliador {
  tipo: number
}
interface iCardProps {
  participante: iParticipanteReuniao
  onDelete: (avaliadorId: string) => void
}

export const CardParticipante: React.FC<iCardProps> = ({ participante, onDelete }) => {
  function ObterTipo(type: number): string {
    switch (type) {
      case 0:
        return 'Rh'

      case 1:
        return 'Colaborador'

      case 2:
        return 'Lider'

      case 3:
        return 'Pares'

      case 4:
        return 'Superiores'

      case 5:
        return 'Alta liderança'

      case 6:
        return 'Outro'

      case 7:
        return 'Responsável'

      default:
        return 'null'
    }
  }
  return (
    <Container>
      <AvatarTitle>
        <Avatar name={participante.nome} src={participante.avatar} />
        <ColumnText>
          {
            participante.nome.length > 17
              ? <span title={participante.nome}>{participante.nome.substring(0, 16)}...</span>
              : <span>{participante.nome}</span>
          }
          <strong>{ObterTipo(participante.tipo)}</strong>
        </ColumnText>
      </AvatarTitle>
      <EndIcon>
        <FaMinusCircle onClick={() => onDelete(participante.id)} />
      </EndIcon>
    </Container>
  )
}
