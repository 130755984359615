import styled from 'styled-components'

export const Container = styled.div`
display: flex;
flex-direction: column;
border-bottom: 1px solid var(--c4);
padding-bottom: 2rem;
h3{
   margin: 2rem 1rem 1rem;
   font-weight: normal;
   color: black;
   font-size: 1.5rem;
}

& > div:first-child{
   span{
      color: var(--terc3);
      margin-left: 1rem;
   }
}
`
