import styled from 'styled-components'

export const Main = styled.div`
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;
    img{
        width: 48%;
        object-fit: scale-down;
    }

    div{
        background-color: white;
        width: 48%;
        padding: 3.75rem;
    }

    h2{
        border-bottom: 0.125rem solid black;
        margin-bottom: 1rem;
    }

    span{
        font-weight: 500;
    }
`
