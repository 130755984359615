/* eslint-disable no-return-assign */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { Flex, FormControl, FormLabel, Select, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Button } from '../../../../components/Button'
import { WhiteBox } from '../../../../components/WhiteBox'
import { iChartNps, iNomeId } from '../../../../interfaces'
import { SigahApi, urlSigah } from '../../../../services/appApi'
import { parseJwt } from '../../../../services/token'
import { ObterToken, PropInvalida } from '../../../../Utils/Helper'
import { Body } from '../../../Layouts/Body'
import { ColumnChart } from './Components/ColumnChart'
import { PieChart } from './Components/PieChart'
import { ComentariosContainer } from './styles'

type iParam = {
  pesquisaId: string
}

interface iChartDemografiaNps {
  nomeDemografia: string
  demografias: iNpsDemografia[]
}

interface iNpsDemografia {
  nomeItem: string
  nps: number
}

interface iDiscursiva {
  pergunta: string
  respostas: string[]
}

interface iResultadoNps {
  graficosNps: iChartNps[]
  graficosDemografia: iChartDemografiaNps[]
  discursivas: iDiscursiva[]
  filtroDemografia: iNomeId[]
}

export const ResultadoEnpsJornada: React.FC = () => {
  const { adm, depid } = parseJwt()
  const param = useParams<iParam>()
  const Route = `${urlSigah}/PesquisaPulse`
  const redirectPage = (at: string, param: string): any => window.location.href = `${Route}/${at}/${param}`

  const nav = useNavigate()

  const [Resultado, setResultado] = useState<iResultadoNps>()
  const [FiltroDemografia, setFiltroDemografia] = useState('')

  function getResultado(): void {
    SigahApi.get(`api/Onboard/eNps/Resultado/${param.pesquisaId as string}?itemDemografiaId=${adm === 'True' ? '' : depid}`, {
      headers: {
        'content-type': 'application/json',
        authorization: ObterToken()
      }
    })
      .then(res => setResultado(res.data))
      .catch(err => console.log(err))
  }

  function FiltrarGrafico(id: string): void {
    setFiltroDemografia(id)
    setResultado(undefined)
    SigahApi.get(`api/Onboard/eNps/Resultado/${param.pesquisaId as string}?itemDemografiaId=${id}`, {
      headers: {
        'content-type': 'application/json',
        authorization: ObterToken()
      }
    })
      .then(res => setResultado(res.data))
      .catch(err => console.log(err))
  }

  useEffect(() => {
    getResultado()
  }, [])

  useEffect(() => {
    if (PropInvalida(FiltroDemografia) && adm === 'False') {
      setFiltroDemografia(depid)
    }
  }, [Resultado?.filtroDemografia])

  return (
    <Body>
      <WhiteBox>
        <Flex justifyContent={'space-between'}>
          <Button onClick={() => nav('/Jornada/eNps')} VarColor="c4" size={'lg'}>Voltar</Button>
          <Button onClick={() => redirectPage('Participantes', param.pesquisaId as string)} VarColor='Azul' size={'lg'}>Gestão de convites</Button>
        </Flex>
        <Flex margin={'2rem 0'}>
          <h1>Jornada {'>'} eNPS {'>'} 90 dias</h1>
        </Flex>

        {
          (Resultado && (Resultado?.graficosDemografia?.length > 0 || Resultado?.filtroDemografia?.length > 0)) && (
            <FormControl marginBottom={'2rem'}>
              <FormLabel fontSize={'xl'} color={'var(--Gray1)'}>Demografia</FormLabel>
              <Select
                isDisabled={adm === 'False'}
                value={FiltroDemografia}
                borderColor={'var(--Gray4)'}
                width={'18rem'} onChange={(e) => {
                  if (e.target.value === '') { getResultado() }
                  FiltrarGrafico(e.target.value)
                }}>
                <option value={''}>Nenhum</option>
                {
                  Resultado?.filtroDemografia?.map((e) => {
                    return (
                      <option key={e.id} value={e.id}>{e.nome}</option>
                    )
                  })
                }
              </Select>
            </FormControl>
          )
        }

        <Tabs size='md' variant='enclosed'>
          <TabList marginBottom={'.01rem'}>
            <Tab _selected={{ borderTopColor: 'var(--Gray4)', borderLeftColor: 'var(--Gray4)', borderRightColor: 'var(--Gray4)', borderRadius: '0rem', borderBotto: 'white' }} bg={'var(--c1)'} color={'black'}>Resultados</Tab>
            {
              (Resultado && Resultado.discursivas.length > 0) && (
                <Tab _selected={{ borderTopColor: 'var(--Gray4)', borderLeftColor: 'var(--Gray4)', borderRightColor: 'var(--Gray4)', borderRadius: '0rem' }} bg={'var(--c1)'} color={'black'}>Questões discursivas</Tab>
              )
            }
          </TabList>
          <TabPanels border={'1px solid var(--Gray4)'}>
            <TabPanel flexDir={'column'}>
              {
                (Resultado) && (
                  <Flex gap={'2rem'} flexDir={'column'} marginBottom={'1rem'} borderBottom={'1px solid var(--Gray4)'}>
                    {
                      Resultado.graficosNps.map((e, i) => {
                        return (
                          <Flex key={i}
                            paddingBottom={'1rem'}
                            flexDir={'column'}>
                            <h3>{e.pergunta}</h3>
                            <Flex marginTop={'1rem'} justifyContent={'center'} width={'100%'}>
                              <PieChart
                                id={`i-ChartNps-${i}`}
                                detratores={e.detratores}
                                neutros={e.neutros}
                                promotores={e.promotores}
                                nps={e.nps.toFixed(0)}
                              />
                            </Flex>
                          </Flex>
                        )
                      })
                    }
                  </Flex>
                )
              }

              {
                Resultado?.graficosDemografia.map((e, i) => {
                  return (
                    <Flex key={e.nomeDemografia} width={'100%'} paddingBottom={'1rem'} marginBottom={'1rem'} borderBottom={'1px solid var(--Gray4)'}>
                      <ColumnChart
                        id={i.toString()}
                        Grafico={{
                          titulo: e.nomeDemografia,
                          labelX: e.demografias.map((e) => {
                            return e.nomeItem
                          }),
                          series: [
                            {
                              nome: 'Processos Absolutos',
                              dados: e.demografias.map((e) => {
                                return e.nps
                              })
                            }
                          ]
                        }}
                      />
                    </Flex>
                  )
                })
              }

            </TabPanel>
            <TabPanel>
              {
                Resultado?.discursivas.map((e, i) => {
                  return (
                    <div key={i}>
                      <Flex flexDir={'column'}>
                        <h3 style={{ color: 'var(--Gray3)' }}>Pergunta:</h3>
                        <span
                          style={{ fontSize: '1.2rem', fontWeight: '400', marginTop: '.5rem' }}
                        >{e.pergunta}</span>
                      </Flex>
                      <ComentariosContainer>
                        <section>
                          <h3>Comentários: {e.respostas.length}</h3>
                          {
                            e.respostas.map((r, i2) => {
                              return (
                                <div key={i2}>
                                  <span>{r}</span>
                                </div>
                              )
                            })
                          }

                        </section>
                      </ComentariosContainer>
                    </div>
                  )
                })
              }
            </TabPanel>
          </TabPanels>
        </Tabs>
      </WhiteBox>
    </Body>
  )
}
