import PesquisaImg from '../../../../../../../assets/Pesquisa.svg'
import { Container } from './styles'
import { FaChartPie, FaCog, FaEdit } from 'react-icons/fa'
import React, { useEffect, useState } from 'react'
import { appApi } from '../../../../../../../services/appApi'
import { Flex } from '@chakra-ui/react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Button } from '../../../../../../../components/Button'

interface iCardPesquisa {
  InteracaoId: string
}

interface iPesquisaInternaStatus {
  respondente: string
  totalQuestoes: number
  formularioAprovado: boolean
  liberarResultado: boolean
  linkResposta?: string
}

export const CardPesquisa: React.FC<iCardPesquisa> = ({ InteracaoId }) => {
  const [Pesquisa, setPesquisa] = useState<iPesquisaInternaStatus>()
  const { pathname } = useLocation()
  const nav = useNavigate()

  useEffect(() => {
    ObterPesquisaPorId()
  }, [])

  function ObterPesquisaPorId(): void {
    appApi.get(`PesquisaInterna/${InteracaoId}/Status?colaboradorId=${pathname.substring(21)}`)
      .then(response => { setPesquisa(response.data) }).catch(err => console.error(err))
  }

  return (
    <Flex flexDir={'column'} gap={'1rem'}>
      <Container>
        <Flex>
          <img src={PesquisaImg} />
          <div>
            <FaEdit color='var(--terc10)' size={45} />
          </div>
        </Flex>
        <div>
          <span>Respondente: <strong>{Pesquisa?.respondente}</strong></span>
          <span>Total de questões:<strong>{Pesquisa?.totalQuestoes}</strong></span>
        </div>

      </Container>
      <Flex justifyContent={'center'} flexDir='column' gap={'1rem'}>
        <Button
          VarColor='Azul'
          leftIcon={<FaCog />}
          onClick={() => nav(`/Jornada/Processo/${pathname.substring(21)}/PesquisaInterna/Configuracao/${InteracaoId}`)}
        >Configurações de envio</Button>

        {
          (Pesquisa?.linkResposta) && (
            <Button
              VarColor='Azul'
              onClick={() => window.open(Pesquisa.linkResposta, '_blank')}
            >Responder pesquisa</Button>
          )
        }

        <Button
          VarColor='Azul'
          isDisabled={!Pesquisa?.liberarResultado}
          leftIcon={<FaChartPie />}
          onClick={() => nav(`/Jornada/PesquisaInterna/Resultado/${InteracaoId}`)}
        >Resultado</Button>
      </Flex>
    </Flex>
  )
}
