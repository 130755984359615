import {
  CardColor, ColorBox,
  InputColor,
  PaletaDeCor, TagBox, FlexIcon
} from './styles'
import { FaQuestionCircle } from 'react-icons/fa'
import { useState } from 'react'
import { Flex, FormControl, FormLabel, Input, Tooltip } from '@chakra-ui/react'
import { TagContainer } from '../../../../../components/TagContainer'
import { Button } from '../../../../../components/Button'
import { ModalBase } from '../../../../../components/ModalBase'

interface IModalNovoGrupo {
  isOpen: boolean
  onRequestClose: () => void
  onCreate: (cor: string, nome: string, tags: string[]) => void
}
export const ModalNovoGrupo: React.FC<IModalNovoGrupo> = ({ onRequestClose, onCreate, isOpen }) => {
  const [Cor, setCor] = useState('FF6900')
  const [NomeGrupo, setNomeGrupo] = useState('Nome do grupo')
  const [TagsSel, setTagsSel] = useState<string[]>([])

  function TrocaCorInput(val: string): void {
    if (val.length === 3 || val.length === 6) {
      setCor(val)
    }
  }

  function addTag(tag: string): void {
    setTagsSel(oldArray => [...oldArray, tag])
  }

  function removeTag(id: string): void {
    setTagsSel(TagsSel.filter(r => r !== id))
  }

  const colors = ['FF6900', 'FCB900', '7BDCB5', '00D084', '8ED1FC', '0693E3', 'ABB8C3', 'EB144C', 'F78DA7', '9900EF']

  return (
    <ModalBase isOpen={isOpen ?? false} onClose={onRequestClose} Titulo='Criação da nova trilha'>
      <Flex padding={'1rem'} gap={'2rem'}>
        <CardColor data-cy="cardGrupo" theme={Cor}><small>{NomeGrupo}</small></CardColor>
        <Flex flexDir={'column'}>
          <FormControl>
            <FormLabel>Nome do grupo</FormLabel>
            <Input
              borderColor={'var(--Gray4)'}
              autoComplete='off'
              type={'text'}
              maxLength={50}
              placeholder='Digite o nome do grupo'
              onChange={(e) => setNomeGrupo(e.target.value)}
            />
            {
              NomeGrupo.length === 50 ? <small>Máximo 50 caracteres</small> : ''
            }
          </FormControl>

          <PaletaDeCor>
          <FormLabel>Selecione uma cor para o card</FormLabel>
            <div>
              {colors.map((e, i) => {
                return (
                  <ColorBox key={i} theme={e} onClick={() => setCor(e)}></ColorBox>
                )
              })}
              <InputColor>
                <div>#</div>
                <input maxLength={6} type={'text'} id='I-PaletaDeCor' placeholder='fff' onChange={(e) => { TrocaCorInput(e.target.value) }} />
              </InputColor>
            </div>
          </PaletaDeCor>
          <TagBox>
            <FlexIcon>
            <FormLabel>Adicionar tags</FormLabel>
              <Tooltip padding='2' borderRadius='4' placement='top' color={'white'} background={'var(--a4)'} label='As tags são utilizadas para facilitar na busca de algum grupo, assim você pode encontrá-los mais facilmente com o texto adicionado na tag' hasArrow arrowSize={15}>
                <div>
                  <FaQuestionCircle />
                </div>
              </Tooltip>
            </FlexIcon>
            <TagContainer
              Selecionadas={TagsSel}
              addTag={addTag}
              removeTag={removeTag}
              isGrey
              tipo={0}
            />
          </TagBox>
          <Flex gap={'1rem'} justifyContent={'end'}>
            <Button VarColor='c4' size='md' onClick={onRequestClose}>Cancelar</Button>
            <Button VarColor='Green2' size='md' onClick={() => onCreate(Cor, NomeGrupo, TagsSel)}>Criar grupo</Button>
          </Flex>
        </Flex>
      </Flex>
    </ModalBase>
  )
}
