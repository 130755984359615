/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Column, FileBox,
  FirstDiv, LabelFile,
  LabelSelecionado, GuideText, BoxIntegracao, VideoBox
} from './styles'
import { FaDownload, FaFolder } from 'react-icons/fa'
import { useEffect, useState } from 'react'
import {
  Flex, FormControl, FormLabel,
  Input, Textarea, useToast
} from '@chakra-ui/react'
import { iImg, iItemIntegracao } from '../../../../../interfaces'
import { appApi } from '../../../../../services/appApi'

export interface iVideoIntroducao {
  tipo: number
  titulo: string
  descricao: string
  arquivo: iImg
}

interface ITipoCardIntegracaoVideo {
  ItemIntegracao: iVideoIntroducao
  onUpdateInput: (item: iVideoIntroducao) => void
}

export const CardVideo: React.FC<ITipoCardIntegracaoVideo> = ({ ItemIntegracao, onUpdateInput }) => {
  const toast = useToast()

  const [Titulo, setTitulo] = useState('')
  const [Descricao, setDescricao] = useState('')

  const [Arquivo, setArquivo] = useState<iImg>({
    name: '',
    size: '',
    uri: ''
  })

  useEffect(() => {
    console.log(ItemIntegracao)
    setDescricao(ItemIntegracao.descricao)
    setTitulo(ItemIntegracao.titulo)
    setArquivo(ItemIntegracao.arquivo)
  }, [ItemIntegracao])

  const AtualizarArquivo = (event: any): void => {
    const formdata = new FormData()
    formdata.append('request', event.target.files[0])

    appApi.post('PrimeiroAcesso/uploadfile', formdata).then(resposnse => {
      onUpdateInput({
        titulo: Titulo,
        descricao: Descricao,
        tipo: ItemIntegracao.tipo,
        arquivo: resposnse.data
      })
      toast({
        title: 'Arquivo cadastrado com sucesso',
        status: 'success',
        isClosable: false,
        position: 'top-right',
        duration: 2000

      })
    }).catch(() => {
      toast({
        title: 'Vídeo excedeu o limite de 25 MB',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 2000
      })
    })
  }

  return (
    <BoxIntegracao>
      <Flex margin={'1rem 0rem 1rem 0rem'}>
        <FirstDiv>
          {
            <FileBox>
              {
                Arquivo.uri.length === 0
                  ? <>
                    <FaDownload color='var(--c7)' size={35} />
                    <GuideText>Arraste e solte o arquivo de vídeo para fazer o envio</GuideText>
                    <small>(MP4 ou MOV)</small>
                    <small>Tamanho limite: 25 MB</small>
                    <input id={'iOne'} type={'file'} onChange={(e) => { AtualizarArquivo(e) }} />
                    <LabelFile theme={'4578D6'} htmlFor={'iOne'}><FaFolder />Selecionar arquivo</LabelFile>
                  </>
                  : <>
                    <Flex marginBottom={'1rem'}>
                      <VideoBox src={Arquivo.uri} />
                      <Column>
                        <span>{Arquivo.name}</span>
                        <small>Tamanho: {Arquivo.size}</small>
                      </Column>
                    </Flex>
                    <div>
                      <input id={'file2'} type={'file'} onChange={(e) => { AtualizarArquivo(e) }} />
                      <LabelSelecionado htmlFor={'file2'}><FaFolder />Selecionar arquivo</LabelSelecionado>
                    </div>
                  </>
              }
            </FileBox>
          }
        </FirstDiv>

        <Flex width={'100%'} height={'100%'} flexDirection={'column'}>
          <FormControl>
            <FormLabel fontSize={'lg'}>Titulo</FormLabel>
            <Input
              value={Titulo}
              borderColor={'var(--Gray4)'}
              onChange={(e) => { setTitulo(e.target.value) }}
              type="text"
              placeholder='Seja muito bem-vindo'
              onBlur={() => onUpdateInput({
                titulo: Titulo,
                descricao: Descricao,
                tipo: ItemIntegracao.tipo,
                arquivo: Arquivo
              })}
            />
          </FormControl>

          <FormControl height={'100%'} marginTop={'1rem'}>
            <FormLabel fontSize={'lg'} >Descrição</FormLabel>
            <Textarea
              fontFamily={'Roboto'}
              borderColor={'var(--Gray4)'}
              height={'12rem'}
              resize={'none'}
              value={Descricao}
              onChange={(e) => { setDescricao(e.target.value) }}
              placeholder='Deixe um texto para aparecer junto o video'
              onBlur={() => onUpdateInput({
                titulo: Titulo,
                descricao: Descricao,
                tipo: ItemIntegracao.tipo,
                arquivo: Arquivo
              })}
            />
          </FormControl>
        </Flex>
      </Flex>
    </BoxIntegracao>
  )
}
