import styled from 'styled-components'

export const NavElipse = styled.div`
    display: flex;
    justify-content: center;
    margin: 1rem 0rem;
    div{
        height: 0.75rem;
        width: 0.75rem;
        border-radius: 0.375rem;
        background-color: var(--c4);
        cursor: pointer;

        &:hover{
            background-color: var(--c7);
            transition: ease .5s all ;
        }
    }

    div:not(:last-child){
        margin-right: 1rem;
    }
`
