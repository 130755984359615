import { Flex } from '@chakra-ui/react'
import { useEffect, useState, useRef } from 'react'
import { FaPlayCircle } from 'react-icons/fa'
import { VscRefresh } from 'react-icons/vsc'
import { ContentVideo, PlayerContent, VideoScreen } from './styles'

interface iPlayerProps {
  playing: boolean
}

interface iPlayerProviderProps {
  playerState: iPlayerProps
  toggleVideoPlay: () => void
}

function usePlayerState(playerRef: any): iPlayerProviderProps {
  const [playerState, setplayerState] = useState({
    playing: false
  })

  useEffect(() => {
    if (playerRef.current !== null) { playerState.playing ? playerRef.current.play() : playerRef.current.pause() }
  }, [playerState.playing])

  function toggleVideoPlay(): void {
    setplayerState({
      ...playerState,
      playing: !playerState.playing
    })
  }

  return {
    playerState, toggleVideoPlay
  }
}

interface iVideoProps {
  url: string
  descricao?: string
  titulo?: string
  tituloPersonalizado?: React.ReactNode
  descricaoPersonalizada?: React.ReactNode
}

export const VideoPlayer: React.FC<iVideoProps> = ({ url, descricao, titulo, tituloPersonalizado, descricaoPersonalizada }) => {
  const [Repeat, setRepeat] = useState(false)
  const $videoPlayer = useRef(null)
  const { playerState, toggleVideoPlay } = usePlayerState($videoPlayer)
  return (
        <Flex flexDir={'column'}>
            <ContentVideo>
                <VideoScreen
                    active={!playerState.playing}
                    controls={playerState.playing}
                    onEnded={() => { toggleVideoPlay(); setRepeat(true) }}
                    onClick={toggleVideoPlay}
                    ref={$videoPlayer} src={url}
                />
                <PlayerContent display={!playerState.playing ? 'flex' : 'none'}>
                    <>
                        {
                            Repeat
                              ? <VscRefresh size={50} onClick={toggleVideoPlay} />
                              : (!playerState.playing) && (
                                    <FaPlayCircle cursor={'pointer'} style={{ marginBottom: '.5rem' }} size={50} onClick={toggleVideoPlay} />
                                )
                        }
                    </>
                    {tituloPersonalizado}
                    {descricaoPersonalizada}

                    {!tituloPersonalizado && titulo && <h2>{titulo}</h2>}
                    {!descricaoPersonalizada && descricao && <span dangerouslySetInnerHTML={{ __html: descricao ?? '' }}></span>}
                </PlayerContent>
            </ContentVideo>
        </Flex>
  )
}
