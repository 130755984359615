import { VideoPlayer } from '../../../../../components/VideoPlayer'
import { Body } from '../../../../Layouts/Body'

interface iVideoProps {
  Titulo?: string
  Descricao?: string
  Url?: string
  isVisible: boolean
}

export const VideoImersao: React.FC<iVideoProps> = ({ Titulo, Descricao, Url, isVisible }) => {
  if (!isVisible) {
    return null
  }
  return (
    <Body>
      <VideoPlayer descricao={Descricao ?? ''} titulo={Titulo ?? ''} url={Url ?? ''}/>
    </Body>
  )
}
