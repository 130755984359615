import styled from 'styled-components'

export const Container = styled.div`
display: flex;
flex-direction: column;
width: fit-content;
button{
    background: var(--v1);
    padding-left: 0.6rem;
    padding-right:0.6rem;
    font-size: 1.25rem;
    border-radius:0.5rem;
    &:hover{
        filter: brightness(1.05);
        box-shadow: var(--SombraBtns);
    }
}
`

export const InputTag = styled.div`
border-radius: 0.625rem;
display: flex;
padding: 0.2rem;
background-color: var(--${props => props.theme});
input[type=text] {
  box-sizing: border-box;
  border: none;
  outline: none;
  width: 100%;
  background-color: var(--${props => props.theme});
}

input[type=text]:focus {
  border: none;
}

input{
    padding: 0.5rem;
}
`

export const MenuDrop = styled.ul`
        display: flex;
        flex-direction: column;
        width: 100%;
        z-index: 1;
        list-style-type: none;
        position: absolute;
        box-shadow: var(--SombraBackground);
        cursor: pointer;
        li{
            background: var(--c1);
            padding: .5rem;
            font-size: .8rem;
            color: var(--a5);
            &:Hover{
                background: var(--c2);
            }
        }

`

export const FlexTags = styled.div`
display: flex;
gap: .8rem .8rem;
flex-wrap: wrap;
margin-bottom: 1rem;
`
