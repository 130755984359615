import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  justify-content: space-between;

  div {
    border-bottom: 6px solid var(--c4);

    padding: 1rem 3rem;
    display: flex;
    flex: 1;
    align-items: center;

    h3 {
      font-size: 1.5rem;
      font-weight: 400;
      color: var(--blue-600);
    }
  }

  aside {
    border-radius: 0.25rem 0.25rem 0 0;
    padding: 1rem;

    background: var(--gray-200);

    input {
      border: 0;
      border-radius: 0.25rem;

      font-size: 1rem;
      padding: 0.5rem;

      & + input {
        margin-left: 0.5rem;
      }
    }
  }
`
