import { FaFolderOpen, FaPlus, FaCheck, FaSearch } from 'react-icons/fa'
import { Body } from '../../Layouts/Body'
import { WhiteBox } from '../../../components/WhiteBox'
import { Competencia } from './Components/Competencia'
import { DropMenu, HeadBox } from './styles'
import { CardCompetencia } from './Components/CardCompetencia'
import { OutlineGreyBox } from '../components/OutlineGreyBox'
import React, { useEffect, useState } from 'react'
import { ModalPreVizualizarQuestao } from './Components/ModalPreVizualizarQuestoes'
import { ModalQuestao } from './Components/ModalQuestao'
import { MetaButton } from '../../../components/Buttons/MetaButton'
import { iCompetencia } from '../../../interfaces'
import { ModalCriarCompetencia } from './Components/ModalCriarCompetencia'
import { ModalTodasCompetencias } from './Components/ModalTodasCompetencias'
import { useFormulario } from '../../../contexts/FormularioContext'
import { useLocation, useNavigate } from 'react-router-dom'
import { Flex, FormLabel, Grid, Input, InputGroup, InputLeftElement, Switch } from '@chakra-ui/react'
import { ModalDelete } from '../../../components/ModalDelete'
import { appApi } from '../../../services/appApi'
import { Button } from '../../../components/Button'

export const FormularioPesquisa: React.FC = () => {
  const path = useLocation().pathname
  const nav = useNavigate()

  const { loadForm, SelectCompetencia, ObterCompetencias, PatchJustificativa, ctxFormulario, ctxCompetencias } = useFormulario()
  const [TxtPesquisa, setTxtPesquisa] = useState('')
  const [PreVisualizarisOpen, setPreVisualizarisOpen] = useState(false)
  const [QuestaoIsOpen, setQuestaoIsOpen] = useState(false)

  const [ModalCompetenciaisOpen, setModalCompetenciaisOpen] = useState(false)
  const [CriarCompetisOpen, setCriarCompetisOpen] = useState(false)

  const [DeleteQuestaoIsOpen, setDeleteQuestaoIsOpen] = useState(false)
  const [DeleteCompetenciaIsOpen, setDeleteCompetenciaIsOpen] = useState(false)

  const [CompetenciaId, setCompetenciaId] = useState<string>()
  const [QuestaoId, setQuestaoId] = useState<string>()
  const [Duplicar, setDuplicar] = useState(false)

  function DeletarQuestao(): void {
    appApi.delete(`Competencia/${CompetenciaId ?? ''}/questao/${QuestaoId ?? ''}`)
      .then(ObterCompetencias)
      .catch(err => console.log(err))
  }

  function DeletarCompetencia(): void {
    appApi.delete(`Competencia/${CompetenciaId ?? ''}`)
      .then(ObterCompetencias)
      .catch(err => console.log(err))
  }

  function onOpenModalEditCompetencia(id: string): void {
    setCompetenciaId(id)
    setCriarCompetisOpen(true)
  }

  function onCloseModalCreateCompetencia(): void {
    setCriarCompetisOpen(false)
    setCompetenciaId(undefined)
  }

  function onOpenModalQuestao(competenciaId: string, questaoId?: string, duplicar?: boolean): void {
    setQuestaoIsOpen(true)
    setCompetenciaId(competenciaId)
    if (questaoId) {
      setQuestaoId(questaoId)
    }

    if (duplicar) {
      setDuplicar(true)
    }
  }

  function onCloseModalQuestao(): void {
    setQuestaoIsOpen(false)
    setCompetenciaId(undefined)
    setQuestaoId(undefined)
    setDuplicar(false)
  }

  function onOpenModalDelete(competenciaId: string, questaoId?: string): void {
    setDeleteQuestaoIsOpen(true)
    setCompetenciaId(competenciaId)
    setQuestaoId(questaoId)
  }

  function onOpendModalDeleteCompetencia(competenciaId: string): void {
    setDeleteCompetenciaIsOpen(true)
    setCompetenciaId(competenciaId)
  }

  function onCloseModalDelete(): void {
    setDeleteQuestaoIsOpen(false)
    setCompetenciaId(undefined)
    setQuestaoId(undefined)
    setDeleteCompetenciaIsOpen(false)
  }

  useEffect(() => {
    ObterCompetencias()
    loadForm()
  }, [])

  useEffect(() => {
    if (ctxFormulario.status === 2) {
      nav(`${path.replace('Formulario', 'VisualizarFormulario')}`)
    }
  }, [ctxFormulario])

  return (
    <Body>

      <ModalDelete
        isOpen={DeleteCompetenciaIsOpen}
        message='Deseja realmente excluir esta competência?'
        onRequestClose={onCloseModalDelete}
        onConfirm={DeletarCompetencia}
      />

      <ModalDelete
        isOpen={DeleteQuestaoIsOpen}
        message='Deseja realmente excluir esta questão?'
        onRequestClose={onCloseModalDelete}
        onConfirm={DeletarQuestao}
      />

      <ModalDelete
        isOpen={DeleteQuestaoIsOpen}
        message='Deseja realmente excluir esta questão?'
        onRequestClose={onCloseModalDelete}
        onConfirm={DeletarQuestao}
      />

      {
        (ModalCompetenciaisOpen) && (
          <ModalTodasCompetencias
            isOpen={ModalCompetenciaisOpen}
            OnCloseModal={() => setModalCompetenciaisOpen(false)}
          />
        )
      }

      <ModalPreVizualizarQuestao
        CompetenciaId={CompetenciaId ?? ''}
        OnCloseModal={() => setPreVisualizarisOpen(false)}
        isOpen={PreVisualizarisOpen}
      />

      <ModalQuestao
        competenciaId={CompetenciaId ?? ''}
        isOpen={QuestaoIsOpen}
        OnCloseModal={onCloseModalQuestao}
        questaoId={QuestaoId}
        duplicar={Duplicar}
      />

      <ModalCriarCompetencia
        OnCloseModal={onCloseModalCreateCompetencia}
        isOpen={CriarCompetisOpen}
        CompetenciaId={CompetenciaId}
      />

      <Flex>
        <Flex flexDir={'column'}>
          <Flex alignItems={'center'} flexWrap={'wrap'} marginBottom={'.5rem'}>
            <h2 style={{ marginRight: '1rem', color: 'var(--a3)' }}
            >Selecione as competências</h2>
          </Flex>
          <span style={{ fontWeight: 'bold', fontSize: '1.25rem', color: 'var(--a3)' }}>Escolha as competências que deseja e selecione as questões relacionadas a elas. Você também pode criar competências personalizadas.</span>
        </Flex>
        <div>
          <InputGroup width={'17rem'}>
            <InputLeftElement
              pointerEvents='none'
              children={<FaSearch color='var(--Gray4)' />}
            />
            <Input
              onChange={(e) => setTxtPesquisa(e.target.value)}
              borderColor={'var(--Gray4)'}
              type='text'
              placeholder='Buscar Competências'
              bg={'white'}
            />
          </InputGroup>
          <DropMenu display={TxtPesquisa.length > 0 ? '' : 'none'}>
            {
              ctxCompetencias.filter(e => !e.discursiva).filter((e: iCompetencia) => {
                if (TxtPesquisa === '') {
                  return e
                } else if (e.nome.toLowerCase().includes(TxtPesquisa.toLocaleLowerCase())) {
                  return e
                }
                return null
              }).map((e: iCompetencia, i) => {
                if (ctxFormulario.selecoes.find(r => r.competenciaId === e.id) === undefined) {
                  return (
                    <li key={i}>
                      <small>{e.nome}</small>
                      <button onClick={() => SelectCompetencia(e.id)}><FaPlus /></button>
                    </li>
                  )
                }
                return (
                  <li key={i}>
                    <small>{e.nome}</small>
                    <div>
                      <FaCheck />
                    </div>
                  </li>
                )
              })
            }
          </DropMenu>
        </div>
      </Flex>

      <WhiteBox margin='1rem 0rem 2rem 1rem'>
        <HeadBox>
          <h3 >Competências mais utilizadas em sua organização</h3>
          <Flex marginBottom={'1rem'} flexWrap={'wrap'}>
            <MetaButton
              onClick={() => setModalCompetenciaisOpen(true)}
              bg='Mostarda' size='md'><FaFolderOpen className='IconLeft'
              />Todas as competências</MetaButton>
            <MetaButton
              onClick={() => setCriarCompetisOpen(true)}
              bg='AzulClaro2' size='md'><FaPlus className='IconLeft'
              />Criar nova competência</MetaButton>
          </Flex>
        </HeadBox>
        <Grid templateColumns={'repeat(7,1fr)'} >
          {
            ctxCompetencias.filter(e => !e.discursiva).map((e: iCompetencia, i) => {
              if (i > 6) return null
              return (
                <CardCompetencia
                  key={i}
                  Competencia={e}
                  Selecionado={ctxFormulario.competencias.find(c => c.id === e.id) !== undefined}
                  Edit={() => { setCompetenciaId(e.id); setCriarCompetisOpen(true) }}
                  onDeleteCompetencia={onOpendModalDeleteCompetencia}
                  onClickPreVisualizar={() => {
                    setCompetenciaId(e.id)
                    setPreVisualizarisOpen(true)
                  }}
                />
              )
            })
          }
        </Grid>
      </WhiteBox>
      {
        (ctxFormulario.competencias.filter(e => !e.discursiva).length > 0) && (
          <>
            <Flex alignItems={'center'} justifyContent={'space-between'}>
              <h2 style={{ color: 'var(--a3)' }}>Questões selecionadas</h2>
              <Flex gap={'.5rem'} alignItems={'center'}>
                <FormLabel margin={0}>Comentários nas questões</FormLabel>
                <Switch isChecked={ctxFormulario.justificativa} onChange={() => PatchJustificativa(ctxFormulario.id)} size={'lg'}/>
              </Flex>
            </Flex>
            <OutlineGreyBox styles={{ marginBottom: '2rem', marginTop: '1rem' }}>
              {
                ctxCompetencias.filter(e => !e.discursiva && ctxFormulario.competencias.find(r => r.id === e.id)).map((e: iCompetencia, i) => {
                  return (
                    <Competencia
                      key={i}
                      competencia={e}
                      onModalQuestao={onOpenModalQuestao}
                      onOpenModalDelete={onOpenModalDelete}
                      Edit={() => onOpenModalEditCompetencia(e.id)}
                    />
                  )
                })
              }
            </OutlineGreyBox>
          </>
        )
      }

      <>
        <h2 style={{ color: 'var(--a3)', marginBottom: '.5rem' }}>Questões discursivas</h2>
        <Flex padding={'0rem 1.5rem 0rem 1.5rem'}>
          {
            ctxCompetencias.filter(e => e.discursiva).map((e: iCompetencia, i) => {
              return (
                <Competencia
                  key={i}
                  competencia={e}
                  onOpenModalDelete={onOpenModalDelete}
                  onModalQuestao={onOpenModalQuestao}
                  Edit={() => { }}
                />
              )
            })
          }
        </Flex>
      </>

      <Flex justifyContent={'end'} marginTop={'1rem'} gap={'1rem'}>
        <Button VarColor='c6' size='lg' onClick={() => nav(-1)}>Voltar</Button>
        <Button
          VarColor='v2'
          size='lg'
          isDisabled={!ctxFormulario.selecoes.filter(r => r.competenciaId !== ctxCompetencias.find(t => t.discursiva)?.id).some(r => r.questoesId.length > 0)}
          onClick={() => nav(`${path.replace('Formulario', 'VisualizarFormulario')}`)}
        >Visualizar formulário</Button>
      </Flex>

    </Body>
  )
}
