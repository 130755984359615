import { Flex, FormControl, FormLabel, Image, Select } from '@chakra-ui/react'
import { Button } from '../../components/Button'
import { WhiteBox } from '../../components/WhiteBox'
import { Body } from '../Layouts/Body'
import { BoxMensagem } from './Components/BoxMensagem'
import { useState, useEffect } from 'react'
import { appApi } from '../../services/appApi'
import { ModalChamadoRh } from '../Colaborador/Perfil/Components/ModalChamadoRh'

interface Chamado {
  id: string
  dataCriacao: string
  texto: string
  status: number
  atualizacoes: Atualizacao[]
  colaborador: Colaborador
}

interface iModel {
  responsavel: boolean
  chamados: Chamado[]
}

interface Atualizacao {
  colaborador: Colaborador
  resposta: string
  dataCriacao: string
  rh: true
}

interface Colaborador {
  id: string
  nome: string
  avatar: string
  nomeCargo: string
}

export const ChamadoRh: React.FC = () => {
  const [ModalChamadoIsOpen, setModalChamadoIsOpen] = useState(false)
  const [Model, setModel] = useState<iModel>({
    chamados: [],
    responsavel: false
  })
  const [Status, setStatus] = useState('')

  function getChamados(): void {
    appApi.get('Chamado')
      .then(res => {
        if (res.status === 200) {
          setModel(res.data)
        }
      })
      .catch(err => console.log(err))
  }

  function PostChamado(texto: string): void {
    const form = {
      texto: texto
    }
    appApi.post('Chamado', form)
      .then(res => {
        setModalChamadoIsOpen(false)
        getChamados()
      }
      ).catch(err => console.log(err))
  }

  // function FilterBy(tipo: string): void {
  //   const arr = [...Model.chamados]

  //   if (tipo === '0') {
  //     arr.sort(function (a, b) {
  //       return new Date(converterData(b.dataCriacao)) > new Date(converterData(a.dataCriacao)) ? 1 : -1
  //     })
  //   } else if (tipo === '1') {
  //     console.log('Aqui')
  //     arr.sort(function (a, b) {
  //       return new Date(converterData(b.dataCriacao)) > new Date(converterData(a.dataCriacao)) ? 1 : -1
  //     })
  //     arr.reverse()
  //   }
  //   console.log()
  //   setModel({ ...Model, chamados: arr })
  // }

  // function converterData(val: string): Date {
  //   const [day, month, year] = val.split('/')
  //   const date = new Date(+year, +month - 1, +day)
  //   return date
  // }

  useEffect(() => {
    getChamados()
  }, [])
  return (
    <Body>
      <ModalChamadoRh
        isOpen={ModalChamadoIsOpen}
        onConfirm={PostChamado}
        onRequestClose={() => setModalChamadoIsOpen(false)}
      />
      <WhiteBox>
        <Flex justifyContent={'space-between'}>
          <h1>Histórico</h1>
          {
            (!Model.responsavel && Model.chamados.find(e => e.status === 0) === undefined) && (
              <Button
                onClick={() => setModalChamadoIsOpen(true)}
                VarColor='Azul'
              >Abrir chamado com RH</Button>
            )
          }
        </Flex>
        <Flex marginTop={'1rem'} gap={'1rem'}>
              {/* <FormControl width={'30%'} >
                <FormLabel fontSize={'xl'} color={'var(--Gray1)'}>Ordenação</FormLabel>
                <Select borderColor={'var(--Gray4)'} size='md' onChange={(e) => { FilterBy(e.target.value) }}>
                  <option value={'0'}>Mais recente</option>
                  <option value={'1'}>Mais antigo</option>
                </Select>
              </FormControl> */}

              <FormControl width={'30%'} >
                <FormLabel fontSize={'xl'} color={'var(--Gray1)'}>Status</FormLabel>
                <Select onChange={(e) => setStatus(e.target.value)} borderColor={'var(--Gray4)'} size='md'>
                  <option value={''}>Nenhum</option>
                  <option value={'0'}>Em andamento</option>
                  <option value={'1'}>Finalizado</option>
                </Select>
              </FormControl>
        </Flex>
        {
          (Model && Model?.chamados.length > 0) && (
            <Flex marginTop={'1rem'} flexDir={'column'} gap='2rem'>
              {
                Model?.chamados.filter(e => (e.status === parseInt(Status)) || Status === '').map((e, i) => {
                  return (
                    <BoxMensagem
                      reload={getChamados}
                      responsavel={Model.responsavel}
                      chamado={e}
                      key={i}
                    />
                  )
                })
              }
            </Flex>
          )
        }

        {
          (Model && Model?.chamados.length === 0) && (
            <Flex alignItems={'center'} color={'var(--Azul)'} justifyContent={'center'}>
              <Image className='sah_Img' height={'20rem'} src='https://sigah.blob.core.windows.net/onboard/bbb6990f-d313-488a-af99-5383c9bd1c82.png' />
              <h3>Ainda não há chamados cadastrados</h3>
            </Flex>
          )
        }
      </WhiteBox>
    </Body>
  )
}
