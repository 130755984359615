import { BrowserRouter as Router, Routes, Route, Outlet, Navigate } from 'react-router-dom'
import { ConfigurarTrilha } from './pages/RH/ConfigurarTrilha'
import { ModifyIntegracao } from './pages/RH/Integracao/Modify'
import { Reuniao } from './pages/RH/Reuniao/Criar'
import { TrilhaOnboard } from './pages/RH/Trilhas'
import { ConfigIntegracao } from './pages/RH/Integracao/Config'
import { ConfigImersao } from './pages/RH/Imersao/Config'
import { ModifyImersao } from './pages/RH/Imersao/Modify'
import { PrimeroAcesso } from './pages/RH/PrimeiroAcesso'
import { FormularioPesquisa } from './pages/PesquisaPercepcao/FormularioPesquisa'
import { VisualizarFormulario } from './pages/PesquisaPercepcao/VisualizarFormulario'
import { PesquisaParticipantes } from './pages/PesquisaPercepcao/Participantes'
import { EnviarPesquisa } from './pages/PesquisaPercepcao/EnviarPesquisa'
import { ResumoPesquisaPercepcao } from './pages/PesquisaPercepcao/Resumo'
import { GestaoPesquisas } from './pages/PesquisaPercepcao/GestaoPesquisas'
import { ResultadoPesquisaPercepcao } from './pages/PesquisaPercepcao/Resultado'
import { ResultadoIndividual } from './pages/PesquisaPercepcao/ResultadoIndividual'
import { CriarPesquisa } from './pages/PesquisaPercepcao/CriarPesquisa'
import { Formularios as FormulariosPercepcao } from './pages/PesquisaPercepcao/Formularios'
import { Painel } from './pages/Colaborador/Perfil'
import { ImersaoColaborador } from './pages/Colaborador/Imersao'
import { IntegracaoColaborador } from './pages/Colaborador/Integracao'
import { PerguntasFrequentesColab } from './pages/Colaborador/PerguntasFrequentes'
import { PerguntasFrequentesRh } from './pages/RH/PerguntasFrequentes'
import { CargoDesenvolvimento } from './pages/Organograma/Desenvolvimento/Cargos'
import { ColaboradoresDesenvolvimento } from './pages/Organograma/Desenvolvimento/Colaboradores'
import { DashboardDesenvolvimento } from './pages/Organograma/Desenvolvimento/Dashboard'
import { DepartamentoDesenvolvimento } from './pages/Organograma/Desenvolvimento/Departamentos'
import { GestaoFuncionarios } from './pages/RH/GestaoOnboard'
import { VisulaizarTrilhaColaborador } from './pages/RH/VisualizarTrilhaColaborador'
import { TurnOver } from './pages/RH/TurnOver'
import { EngajamentoDashboard } from './pages/Engajamento/Dashboard'
import { EngajamentoeNps } from './pages/Engajamento/eNps'
import { NavBar } from './components/NavBar'
import { AberturaPesquisa } from './pages/PesquisaPercepcao/Abertura'
import { RespostaPesquisa } from './pages/PesquisaPercepcao/RespostaPesquisa'
import { Login } from './pages/Login'
import { AlterarSenha } from './pages/Usuario/AlterarSenha'
import { ConfigNps } from './pages/Engajamento/ConfigNps/indes'
import { RespostaNps } from './pages/Engajamento/RespostaNps'
import { GestaoImersao } from './pages/Imersao/Gestao'
import { CriarImersao } from './pages/Imersao/Editar'
import { GestaoConvitesImersao } from './pages/Imersao/Convites'
import { PrevisualizarImersao } from './pages/Imersao/Previsualizar/Todos'
import { PrevisualizarImersaoIndividual } from './pages/Imersao/Previsualizar/Individual'
import { VisualizarImersao } from './pages/Imersao/Visualizar'
import { GestaoEnpsJornada } from './pages/Jornada/eNps/Gestao'
import { ResultadoEnpsJornada } from './pages/Jornada/eNps/Resultado'
import { GestaoPesquisaInternaJornada } from './pages/Jornada/PesquisaInterna/Gestao'
import { CriarPesquisaInterna } from './pages/Jornada/PesquisaInterna/Criar'
import { FormularioPesquisaInterna } from './pages/Jornada/PesquisaInterna/Formulario'
import { AprovacaoPesquisaInterna } from './pages/Jornada/PesquisaInterna/Aprovacao'
import { ConfiguracaoPesquisaInterna } from './pages/Jornada/PesquisaInterna/Configuracao'
import { ConvitesPesquisaInterna } from './pages/Jornada/PesquisaInterna/Convites'
import { DashboardReuniaoOne } from './pages/Jornada/ReuniaoOne/Dashboard'
import { ConfigReuniaoOne } from './pages/Jornada/ReuniaoOne/Configuracao'
import { AberturaPesquisaInterna } from './pages/Jornada/PesquisaInterna/Abertura'
import { PreenchimentoPesquisaInterna } from './pages/Jornada/PesquisaInterna/Preenchimento'
import { AvaliacaoPDI } from './pages/PDI/Avaliacao'
import { ResultadoGeral } from './pages/PesquisaPercepcao/ResultadoGeral'
import { DashboardJornada } from './pages/Jornada/Dashboard'
import { DashboardDesempenho } from './pages/Desempenho/Dashboard'
import { ChamadoRh } from './pages/ChamadoRH'
import { ResultadoPesquisaInterna } from './pages/Jornada/PesquisaInterna/Resultado'
import { LogOut } from './pages/Login/LogOut'
import { Privacidade } from './pages/RH/Privacidade'
import { GerenciarPermissoes } from './pages/Usuario/Empresa/GerenciarPermissoes'
import { ConfiguracaoReuniao } from './pages/RH/Reuniao/Configuracao'
import { ConfiguracaoEmpresa } from './pages/Usuario/Empresa/Configuracao'
import { CriarPDI } from './pages/PDI/Criar'
import { DashboardCorporativoPDI } from './pages/PDI/Dashboard/Corporativo'
import { DashboardIndividualPDI } from './pages/PDI/Dashboard/Individual'
import { EsqueciSenha } from './pages/Login/EsqueciSenha'
import { DetalhesCargo } from './pages/AssessmentPI'
import { DashBoardJobTarget } from './pages/AssessmentPI/JobTarget'
import { JobTargetCreate } from './pages/AssessmentPI/JobTarget/Create'
import { isAuth } from './services/token'
import { CriarEnpsJornada } from './pages/Jornada/eNps/Criar'
import { FormularioProvider } from './contexts/FormularioContext'
import { AgendamentoReuniaoOne } from './pages/Jornada/ReuniaoOne/Agendamento'
import { CentralComando } from './pages/CentralComando'
import { CriarEmpresa } from './pages/CentralComando/CriarEmpresa'
import { EtapaPDI } from './pages/PDI/Etapa'

export function AppRoutes(): any {
  const Rotas = (): any => {
    if (isAuth()) {
      return <Outlet />
    }
    return <Navigate to={'/'} />
  }

  return (
    <Router>
      <NavBar />
      <Routes>
        <Route path='/LogOut' element={<LogOut />} />

        <Route element={<Rotas />}>
          <Route path='Usuario'>
            <Route path='Permissoes' element={<GerenciarPermissoes />} />
            <Route path='AlterarSenha' element={<AlterarSenha />} />
            <Route path='Empresa' element={<ConfiguracaoEmpresa />} />
            <Route path='ChamadoRh' element={<ChamadoRh />} />
            <Route path='PerguntasFrequentes/Editar' element={<PerguntasFrequentesRh />} />
            <Route path='PerguntasFrequentes' element={<PerguntasFrequentesColab />} />
          </Route>

          <Route path='Rh'>
            <Route path='PesquisaPercepcao'>
              <Route path='Gestao' element={<GestaoPesquisas />} />
              <Route path='Criar' element={<CriarPesquisa />}>
                <Route path='Trilha/:trilhaId' element={<CriarPesquisa />} />
                <Route path='Processo/:processoId/:colaboradorId' element={<CriarPesquisa />} />
              </Route>

              <Route path='Editar' element={<CriarPesquisa />}>
                <Route path=':etapaId/Trilha/:trilhaId' element={<CriarPesquisa />} />
                <Route path=':etapaId/Processo/:processoId' element={<CriarPesquisa />} />
              </Route>

              <Route path='Formularios/:pesquisaId' element={<FormulariosPercepcao />}>
                <Route path='Trilha/:trilhaId' element={<FormulariosPercepcao />} />
                <Route path='Processo/:processoId' element={<FormulariosPercepcao />} />
              </Route>

              <Route path='Formulario/:pesquisaId'
                element={
                  <FormularioProvider>
                    <FormularioPesquisa />
                  </FormularioProvider>
                }
              >
                <Route path='Trilha/:trilhaId'
                  element={
                    <FormularioProvider>
                      <FormularioPesquisa />
                    </FormularioProvider>
                  }
                />

                <Route path='Processo/:processoId'
                  element={
                    <FormularioProvider>
                      <FormularioPesquisa />
                    </FormularioProvider>
                  }
                />
              </Route>

              <Route path='VisualizarFormulario/:pesquisaId'>
                <Route path='' element={<VisualizarFormulario />}/>
                <Route path='Trilha/:trilhaId' element={<VisualizarFormulario />} />
                <Route path='Processo/:processoId' element={<VisualizarFormulario />} />
                <Route path='Previsualizar' element={<RespostaPesquisa />} />
              </Route>

              <Route path='Participantes/:pesquisaId' element={<PesquisaParticipantes />}>
                <Route path='Editar' element={<PesquisaParticipantes />} />
                <Route path='Processo' element={<CriarPesquisa />} />
              </Route>

              <Route path='EnviarPesquisa/:pesquisaId' element={<EnviarPesquisa />} >
                <Route path='Editar' element={<EnviarPesquisa />} />
                <Route path='Processo/Editar' element={<EnviarPesquisa />} />
                <Route path='Trilha/:trilhaId' element={<EnviarPesquisa />} />
                <Route path='Processo/:processoId' element={<EnviarPesquisa />} />
              </Route>

              <Route path='ResumoPesquisa/:pesquisaId' element={<ResumoPesquisaPercepcao />} />

              <Route path='ResultadoPesquisa/:pesquisaId' element={<ResultadoPesquisaPercepcao />} />
              <Route path='ResultadoIndividual/:avaliacaoId' element={<ResultadoIndividual />} />
              <Route path='ResultadoGeral/:pesquisaId' element={<ResultadoGeral />} />
            </Route>

            <Route path='AnaliseDesempenho'>
              <Route path='Gestao' element={<GestaoPesquisas />} />
              <Route path='Criar' element={<CriarPesquisa />} />
              <Route path='Formularios/:pesquisaId' element={<FormulariosPercepcao />} />
              <Route path='Formulario/:pesquisaId'
                element={
                  <FormularioProvider>
                    <FormularioPesquisa />
                  </FormularioProvider>
                }
              />

              <Route path='VisualizarFormulario/:pesquisaId'>
                <Route path='' element={<VisualizarFormulario />}/>
                <Route path='Previsualizar' element={<RespostaPesquisa />} />
              </Route>
              <Route path='Participantes/:pesquisaId' element={<PesquisaParticipantes />}>
                <Route path='Editar' element={<PesquisaParticipantes />} />
              </Route>
              <Route path='EnviarPesquisa/:pesquisaId' element={<EnviarPesquisa />} >
                <Route path='Editar' element={<EnviarPesquisa />} />
              </Route>
              <Route path='ResumoPesquisa/:pesquisaId' element={<ResumoPesquisaPercepcao />} />
              <Route path='ResultadoPesquisa/:pesquisaId' element={<ResultadoPesquisaPercepcao />} />
              <Route path='ResultadoIndividual/:avaliacaoId' element={<ResultadoIndividual />} />
              <Route path='ResultadoGeral/:pesquisaId' element={<ResultadoGeral />} />
            </Route>

            <Route path='VisualizarPerfil/:colaboradorId' element={<VisulaizarTrilhaColaborador />} />
          </Route>

          <Route path='Organograma'>
            <Route path='Cargos' element={<CargoDesenvolvimento />} />
            <Route path='Colaboradores' element={<ColaboradoresDesenvolvimento />} />
            <Route path='Dashboard' element={<DashboardDesenvolvimento />} />
            <Route path='Departamentos' element={<DepartamentoDesenvolvimento />} />
          </Route>

          <Route path='Engajamento'>
            <Route path='' element={<EngajamentoDashboard />} />
            <Route path='eNPS' element={<EngajamentoeNps />} />
            <Route path='ConfigNps' element={<ConfigNps />} />
          </Route>

          <Route path='Jornada'>
            <Route path='PesquisaInterna'>
              <Route path='Gestao' element={<GestaoPesquisaInternaJornada />} />
              <Route path='Criar' element={<CriarPesquisaInterna />} />
              <Route path='Resultado/:pesquisaId' element={<ResultadoPesquisaInterna />} />
              <Route path='Formulario/:pesquisaId' element={<FormularioPesquisaInterna />} />
              <Route path='Aprovacao/:pesquisaId' element={<AprovacaoPesquisaInterna />} />
              <Route path='Configuracao/:pesquisaId' element={<ConfiguracaoPesquisaInterna />} />
              <Route path='Convites/:pesquisaId' element={<ConvitesPesquisaInterna />} />
              <Route path='Abertura/:paramId/Previsualizar' element={<AberturaPesquisaInterna />} />
            </Route>

            <Route path='Imersao'>
              <Route path='' element={<GestaoImersao />} />

              <Route path=':imersaoId'>
                <Route path='' element={<CriarImersao />} />
                <Route path='Convites' element={<GestaoConvitesImersao />} />
                <Route path='Previsualizar' element={<PrevisualizarImersao />} />
                <Route path='Item/:itemId' element={<PrevisualizarImersaoIndividual />} />
              </Route>
            </Route>

            <Route path='Configuracao'>
              <Route path='' element={<TrilhaOnboard />} />
              <Route path='Trilha'>
                <Route path=':trilhaId' element={<ConfigurarTrilha />} />

                <Route path=':trilhaId/Reuniao'>
                  <Route path='' element={<Reuniao />} />
                  <Route path='Editar/:etapaId' element={<Reuniao />} />
                </Route>

                <Route path=':trilhaId/Imersao'>
                  <Route path='' element={<ConfigImersao />} />

                  <Route path=':imersaoId'>
                    <Route path='' element={<ModifyImersao />} />
                    <Route path='Previsualizar' element={<PrevisualizarImersao />} />

                    <Route path='Editar/:etapaId'>
                      <Route path='' element={<ModifyImersao />} />
                      <Route path='Previsualizar' element={<PrevisualizarImersao />} />
                      <Route path='Item/:itemId' element={<PrevisualizarImersaoIndividual />} />
                    </Route>

                    <Route path='Item/:itemId' element={<PrevisualizarImersaoIndividual />} />
                  </Route>
                </Route>

                <Route path=':trilhaId/Integracao'>
                  <Route path='' element={<ConfigIntegracao />} />

                  <Route path=':imersaoId'>
                    <Route path='Previsualizar' element={<PrevisualizarImersao />} />
                    <Route path='Item/:itemId' element={<PrevisualizarImersaoIndividual />} />
                    <Route path='' element={<ModifyIntegracao />} />

                    <Route path='Editar/:etapaId'>
                      <Route path='' element={<ModifyIntegracao />} />
                      <Route path='Previsualizar' element={<PrevisualizarImersao />} />
                      <Route path='Item/:itemId' element={<PrevisualizarImersaoIndividual />} />
                    </Route>

                  </Route>
                </Route>

                <Route path=':id/eNps'>
                  <Route path='Criar' element={<CriarEnpsJornada />} />
                  <Route path='Editar/:etapaId' element={<CriarEnpsJornada />} />
                </Route>

                <Route path=':trilhaId/PesquisaInterna'>
                  <Route path='Gestao' element={<GestaoPesquisaInternaJornada />} />
                  <Route path='Criar' element={<CriarPesquisaInterna />} />
                  <Route path='Editar/:etapaId' element={<CriarPesquisaInterna />} />
                  <Route path='Formulario/:pesquisaId' element={<FormularioPesquisaInterna />} />
                  <Route path='Aprovacao/:pesquisaId' element={<AprovacaoPesquisaInterna />} />
                  <Route path='Configuracao/:pesquisaId' element={<ConfiguracaoPesquisaInterna />} />
                </Route>

                <Route path=':trilhaId/ReuniaoOne/Configuracao'>
                  <Route path='' element={<ConfigReuniaoOne />}>
                    <Route path=':etapaId' element={<ConfigReuniaoOne />} />
                  </Route>
                </Route>

                <Route path=':trilhaId/PDI'>
                  <Route path='' element={<EtapaPDI />} />
                  <Route path=':etapaId' element={<EtapaPDI />} />
                </Route>
              </Route>
            </Route>

            <Route path='Processo'>
              <Route path=':processoId/Reuniao'>
                <Route path='' element={<Reuniao />} />
                <Route path='Editar/:etapaId' element={<Reuniao />} />
              </Route>

              <Route path=':processoId/Imersao'>
                <Route path='' element={<ConfigImersao />} />

                <Route path=':imersaoId'>
                  <Route path='Previsualizar' element={<PrevisualizarImersao />} />
                  <Route path='' element={<ModifyImersao />} />

                  <Route path='Editar/:etapaId'>
                    <Route path='' element={<ModifyImersao />} />
                    <Route path='Previsualizar' element={<PrevisualizarImersao />} />
                    <Route path='Item/:itemId' element={<PrevisualizarImersaoIndividual />} />
                  </Route>

                  <Route path='Item/:itemId' element={<PrevisualizarImersaoIndividual />} />
                </Route>
              </Route>

              <Route path=':processoId/Integracao'>
                <Route path='' element={<ConfigIntegracao />} />

                <Route path=':imersaoId'>
                  <Route path='Previsualizar' element={<PrevisualizarImersao />} />
                  <Route path='Item/:itemId' element={<PrevisualizarImersaoIndividual />} />
                  <Route path='' element={<ModifyIntegracao />} />

                  <Route path='Editar/:etapaId'>
                    <Route path='' element={<ModifyIntegracao />} />
                    <Route path='Previsualizar' element={<PrevisualizarImersao />} />
                    <Route path='Item/:itemId' element={<PrevisualizarImersaoIndividual />} />
                  </Route>

                </Route>
              </Route>

              <Route path=':id/eNps'>
                <Route path='Criar' element={<CriarEnpsJornada />} />
                <Route path='Editar/:etapaId' element={<CriarEnpsJornada />} />
              </Route>

              <Route path=':processoId/PesquisaInterna'>
                <Route path='Gestao' element={<GestaoPesquisaInternaJornada />} />
                <Route path='Criar' element={<CriarPesquisaInterna />} />
                <Route path='Editar/:etapaId' element={<CriarPesquisaInterna />} />
                <Route path='Formulario/:pesquisaId' element={<FormularioPesquisaInterna />} />
                <Route path='Aprovacao/:pesquisaId' element={<AprovacaoPesquisaInterna />} />
                <Route path='Configuracao/:pesquisaId' element={<ConfiguracaoPesquisaInterna />} />
              </Route>

              <Route path=':processoId/ReuniaoOne/Configuracao'>
                <Route path='' element={<ConfigReuniaoOne />}>
                  <Route path=':etapaId' element={<ConfigReuniaoOne />} />
                </Route>
              </Route>

              <Route path=':processoId/PDI'>
                <Route path='' element={<EtapaPDI />} />
                <Route path=':etapaId' element={<EtapaPDI />} />
              </Route>
            </Route>

            <Route path='eNps'>
              <Route path='' element={<GestaoEnpsJornada />} />
              <Route path='Resultado/:pesquisaId' element={<ResultadoEnpsJornada />} />
            </Route>

            <Route path='Onboard' element={<GestaoFuncionarios />} />
            <Route path='TurnOver' element={<TurnOver />} />
            <Route path='Dashboard' element={<DashboardJornada />} />

            <Route path='ReuniaoOne'>
              <Route path='' element={<DashboardReuniaoOne />} />
              <Route path='Configuracao' element={<ConfigReuniaoOne />}>
                <Route path=':reuniaoId' />
                <Route path=':reuniaoId/Remarcar' />
              </Route>
            </Route>
          </Route>

          <Route path='Desempenho'>
            <Route path='ReuniaoOne' element={<DashboardReuniaoOne />} />
            <Route path='ReuniaoOne/Configuracao' element={<ConfigReuniaoOne />}>
              <Route path=':reuniaoId' />
              <Route path=':reuniaoId/Remarcar' />
            </Route>

            <Route path='Dashboard' element={<DashboardDesempenho />} />

            <Route path='PDI'>
              <Route path='Dashboard/Corporativo' element={<DashboardCorporativoPDI />} />
              <Route path='Dashboard/Individual' element={<DashboardIndividualPDI />} />
              <Route path='Criar' element={<CriarPDI />} />
              <Route path='Onboard/:pdiId' element={<CriarPDI />} />
              <Route path='Editar/:pdiId' element={<CriarPDI />} />
              <Route path='Avaliacao/:pdiId' element={<AvaliacaoPDI />} />
            </Route>
          </Route>

          <Route path='AssessmentPI'>
            <Route path='Dashboard' element={<DashBoardJobTarget />} />
            <Route path='JobTarget' element={<DetalhesCargo />} />
            <Route path='JobTarget/:jobId' element={<DetalhesCargo />} />
            <Route path='JobTarget/Create' element={<JobTargetCreate />} />
            <Route path='JobTarget/Edit/:jobId' element={<JobTargetCreate />} />
          </Route>
          <Route path='CentralComando'>
            <Route path='' element={<CentralComando />} />
            <Route path='Dashboard' element={<CentralComando />} />
            <Route path='Criar' element={<CriarEmpresa />} />
            <Route path='Editar/:empresaId' element={<CriarEmpresa />} />
          </Route>

          <Route path='PrimeiroAcesso' element={<PrimeroAcesso />} />

          <Route path='Colaborador' element={<Painel />} />
          <Route path='Colaborador/Imersao/:interacaoId/Etapa/:etapaId' element={<ImersaoColaborador />} />
          <Route path='Colaborador/Integracao/:interacaoId/Etapa/:etapaId' element={<IntegracaoColaborador />} />
        </Route>

        <Route path='/' element={<Login />} />
        <Route path='Abertura/:tokenId' element={<AberturaPesquisa />} />
        <Route path='RespostaNps/:conviteId' element={<RespostaNps />} />
        <Route path='RespostaPesquisa' element={<RespostaPesquisa />} />
        <Route path='VisualizarImersao/:conviteId' element={<VisualizarImersao />} />
        <Route path='Processo/:processoId/ReuniaoOne/:etapaId/Agendar' element={<AgendamentoReuniaoOne />} />
        <Route path='Rh/Reuniao/Config/:reuniaoId/:processoId' element={<ConfiguracaoReuniao />} />
        <Route path='Usuario/EsqueciSenha' element={<EsqueciSenha />} />
        <Route path='Privacidade' element={<Privacidade />} />
        <Route path='PI/Preenchimento/:paramId' element={<PreenchimentoPesquisaInterna />} />
        <Route path='PI/Abertura/:paramId' element={<AberturaPesquisaInterna />}></Route>
      </Routes>
    </Router>
  )
}
