import { Avatar, Flex, Grid, Img } from '@chakra-ui/react'
import { FaChartBar, FaCheckCircle, FaClipboardList, FaCog, FaRegPlayCircle, FaRegStopCircle, FaRegTimesCircle, FaEye, FaEyeSlash } from 'react-icons/fa'
import { useLocation, useNavigate } from 'react-router-dom'
import { Button } from '../../../../../components/Button'
import { Container, ColumnText, RadiusAmarelo } from './styles'
import IAvaliadores from '../../../../../assets/IAvaliadores.svg'
import { parseJwt } from '../../../../../services/token'
import { PropInvalida } from '../../../../../Utils/Helper'
import { iAvaliado } from '../..'

interface iCardProps {
  avaliado: iAvaliado
  pesquisaId: string
  avaliacaoAnterior?: iAvaliado
  proximaAvaliacao?: iAvaliado
  interromperPesquisa?: boolean
  onEncerrar: (id: string) => void
  onReabrir: (id: string) => void
  onVisibilidade: (id: string, status: boolean) => void
  openConfiguracao?: (avaliado: iAvaliado) => void
}

export const CardAvaliado: React.FC<iCardProps> = ({ avaliado, pesquisaId, avaliacaoAnterior, proximaAvaliacao, interromperPesquisa, openConfiguracao, onEncerrar, onReabrir, onVisibilidade }) => {
  const nav = useNavigate()
  const { pathname } = useLocation()
  const interrompidaSemResposta = ((avaliado?.interrompida && !avaliado?.totalRespostas))

  function VerificarBloqueio(): boolean {
    if (!PropInvalida(avaliado.dataInicio) && avaliado.encerrada) { // Requisitos necessarios
      if (PropInvalida(avaliacaoAnterior)) {
        if (PropInvalida(proximaAvaliacao) || !proximaAvaliacao?.iniciada) {
          return true
        }
      } else {
        if (avaliacaoAnterior?.encerrada) {
          return true
        }
      }
    }
    return false
  }
  return (
        <Container>
            <RadiusAmarelo />
            <Flex width={'100%'} justifyContent={'space-between'} opacity={interrompidaSemResposta ? '50%' : 1} cursor={interrompidaSemResposta ? 'not-allowed' : 'pointer'}>
                <Flex gap={'1rem'}>
                    {
                        (PropInvalida(avaliado.dataFim)) && (
                            <Flex alignItems={'center'}>
                                <Avatar marginRight='.5rem' name={avaliado.colaborador.nome} src={avaliado.colaborador.avatar} />
                                <ColumnText>
                                    <h4 style={{ width: '10rem' }}>{avaliado.colaborador.nome}</h4>
                                    {
                                        (avaliado.colaborador.nomeDepartamento !== null) && (
                                            <span title={avaliado.colaborador?.nomeDepartamento}>{avaliado.colaborador.nomeDepartamento?.substring(0, 18)}</span>
                                        )
                                    }
                                </ColumnText>
                            </Flex>
                        )
                    }
                    <Grid gap={'2rem'} templateColumns='repeat(4, 1fr)'>

                        {
                            (!PropInvalida(avaliado.dataFim)) && (
                                <ColumnText>
                                    <span>Início: {avaliado.dataInicio}</span>
                                    <span>Fim: {avaliado.dataFim}</span>
                                </ColumnText>
                            )
                        }

                        <Flex alignItems={'center'}>
                            <Img width={'40px'} height={'40px'} src={IAvaliadores} />
                            <ColumnText>
                                <h3>{avaliado.totalAvaliadores}</h3>
                                <span>Avaliadores</span>
                            </ColumnText>
                        </Flex>

                        <Flex alignItems={'center'}>
                            <FaClipboardList size={35} style={{ marginRight: '.5rem', color: 'var(--a5)' }} />
                            <ColumnText>
                                <h3>{avaliado.totalRespostas}</h3>
                                <span>Respostas</span>
                            </ColumnText>
                        </Flex>

                        {
                            (avaliado.autoavaliacao !== null || avaliado.gestor !== null) && (
                                <Flex justifyContent={'center'} alignItems={'start'} flexDir={'column'}>
                                    {
                                        (avaliado.autoavaliacao !== null) && (
                                            <Flex marginBottom={'.3rem'} alignItems='center'>
                                                {(avaliado.autoavaliacao) && <FaCheckCircle color='var(--v2)' style={{ marginRight: '.3rem' }} />}
                                                {!avaliado.autoavaliacao && <FaRegTimesCircle color='var(--terc3)' style={{ marginRight: '.3rem' }} />}
                                                <small>Autoavaliação</small>
                                            </Flex>
                                        )
                                    }
                                    {
                                        (avaliado.gestor !== null) && (
                                            <Flex marginBottom={'.3rem'} alignItems='center'>
                                                {avaliado.gestor && <FaCheckCircle color='var(--v2)' style={{ marginRight: '.3rem' }} />}
                                                {!avaliado.gestor && <FaRegTimesCircle color='var(--terc3)' style={{ marginRight: '.3rem' }} />}
                                                <small>Gestor</small>
                                            </Flex>
                                        )
                                    }
                                </Flex>
                            )
                        }
                    </Grid>
                </Flex>

                <Flex alignItems={'center'} paddingRight='1rem' gap={'.5rem'}>
                    {
                        (avaliado.encerrada && avaliado.liberarResultado === 0 && (parseJwt().adm === 'True' || parseJwt().cid === avaliado.colaborador.gestorId)) && (
                            <Button
                                borderRadius={'.5rem'}
                                VarColor='Orange'
                                size={'sm'}
                                leftIcon={<FaEyeSlash />}
                                onClick={() => onVisibilidade(avaliado.id, false)}
                                disabled={!avaliado.totalRespostas}
                            >Ocultar resultado</Button>
                        )
                    }

                    {
                        (avaliado.encerrada && avaliado.liberarResultado === 1 && (parseJwt().adm === 'True' || parseJwt().cid === avaliado.colaborador.gestorId)) && (
                            <Button
                                leftIcon={<FaEye />}
                                borderRadius={'.5rem'}
                                VarColor='Orange'
                                size={'sm'}
                                onClick={() => onVisibilidade(avaliado.id, true)}
                            >Liberar resultado</Button>
                        )
                    }

                    {
                        (((!avaliado.encerrada && !PropInvalida(avaliado.dataInicio) && avaliado.iniciada) || (!avaliado.encerrada && PropInvalida(avaliado.dataInicio))) && parseJwt().adm === 'True') && (
                            <Button
                                leftIcon={<FaRegStopCircle />}
                                borderRadius={'.5rem'}
                                VarColor='terc1'
                                size={'sm'}
                                onClick={() => onEncerrar(avaliado.id)}
                            >Encerrar</Button>
                        )
                    }

                    {
                        ((!PropInvalida(avaliado.dataInicio) && !avaliado.encerrada)) && (
                            <Button
                                rightIcon={<FaCog />}
                                borderRadius={'.5rem'}
                                VarColor='Green2'
                                size={'sm'}
                                onClick={() => {
                                  if (openConfiguracao !== undefined) {
                                    openConfiguracao(avaliado)
                                  }
                                }}
                            >Configurações</Button>
                        )
                    }

                    {
                        ((PropInvalida(avaliado.dataInicio) && avaliado.encerrada) && parseJwt().adm === 'True') && (
                            <Button
                                leftIcon={<FaRegPlayCircle />}
                                borderRadius={'.5rem'}
                                VarColor='terc4'
                                size={'sm'}
                                onClick={() => onReabrir(avaliado.id)}
                            >Reabrir</Button>
                        )
                    }

                    {
                        ((VerificarBloqueio() && parseJwt().adm === 'True') && !interromperPesquisa) && (
                            <Button
                                leftIcon={<FaRegPlayCircle />}
                                borderRadius={'.5rem'}
                                VarColor='terc4'
                                size={'sm'}
                                onClick={() => onReabrir(avaliado.id)}
                            >Reabrir</Button>
                        )
                    }

                    {
                        (avaliado.encerrada && (((parseJwt().cid === avaliado.colaborador.id && avaliado.liberarResultado === 0) || parseJwt().cid === avaliado.colaborador.gestorId) || parseJwt().role === '2' || parseJwt().adm === 'True')) && (
                            <Button
                                borderRadius={'.5rem'}
                                VarColor='vm3'
                                size={'sm'}
                                leftIcon={<FaChartBar />}
                                onClick={() => nav(`${pathname.replace(`ResultadoPesquisa/${pesquisaId}`, 'ResultadoIndividual')}/${avaliado.id}`)}
                                disabled={!avaliado.totalRespostas}
                            >Resultados</Button>
                        )
                    }
                </Flex>
            </Flex>
        </Container>
  )
}
