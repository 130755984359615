import styled from 'styled-components'
import { Column, Flex } from '../../../../../styles/styledGlobal'

export const Head = styled(Flex)`
    justify-content: space-between;
    padding: 1rem;
    border-bottom: .1rem solid var(--c4);

    h2,span{
        color: var(--a4);
    }
    
    span{
        width: 50rem;
        font-weight: 500;
    }

    svg{
        color: var(--a2);
        cursor: pointer;
    }
`

export const Body = styled(Column)`
padding: 1rem;
`

export const Footer = styled(Flex)`
justify-content: end;
padding: 1rem;
`
