import { Avatar, Divider, Flex, Image, Text, Textarea, useToast } from '@chakra-ui/react'
import { WhiteBox } from '../../../components/WhiteBox'
import { Body } from '../../Layouts/Body'
import { Button } from '../../../components/Button'
import { useState, useEffect } from 'react'

import IconeLove from '../../../assets/IconeLove.svg'
import IconeMagoado from '../../../assets/IconeMagoado.svg'
import IconeFeliz from '../../../assets/IconeFeliz.svg'
import IconePiscada from '../../../assets/IconePiscada.svg'
import IconeTriste from '../../../assets/IconeTriste.svg'

import { iColaboradorBase, iNomeId } from '../../../interfaces'
import { useNavigate, useParams } from 'react-router-dom'
import { Emoji } from './Components/Emoji'
import { appApi } from '../../../services/appApi'
import { ButtonMenu } from './styles'

interface iDadoPDI {
  origem: string
  totalAcaoConcluida: number
  totalAcao: number
  dataFinal: string
}

interface iAvaliacaoPDI {
  dadoPDI: iDadoPDI
  colaborador: iColaboradorBase
  competencias: iNomeId[]
}

interface iPostAvaliacao {
  notas: iAvaliacaoXCompetencia[]
  comentario: string
  encerrar: boolean
  adicionarAcao: boolean
}

interface iAvaliacaoXCompetencia{
  competenciaId: string
  nota: number
}

type iLocation = {
  pdiId: string
}

export const AvaliacaoPDI: React.FC = () => {
  const toast = useToast()
  const { pdiId } = useParams<iLocation>()
  const nav = useNavigate()
  const [Model, setModel] = useState<iAvaliacaoPDI>()
  const [Notas, setNotas] = useState<iAvaliacaoXCompetencia[]>([])
  const [Comentario, setComentario] = useState('')

  const [Opcao, setOpcao] = useState<string>()

  function PostAvaliacao(): void {
    if (Notas.length < (Model?.competencias.length ?? 0)) {
      toast({
        title: 'Você deve avaliar todas as comptências!',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 2000
      })
      return
    }
    const form: iPostAvaliacao = {
      comentario: Comentario,
      encerrar: Opcao === '3' ?? false,
      notas: Notas,
      adicionarAcao: Opcao === '0' || Opcao === '2'
    }

    appApi.post(`PDI/${pdiId as string}/Avaliacao`, form)
      .then(() => {
        toast({
          title: 'PDI avaliado com sucesso!',
          status: 'success',
          isClosable: false,
          position: 'top-right',
          duration: 2000
        })
        nav(-1)
      })
      .catch(err => console.log(err))
  }

  function getPDI(): void {
    appApi.get(`PDI/${pdiId as string}/Avaliacao`)
      .then(res => setModel(res.data))
      .catch(err => console.log(err))
  }

  function SelecionarNota(competenciaId: string, nota: number): void {
    if (!Notas.some(r => r.competenciaId === competenciaId)) {
      setNotas([...Notas, {
        competenciaId: competenciaId,
        nota: nota
      }])
    } else {
      const copy = [...Notas]
      const pos = copy.findIndex(e => e.competenciaId === competenciaId)
      copy[pos].nota = nota
      setNotas(copy)
    }
  }

  useEffect(() => {
    getPDI()
  }, [])

  useEffect(() => {
    setOpcao(undefined)
  }, [Notas])

  const isOpcaoSelecionado = (id: string): boolean => Opcao === id

  return (
        <Body>
            {
                (Model) && (
                    <WhiteBox>
                        <Flex flexDir={'column'} alignItems='center'>
                            <h1>Andamento do PDl</h1>
                            <Flex width={'100%'} margin={'2rem 0rem'} justifyContent={'center'} alignItems={'center'} gap='1rem'>
                                <Flex width={'50%'} justifyContent={'center'}>
                                    <Avatar src={Model?.colaborador.avatar} name={Model?.colaborador.nome} marginRight={'1rem'} size={'xl'} />
                                    <Flex fontFamily={'Poppins'} flexDir={'column'}>
                                        <Text lineHeight={'115%'} fontWeight={'400'} fontSize={'1.75rem'}>Avaliado</Text>
                                        <Text lineHeight={'115%'} fontWeight={'500'} fontSize={'2rem'}>{Model?.colaborador.nome}</Text>
                                        <Text lineHeight={'115%'} fontWeight={'700'} fontSize={'1.375rem'}>{Model?.colaborador.nomeDepartamento}</Text>
                                    </Flex>
                                </Flex>
                                <Divider orientation='vertical' borderColor={'var(--Gray4)'} />
                                <Flex width={'50%'} fontWeight={'400'} fontFamily={'Roboto'} flexDir={'column'} gap='.2rem'>
                                    <Text>Origem: {Model.dadoPDI.origem}</Text>
                                    <Text>Ações concluidas: {Model.dadoPDI.totalAcaoConcluida}</Text>
                                    <Text>Total ações: {Model.dadoPDI.totalAcao}</Text>
                                    <Text>Data final: {Model.dadoPDI.dataFinal}</Text>
                                </Flex>
                            </Flex>
                        </Flex>

                        <Flex gap={'1rem'} flexDir={'column'}>
                            {
                                Model.competencias.map((e, i) => {
                                  return (
                                        <Flex key={i} flexDir={'column'} gap={'2rem'} padding={'2rem'} borderRadius={'1rem'} border={'1px solid var(--Gray4)'}>
                                            <Flex justifyContent={'center'} alignItems={'center'}>
                                                <Flex flexDir={'column'} alignItems={'center'} gap='.25rem'>
                                                    <Image width={'7.25rem'} objectFit='scale-down' src={e.avatar} />
                                                    <Text fontWeight={'500'} fontSize='1.2rem'>{e.nome}</Text>
                                                </Flex>
                                                <Flex justifyContent={'center'}>
                                                    <h3>Como você percebe a evolução do colaborador nessa competência?</h3>
                                                </Flex>
                                            </Flex>

                                            <Flex justifyContent={'center'} gap={'2rem'}>
                                                <Emoji
                                                    texto='Não melhorou em nada'
                                                    icon={IconeTriste}
                                                    disabled={!Notas.some(t => t.competenciaId === e.id && t.nota === 1)}
                                                    onClick={() => SelecionarNota(e.id, 1)}
                                                />

                                                <Emoji
                                                    texto='Precisa se esforçar mais'
                                                    icon={IconeMagoado}
                                                    disabled={!Notas.some(t => t.competenciaId === e.id && t.nota === 2)}
                                                    onClick={() => SelecionarNota(e.id, 2)}
                                                />

                                                <Emoji
                                                    texto='Está no caminho certo'
                                                    icon={IconeFeliz}
                                                    disabled={!Notas.some(t => t.competenciaId === e.id && t.nota === 3)}
                                                    onClick={() => SelecionarNota(e.id, 3)}
                                                />

                                                <Emoji
                                                    texto='Já melhorou muito'
                                                    icon={IconePiscada}
                                                    disabled={!Notas.some(t => t.competenciaId === e.id && t.nota === 4)}
                                                    onClick={() => SelecionarNota(e.id, 4)}
                                                />

                                                <Emoji
                                                    texto='Já atingiu o esperado'
                                                    icon={IconeLove}
                                                    disabled={!Notas.some(t => t.competenciaId === e.id && t.nota === 5)}
                                                    onClick={() => SelecionarNota(e.id, 5)}
                                                />
                                            </Flex>

                                            {/* <Flex>
                                                <Button
                                                    onClick={() => nav(-1)}
                                                    VarColor='c5'
                                                >Avaliar mais tarde</Button>
                                            </Flex> */}
                                        </Flex>
                                  )
                                })
                            }
                            {
                                (Notas.some(r => r.nota === 1) && (Model.dadoPDI.totalAcao !== Model.dadoPDI.totalAcaoConcluida)) && (
                                    <Flex flexDir={'column'} gap={'2rem'} padding={'2rem'} borderRadius={'1rem'} border={'1px solid var(--Gray4)'}>
                                        <Flex justifyContent={'center'}>
                                            <h3>Você recomendaria adicionar mais ações para que ele trabalhe essa competência?</h3>
                                        </Flex>
                                        <Flex justifyContent={'center'} gap='1rem'>
                                            <ButtonMenu onClick={() => setOpcao('0')} selected={isOpcaoSelecionado('0')}>Sim, ele pode realizar mais ações</ButtonMenu>
                                            <ButtonMenu onClick={() => setOpcao('1')} selected={isOpcaoSelecionado('1')}>Não, pode continuar com as ações aprovadas</ButtonMenu>
                                        </Flex>
                                    </Flex>
                                )
                            }

                            {
                                (Model.dadoPDI.totalAcao === Model.dadoPDI.totalAcaoConcluida) && (
                                    <Flex flexDir={'column'} gap={'2rem'} padding={'2rem'} borderRadius={'1rem'} border={'1px solid var(--Gray4)'}>
                                        <Flex justifyContent={'center'}>
                                            <h3>Todas as ações já foram finalizadas, você recomendaria adicionar mais ações para que ele trabalhe essa competencia?</h3>
                                        </Flex>
                                        <Flex gap='1rem' justifyContent={'center'}>
                                            <ButtonMenu onClick={() => setOpcao('2')} selected={isOpcaoSelecionado('2')}>Sim, ele pode realizar mais ações</ButtonMenu>
                                            <ButtonMenu onClick={() => setOpcao('3')} selected={isOpcaoSelecionado('3')}>Não, o PDI pode ser concluído</ButtonMenu>
                                        </Flex>
                                    </Flex>
                                )
                            }

                            <Flex flexDir={'column'} gap={'2rem'} padding={'2rem'} borderRadius={'1rem'} border={'1px solid var(--Gray4)'}>
                                <Flex justifyContent={'center'}>
                                    <h3>Gostaria de deixar algum comentário para seu colaborador? </h3>
                                </Flex>
                                <Flex>
                                    <Textarea
                                        onChange={(e) => setComentario(e.target.value)}
                                        value={Comentario}
                                        borderColor={'var(--Gray4)'}
                                    />
                                </Flex>
                            </Flex>

                            <Flex justifyContent={'center'}>
                                <Button onClick={() => nav(-1)} size={'lg'} marginRight={'1rem'} VarColor='c4'>Voltar</Button>
                                <Button size={'lg'} VarColor='v2' onClick={() => PostAvaliacao()}>Enviar avaliação</Button>
                            </Flex>
                        </Flex>
                    </WhiteBox>
                )
            }
        </Body>
  )
}
