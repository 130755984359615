import { Flex, FormControl, FormLabel, Input } from '@chakra-ui/react'
import { useState } from 'react'
import Modal from 'react-modal'
import { GreenButton } from '../Buttons/GreenButton'
import { GreyButton } from '../Buttons/GreyButton'
import { WhiteModal } from './styles'

interface iModalProps {
  isOpen: boolean
  title?: string
  onCreate: (texto: string) => void
  onClose: () => void
}

export const ModalCreateForm: React.FC<iModalProps> = ({ isOpen, title, onClose, onCreate }) => {
  const [Texto, setTexto] = useState('')

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      overlayClassName='react-modal-overlay'
      className='react-modal-content-default'
    >
      <WhiteModal>
        {/* <h3>{title ?? 'Salvando'}</h3> */}
        <FormControl>
          <FormLabel color={'var(--a4)'} fontSize={'lg'}>Defina um nome para seu formulário</FormLabel>
          <Input
            value={Texto}
            borderColor={'var(--Gray4)'}
            onChange={(e) => { setTexto(e.target.value) }}
            type="text"
            placeholder='Digite o nome'
          />
        </FormControl>

        <Flex justifyContent={'end'} marginTop={'1rem'} gap={'1rem'}>
          <GreyButton isNav={false} Size={'md'} onClick={onClose}>Cancelar</GreyButton>
          <GreenButton isNav={false} Size={'md'} OnClick={() => onCreate(Texto)}>Salvar</GreenButton>
        </Flex>
      </WhiteModal>
    </Modal>
  )
}
