import { Divider, Flex, Text } from '@chakra-ui/react'
import { MdTimer } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import { Button } from '../../../../../../components/Button'
import { OutlineContainer } from '../../styles'

interface iAcao {
  id: string
  assunto: string
  titulo: string
  descricao: string
  dataEntrega: string
  tempoDedicacao: number
  status: number
  dataInicio: string
  comentario?: string
}

interface iAcaoCardProps {
  acao: iAcao
  pdiId: string
  onOpenComentario: (id: string, acao: iAcao) => void
  onOpenFinalizar: (id: string, acao: iAcao) => void
}

export const AcaoCard: React.FC<iAcaoCardProps> = ({ pdiId, acao, onOpenComentario, onOpenFinalizar }) => {
  const nav = useNavigate()
  return (
        <OutlineContainer theme={acao.status === 6 ? 'Red' : 'Gray4'}>
            <Flex gap={'1rem'} height={'100%'} justifyContent={'space-between'} flexDir={'column'}>
                <Flex flexDir={'column'} gap={'1rem'}>
                    <Flex justifyContent={'center'}>
                        <Text fontWeight={'500'}>{acao.assunto}</Text>
                    </Flex>
                    <Flex flexDir={'column'}>
                        <Text lineHeight={'115%'} marginBottom='0.5rem' fontWeight={'500'} color={'var(--Gray1)'} fontSize={'1.25rem'}>{acao.titulo}</Text>
                        <Text fontWeight={'400'} color={'var(--Gray1)'}>{acao.descricao}</Text>
                    </Flex>
                    {
                        (acao.status === 5 || acao.status === 6) && (
                            <Flex color={acao.status === 6 ? 'var(--Red)' : 'var(--Gray1)'} gap={'1rem'} textAlign='center'>
                                <Flex flexDir={'column'}>
                                    <Text fontWeight={'500'} fontSize={'1rem'}>Data para entrega</Text>
                                    <Text fontWeight={'400'} fontSize={'1rem'}>{acao.dataEntrega}</Text>
                                </Flex>
                                <Divider orientation='vertical' borderColor={'var(--Gray4)'} />
                                <Flex flexDir={'column'}>
                                    <Text fontWeight={'500'} fontSize={'1rem'}>Tempo de dedicação</Text>
                                    <Text fontWeight={'400'} fontSize={'1rem'}>{acao.tempoDedicacao}h</Text>
                                </Flex>
                            </Flex>
                        )
                    }

                    {
                        (acao.status === 7) && (
                            <Flex color={'var(--Gray1)'} justifyContent='center'>
                                <Text fontWeight={'500'} fontSize={'1rem'}>Ação concluída</Text>
                            </Flex>
                        )
                    }

                    {
                        (acao.status === 2) && (
                            <Flex color={'var(--Azul)'} justifyContent='center'>
                                <Text fontWeight={'700'} fontSize={'1rem'}>Veja a solicitação de ajuste e atualize a ação</Text>
                            </Flex>
                        )
                    }
                </Flex>
                {
                    (acao.status === 5) && (
                        <Button size={'sm'} borderRadius={'1.5rem'} onClick={() => onOpenFinalizar(pdiId, acao)} VarColor='Rosa'>Finalizar ação</Button>
                    )
                }

                {
                    (acao.status === 2) && (
                        <Button
                            size={'sm'}
                            borderRadius={'1.5rem'}
                            onClick={() => nav(`/Desempenho/PDI/Editar/${pdiId}`)}
                            VarColor='Azul'>Atualizar
                        </Button>
                    )
                }

                {
                    (acao.status === 7) && (
                        <Button size={'sm'} onClick={() => onOpenComentario(pdiId, acao)} borderRadius={'1.5rem'} VarColor='Gray4'>Comentários</Button>
                    )
                }

                {
                    (acao.status === 1) && (
                        <Flex alignItems={'center'} gap='.5rem' justifyContent={'center'}>
                            <MdTimer size={24}/>
                            <Text
                                fontWeight={'700'}
                                fontSize={'1rem'}
                                color={'var(--secundario5)'}
                            >Aguardando aprovação do responsável</Text>
                        </Flex>
                    )
                }
            </Flex>
        </OutlineContainer>
  )
}
