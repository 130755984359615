import { Avatar, CircularProgress, CircularProgressLabel, Flex, Input, InputGroup, InputLeftElement } from '@chakra-ui/react'
import { Container } from './styles'
import { useState } from 'react'

import { iColaboradorBase } from '../../../../../interfaces'
import { FaSearch } from 'react-icons/fa'
import { Button } from '../../../../../components/Button'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../../../../contexts/AuthContext'

export interface iDadoTabela {
  id: string
  colaborador: iColaboradorBase
  tipo: string
  inicio?: string
  progresso: number
  concluido: boolean
}

interface iTabelaProps {
  dados: iDadoTabela[]
}

export const Tabela: React.FC<iTabelaProps> = ({ dados }) => {
  const { permitirFuncao } = useAuth()
  const nav = useNavigate()

  const [Texto, setTexto] = useState('')
  function getColor(numero: number): string {
    if (numero < 50) {
      return '#FE7B1E'
    } else if (numero >= 50 && numero < 75) {
      return '#6DA7A8'
    } else if (numero >= 75 && numero < 100) {
      return '#1961E0'
    } else {
      return '#89DB81'
    }
  }

  return (
    <Flex flexDir={'column'} width={'100%'}>
      <Flex width={'100%'} justifyContent={'end'}>
        <InputGroup width={'35%'}>
          <InputLeftElement
            pointerEvents='none'
            children={<FaSearch color='var(--Gray4)' />}
          />
          <Input
            onChange={(e) => setTexto(e.target.value)}
            borderColor={'var(--Gray4)'}
            type='text'
            placeholder='Digite o nome do colaborador'
          />
        </InputGroup>
      </Flex>
      <Container>
        <thead>
          <tr>
            <th>Colaborador</th>
            <th>Tipo</th>
            <th>Início</th>
            <th>Progresso</th>
          </tr>
        </thead>
        <tbody>
          {
            dados.filter((e) => {
              if (Texto === '') {
                return e
              } else if (e.colaborador.nome.toLowerCase().includes(Texto.toLowerCase())) {
                return e
              }
              return null
            }).map((e, i) => {
              return (
                <tr key={i}>
                  <th>
                    <Flex alignItems={'center'}>
                      <Avatar marginRight={'1rem'} size={'md'} src={e.colaborador.avatar} name={e.colaborador.nome} />
                      <span>{e.colaborador.nome}</span>
                    </Flex>
                  </th>
                  <th>{e.tipo}</th>
                  <th>{e.inicio}</th>
                  <th>
                    <CircularProgress value={e.progresso} color={getColor(e.progresso)}>
                      <CircularProgressLabel top={'45%'}>{e.progresso.toFixed(0)}%</CircularProgressLabel>
                    </CircularProgress>
                  </th>
                </tr>
              )
            })
          }
        </tbody>
      </Container>

      {permitirFuncao('Jornada', 'onboardings') && <Button onClick={() => nav('/Jornada/Onboard')} marginTop={'1rem'} VarColor='Azul'>Gerenciamento de onboarding</Button>}
    </Flex>
  )
}
