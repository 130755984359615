
import { MdArrowForward } from 'react-icons/md'
import Modal from 'react-modal'
import { MetaButton } from '../../../../../components/Buttons/MetaButton'
import { ModalBody, ModalFooter, Content } from './styles'

interface props {
  isOpen: boolean
  onRequestClose: () => void
}

export const ModalSucessPrimeiroAceso: React.FC<props> = ({ isOpen, onRequestClose }) => {
  Modal.setAppElement('#root')
  return (
    <Modal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        overlayClassName='react-modal-overlay'
        className='react-modal-content-default'
    >
      <Content>
        <ModalBody>
            <h2>Parabéns!</h2>
            <span>Você finalizou o seu primeiro acesso</span>
        </ModalBody>

        <ModalFooter>
            <MetaButton onClick={onRequestClose} size='md' bg='v2'>Visualizar trilha para acompanhamento <MdArrowForward size={24} className='IconRigth'/></MetaButton>
        </ModalFooter>
      </Content>
    </Modal>
  )
}
