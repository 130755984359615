import styled from 'styled-components'
import { Column } from '../../../../../styles/styledGlobal'

interface CardProps{
  border: string
}

export const Container = styled.div<CardProps>`
padding: .5rem;
border-radius: 0.625rem;
display: flex;
flex-direction: column;
justify-content: space-between;
background:var(--c2);
margin-bottom: 1rem;
border: 0.125rem solid var(--${props => props.border});
`

export const ImgText = styled.div`
display: flex;
flex-direction: column;
align-items: center;
    span{
        font-weight: 400;
        font-size: 0.875rem;
        color:var(--a3);
    }

    img{
        height: 4.25rem;
        width: 4.25rem;
        border-radius: 50%;
        margin-bottom: .5rem;
        border: 0.188rem solid var(--a2);
    }
`

export const DropMenu = styled.ul`
list-style-type: none;
background: white;
box-shadow: var(--SombraBackground);
border-radius: .5rem;
position: absolute;
overflow-y: hidden;
max-height: 0rem;
z-index: 100;

li{
    padding: .5rem;
    cursor: pointer;

    svg{
        margin-right:.5rem;
    }
}

li:hover{
    background: var(--c2);
    font-weight: 500;
}

li:last-child{
    margin-bottom:.2rem;
}

li:first-child{
    margin-top:.2rem;
}
`

export const Menu = styled(Column)`
align-items: flex-end;
width: 3rem;
cursor: pointer;
&:hover{
    ul{
        transition: all .4s ease;
        max-height: 7.897rem;
    }
}
`
