import { useEffect, useState } from 'react'
import { Container, FlexIcon } from './styles'
import { FaClipboardList, FaCog, FaMapMarkerAlt, FaUserAlt } from 'react-icons/fa'
import { appApi } from '../../../../../../../services/appApi'
import { Button } from '../../../../../../../components/Button'
import { useNavigate, useParams } from 'react-router-dom'
interface iCardReuniao {
  reuniaoId: string
  etapaId: string
}

interface iReuniao {
  tipoReuniao: number
  participanteId?: string
  assunto: string
  descricao: string
  dataInicio?: string
  duracao?: number
  presencial: boolean
  local?: string
}

export const CardReuniaoOne: React.FC<iCardReuniao> = ({ reuniaoId, etapaId }) => {
  const nav = useNavigate()
  const { colaboradorId } = useParams<{ colaboradorId: string }>()

  const [Model, setModel] = useState<iReuniao>({
    tipoReuniao: 0,
    assunto: '',
    descricao: '',
    presencial: false,
    duracao: 5
  })

  function ObterEtapa(): void {
    appApi.get(`ReuniaoOne/${reuniaoId ?? ''}`)
      .then(res => {
        setModel(res.data)
      })
      .catch(err => console.log(err))
  }

  useEffect(() => {
    ObterEtapa()
  }, [])

  return (
    <Container>
      <FlexIcon>
        <FaUserAlt size={30} />
        <div>
          <h3>Tipo de reunião</h3>
          <strong>{Model.tipoReuniao === 0 ? 'Feedback com o gestor' : 'Feedback com o RH'}</strong>
        </div>
      </FlexIcon>

      <FlexIcon>
        <FaMapMarkerAlt size={30} />
        <div>
          <h3>Local da reunião</h3>
          <strong>{Model.local}</strong>
        </div>
      </FlexIcon>

      {
        (Model.descricao !== null) && (
          <FlexIcon>
            <FaClipboardList size={30} />
            <div>
              <h3>Descrição</h3>
              <small dangerouslySetInnerHTML={{ __html: Model.descricao }}></small>
            </div>
          </FlexIcon>
        )
      }

      {
        (Model.dataInicio === null) && (
          <Button VarColor='Azul' leftIcon={<FaCog />}
            onClick={() => nav(`/Processo/${colaboradorId ?? ''}/ReuniaoOne/${etapaId}/Agendar`)}
          >Configurar envio</Button>
        )
      }
    </Container>
  )
}
