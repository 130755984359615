import styled from 'styled-components'
import { Flex } from '../../../styles/styledGlobal'

export const Main = styled(Flex)`
justify-content: space-between;
margin-top: 1.5rem;
section{
    width: 49%;
}

img{
    width: 49%;
    height: 35rem;
}
`

export const FlexHead = styled(Flex)`
justify-content: space-between;
h1{
color:var(--a4);
}
`
