import styled from 'styled-components'

export const DateText = styled.div`
margin-top: 1rem;
display: flex;

span{
    font-weight: 500;
    font-size: 20px;
    color:var(--a3);
}

span:not(:last-child){
margin-right:1.5rem;
}
`
