import { Flex, Textarea, useToast } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Body } from '../../Layouts/Body'
import { GreyButton } from '../../../components/Buttons/GreyButton'
import { GreenButton } from '../../../components/Buttons/GreenButton'
import { WhiteBox } from '../../../components/WhiteBox'
import { iImg } from '../../../interfaces'
import { appApi } from '../../../services/appApi'
import { FlexBackBtn, Main, SubtitleLg, SubtitleMd, WelcomeText } from './styles'
import { CardVideo } from './Components/CardVideo'

export interface iVideoIntroducao {
  tipo: number
  titulo: string
  descricao: string
  arquivo: iImg
}

export interface iModel {
  textoBoasVindas: string
  videoIntroducao: iVideoIntroducao
}

export interface VideoIntroducao {
  tipo: number
  titulo: string
  descricao: string
  arquivo: Arquivo
}

export interface Arquivo {
  uri: string
  name: string
  size: string
}

export const PrimeroAcesso: React.FC = () => {
  const navigate = useNavigate()
  const toast = useToast()

  const [Model, setModel] = useState<iModel>({
    textoBoasVindas: '',
    videoIntroducao: {
      descricao: '',
      tipo: 3,
      titulo: '',
      arquivo: {
        name: '',
        size: '',
        uri: ''
      }
    }
  })

  useEffect(() => {
    ObterPrimeiroAcesso()
  }, [])

  function ObterPrimeiroAcesso(): void {
    appApi.get('PrimeiroAcesso/ByEmpresaId').then(response => {
      setModel(response.data)
    }).catch(e => console.log(e))
  }

  function AtualizarPrimeiroAcesso(): void {
    appApi.put('PrimeiroAcesso', Model).then(response => {
      if (response.status === 200) {
        toast({
          title: 'Primeiro acesso atualizado com sucesso',
          status: 'success',
          isClosable: false
        })
        navigate(-1)
      } else {
        toast({
          title: 'Não foi possível atualizar',
          status: 'error',
          isClosable: false
        })
      }
    }).catch(error => console.log(error))
  }

  function AtualizarItemIntegracao(item: iVideoIntroducao): void {
    setModel({ ...Model, videoIntroducao: item })
  }

  return (
    <Body>
      <Main>
        <FlexBackBtn>
          <GreyButton Size='lg' isNav={false} onClick={() => navigate(-1)}>Voltar</GreyButton>
        </FlexBackBtn>
        <Flex justifyContent={'space-between'}>
          <h1 onClick={() => console.log(Model)}>Configurando primeiro acesso</h1>
        </Flex>
        <SubtitleLg marginBottom='1rem'>Aqui você pode configurar as mensagens de boas vindas ao novo colaborador. Obs.: O primeiro acesso é o mesmo para todos os novos usuários.</SubtitleLg>
        <WhiteBox>
          <WelcomeText>
            <h2>Faça um breve texto de boas-vindas </h2>
            <SubtitleMd>Esse texto aparecerá junto com a logomarca da empresa assim que o colaborador realizar o login no aplicativo ou navegador</SubtitleMd>
            <Textarea
              borderColor={'var(--Gray4)'}
              height='10rem'
              marginTop={'1rem'}
              resize='none'
              value={Model.textoBoasVindas}
              placeholder='Digite o texto'
              onChange={(e) => setModel({ ...Model, textoBoasVindas: e.target.value })}
            />
          </WelcomeText>

          <Flex flexDir={'column'}>
            <h2>Deixe um vídeo sobre a organização</h2>
            <SubtitleMd>O vídeo de introdução pode fazer com que o colaborador se sinta mais a vontade e faça uma breve apresentação da cultura, da liderança e até dos ambientes da organização.</SubtitleMd>
            <CardVideo
              onUpdateInput={AtualizarItemIntegracao}
              ItemIntegracao={Model.videoIntroducao}
            />
          </Flex>
        </WhiteBox>
        <Flex marginTop={'1rem'} justifyContent={'end'}>
          {
            <GreenButton Size='lg' isNav={false} OnClick={AtualizarPrimeiroAcesso}>Atualizar primeiro acesso</GreenButton>
          }
        </Flex>
      </Main>
    </Body>
  )
}
