import styled from 'styled-components'

export const InfoBox = styled.div`
display: flex;
align-items: center;
justify-content: center;
background: var(--Azul);
color: white;
border-radius: 0.625rem;
height: 5.625rem;
width: 100%;
box-shadow: var(--SombraBtns);

div{
    width: min-content;
    margin-left: .5rem;
}
`
export const Titulo = styled.h2`
font-family: 'Poppins';
font-weight: 500;
font-size: 28px;
color: #2C4D8A;
`
