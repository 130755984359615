import { Flex } from '@chakra-ui/react'
import { LayoutFull } from '../LayoutFull'
import { Container, HeaderContainer } from './styles'

interface iLayoutProps{
  bg?: string
  isRespondido: boolean
  titulo: string
}

export const LayoutFullAnswer: React.FC<iLayoutProps> = ({ bg, titulo, isRespondido, children }) => {
  return (
        <LayoutFull>
            <HeaderContainer>
                <h1>{titulo}</h1>
                <img alt='Logo da empresa' src='https://sigah.blob.core.windows.net/onboard/f99445d7-d98d-48ad-9cdb-a443a9f636a2.png' />
            </HeaderContainer>
            {
                (!isRespondido) && (
                    <Container>
                        {children}
                    </Container>
                )
            }

            {
                (isRespondido) && (
                    <Flex background={'white'} height={'100%'}>
                        <Flex padding={'3rem 3rem'} flexDirection={'column'}>
                            <h3>Obrigado por participar!</h3>
                            <span style={{ color: 'var(--c6)', marginTop: '1rem', fontWeight: '400' }}>Participação contabilizada</span>
                        </Flex>
                    </Flex>
                )
            }

        </LayoutFull>
  )
}
