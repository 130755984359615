/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { FaSearch, FaStop } from 'react-icons/fa'
import { Body } from '../../Layouts/Body'
import { WhiteBox } from '../../../components/WhiteBox'
import { DateText } from './styles'
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Avatar, Box, Divider, Flex, FormControl, FormLabel, Input, InputGroup, InputLeftElement, Select, useToast, useDisclosure } from '@chakra-ui/react'
import { CardAvaliado } from './Components/CardAvaliado'
import { useEffect, useRef, useState } from 'react'
import { appApi } from '../../../services/appApi'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { NavLine } from '../../../components/NavLine'
import { parseJwt } from '../../../services/token'
import { PropInvalida } from '../../../Utils/Helper'
import { ModalConfiguracao } from './Components/ModalConfiguracao'
import { Button } from '../../../components/Button'
import { AiOutlineStop } from 'react-icons/ai'

interface iModel {
  nomePesquisa: string
  dataInicio: string
  dataFim: string
  avaliacoes: iAvaliado[]
  statusPesquisa: string
  encerrada: boolean
  totalAvaliadores: number
  totalAvaliados: number
  ciclo?: string
}

interface iColaboradorBase {
  id: string
  nome: string
  avatar: string
  nomeDepartamento: string
  gestorId: string
}

interface iAvaliador {
  nome?: string
  email: string
  respondido: boolean
  externo: boolean
}

export interface iAvaliado {
  id: string
  colaborador: iColaboradorBase
  totalAvaliadores: number
  totalRespostas: number
  autoavaliacao?: boolean
  gestor?: boolean
  encerrada: boolean
  avaliadores: iAvaliador[]
  iniciada: boolean
  dataInicio?: string
  dataFim?: string
  liberarResultado: number
  interrompida: boolean
}

export const ResultadoPesquisaPercepcao: React.FC = () => {
  const nav = useNavigate()
  const toast = useToast()

  const { pathname } = useLocation()

  const [Model, setModel] = useState<iModel>({
    avaliacoes: [],
    dataFim: '',
    dataInicio: '',
    encerrada: false,
    nomePesquisa: '',
    statusPesquisa: '',
    totalAvaliadores: 0,
    totalAvaliados: 0
  })
  const [Pesquisa, setPesquisa] = useState('')
  const { pesquisaId } = useParams<{ pesquisaId: string }>()
  const [StatusPesquisa, setStatusPesquisa] = useState('')

  const [ModalConfiguracaoIsOpen, setModalConfiguracaoIsOpen] = useState(false)
  const [Avaliado, setAvaliado] = useState<iAvaliado>()

  const [UltimaEncerrada, setUltimaEncerrada] = useState<iAvaliado>()

  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = useRef<HTMLButtonElement>(null)

  const [ColaboradorId, setColaboradorId] = useState<string>('')

  function orderByName(isDesc: boolean): void {
    const arr = [...Model?.avaliacoes]
    arr.sort((a, b) => {
      return a.colaborador.nome.localeCompare(b.colaborador.nome, 'pt-BR')
    })
    if (isDesc) arr.reverse()
    setModel({ ...Model, avaliacoes: arr })
  }

  function getAvaliados(): void {
    appApi.get(`AvaliacaoPesquisa/${pesquisaId as string}/Resultado`)
      .then(res => setModel(res.data))
      .catch(err => console.log(err))
  }

  function EncerrarAvaliacao(id: string): void {
    appApi.patch(`AvaliacaoPesquisa/${id}/Encerrar`)
      .then(getAvaliados)
      .catch(err => console.log(err))
  }

  function ReabrirAvaliacao(id: string): void {
    appApi.patch(`AvaliacaoPesquisa/${id}/Reabrir`)
      .then(getAvaliados)
      .catch(err => console.log(err))
  }

  function AlterarVisibiliade(id: string, visibilidade: boolean): void {
    appApi.patch(`AvaliacaoPesquisa/${id}/AlterarVisibilidade?visibilidade=${visibilidade ? 0 : 1}`)
      .then(getAvaliados)
      .catch(err => console.log(err))
  }

  function GroupByAvaliado(avaliacoes: iAvaliado[]): iAvaliado[][] {
    const arrDistinct: iAvaliado[] = []
    const arrAvaliacoes: iAvaliado[][] = []
    avaliacoes.forEach((e) => {
      if (arrDistinct.find(r => r.colaborador.nome === e.colaborador.nome) === undefined) {
        arrDistinct.push(e)
      }
    })
    arrDistinct.forEach((e) => {
      const avaliacoesFiltradas = avaliacoes.filter(r => r.colaborador.nome === e.colaborador.nome) ?? []
      arrAvaliacoes.push(avaliacoesFiltradas)
    })
    return arrAvaliacoes
  }

  function openConfiguracao(avaliado: iAvaliado): void {
    setAvaliado(avaliado)
    setModalConfiguracaoIsOpen(true)
  }

  function closeConfiguracao(): void {
    setAvaliado(undefined)
    setModalConfiguracaoIsOpen(false)
  }

  function Salvar(dataInicio: string, dataFim: string, reenviar: boolean): void {
    appApi.put(`AvaliacaoPesquisa/${Avaliado?.id ?? ''}/Salvar/Ciclica?dataInicio=${dataInicio}&dataFim=${dataFim}&reenviar=${reenviar ?? ''}`)
      .then(() => {
        toast({
          title: 'Salvo com sucesso',
          status: 'success',
          isClosable: false,
          position: 'top-right'
        })
        closeConfiguracao()
        getAvaliados()
      })
      .catch(err => console.log(err))
  }

  function EncerrarPesquisa(pesquisaId: string, colaboradorId: string, interromper: boolean): void {
    appApi.patch(`PesquisaPercepcao/Encerrar/${pesquisaId}/${colaboradorId}/${interromper}`)
      .then(e => {
        toast({
          title: 'Encerrada com sucesso',
          status: 'success',
          isClosable: false,
          position: 'top-right'
        })
        getAvaliados()
      })
      .catch(err => console.log(err))

    const avaliacoesColaborador = Model.avaliacoes.filter(e => e.colaborador.id === colaboradorId)
    const avaliacoesOutros = Model.avaliacoes.filter(e => e.colaborador.id !== colaboradorId)

    if (avaliacoesColaborador.length > 1) {
      avaliacoesColaborador.length = 1
    }

    const avaliacoesCombinadas = avaliacoesColaborador.concat(avaliacoesOutros)

    setModel({ ...Model, avaliacoes: avaliacoesCombinadas })
  }

  useEffect(() => {
    getAvaliados()
  }, [])

  useEffect(() => {
    if (Model.avaliacoes.length >= 2 && Model.avaliacoes.some(r => r.encerrada)) {
      const encerradas = Model.avaliacoes.filter(e => e.encerrada)
      setUltimaEncerrada(encerradas[encerradas.length - 1])
    }
  }, [Model])

  return (
    <Body>
      <ModalConfiguracao
        isOpen={ModalConfiguracaoIsOpen}
        avaliado={Avaliado}
        dataFimUltimoEncerrado={UltimaEncerrada?.dataFim}
        onConfirm={Salvar}
        onRequestClose={closeConfiguracao}
      />
      <Flex margin={'1rem 0'}>
        <NavLine options={[
          {
            nome: 'Gestão de pesquisas',
            path: `${pathname.replace(`ResultadoPesquisa/${pesquisaId as string}`, 'Gestao')}`
          },
          {
            nome: Model?.nomePesquisa ?? '',
            path: ''
          }
        ]} />
      </Flex>

      <Flex justifyContent={'space-between'}>
        <Flex flexDirection={'column'}>
          <h1>{Model?.nomePesquisa}</h1>
          <DateText>
            {Model.ciclo && <span>Ciclo: {Model?.ciclo}</span>}
            {Model.dataInicio && <span>Início: {Model?.dataInicio}</span>}
            {Model.dataFim && <span>Fim: {Model?.dataFim}</span>}
            <span>Avaliados: {Model?.totalAvaliados}</span>
            <span>Total de avaliadores: {Model?.totalAvaliadores}</span>
          </DateText>
        </Flex>
        {
          (Model?.avaliacoes.find(e => e.totalRespostas > 1) !== undefined && parseJwt().adm === 'True') && (
            <Button
              onClick={() => nav(`${pathname.replace('ResultadoPesquisa', 'ResultadoGeral')}`)}
              margin='0rem 1rem' VarColor='Azul' size='lg'
            >Relatório geral</Button>
          )
        }
      </Flex>

      <WhiteBox margin='1rem 0rem 0rem 0rem'>
        <Flex marginBottom={'1rem'} justifyContent={'space-between'} alignItems={'end'}>
          {
            (PropInvalida(Model.ciclo)) && (
              <Flex gap={'1rem'}>
                <FormControl width={'30rem'}>
                  <FormLabel>Ordenação</FormLabel>
                  <Select borderColor={'var(--Gray4)'}
                    onChange={(e) => {
                      if (e.target.value === '0') {
                        orderByName(false)
                      } else if (e.target.value === '1') {
                        orderByName(true)
                      }
                    }}>
                    <option value={'0'}>A-Z</option>
                    <option value={'1'}>Z-A</option>
                  </Select>
                </FormControl>
                <FormControl>
                  <FormLabel>Status</FormLabel>
                  <Select borderColor={'var(--Gray4)'} onChange={(e) => setStatusPesquisa(e.target.value)}>
                    <option value={''}>Nenhum</option>
                    <option value={'Encerrada'}>Encerrada</option>
                    <option value={'Andamento'}>Em andamento</option>
                  </Select>
                </FormControl>

              </Flex>
            )
          }
          <Flex w='100%' justifyContent='space-between' alignItems='center'>
            <InputGroup width={'20rem'} gap='4' alignItems='center'>
              <InputLeftElement
                pointerEvents='none'
                children={<FaSearch color='var(--Gray4)' />}
              />

              <Input
                borderColor={'var(--Gray4)'}
                type='text'
                placeholder='Pesquisa'
                onChange={(e) => setPesquisa(e.target.value)}
              />
            </InputGroup>

          </Flex>
        </Flex>

        <h3 style={{ marginBottom: '.5rem', color: 'var(--a4)' }}>Avaliados</h3>
        {
          (Model && Model.avaliacoes.length > 0 && PropInvalida(Model.ciclo)) && (
            <Flex gap={'1rem'} flexDirection={'column'}>
              {
                Model?.avaliacoes.filter((e) => {
                  if (StatusPesquisa !== '' && ((StatusPesquisa !== 'Encerrada' && e.encerrada) || (StatusPesquisa !== 'Andamento' && !e.encerrada))) {
                    return null
                  } else if (Pesquisa === '') return e
                  else if (e.colaborador.nome.toLowerCase().includes(Pesquisa.toLowerCase())) {
                    return e
                  }

                  return null
                }).map((e) => {
                  return (
                    <CardAvaliado
                      onEncerrar={EncerrarAvaliacao}
                      onReabrir={ReabrirAvaliacao}
                      onVisibilidade={AlterarVisibiliade}
                      key={e.id}
                      avaliado={e}
                      pesquisaId={pesquisaId as string}
                    />
                  )
                })
              }
            </Flex>
          )
        }

        {
          (Model && Model.avaliacoes.length > 0 && !PropInvalida(Model.ciclo)) && (
            <Flex gap={'1rem'} flexDirection={'column'}>
              {
                GroupByAvaliado(Model.avaliacoes.filter((e) => {
                  if (StatusPesquisa !== '' && ((StatusPesquisa !== 'Encerrada' && e.encerrada) || (StatusPesquisa !== 'Andamento' && !e.encerrada))) {
                    return null
                  } else if (Pesquisa === '') return e
                  else if (e.colaborador.nome.toLowerCase().includes(Pesquisa.toLowerCase())) {
                    return e
                  }
                  return null
                })).map((e, i) => {
                  return (
                    <Accordion key={i} allowToggle >
                      <AccordionItem>
                        <h2>
                          <Divider borderColor={'var(--Gray4)'} />
                          <AccordionButton bg={'none'} color='black'>
                            <Box gap={'1rem'} alignItems={'center'} width={'100%'} display={'flex'} textAlign='left' justifyContent='space-between'>
                              <Flex alignItems={'center'} gap={'1rem'} >
                                <Avatar src={e[0].colaborador.avatar} name={e[0].colaborador.nome} />
                                {e[0].colaborador.nome}
                              </Flex>
                              {
                                Model.avaliacoes.some(
                                  (avaliacao) =>
                                    avaliacao.colaborador.id === e[0].colaborador.id && !avaliacao.encerrada
                                )
                                  ? (
                                  <Button
                                    leftIcon={<FaStop />}
                                    VarColor="Red"
                                    onClick={() => { setColaboradorId(e[0].colaborador.id); onOpen() }}
                                    size="sm"
                                    mr="2"
                                  >
                                    Interromper pesquisa
                                  </Button>
                                    )
                                  : <AiOutlineStop size='24' color='red'/>
                              }

                              {
                                <AlertDialog
                                  isOpen={isOpen}
                                  leastDestructiveRef={cancelRef}
                                  onClose={onClose}
                                >
                                  <AlertDialogOverlay>
                                    <AlertDialogContent>
                                      <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                                        Interromper pesquisa
                                      </AlertDialogHeader>

                                      <AlertDialogBody>
                                        Você tem certeza que deseja interromper a pesquisa? Você não poderá desfazer essa operação depois.
                                      </AlertDialogBody>

                                      <AlertDialogFooter>
                                        <Button colorScheme='blackAlpha' onClick={onClose}>
                                          Cancelar
                                        </Button>
                                        <Button colorScheme='red'
                                        onClick={() => {
                                          onClose(); EncerrarPesquisa(pesquisaId ?? '', ColaboradorId ?? '', true)
                                        }}
                                          ml={3}>
                                          Interromper pesquisa
                                        </Button>
                                      </AlertDialogFooter>
                                    </AlertDialogContent>
                                  </AlertDialogOverlay>
                                </AlertDialog>
                              }
                            </Box>
                            <AccordionIcon />
                          </AccordionButton>
                          <Divider borderColor={'var(--Gray4)'} />
                        </h2>
                        {
                          e.map((c, i2) => {
                            return (
                              <AccordionPanel key={i2} pb={4}>
                                <CardAvaliado
                                  onEncerrar={EncerrarAvaliacao}
                                  onReabrir={ReabrirAvaliacao}
                                  openConfiguracao={openConfiguracao}
                                  interromperPesquisa={e.some(e => e.interrompida)}
                                  onVisibilidade={AlterarVisibiliade}
                                  key={c.id}
                                  avaliado={c}
                                  avaliacaoAnterior={e[i2 - 1]}
                                  proximaAvaliacao={e[i2 + 1]}
                                  pesquisaId={pesquisaId as string}
                                />
                              </AccordionPanel>
                            )
                          })
                        }
                      </AccordionItem>
                    </Accordion>
                  )
                })
              }
            </Flex>
          )
        }

        {
          (Model && Model.avaliacoes.length === 0) && (
            <Flex justifyContent={'center'} padding='1rem'>
              <h1>Selecione um participante para esta pesquisa</h1>
            </Flex>
          )
        }
      </WhiteBox>
    </Body>
  )
}
