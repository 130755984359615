import { Flex } from '@chakra-ui/react'

interface iProps {
  titulo?: number
  varColorCircle: string
  varColorText: string
  fontsize: '14px' | '12px'
}
export const CircleText: React.FC<iProps> = ({ varColorCircle, varColorText, fontsize, titulo, children }) => {
  return (
    <Flex alignItems={'center'}>
      <Flex
        width={'13px'}
        bg={`var(--${varColorCircle})`}
        height={'13px'}
        borderRadius={'full'}
        marginRight='.5rem'
        alignItems={'center'}
      />
      {titulo && <span style={{ fontSize: '1rem', color: 'var(--a2})', fontWeight: '700', marginRight: '.5rem' }}>{titulo}</span>}
      <span style={{ marginLeft: '.5rem', fontSize: fontsize, color: `var(--${varColorText})` }}>{children}</span>
    </Flex>
  )
}
