/* eslint-disable @typescript-eslint/restrict-template-expressions */

import { useEffect, useState } from 'react'
import { Body } from '../../Layouts/Body'
import { MetaButton } from '../../../components/Buttons/MetaButton'
import { WhiteBox } from '../../../components/WhiteBox'
import { appApi } from '../../../services/appApi'
import { ColumnChart } from './Components/ColumnChart'
import { ChartTitle, TableTurnOver } from './styles'
import { Flex, FormControl, FormLabel, Image, Input, Select } from '@chakra-ui/react'

interface iTurnOverModel {
  isVoluntario: boolean
  periodicidade: number
  rangeFiltro: number
  dataInicio: string
  dataFim: string
  amostrasAtual: iGrafico
  processosTotal: number
  processosDesligados: number
}

interface iGrafico {
  labelX: string[]
  series: iSeries[]
}

interface iSeries {
  nome: string
  dados: number[]
}

export const TurnOver: React.FC = () => {
  const [GraficoVoluntario, setGraficoVoluntario] = useState<iTurnOverModel>()
  const [GraficoInvoluntario, setGraficoInvoluntario] = useState<iTurnOverModel>()

  const [Periodo, setPeriodo] = useState(3)
  const [Data, setData] = useState<string>()

  const [IsLoading, setIsLoading] = useState(false)

  function getTurnOver(isVoluntario: boolean): void {
    setIsLoading(true)
    const url = Data
      ? `Turnover?voluntario=${isVoluntario}&rangeFiltro=${Periodo}&dataFim=${Data}`
      : `Turnover?voluntario=${isVoluntario}&rangeFiltro=${Periodo}`

    appApi.get(url)
      .then(e => {
        if (isVoluntario) {
          setGraficoVoluntario(e.data)
        } else {
          setGraficoInvoluntario(e.data)
        }
        setIsLoading(false)
      })
      .catch(err => console.log(err))
  }

  function onFilter(): void {
    getTurnOver(true)
    getTurnOver(false)
  }

  useEffect(() => {
    onFilter()
  }, [])

  return (
    <Body isLoading={IsLoading}>
      <h1>Turnover precoce</h1>

      <WhiteBox margin='1rem 0rem 0rem 0rem'>
        <Flex gap={'1rem'} alignItems='center'>
          <FormControl>
            <FormLabel fontSize={'xl'}>Período atual</FormLabel>
            <Select
              borderColor={'var(--Gray4)'}
              value={Periodo}
              onChange={(e) => setPeriodo(parseInt(e.target.value))}
            >
              <option value={0}>Últimos 28 dias</option>
              <option value={1}>Últimos 90 dias</option>
              <option value={2}>Últimos 180 dias</option>
              <option value={3}>Ano atual</option>
              <option value={4}>Ano anterior</option>
            </Select>
          </FormControl>

          <FormControl>
            <FormLabel fontSize={'xl'}>Fim</FormLabel>
            <Input
              type={'date'}
              borderColor={'var(--Gray4)'}
              max={new Date().toISOString().split('T')[0]}
              onChange={(e) => setData(e.target.value)}
            />
          </FormControl>
        </Flex>
        <Flex justifyContent={'end'} marginTop={'1rem'}>
          <MetaButton bg='Azul' size='md' onClick={onFilter}>Aplicar filtro</MetaButton>
        </Flex>

      {
        (GraficoInvoluntario === undefined && GraficoVoluntario === undefined && !IsLoading) && (
          <Flex alignItems={'center'} color={'var(--Azul)'} justifyContent={'center'}>
            <Image className='sah_Img' height={'20rem'} src='https://sigah.blob.core.windows.net/onboard/bbb6990f-d313-488a-af99-5383c9bd1c82.png' />
            <h3>Você ainda não possui dados para o cálculo do turnover</h3></Flex>
        )
      }
      </WhiteBox>

      {
        (GraficoVoluntario !== undefined) && (
          <WhiteBox margin='1rem 0rem' >
            <ChartTitle>
              <h3>Turnover voluntário</h3>
              <ColumnChart Grafico={GraficoVoluntario.amostrasAtual} id={'gr-1'} />
            </ChartTitle>
            <TableTurnOver>
              <thead>
                <tr>
                  <th>Legenda</th>
                  {
                    GraficoVoluntario?.amostrasAtual.labelX.map((e: string, i) => {
                      return (
                        <th key={`thead-${i}`}>{e}</th>
                      )
                    })
                  }
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>Total de colaboradores do período de 90 dias</th>
                  {
                    GraficoVoluntario?.amostrasAtual.series[1].dados.map((e: number, i) => {
                      return (
                        <th key={i}>{e}</th>
                      )
                    })
                  }
                </tr>
                <tr>
                  <th>Demissões antes de completar 90 dias</th>
                  {
                    GraficoVoluntario?.amostrasAtual.series[0].dados.map((e: number, i) => {
                      return (
                        <th key={`tbody-${i}`}>{e}</th>
                      )
                    })
                  }
                </tr>
              </tbody>
            </TableTurnOver>
          </WhiteBox>
        )
      }

      {
        (GraficoInvoluntario !== undefined) && (
          <WhiteBox margin='1rem 0rem'>
            <ChartTitle>
              <h3>Turnover involuntário</h3>
              <ColumnChart Grafico={GraficoInvoluntario.amostrasAtual} id='gr-2' />
            </ChartTitle>

            <TableTurnOver>
              <thead>
                <tr>
                  <th>Legenda</th>
                  {
                    GraficoInvoluntario?.amostrasAtual.labelX.map((e: string, i) => {
                      return (
                        <th key={`thead-${i}`}>{e}</th>
                      )
                    })
                  }
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>Total de colaboradores do período de 90 dias</th>
                  {
                    GraficoInvoluntario?.amostrasAtual.series[1].dados.map((e: number, i) => {
                      return (
                        <th key={`tbody-${i}`}>{e}</th>
                      )
                    })
                  }
                </tr>
                <tr>
                  <th>Demissões antes de completar 90 dias</th>
                  {
                    GraficoInvoluntario?.amostrasAtual.series[0].dados.map((e: number, i) => {
                      return (
                        <th key={`tbody-${i}`}>{e}</th>
                      )
                    })
                  }
                </tr>
              </tbody>
            </TableTurnOver>
          </WhiteBox>
        )
      }
    </Body>
  )
}
