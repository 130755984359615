import styled from 'styled-components'
import { Column, Flex } from '../../../../../styles/styledGlobal'

export const Container = styled(Column)<{open?: boolean, isFisrt?: boolean}>`
padding: 1rem 0rem;


${({ isFisrt }) => isFisrt && `
    border-top: 0.188rem solid var(--c5);
`}

border-bottom: 0.188rem solid var(--c5);

span{
    padding: 0rem .5rem;
    line-height: 140%;
    color: var(--a5);
    max-height: 0rem;
    transition: max-height 1.2s;
    overflow-y: hidden;
    ${({ open }) => open && `
          max-height: 30rem;
    `}
}
`

export const HeadBox = styled(Flex)<{open?: boolean}>`
justify-content: space-between;
align-items: center;
transition: margin-bottom .6s;

${({ open }) => open && `
       margin-bottom: 1rem;
  `}

h4{
  color: var(--a4);
}


svg:nth-child(1){
    cursor: pointer;
    margin-bottom: 1rem;
    color:var(--a3);
    transition: transform 1.2s;
    ${({ open }) => open && `
        transition: transform 1.2s;
       transform: rotate(135deg);
    `}
}
`
