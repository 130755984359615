import { Checkbox } from '@chakra-ui/react'
import { iPermissaoFuncionalidade, useAuth } from '../../../../../contexts/AuthContext'
import { Container } from './styles'

interface TabelaProps {
  permissao: iPermissaoFuncionalidade[]
}

export const TabelaPermissoes: React.FC<TabelaProps> = ({ permissao }) => {
  const { Atualizar } = useAuth()

  function TemPermissao(tipo: number, funcionaliade: string): boolean {
    return permissao.find(e => e.tipoUsuario === tipo && e.funcionalidade === funcionaliade)?.permitir as boolean
  }

  function DistinctFuncionaliades(): iPermissaoFuncionalidade[] {
    const arr: iPermissaoFuncionalidade[] = []

    permissao.forEach((e) => {
      if (arr.find(r => r.funcionalidade === e.funcionalidade) === undefined) {
        arr.push(e)
      }
    })

    return arr
  }

  function primeiraLetraMaiuscula(palavra: string): string {
    return palavra.charAt(0).toUpperCase() + palavra.slice(1)
  }

  function renomearFuncionalidades(nome: string): string {
    switch (nome.toLowerCase()) {
      case 'dash':
        nome = 'Dashboard'
        break
      case 'onboardings':
        nome = 'Onboarding'
        break

      case 'trilha-onboard':
        nome = 'Trilha de onboarding'
        break

      case 'trilha-desenvolvimento':
        nome = 'Trilha de desenvolvimento'
        break

      case 'trilha-offboard':
        nome = 'Offboarding'
        break

      case '1on1':
        nome = 'Reunião 1:1'
        break

      case 'pesquisa-percepcao':
        nome = 'Pesquisa de percepção'
        break

      case 'pesquisa-interna':
        nome = 'Pesquisa interna'
        break

      case 'imersao-cultural':
        nome = 'Imersão cultural'
        break

      case 'turnover':
        nome = 'Turn Over'
        break

      case 'enps':
        nome = 'eNPS'
        break

      case 'pdi':
        nome = 'PDI'
        break

      case 'pesquisa-desempenho':
        nome = 'Pesquisa de desempenho'
        break

      case 'pec':
        nome = 'Pesquisa de engajamento'
        break

      case 'feedback':
        nome = 'Feedback para organização'
        break

      case 'visao-geral':
        nome = 'Visão geral'
        break

      case 'areas':
        nome = 'Áreas'
        break

      case 'funcoes':
        nome = 'Funções'
        break

      default:
        return primeiraLetraMaiuscula(nome)
    }
    return nome
  }

  const blackList = ['trilha-onboard', 'trilha-desenvolvimento', 'trilha-offboard']

  return (
    <Container>
      <thead>
        <tr>
          <th style={{ textAlign: 'start' }}>Funcionalidade</th>
          <th>Colaborador</th>
          <th>Gestor</th>
        </tr>
      </thead>
      <tbody>
        {
          DistinctFuncionaliades().map((e, i) => {
            return (
              <tr key={i}>
                <td style={{ textAlign: 'start' }}>
                  {renomearFuncionalidades(e.funcionalidade)}
                </td>
                <td>
                  <div className='tdCenter'>
                    {!blackList.some(r => r === e.funcionalidade) &&
                      <Checkbox
                        onChange={(c) => Atualizar(e.modulo, e.funcionalidade, c.target.checked, 0)}
                        isDisabled={e.tipoUsuario === 0 && e.funcionalidade.toLowerCase().includes('dash')}
                        isChecked={TemPermissao(0, e.funcionalidade)}
                        borderColor={'var(--Gray4)'}
                      />}
                  </div>
                </td>
                <td>
                  <div className='tdCenter'>
                    <Checkbox onChange={(c) => Atualizar(e.modulo, e.funcionalidade, c.target.checked, 1)}
                      isChecked={TemPermissao(1, e.funcionalidade)}
                      borderColor={'var(--Gray4)'}
                    />
                  </div>
                </td>
              </tr>
            )
          })
        }
      </tbody>
    </Container>
  )
}
