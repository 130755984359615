import { Button, ButtonProps } from '@chakra-ui/react'

interface iButtonProps extends ButtonProps{
  varColor: string
}

export const OutlineButton: React.FC<iButtonProps> = ({ varColor, children, ...rest }) => {
  return (
    <Button
        border={`1px solid var(--${varColor})`}
        bg={'none'}
        color={`var(--${varColor})`}
        _hover={{ bg: `var(--${varColor})`, color: 'white' }}
        size={'sm'}
        borderRadius={'10px'}
        {...rest}
    >
    {children}
  </Button>
  )
}
