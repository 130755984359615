import { Flex, FormControl, FormLabel, Input, InputGroup, InputLeftElement, Select } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { FaSearch } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import { Button } from '../../../../components/Button'
import { ImgSah } from '../../../../components/ImgSah'
import { ModalDelete } from '../../../../components/ModalDelete'
import { WhiteBox } from '../../../../components/WhiteBox'
import { appApi } from '../../../../services/appApi'
import { Body } from '../../../Layouts/Body'
import { CardGestao } from './Components/CardGestao'
import { ModalCopy } from './Components/ModalCopy'

interface iGestaoPesquisaInterna {
  id: string
  nomePesquisa: string
  descricao: string
  dataInicio: string
  dataFim?: string
  ciclo: string
  statusPesquisa: string
  statusForm: string
  convites: number
  respostas: number
  onboarding: boolean
  anonima: boolean
}

interface iCopyPesquisa {
  nome: string
  descricao: string
}

export const GestaoPesquisaInternaJornada: React.FC = () => {
  const nav = useNavigate()

  const [Pesquisas, setPesquisas] = useState<iGestaoPesquisaInterna[]>([])
  const [ModalCopyIsOpen, setModalCopyIsOpen] = useState(false)
  const [ModalDeleteIsOpen, setModalDeleteIsOpen] = useState(false)
  const [ModalEncerrarIsOpen, setModalEncerrarIsOpen] = useState(false)

  const [PesquisaId, setPesquisaId] = useState<string>()
  const [StatusPesquisa, setStatusPesquisa] = useState('')
  const [TipoPesquisa, setTipo] = useState('')
  const [NomePesquisa, setNomePesquisa] = useState('')

  const [Copy, setCopy] = useState<iCopyPesquisa>()
  const [IsLoading, setIsLoading] = useState(false)

  function onOpenModalDelete(id: string): void {
    setModalDeleteIsOpen(true)
    setPesquisaId(id)
  }

  function onOpenModalEncerrar(id: string): void {
    setModalEncerrarIsOpen(true)
    setPesquisaId(id)
  }

  function onCloseModalDelete(): void {
    setModalDeleteIsOpen(false)
    setModalEncerrarIsOpen(false)
  }

  function getPesquisas(): void {
    setIsLoading(true)
    appApi.get('PesquisaInterna/Gestao')
      .then(res => {
        if (res.status === 200) {
          setPesquisas(res.data)
        }
        setIsLoading(false)
      })
      .catch(err => console.log(err))
  }

  function onDelete(): void {
    appApi.delete(`PesquisaInterna/${PesquisaId ?? ''}`)
      .then(getPesquisas)
      .catch(err => console.log(err))
  }

  function EncerrarPesquisa(): void {
    appApi.patch(`PesquisaInterna/${PesquisaId ?? ''}/Encerrar`)
      .then(getPesquisas)
      .catch(err => console.log(err))
  }

  function DuplicarPesquisa(texto: string, descricao: string): void {
    const form = {
      texto: texto,
      descricao: descricao
    }

    appApi.post(`PesquisaInterna/Copy/${PesquisaId as string}`, form)
      .then(res => {
        nav(`/Jornada/PesquisaInterna/Formulario/${res.data as string}`)
      })
      .catch(err => console.log(err))
  }

  function onOpenModalCopy(id: string): void {
    setPesquisaId(id)
    setModalCopyIsOpen(true)
    const pesquisa = Pesquisas?.find(e => e.id === id)
    if (pesquisa !== undefined) {
      setCopy({
        descricao: pesquisa.descricao,
        nome: pesquisa.nomePesquisa
      })
    }
  }

  function onCloseModalCopy(): void {
    setPesquisaId(undefined)
    setCopy(undefined)
    setModalCopyIsOpen(false)
  }

  useEffect(() => {
    getPesquisas()
  }, [])

  return (
    <Body isLoading={IsLoading}>
      <ModalDelete
        isOpen={ModalDeleteIsOpen}
        message='Deseja realmente excluir esta pesquisa?'
        onRequestClose={() => onCloseModalDelete()}
        onConfirm={onDelete}
      />

      <ModalDelete
        isOpen={ModalEncerrarIsOpen}
        message='Deseja realmente encerrar esta pesquisa?'
        onRequestClose={() => onCloseModalDelete()}
        onConfirm={EncerrarPesquisa}
      />

      <ModalCopy
        isOpen={ModalCopyIsOpen}
        onClose={onCloseModalCopy}
        onCreate={DuplicarPesquisa}
        copy={Copy}
      />

      <WhiteBox>
        <Flex justifyContent={'space-between'}>
          <h1>Jornada {'>'} Pesquisas internas</h1>
          <Button onClick={() => nav('/Jornada/PesquisaInterna/Criar')} VarColor='Azul' size={'lg'}>Criar nova pesquisa</Button>
        </Flex>

        <Flex justifyContent={'space-between'} width={'100%'} alignItems={'end'} margin={'2rem 0rem'}>

          <Flex gap={'1rem'} width='80%'>
            <FormControl width={'30%'} >
              <FormLabel fontSize={'xl'} color={'var(--Gray1)'}>Tipo</FormLabel>
              <Select borderColor={'var(--Gray4)'} size='md' onChange={(e) => setTipo(e.target.value)}>
                <option value={''}>Todas</option>
                <option value={'0'}>Nova</option>
                <option value={'1'}>Vinculada ao onboard</option>
              </Select>
            </FormControl>

            <FormControl width={'30%'} >
              <FormLabel fontSize={'xl'} color={'var(--Gray1)'}>Status</FormLabel>
              <Select borderColor={'var(--Gray4)'} size='md' onChange={(e) => setStatusPesquisa(e.target.value)}>
                <option value={''}>Nenhum</option>
                <option value={'Não enviado'}>Não enviado</option>
                <option value={'Em andamento'}>Em andamento</option>
                <option value={'Finalizado'}>Finalizado</option>
              </Select>
            </FormControl>
          </Flex>

          {/* <FormControl width={'30%'}>
                        <FormLabel fontSize={'xl'} color={'var(--Gray1)'}>Status</FormLabel>
                        <Select borderColor={'var(--Gray4)'} size='md'>
                            <option value={'2'}>Mensal</option>
                            <option value={'3'}>Bimestral</option>
                            <option value={'4'}>Trimestral</option>
                            <option value={'5'}>Semestral</option>
                            <option value={'6'}>Anual</option>
                        </Select>
                    </FormControl> */}
          {/*
                    <FormControl>
                        <FormLabel fontSize={'xl'} color={'var(--Gray1)'}>De</FormLabel>
                        <Input
                            size="md"
                            type="date"
                            borderColor={'var(--Gray4)'}
                            max={new Date().toISOString().split('T')[0]}
                        />
                    </FormControl>

                    <FormControl>
                        <FormLabel fontSize={'xl'} color={'var(--Gray1)'}>Até</FormLabel>
                        <Input
                            size="md"
                            type="date"
                            borderColor={'var(--Gray4)'}
                            max={new Date().toISOString().split('T')[0]}
                        />
                    </FormControl>
            */}

          <InputGroup width={'30%'}>
            <InputLeftElement
              pointerEvents='none'
              children={<FaSearch color='var(--Gray4)' />}
            />
            <Input
              borderColor={'var(--Gray4)'}
              type='text'
              placeholder='Pesquisa'
              onChange={(e) => setNomePesquisa(e.target.value)}
            />
          </InputGroup>
        </Flex>

        <Flex flexDir={'column'} gap={'2rem 0'}>
          {
            Pesquisas?.filter((e) => {
              if (TipoPesquisa !== '' && ((TipoPesquisa === '0' && e.onboarding) || (TipoPesquisa === '1' && !e.onboarding))) {
                return null
              } else if (NomePesquisa.length > 0 && !e.nomePesquisa.toLowerCase().includes(NomePesquisa.toLowerCase())) {
                return null
              } else if (StatusPesquisa === '') {
                return e
              } else if (e.statusPesquisa === StatusPesquisa) {
                return e
              }
              return null
            }).map((e, i) => {
              return (
                <CardGestao
                  key={i}
                  pesquisa={e}
                  onDelete={onOpenModalDelete}
                  onEncerrar={onOpenModalEncerrar}
                  onCopy={onOpenModalCopy}
                />
              )
            })
          }

        </Flex>
        {
          (Pesquisas?.length === 0 && !IsLoading) && (
            <ImgSah entidade='pesquisa'>
              <Button onClick={() => nav('/Jornada/PesquisaInterna/Criar')}
                VarColor='Azul' size={'lg'}
              >Criar nova pesquisa</Button>
            </ImgSah>
          )
        }
      </WhiteBox>
    </Body>
  )
}
