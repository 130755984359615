import { Flex, FormControl, FormLabel, Input, Radio, RadioGroup, Stack, Text, Textarea } from '@chakra-ui/react'
import { MdClose } from 'react-icons/md'
import Modal from 'react-modal'
import { useState, useEffect } from 'react'
import { Container, Header } from './styles'
import { Button } from '../../../../../../components/Button'
import { useAuth } from '../../../../../../contexts/AuthContext'
import { iPutDesligamento } from '../../../../../../interfaces'

interface iModalProps {
  onClose: () => void
  onDesligar: (form: iPutDesligamento) => void
  mensagemAlternativa?: string
}

export const PopUpDesligarColaborador: React.FC<iModalProps> = ({ mensagemAlternativa, onClose, onDesligar }) => {
  const { Permissoes } = useAuth()
  const [Passo, setPasso] = useState('Padrao')
  const [Model, setModel] = useState<iPutDesligamento>({
    desligamentoVoluntario: false,
    manterAcesso: false
  })

  function InternalDesligar(): void {
    onDesligar({
      desligamentoVoluntario: Model.desligamentoVoluntario,
      observacao: Model.observacao,
      dataDesligamento: Model.dataDesligamento,
      manterAcesso: Passo === 'Sim'
    })
  }

  useEffect(() => {
    if (!Permissoes.jornada) {
      setPasso('Nao')
    }
  }, [])

  return (
    <Modal
      isOpen
      onRequestClose={onClose}
      overlayClassName='react-modal-overlay'
      className={'react-modal-content-scroll'}
    >
      <Container width={'50rem'}>
        <Header>
          <Flex width={'100%'} justifyContent={'center'}>
            <h3>Atenção!</h3>
          </Flex>
          <Flex>
            <MdClose size={24} onClick={onClose} />
          </Flex>
        </Header>
        {Passo === 'Padrao' &&
          <Flex flexDir={'column'} padding={'1rem'}>
            <Text fontFamily={'Roboto'} fontWeight={'700'} textAlign='center' fontSize={'1.25rem'}>
              {mensagemAlternativa ?? 'Você está desligando um colaborador, gostaria de adicionar uma trilha de offboarding para acompanhar esse deligamento?'}</Text>
            <Flex marginTop={'2rem'} gap={'1rem'} justifyContent={'center'}>
              <Button onClick={() => setPasso('Nao')} VarColor='Rosa'>Não</Button>
              <Button onClick={() => setPasso('Sim')} VarColor='Azul'>Sim</Button>
            </Flex>
          </Flex>}
        {(Passo === 'Sim' || Passo === 'Nao') &&
          <Flex padding={'1rem'} flexDir={'column'}>
            <Text
              marginTop={'1rem'}
              marginBottom='1rem'
              textAlign={'center'}
              fontSize='1.25rem'
              fontWeight={'700'}
            >Qual o motivo para o desligamento?</Text>
            <Flex justifyContent={'center'}>
              <RadioGroup justifyContent={'center'} onChange={(e) => setModel({ ...Model, desligamentoVoluntario: e === '0' })}>
                <Stack direction={'column'}>
                  <Radio value={'0'}>Colaborador pediu desligamento por vontade própria</Radio>
                  <Radio value={'1'}>A empressa desligou o colaborador</Radio>
                </Stack>
              </RadioGroup>
            </Flex>
            {Passo === 'Sim' &&
              <Flex justifyContent={'center'} marginTop='1rem'>
                <FormControl width={'25rem'}>
                  <FormLabel>Data de desligamento</FormLabel>
                  <Input
                    onChange={(e) => setModel({ ...Model, dataDesligamento: e.target.value })}
                    type={'date'}
                  />
                </FormControl>
              </Flex>}

            {Passo === 'Nao' &&
              <FormControl margin={'1rem 0rem'}>
                <FormLabel fontSize={'md'}>Deixe um comentário abaixo</FormLabel>
                <Textarea
                  height={'8rem'}
                  borderColor={'var(--Gray4)'}
                  placeholder='Digite seu comentário aqui'
                  onChange={(e) => setModel({ ...Model, observacao: e.target.value })}
                />
              </FormControl>}

            <Flex marginTop={'2rem'} gap={'1rem'} justifyContent={'center'}>
              {Passo === 'Sim' && <Button
                VarColor='c6'
                onClick={onClose}
              >Cancelar</Button>}

              {Passo === 'Sim' && <Button
                VarColor='Azul'
                onClick={InternalDesligar}
              >Seguir para seleção da trilha</Button>}

              {Passo === 'Nao' && <Button
                VarColor='c6'
                onClick={onClose}
              >Cancelar</Button>}

              {Passo === 'Nao' && <Button
                VarColor='Azul'
                onClick={InternalDesligar}
              >Desligar colaborador</Button>}
            </Flex>
          </Flex>}
      </Container>
    </Modal>
  )
}
