import { InputHTMLAttributes } from 'react'
import { Container } from './styles'

interface iProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string
  label: string
}

export const TextRadio: React.FC<iProps> = ({ label, name, ...rest }) => {
  return (
    <Container>
        <input id={name} type={'radio'} {...rest} />
        <label htmlFor={name}>{label}</label>
    </Container>
  )
}
