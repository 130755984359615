/* eslint-disable @typescript-eslint/no-misused-promises */
import { Flex, Text } from '@chakra-ui/react'
import React, { useEffect, useRef, useState } from 'react'
import { Button } from '../../../../../../components/Button'
import { ModalBase } from '../../../../../../components/ModalBase'
import { appApi, SigahApi } from '../../../../../../services/appApi'
import { InputFile, Label } from './styles'
import { ObterToken } from '../../../../../../Utils/Helper'
import AvatarEditor, { CroppedRect, Position } from 'react-avatar-editor'
import { getCroppedImg } from './cropImage'
interface iModalProps {
  isOpen: boolean
  onRequestClose: () => void
}

export const ModalCrop: React.FC<iModalProps> = ({ isOpen, onRequestClose }) => {
  const [Url, setUrl] = useState('')
  const [Nome, setNome] = useState('')
  const [zoom, setZoom] = useState(1)
  const [altura, setAltura] = useState(200)
  const [largura, setLargura] = useState(300)
  const [position, setPosition] = useState<Position>({
    x: 0,
    y: 0
  })
  const [margin, setMargin] = useState('9rem')
  const [base64, setBase64] = useState('')

  const AtualizarArquivo = (event: any): void => {
    const formdata = new FormData()
    formdata.append('request', event.target.files[0])
    appApi.post('UploadFile', formdata).then(resposnse => {
      setUrl(resposnse.data.uri)
      setNome(resposnse.data.name)
    }).catch(e => console.log(e))
  }
  const editor = useRef<AvatarEditor>(null)

  const HandleSaveCrop = async (): Promise<void> => {
    const scaleCanvas: HTMLCanvasElement | undefined = editor.current?.getImageScaledToCanvas()
    const cropped: CroppedRect | undefined = editor.current?.getCroppingRect()
    console.log(cropped)
    if (cropped !== undefined && position !== undefined) {
      await getCroppedImg(Url ?? '', cropped, scaleCanvas, position).then(res => setBase64(res))
    }
  }
  function Salvar(): void {
    const file = DataURIToBlob(base64 ?? '')
    const formData = new FormData()

    formData.append('request', file, Nome)

    SigahApi.post('api/Onboard/Empresa/Logo', formData, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: ObterToken()
      }
    })
      .then((responsme) => { localStorage.setItem('logo', responsme.data); location.reload() })
      .catch(e => console.log(e))
  }

  function DataURIToBlob(dataURI: string): any {
    const splitDataURI = dataURI.split(',')
    const byteString = splitDataURI[0].includes('base64') ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
    const mimeString = splitDataURI[0].split(':')[1].split(';')[0]
    const ia = new Uint8Array(byteString.length)
    for (let i = 0; i < byteString.length; i++) { ia[i] = byteString.charCodeAt(i) }

    return new Blob([ia], { type: mimeString })
  }

  useEffect(() => {
    if (base64) {
      Salvar()
    }
  }, [base64])

  return (
    <ModalBase
      Titulo='Trocar logo'
      isOpen={isOpen}
      onClose={onRequestClose}
      Width='50rem'
    >
      <Flex flexDir={'column'}>
        <Flex position={'relative'} height={'20rem'}>
          {Url === '' &&
            <Flex alignItems={'center'} width='100%' justifyContent={'center'}>
              <Label htmlFor='inp'>Clique aqui para selecionar uma imagem</Label>
              <InputFile
                id='inp'
                type={'file'}
                onChange={(e) => { AtualizarArquivo(e) }}
              />
            </Flex>
          }
          {Url && Url.length > 3 &&
            <Flex width={'100%'} flexDir={'column'} alignItems={'center'} justifyContent={'center'} marginRight={margin}>
              <Flex width={'15rem'} zIndex={100}>
                <AvatarEditor
                  ref={editor}
                  image={Url}
                  width={largura}
                  height={altura}
                  border={45}
                  scale={zoom}
                  onPositionChange={(e: any) => setPosition(e)}
                />
              </Flex>
            </Flex>
          }
        </Flex>
        <Flex flexDir={'column'} padding={'1rem'} alignItems='center' justifyContent='center'>
          {Url && Url.length > 3 &&
            <Flex marginBottom={'1rem'} flexDir={'column'} gap='1rem'>
              <Flex alignItems={'center'} gap='.5rem'>
                <Text>Zoom:</Text>
                <input
                  type={'range'}
                  min={0.05}
                  max={15}
                  step={0.01}
                  value={zoom}
                  onChange={(e) => setZoom(Number(e.target.value))}
                  aria-labelledby="Zoom"
                />
              </Flex>
              <Flex alignItems={'center'} gap='.5rem'>
                <Text>Aspecto:</Text>
                <input
                  type={'range'}
                  min={0}
                  max={250}
                  step={0.01}
                  value={altura}
                  onChange={(e) => setAltura(Number(e.target.value))}
                  aria-labelledby="Zoom"
                />
              </Flex>
              <Flex alignItems={'center'} gap='.5rem'>
                  <Button onClick={() => { setLargura(300); setAltura(200); setMargin('9rem') }} VarColor='Gray4'>Formato retangular 3:2</Button>
                  <Button onClick={() => { setLargura(200); setAltura(200); setMargin('') }} VarColor='Gray4'>Formato quadrado 1:1</Button>
                </Flex>
              <Flex alignItems={'center'} gap='.5rem'>
                <Flex direction={'column'}>

                </Flex>
              </Flex>
            </Flex>
        }
        <Flex flexDir={'column'} padding={'1rem'} alignItems='center' justifyContent='center'>
          <Flex gap={'3rem'}>
            <Button onClick={onRequestClose} VarColor='Gray4'>Cancelar</Button>
            {Url && <Button onClick={HandleSaveCrop} VarColor='Rosa'>Salvar imagem</Button>}
          </Flex>
        </Flex>
        </Flex>
      </Flex>
    </ModalBase>
  )
}
