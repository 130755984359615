import styled from 'styled-components'
import { Flex } from '../../../styles/styledGlobal'

export const TabHead = styled(Flex)`
padding: 1.5rem 1rem;
align-items: flex-end;
justify-content: space-between;
background-color: var(--c1);
flex-wrap: wrap;
`

export const GridBox = styled.div`
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 2rem 1.5rem;
`

export const Box = styled.div`
    padding: 1rem;
    border: 1px solid var(--Gray4);
    color: ${props => props.color};
    border-radius: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    strong{
        font-weight: 700;
        font-size: 1.75rem;
    }

    span{
        font-weight: 400;
    }
`
