import styled from 'styled-components'

export const FirstDiv = styled.div`
margin-right: 1rem;
input[type=file]{
    display: none;
}
`

export const GuideText = styled.div`
font-family: 'Roboto','sans-serif';
font-style: normal;
font-weight: 700;
font-size: 20px;
color:var(--a5);
`

export const FileBox = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
background: var(--c2);
padding: 1rem;
text-align: center;
height: 20rem;
width: 20rem;

small{
    font-weight: bold;
    font-size: 14px;
}
`

export const Column = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;


span ,small{
    font-weight: bold;
}

span{
    color: var(--a2);
    margin-top: .25rem;
    word-break: break-all;
    text-align: start;
}

small{
    color: var(--c7);
}
`

export const LabelFile = styled.label`
display: flex;
align-items: center;
padding:0.6rem 2rem 0.6rem 2rem;
background:#${props => props.theme};
border-radius: 8px;
color: var(--c1);
margin-top: 1rem;

&:hover{
    filter: brightness(1.05);
    box-shadow: var(--SombraBtns);
}
cursor: pointer;
    svg{
        margin: 0rem 0.3rem 0rem .3rem;
    }
`

export const LabelSelecionado = styled.label`
display: flex;
align-items: center;
padding:0.6rem 1rem 0.6rem 1rem;
border-radius: 8px;
border: 1px solid var(--c7);
width: 12rem;

&:hover{
    background: var(--c7);
    color: white;

    svg{
        color: white;
    }
}

cursor: pointer;
    svg{
        color:var(--c7) ;
        margin: 0rem 0.3rem 0rem .3rem;
    }
`

export const BoxIntegracao = styled.div`
width: 100%;
display: flex;
flex-direction: column;
padding: 1rem;
margin-bottom: 1rem;
`

export const VideoBox = styled.video`
min-width:110px;
max-width:110px;
height: 110px;
margin-right: 1rem;
background-repeat: no-repeat;
background-size: contain;
background-position: center;
border-radius: .5rem;
border: 1px solid var(--c5);
`
