import { Avatar, Button, CircularProgress, CircularProgressLabel, Flex, Image, Input, InputGroup, InputLeftElement, Menu, MenuButton, MenuItem, MenuList, Text } from '@chakra-ui/react'
import { Container } from './styles'
import React, { useEffect, useState } from 'react'

import IconeLove from '../../../../../../assets/IconeLove.svg'
import IconeMagoado from '../../../../../../assets/IconeMagoado.svg'
import IconeFeliz from '../../../../../../assets/IconeFeliz.svg'
import IconePiscada from '../../../../../../assets/IconePiscada.svg'
import IconeTriste from '../../../../../../assets/IconeTriste.svg'

import { FaCheckCircle, FaChevronDown, FaChevronUp, FaClipboardList, FaEdit, FaEye, FaSearch, FaTasks, FaTrash } from 'react-icons/fa'
import { iColaboradorBase } from '../../../../../../interfaces'
import { useNavigate } from 'react-router-dom'
import { BsThreeDotsVertical } from 'react-icons/bs'

interface iDadoTabela {
  id: string
  colaborador: iColaboradorBase
  origem: string
  totalAcoesRealizadas: number
  totalAcoes: number
  prazo: string
  notaUltimaAvaliacao?: number
  status: number
  totalAcoesAtrasadas: number
}

interface iTabelaProps {
  onOpenModalDelete: (id: string) => void
  slice?: number
  dados: iDadoTabela[]
}

export const Tabela: React.FC<iTabelaProps> = ({ dados, slice, onOpenModalDelete }) => {
  const nav = useNavigate()
  const [Texto, setTexto] = useState('')
  const [Dados, setDados] = useState<iDadoTabela[]>()
  const [Filter, setFilter] = useState('alfabetica')
  const [IsLoading, setIsLoading] = useState<boolean>()
  const [CrescenteDecrescente, setCrescenteDecrescente] = useState<boolean>(false)

  function getColor(numero: number): string {
    if (numero < 50) {
      return '#FE7B1E'
    } else if (numero >= 50 && numero < 75) {
      return '#6DA7A8'
    } else if (numero >= 75 && numero < 100) {
      return '#1961E0'
    } else {
      return '#89DB81'
    }
  }

  function UltimaAvaliacao(status: number, nota?: number): any {
    if (status === 1) {
      return <FaTasks color='var(--Rosa)' size={28}/>
    } else if (status === 6) {
      return <Text fontWeight={'900'} color={'var(--Red)'}>Ação em atraso</Text>
    }

    if (nota === 1) {
      return <Image src={IconeTriste}/>
    } else if (nota === 2) {
      return <Image src={IconeMagoado}/>
    } else if (nota === 3) {
      return <Image src={IconeFeliz}/>
    } else if (nota === 4) {
      return <Image src={IconePiscada}/>
    } else if (nota === 5) {
      return <Image src={IconeLove}/>
    } else {
      return '-'
    }
  }

  function setFilterList(tipoFiltro: string, metodoFiltro: boolean): void {
    setIsLoading(true)
    if (tipoFiltro === Filter) {
      setCrescenteDecrescente(metodoFiltro)
    } else {
      setFilter(tipoFiltro)
      setCrescenteDecrescente(false)
    }
  }
  useEffect(() => {
    const copy: iDadoTabela[] = dados
    if (Filter === 'alfabetica') {
      copy.sort((a, b) => {
        if (!CrescenteDecrescente && a.colaborador.nome < b.colaborador.nome) {
          return 1
        }
        return -1
      })
    }
    if (Filter === 'totalAcoes') {
      copy.sort((a, b) => {
        if (!CrescenteDecrescente && a.totalAcoes < b.totalAcoes) {
          return 1
        }
        return -1
      })
    }
    if (Filter === 'progresso') {
      copy.sort((a, b) => {
        if (!CrescenteDecrescente && a.totalAcoes / a.totalAcoesRealizadas < b.totalAcoes / b.totalAcoesRealizadas) {
          return 1
        }
        return -1
      })
    }
    if (Filter === 'atrasados') {
      copy.sort((a, b) => {
        if (!CrescenteDecrescente && a.totalAcoesAtrasadas < b.totalAcoesAtrasadas) {
          return 1
        }
        return -1
      })
    }
    if (Filter === 'prazo') {
      copy.sort((a, b) => {
        const data1 = a.prazo === null ? CrescenteDecrescente ? ['31', '12', '2050'] : ['31', '10', '2000'] : a.prazo.split('/', 3)
        const data2 = b.prazo === null ? CrescenteDecrescente ? ['31', '12', '2040'] : ['31', '10', '2010'] : b.prazo.split('/', 3)
        if (!CrescenteDecrescente && new Date(parseInt(data1[2]), parseInt(data1[1]), parseInt(data1[0])) < new Date(parseInt(data2[2]), parseInt(data2[1]), parseInt(data2[0]))) {
          return 1
        }
        return -1
      })
    }
    if (Filter === 'nota') {
      copy.sort((a, b) => {
        const nota1 = a.notaUltimaAvaliacao === undefined ? 0 : a.notaUltimaAvaliacao
        const nota2 = b.notaUltimaAvaliacao === undefined ? 0 : b.notaUltimaAvaliacao
        if (!CrescenteDecrescente && nota1 < nota2) {
          return 1
        }
        return -1
      })
    }
    setDados(copy)
    setIsLoading(false)
  }, [Filter, CrescenteDecrescente])
  useEffect(() => {
    setFilterList('alfabetica', true)
  }, [])

  return (
    <Flex flexDir={'column'} width={'100%'}>
      <Flex width={'100%'} justifyContent={'space-between'}>
        <h3>PDI do time</h3>
        <InputGroup width={'30%'}>
          <InputLeftElement
            pointerEvents='none'
            children={<FaSearch color='var(--Gray4)' />}
          />
          <Input
            onChange={(e) => setTexto(e.target.value)}
            borderColor={'var(--Gray4)'}
            type='text'
            placeholder='Pesquisa'
          />
        </InputGroup>
      </Flex>
      <Container>
        <thead>
          <tr>
            <th><Flex alignItems={'center'} gap={'.5rem'} cursor={'pointer'} onClick={() => setFilterList('alfabetica', !CrescenteDecrescente)}>Colaborador {Filter === 'alfabetica' && !CrescenteDecrescente ? <FaChevronUp/> : <FaChevronDown/>}</Flex></th>
            <th><Flex justifyContent={'center'} alignItems={'center'} gap={'.5rem'} cursor={'pointer'} onClick={() => setFilterList('origem', !CrescenteDecrescente)}>Origem {Filter === 'origem' && !CrescenteDecrescente ? <FaChevronUp/> : <FaChevronDown/>}</Flex></th>
            <th><Flex justifyContent={'center'} alignItems={'center'} gap={'.5rem'} cursor={'pointer'} onClick={() => setFilterList('totalAcoes', !CrescenteDecrescente)}>Quant. de ações {Filter === 'totalAcoes' && !CrescenteDecrescente ? <FaChevronUp/> : <FaChevronDown/>}</Flex></th>
            <th><Flex justifyContent={'center'} alignItems={'center'} gap={'.5rem'} cursor={'pointer'} onClick={() => setFilterList('atrasados', !CrescenteDecrescente)}>Atrasadas {Filter === 'atrasados' && !CrescenteDecrescente ? <FaChevronUp/> : <FaChevronDown/>}</Flex></th>
            <th><Flex justifyContent={'center'} alignItems={'center'} gap={'.5rem'} cursor={'pointer'} onClick={() => setFilterList('progresso', !CrescenteDecrescente)}>Progresso {Filter === 'progresso' && !CrescenteDecrescente ? <FaChevronUp/> : <FaChevronDown/>}</Flex></th>
            <th><Flex justifyContent={'center'} alignItems={'center'} gap={'.5rem'} cursor={'pointer'} onClick={() => setFilterList('prazo', !CrescenteDecrescente)}>Prazo {Filter === 'prazo' && !CrescenteDecrescente ? <FaChevronUp/> : <FaChevronDown/>}</Flex></th>
            <th><Flex justifyContent={'center'} alignItems={'center'} gap={'.5rem'} cursor={'pointer'} onClick={() => setFilterList('nota', !CrescenteDecrescente)}>Última avaliação {Filter === 'nota' && !CrescenteDecrescente ? <FaChevronUp/> : <FaChevronDown/>}</Flex></th>
          </tr>
        </thead>
        {!IsLoading && <tbody>
          {Dados?.filter((e) => {
            if (Texto === '') {
              return e
            } else if (e.colaborador.nome.toLowerCase().includes(Texto.toLowerCase())) {
              return e
            }
            return null
          }).slice(0, slice).map((e, i) => {
            return (
                <tr key={i}>
                  <th>
                    <Flex alignItems={'center'}>
                      <Avatar marginRight={'1rem'} size={'md'} src={e.colaborador.avatar} name={e.colaborador.nome} />
                      <Flex textAlign={'start'} flexDir={'column'}>
                        <span>{e.colaborador.nome}</span>
                        <strong>{e.colaborador.nomeDepartamento}</strong>
                      </Flex>
                    </Flex>
                  </th>
                  <th>{e.origem}</th>
                  <th>{e.totalAcoesRealizadas}/{e.totalAcoes}</th>
                  <th>{e.totalAcoesAtrasadas}</th>
                  <th>
                    <CircularProgress value={((e.totalAcoesRealizadas / e.totalAcoes) * 100)} color={getColor(((e.totalAcoesRealizadas / e.totalAcoes) * 100))}>
                      <CircularProgressLabel top={'45%'}>{((e.totalAcoesRealizadas / e.totalAcoes) * 100).toFixed(0)}%</CircularProgressLabel>
                    </CircularProgress>
                  </th>
                  <th>
                    {e.prazo}
                  </th>

                  <th>
                    <Flex justifyContent={'center'} height='2.5rem'>
                      {
                        UltimaAvaliacao(e.status, e.notaUltimaAvaliacao)
                      }
                    </Flex>
                  </th>
                  <th>
                    <Flex justifyContent={'end'} gap={'.5rem'} marginRight={'1rem'}>
                    {(e.status === 0 || e.status === 1 || e.status === 5 || e.totalAcoesRealizadas === 0) &&
                      <Menu matchWidth={true} placement={'bottom-end'}>
                      <MenuButton _focus={{ backgroundColor: 'transparent' }} _active={{ backgroundColor: 'transparent', boxShadow: '0px 0px 5px rgb(0,0,0,0.3)' }} as={Button} backgroundColor={'transparent'} _hover={{ backgroundColor: 'transparent', boxShadow: '0px 0px 5px rgb(0,0,0,0.3)' }} color={'var(--c7)'} padding={'0px'}><BsThreeDotsVertical /></MenuButton>
                      <MenuList paddingY={'10px'} boxShadow={'0px 0px 5px rgb(0,0,0,0.3)'} borderRadius={'8px'}>
                        {
                          (e.status === 0) && (
                            <MenuItem onClick={() => nav(`/Desempenho/PDI/Editar/${e.id}`)} color={'var(--g1)'} _focus={{ backgroundColor: 'var(--c1)' }} backgroundColor={'var(--c1)'} _hover={{ backgroundColor: 'var(--Azul)', color: '#ffff' }} icon={<FaEdit />}>
                              <span>Editar</span>
                            </MenuItem>
                          )
                        }
                        {
                          (e.status === 1) && (
                            <MenuItem onClick={() => nav(`/Rh/VisualizarPerfil/${e.colaborador.id}?pdiId=${e.id}`)} color={'var(--g1)'} _focus={{ backgroundColor: 'var(--c1)' }} backgroundColor={'var(--c1)'} _hover={{ backgroundColor: 'var(--Azul)', color: '#ffff' }} icon={<FaCheckCircle />}>
                              <span>Aprovar</span>
                            </MenuItem>
                          )
                        }
                        {
                          (e.status === 5) && (
                            <>
                              <MenuItem onClick={() => nav(`/Desempenho/PDI/Avaliacao/${e.id}`)} color={'var(--g1)'} _focus={{ backgroundColor: 'var(--c1)' }} backgroundColor={'var(--c1)'} _hover={{ backgroundColor: 'var(--Azul)', color: '#ffff' }} icon={<FaClipboardList />}>
                                <span>Avaliar</span>
                              </MenuItem>
                              <MenuItem onClick={() => nav(`/Desempenho/PDI/Editar/${e.id}`)} color={'var(--g1)'} _focus={{ backgroundColor: 'var(--c1)' }} backgroundColor={'var(--c1)'} _hover={{ backgroundColor: 'var(--Azul)', color: '#ffff' }} icon={<FaEye />}>
                                <span>Visualizar</span>
                              </MenuItem>
                            </>
                          )
                        }
                        {
                          (e.totalAcoesRealizadas === 0) && (
                            <MenuItem onClick={() => onOpenModalDelete(e.id)} color={'var(--g1)'} _focus={{ backgroundColor: 'var(--c1)' }} backgroundColor={'var(--c1)'} _hover={{ backgroundColor: 'var(--Azul)', color: '#ffff' }} icon={<FaTrash />}>
                              <span>Excluir</span>
                            </MenuItem>
                          )
                        }
                      </MenuList>
                    </Menu>
                    }
                    </Flex>
                  </th>
                </tr>
            )
          })}
        </tbody> }
        {IsLoading && <tbody>
          {Dados?.filter((e) => {
            if (Texto === '') {
              return e
            } else if (e.colaborador.nome.toLowerCase().includes(Texto.toLowerCase())) {
              return e
            }
            return null
          }).slice(0, slice).map((e, i) => {
            return (
                <tr key={i}>
                  <th>
                    <Flex alignItems={'center'}>
                      <Avatar marginRight={'1rem'} size={'md'} src={e.colaborador.avatar} name={e.colaborador.nome} />
                      <Flex textAlign={'start'} flexDir={'column'}>
                        <span>{e.colaborador.nome}</span>
                        <strong>{e.colaborador.nomeDepartamento}</strong>
                      </Flex>
                    </Flex>
                  </th>
                  <th>{e.origem}</th>
                  <th>{e.totalAcoesRealizadas}/{e.totalAcoes}</th>
                  <th>10</th>
                  <th>
                    <CircularProgress value={((e.totalAcoesRealizadas / e.totalAcoes) * 100)} color={getColor(((e.totalAcoesRealizadas / e.totalAcoes) * 100))}>
                      <CircularProgressLabel top={'45%'}>{((e.totalAcoesRealizadas / e.totalAcoes) * 100).toFixed(0)}%</CircularProgressLabel>
                    </CircularProgress>
                  </th>
                  <th>
                    {e.prazo}
                  </th>

                  <th>
                    <Flex justifyContent={'center'} height='2.5rem'>
                      {
                        UltimaAvaliacao(e.status, e.notaUltimaAvaliacao)
                      }
                    </Flex>
                  </th>
                  <th>
                    <Flex justifyContent={'end'} gap={'.5rem'} marginRight={'1rem'}>
                    {(e.status === 0 || e.status === 1 || e.status === 5 || e.totalAcoesRealizadas === 0) &&
                      <Menu matchWidth={true} placement={'bottom-end'}>
                      <MenuButton _focus={{ backgroundColor: 'transparent' }} _active={{ backgroundColor: 'transparent' }} as={Button} backgroundColor={'transparent'} _hover={{ backgroundColor: 'transparent', boxShadow: '0px 0px 5px rgb(0,0,0,0.3)' }} color={'var(--c7)'} padding={'0px'}><BsThreeDotsVertical /></MenuButton>
                      <MenuList padding={'0'} boxShadow={'0px 0px 12px rgb(0,0,0,0.3)'}>
                        {
                          (e.status === 0) && (
                            <MenuItem onClick={() => nav(`/Desempenho/PDI/Editar/${e.id}`)} color={'var(--a3)'} _focus={{ backgroundColor: 'var(--c1)' }} backgroundColor={'var(--c1)'} _hover={{ backgroundColor: '#d5d5d5', color: '#ffff' }}>
                              <span>Editar</span>
                            </MenuItem>
                          )
                        }
                        {
                          (e.status === 1) && (
                            <MenuItem onClick={() => nav(`/Rh/VisualizarPerfil/${e.colaborador.id}?pdiId${e.id}`)} color={'var(--a3)'} _focus={{ backgroundColor: 'var(--c1)' }} backgroundColor={'var(--c1)'} _hover={{ backgroundColor: '#d5d5d5', color: '#ffff' }}>
                              <span>Aprovar</span>
                            </MenuItem>
                          )
                        }
                        {
                          (e.status === 5) && (
                            <>
                              <MenuItem onClick={() => nav(`/Desempenho/PDI/Avaliacao/${e.id}`)} color={'var(--a3)'} _focus={{ backgroundColor: 'var(--c1)' }} backgroundColor={'var(--c1)'} _hover={{ backgroundColor: '#d5d5d5', color: '#ffff' }}>
                                <span>Avaliar</span>
                              </MenuItem>
                              <MenuItem onClick={() => nav(`/Desempenho/PDI/Editar/${e.id}`)} color={'var(--a3)'} _focus={{ backgroundColor: 'var(--c1)' }} backgroundColor={'var(--c1)'} _hover={{ backgroundColor: '#d5d5d5', color: '#ffff' }}>
                                <span>Visualizar</span>
                              </MenuItem>
                            </>
                          )
                        }
                        {
                          (e.totalAcoesRealizadas === 0) && (
                            <MenuItem onClick={() => onOpenModalDelete(e.id)} color={'var(--Red)'} _focus={{ backgroundColor: 'var(--c1)' }} backgroundColor={'var(--c1)'} _hover={{ backgroundColor: '#d5d5d5', color: '#ffff' }} >
                              <span><FaTrash /></span>
                            </MenuItem>
                          )
                        }
                      </MenuList>
                    </Menu>
                    }
                    </Flex>
                  </th>
                </tr>
            )
          })}
        </tbody> }
      </Container>
    </Flex>
  )
}
