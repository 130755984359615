import React, { useEffect, useState } from 'react'
import Modal from 'react-modal'
import { MdClose } from 'react-icons/md'
import { Header } from './styles'
import { Flex, FormControl, FormLabel, useToast } from '@chakra-ui/react'
import { iNomeId, ISquad } from '../../../../../../../../interfaces'
import { appApi } from '../../../../../../../../services/appApi'
import { FindUser } from '../../../../../../../../components/FindBox'
import { Button } from '../../../../../../../../components/Button'
import { CompleteTagContainer } from '../../../../../../../../components/CompleteTagContainer'

interface iModalSquad {
  isOpen: boolean
  squad: ISquad
  onRequestClose: () => void
  onConfirm: (colaboradorId: string, model: iPostColaboradorSquad) => void
}

interface iPostColaboradorSquad {
  squadId: string
  papelId: string
}

export const ModalSquad: React.FC<iModalSquad> = ({ isOpen, squad, onConfirm, onRequestClose }) => {
  const toast = useToast()
  Modal.setAppElement('#root')
  const [Model, setModel] = useState<iPostColaboradorSquad>({
    squadId: squad.id,
    papelId: ''
  })
  const [Options, setOptions] = useState<iNomeId[]>([])
  const [Selecionado, setSelecionado] = useState('')

  const [ColaboradorIsIvalid, setColaboradorIsIvalid] = useState(false)

  function getSelectColaboradores(): void {
    appApi.get('Colaborador/Select').then(res => setOptions(res.data)).catch(err => console.log(err))
  }

  function SelecionarColaborador(id: string): void {
    setSelecionado(id)
  }

  function addTag(tag: string): void {
    setModel({ ...Model, papelId: tag })
  }

  function removeTag(id: string): void {
    setModel({ ...Model, papelId: '' })
  }

  function InternalOnConfirm(): void {
    setColaboradorIsIvalid(false)

    if (Selecionado === '') {
      setColaboradorIsIvalid(true)
      toast({
        title: 'Selecione um colaborador',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return
    }

    if (Model.squadId === '') {
      toast({
        title: 'Selecione um squad',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return
    }

    if (Model.papelId === '') {
      toast({
        title: 'Selecione um papel',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return
    }

    onConfirm(Selecionado, Model)
  }

  useEffect(() => {
    getSelectColaboradores()
  }, [])

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName='react-modal-overlay'
      className='react-modal-content-default'
    >
      <Header>
        <Flex justifyContent={'space-between'}>
          <div>
            <h2>Adicione um colaborador neste squad</h2>
          </div>
          <MdClose color='var(--a3)' cursor={'pointer'} onClick={onRequestClose} />
        </Flex>
      </Header>
      <Flex flexDir={'column'} padding={'1rem'} gap={'1rem'}>
        <Flex flexDir={'column'}>
          <FormLabel>Nome do colaborador</FormLabel>
          <FindUser
            lista={Options.filter(e => squad.colaboradores.find(r => r.id === e.id) === undefined)}
            onChoice={SelecionarColaborador}
            placeholder='Digite o nome do participante'
            selecionado={Selecionado}
            isInvalid={ColaboradorIsIvalid}
          />
        </Flex>
        <FormControl>
          <FormLabel>Squad</FormLabel>
          <CompleteTagContainer
            isGrey
            addTag={addTag}
            removeTag={removeTag}
            selecionadas={[squad.id, Model.papelId]}
            defaultValue={squad.id}
          />
        </FormControl>
      </Flex>
      <Flex alignItems={'center'} justifyContent={'end'} padding={'0rem 1rem 1rem 0rem'}>
        <Button
          size='lg'
          VarColor='v2'
          onClick={() => InternalOnConfirm()}
        >Adicionar</Button>
      </Flex>
    </Modal>
  )
}
