import { FaCopy, FaPen, FaTrashAlt } from 'react-icons/fa'
import { HeadContainer, ContainerFormulario, Btns, Main } from './styles'
import { GreyButton } from '../../../../components/Buttons/GreyButton'
import { GreenButton } from '../../../../components/Buttons/GreenButton'
import { useEffect, useState } from 'react'
import { iIntegracao, iEtapa } from '../../../../interfaces'
import { appApi } from '../../../../services/appApi'
import { TimeLineOnboard } from '../../../../components/TimeLineOnboard'
import { useNavigate, useParams } from 'react-router-dom'
import { useToast } from '@chakra-ui/react'
import { Body } from '../../../Layouts/Body'
import { ModalDelete } from '../../../../components/ModalDelete'
import { CreateEtapaBox } from '../../../../components/CreateEtapaBox'
import { ModalCreateForm } from '../../../../components/ModalCreateForm'
import { ValidarEtapa } from '../../../../Utils/Helper'
type LocationProps = {
  trilhaId: string
  processoId?: string
}

export const ConfigIntegracao: React.FC = () => {
  const toast = useToast()
  const navigate = useNavigate()

  const { trilhaId, processoId } = useParams<LocationProps>()
  const [Integracoes, setIntegracoes] = useState<iIntegracao[]>([])
  const [showModal, setshowModal] = useState(false)

  const [Selecionar, setSelecionar] = useState(false)

  const [ModalConfirmIsOpen, setModalConfirmIsOpen] = useState(false)
  const [ModalCopyIsOpen, setModalCopyIsOpen] = useState(false)

  const [Id, setId] = useState('')

  const [Integracao, setIntegracao] = useState<iIntegracao>({
    id: '',
    itensIntegracao: [],
    nome: ''
  })

  function DeletarIntegracao(): void {
    appApi.delete(`Integracao/${Id}`)
      .then(resposne => {
        if (resposne.status === 200) {
          setIntegracoes(Integracoes.filter(e => e.id !== Id))
        }
      }).catch(err => console.error(err))
  }

  function getIntegracoes(): void {
    appApi.get('Integracao').then(response => { setIntegracoes(response.data) })
      .catch(err => console.log(err))
  }

  function AdicionarEtapa(form: iEtapa): void {
    if (!ValidarEtapa(form)) {
      toast({
        title: 'Preencha os campos de etapa',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return
    }
    const url = trilhaId ? `IntegracaoEtapa/${trilhaId}` : processoId ? `IntegracaoEtapaColaborador/${processoId}` : ''

    appApi.post(`${url}?integracaoId=${Integracao.id}`, form).then(() => {
      toast({
        title: 'Etapa cadastrada com sucesso',
        status: 'success',
        isClosable: false
      })
      navigate(-1)
    }).catch(() => {
      toast({
        title: 'Erro ao cadastrar a etapa',
        status: 'error',
        isClosable: false
      })
    })
  }

  function CopyIntegrcao(Texto: string): void {
    const form = {
      texto: Texto
    }
    appApi.post(`Integracao/Copy/${Id}`, form)
      .then(response => {
        if (response.status === 200) {
          getIntegracoes()
          setModalCopyIsOpen(false)
        }
      })
      .catch(err => console.log(err))
  }

  function closeModalConfirm(): void {
    setModalConfirmIsOpen(false)
    setId('')
  }

  function onCloseModalCopy(): void {
    setModalCopyIsOpen(false)
    setId('')
  }

  function onOpenModalCopy(id: string): void {
    setModalCopyIsOpen(true)
    setId(id)
  }

  function onBackEtapa(): void {
    setSelecionar(false)
  }

  function CriarIntegracao(Texto: string): void {
    appApi.post('Integracao', { nome: Texto }).then(response => {
      navigate(response.data)
    }).catch(e => console.log(e))
  }

  useEffect(() => {
    getIntegracoes()
  }, [])

  return (
      <Body>
        <ModalDelete
          onRequestClose={closeModalConfirm}
          onConfirm={DeletarIntegracao}
          isOpen={ModalConfirmIsOpen}
          message='Realmente deseja excluir essa integração?'
        />
        <Main>

          <ModalCreateForm
            isOpen={showModal}
            onClose={() => setshowModal(false)}
            onCreate={CriarIntegracao}
          />

          <ModalCreateForm
            onClose={onCloseModalCopy}
            onCreate={CopyIntegrcao}
            isOpen={ModalCopyIsOpen}
          />
          <TimeLineOnboard hide={processoId !== undefined} />
          {
            !Selecionar
              ? <div>
                <HeadContainer>
                  <h1>Integração</h1>
                  <Btns>
                    <GreenButton Size='lg' isNav={false} OnClick={() => setshowModal(true)}>Criar nova lista de integração</GreenButton>
                    <GreyButton
                      Size='lg'
                      isNav={true}
                    >Voltar</GreyButton>
                  </Btns>
                </HeadContainer>

                <h2>Integrações salvas</h2>
                <section>
                  {
                    Integracoes.map((e: iIntegracao, i) => {
                      return (
                        <ContainerFormulario key={i}>
                          <span>{e.nome}</span>
                          <div>
                            <button onClick={() => { setSelecionar(true); setIntegracao(e) }}>Selecionar</button>
                            <button onClick={() => onOpenModalCopy(e.id)}><FaCopy size={11} />Copiar integração</button>
                            <button onClick={() => {
                              navigate(`${e.id}`)
                            }}><FaPen size={11} />Editar</button>
                            <button onClick={() => { setModalConfirmIsOpen(true); setId(e.id) }}><FaTrashAlt size={11} /></button>
                          </div>
                        </ContainerFormulario>
                      )
                    })
                  }
                </section>
              </div>
              : <>
                <h1>Integração</h1>
                <CreateEtapaBox
                  onGreenAction={AdicionarEtapa}
                  onGreyAction={onBackEtapa}
                  keyWord={'integração'}
                />
              </>
          }
        </Main>
      </Body>
  )
}
