import { MdClose } from 'react-icons/md'
import Modal from 'react-modal'
import { MetaButton } from '../Buttons/MetaButton'
import { Column, Flex } from '../../styles/styledGlobal'

interface props {
  isOpen: boolean
  onRequestClose: () => void
  onConfirm: () => void
  message: string
}

export const ModalDelete: React.FC<props> = ({ isOpen, onRequestClose, onConfirm, message }) => {
  Modal.setAppElement('#root')
  return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            overlayClassName='react-modal-overlay'
            className='react-modal-content-poupUp'
        >
            <Flex>
                <MdClose size={24} onClick={onRequestClose} />
            </Flex>

            <Column>
                <h2 style={{ color: 'var(--secundario5)' }}>Atenção!</h2>
                <span>{message}</span>
                <Flex>
                    <MetaButton bg='c5' size='md' onClick={onRequestClose}>Cancelar</MetaButton>
                    <MetaButton bg='v2' size='md' onClick={() => { onConfirm(); onRequestClose() }}>Continuar</MetaButton>
                </Flex>
            </Column>
        </Modal>
  )
}
