import styled from 'styled-components'

export const Form = styled.form`
span,li{
    color:#203864;
    font-weight: 500;
}
`

export const Tags = styled.div`
display: flex;
flex-wrap: wrap;
gap: 1rem 1rem;
span{
    color: white;
}
`
