/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { Checkbox, Flex } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { FaFilter, FaPaperPlane } from 'react-icons/fa'
import { Button } from '../../../../../../components/Button'
import { iConvite } from '../../../../../../interfaces'
import { CircleText } from '../CircleText'
import { TableDiv } from './styles'

interface iTabelaprops {
  rows: iConvite[]
  anonima?: boolean
  selecionados?: string[]
  onCheck: (id: string, all?: boolean) => void
  onSend: (id: string) => void
  isEdit?: boolean
}

export const TableConvites: React.FC<iTabelaprops> = ({ rows, isEdit, selecionados, anonima, onCheck, onSend }) => {
  const [Rows, setRows] = useState<iConvite[]>([])

  useEffect(() => {
    setRows(rows)
  }, [rows])

  function SelecionarTodos(check: boolean): void {
    onCheck('', check)
  }

  function FilterBy(tipo: number): void {
    const arr = [...Rows]
    arr.sort((a, b) => {
      switch (tipo) {
        case 0:
          return a.nome.toLowerCase() > b.nome.toLowerCase() ? 1 : -1

        case 1:
          return a.email.toLowerCase() > b.email.toLowerCase() ? 1 : -1

        case 2:
          return a.area.toLowerCase() > b.area.toLowerCase() ? 1 : -1

        default:
          return a.nome.toLowerCase() > b.nome.toLowerCase() ? 1 : -1
      }
    })
    setRows(arr)
  }

  function ObterCor(e: iConvite): string {
    if (!anonima) {
      return e.status === 'Não enviado' ? 'c5' : e.status === 'Enviado' ? 'Rosa' : e.status === 'Respondido' ? 'v2' : ''
    } else {
      return e.status === 'Respondido' || e.status === 'Enviado' ? 'Rosa' : 'c5'
    }
  }

  return (
    <TableDiv>
      <table>
        <thead>
          <tr>
            {isEdit && <th>
              <Flex gap={'0.50rem'}>
                <span>Selecionar todos </span>
                <Checkbox marginLeft={'1rem'} borderColor={'var(--Gray4)'} onChange={(e) => SelecionarTodos(e.target.checked)}/>
              </Flex></th>}
            <th onClick={() => FilterBy(0)}>
              <div>Nome<FaFilter /></div>
            </th>
            <th onClick={() => FilterBy(1)}>
              <div>Email<FaFilter /></div>
            </th>
            <th onClick={() => FilterBy(2)}>
              <div>Área<FaFilter /></div>
            </th>
            {!isEdit &&
              <th onClick={() => FilterBy(3)}>
                <div>Status<FaFilter /></div>
              </th>
            }
          </tr>
        </thead>
        <tbody>
          {
            Rows.map((e, i) => {
              return (
                <tr key={i}>
                  {isEdit && <td>
                    <Checkbox isChecked={selecionados?.find(r => r === e.id) !== undefined} onChange={() => onCheck(e.id)} borderColor={'var(--a4)'} />
                  </td>
                  }
                  <td>{e.nome}</td>
                  <td>{e.email}</td>
                  <td>{e.area}</td>
                  {!isEdit && <td>
                    <Flex justifyContent={'center'} gap={'1rem 1rem'} flexWrap={'wrap'}>
                      <CircleText fontsize='14px'
                        varColorCircle={ObterCor(e)}
                        varColorText=''
                      >
                        {!anonima ? e.status : e.status === 'Respondido' || e.status === 'Enviado' ? 'Enviado' : 'Não enviado'}
                      </CircleText>
                      {
                        (e.status === 'Não enviado' && !anonima) && (
                          <Button
                            onClick={() => onSend(e.id)}
                            leftIcon={<FaPaperPlane />}
                            color={'var(--a5)'}
                            VarColor='Yellow5'
                          >Enviar</Button>
                        )
                      }

                      {
                        (e.status === 'Enviado' && !anonima) && (
                          <Button
                            onClick={() => onSend(e.id)}
                            leftIcon={<FaPaperPlane />}
                            color={'var(--a5)'}
                            VarColor='secundario2'
                          >Reenviar</Button>
                        )
                      }
                    </Flex>
                  </td>}
                </tr>
              )
            })
          }
        </tbody>
      </table>
    </TableDiv>
  )
}
