import styled from 'styled-components'

export const ContainerMax = styled.div`
display: flex;
justify-content: center;
min-height: 100vh;
`

export const Container = styled.div`
width: 75rem;
margin: 0 auto;
`
