import React from 'react'
import { FaDoorOpen, FaTrashAlt } from 'react-icons/fa'
import { FiEdit } from 'react-icons/fi'

import { Container } from './styles'

interface IDepartment {
  id: string
  nome: string
  departamentoPai?: IDepartment
}

interface IDepartmentCardProps {
  department: IDepartment
  onEdit: (department: IDepartment) => void
  onDelete: (department: IDepartment) => void
}

export const DepartmentCard: React.FC<IDepartmentCardProps> = ({
  department,
  onEdit,
  onDelete
}) => {
  return (
    <Container>
      <header>
        <FaDoorOpen />
        <div>
          <strong>{department.nome}</strong>
          <span>{department.departamentoPai?.nome}</span>
        </div>
      </header>
      <div>
        <button type="button" onClick={() => onEdit(department)}>
          <FiEdit />
        </button>

        <button type="button" onClick={() => onDelete(department)}>
          <FaTrashAlt size={20} />
        </button>
      </div>
    </Container>
  )
}
