import styled, { css } from 'styled-components'
import { Flex } from '../../../../styles/styledGlobal'

interface iContainerProps{
  width?: string
  margin?: string
  borderColor?: string
}

export const Container = styled(Flex)<iContainerProps>`
justify-content: center;
align-items: center;
padding: 1rem;
border: 0.063rem solid #1961E0;
border-radius: 1.5rem;
${props => props.width && css<iContainerProps>`
     width: ${props => props.width};
`}

${props => props.margin && css<iContainerProps>`
     margin: ${props => props.margin};
`}

${props => props.borderColor && css<iContainerProps>`
     border: 0.063rem solid var(--${props => props.borderColor});
`}
`
