import { Flex } from '@chakra-ui/react'
import { useState } from 'react'
import { AiOutlinePlus } from 'react-icons/ai'
import { FaPen } from 'react-icons/fa'
import { iPerguntasFrequentes } from '../../../../../interfaces'
import { Container, HeadBox } from './styles'

interface iTextBoxProps {
  pergunta: iPerguntasFrequentes
  isFirst?: boolean
  openModal: (pergunta: iPerguntasFrequentes) => void
}

export const TextBox: React.FC<iTextBoxProps> = ({ isFirst, pergunta, openModal }) => {
  const [isOpen, setisOpen] = useState(false)
  return (
    <Container open={isOpen} isFisrt={isFirst}>
      <HeadBox open={isOpen}>
        <h4>{pergunta.titulo}</h4>
        <Flex flexDir={'column'}>
          <AiOutlinePlus onClick={() => setisOpen(!isOpen)} size={18} />
          <FaPen onClick={() => openModal(pergunta)} cursor={'pointer'} color='var(--a2)' size={15} />
        </Flex>
      </HeadBox>
      <span>{pergunta.descricao}</span>
    </Container>
  )
}
