import { useEffect, useState } from 'react'
import { FaCog, FaEye, FaPen, FaPeopleArrows, FaUserCheck, FaUserFriends, FaUsers, FaUserTie } from 'react-icons/fa'
import { BiMailSend } from 'react-icons/bi'
import { Container, IconText } from './styles'
import { appApi } from '../../../../../services/appApi'
import { Flex } from '@chakra-ui/react'
import { Button } from '../../../../../components/Button'
import { useNavigate } from 'react-router-dom'

interface ICardPesquisaStackHolder {
  pesquisaId: string
  trilhaId: string
}

interface iCardResponse {
  participantes: number[]
  formularioSelecionado: boolean
  formularioAprovado: boolean
}

export const CardPesquisaStackholder: React.FC<ICardPesquisaStackHolder> = ({ pesquisaId, trilhaId }) => {
  const nav = useNavigate()

  const [Model, setModel] = useState<iCardResponse>()

  useEffect(() => {
    ObterParticipantes()
  }, [])

  function ObterParticipantes(): void {
    appApi.get(`PesquisaPercepcao/${pesquisaId}/Detalhe`)
      .then(response => { setModel(response.data) })
      .catch(err => console.error(err))
  }

  return (
    <Container>
      <h3>Quem irá participar:</h3>
      {
        Model?.participantes.map((e: number, i) => {
          if (e === 0) {
            return (
              <IconText key={i}>
                <FaUserCheck size={25} />
                <span>Autoavaliação</span>
              </IconText>
            )
          } else if (e === 1) {
            return (
              <IconText key={i}>
                <FaUserTie size={25} />
                <span>Gestor</span>
              </IconText>
            )
          } else if (e === 2) {
            return (
              <IconText key={i}>
                <FaPeopleArrows size={25} />
                <span>Par</span>
              </IconText>
            )
          } else if (e === 3) {
            return (
              <IconText key={i}>
                <FaUsers size={25} />
                <span>Subordinado</span>
              </IconText>
            )
          } else if (e === 4) {
            return (
              <IconText key={i}>
                <FaUserFriends size={25} />
                <span>Outro</span>
              </IconText>
            )
          } else if (e === 6) {
            return (
              <IconText key={i}>
                <FaUserFriends size={25} />
                <span>Alta liderança</span>
              </IconText>
            )
          }
          return null
        })
      }

      <Flex justifyContent={'center'} flexDir='column' gap={'1rem'}>
        {
          (!Model?.formularioSelecionado) && (
            <Button
              marginTop={'1rem'}
              leftIcon={<FaCog />}
              VarColor='Azul'
              onClick={() => nav(`/Rh/PesquisaPercepcao/Formularios/${pesquisaId}/Trilha/${trilhaId}`)}
            >Selcionar formulário</Button>
          )
        }

        {
          (Model?.formularioSelecionado && !Model?.formularioAprovado) && (
            <Button
              marginTop={'1rem'}
              leftIcon={<FaPen />}
              VarColor='Azul'
              onClick={() => nav(`/Rh/PesquisaPercepcao/Formulario/${pesquisaId}/Trilha/${trilhaId}`)}
            >Editar formulário</Button>
          )
        }

        {
          (Model?.formularioSelecionado && Model?.formularioAprovado) && (
            <Button
              marginTop={'1rem'}
              VarColor='Azul'
              leftIcon={<BiMailSend />}
              onClick={() => nav(`/Rh/PesquisaPercepcao/EnviarPesquisa/${pesquisaId}/Trilha/${trilhaId}`)}
            >Editar envio da pesquisa</Button>
          )
        }

        {
          (Model?.formularioSelecionado) && (
            <Button
              marginTop={'1rem'}
              leftIcon={<FaEye />}
              VarColor='Azul'
              onClick={() => nav(`/Rh/PesquisaPercepcao/VisualizarFormulario/${pesquisaId}/Trilha/${trilhaId}`)}
            >Visualizar formulário</Button>
          )
        }
      </Flex>
    </Container>
  )
}
