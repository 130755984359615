import { Avatar, Flex } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { MdClose } from 'react-icons/md'
import Modal from 'react-modal'
import { OutlineMetaButton } from '../../../../../components/Buttons/OutlineMetaButton'
import { appApi } from '../../../../../services/appApi'
import { ModalBody, ModalFooter, ModalHeader, TextoDescricao, TextoResponsavel } from './styles'

interface props {
  isOpen: boolean
  onRequestClose: () => void
  reuniaoId?: string
}

interface iResponsavel {
  avatar?: string
  nome: string
  nomeArea?: string
}

interface iReuniao {
  responsavel: iResponsavel
  data: string
  hora: string
  duracao: string
}

export const ModalReuniao: React.FC<props> = ({ isOpen, onRequestClose, reuniaoId }) => {
  Modal.setAppElement('#root')

  const [Model, setModel] = useState<iReuniao>()
  function getReuniao(): void {
    appApi.get(`Reuniao/${reuniaoId as string}/Detalhe`)
      .then(res => setModel(res.data))
      .catch(err => console.log(err))
  }

  useEffect(() => {
    getReuniao()
  }, [])
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName='react-modal-overlay'
      className='react-modal-content-default'
    >
      <ModalHeader>
        <h2>Reunião</h2>
        <MdClose size={24} onClick={onRequestClose} />
      </ModalHeader>

      <ModalBody>
        {
          Model?.data !== null
            ? <>
              <h2>Data da reunião: {Model?.data}</h2>
              <h2>Horário: {Model?.hora}</h2>
              <h2>Duração: {Model?.duracao}</h2>
              <Flex flexDir={'column'}>
                <TextoResponsavel>Resposável pela reunião:</TextoResponsavel>
                <Flex justifyContent={'center'} alignItems={'center'}>
                  <Avatar marginRight={'.5rem'} size={'sm'} src={Model?.responsavel.avatar} name={Model?.responsavel.nome}/>
                  <Flex textAlign={'start'} flexDir={'column'}>
                    <span>{Model?.responsavel.nome}</span>
                    {Model?.responsavel.nomeArea &&
                    <strong>{Model?.responsavel.nomeArea}</strong>
                    }
                  </Flex>
                </Flex>
              </Flex>
            </>
            : <>
              <h2>Aguardando data pelo responsável...</h2>
              <TextoDescricao>O responsável ainda não definiu uma data para essa reunião... você será notificado assim que a data for marcada</TextoDescricao>
              <Flex flexDir={'column'}>
                <TextoResponsavel>Responsável pela reunião:</TextoResponsavel>
                <Flex justifyContent={'center'} alignItems={'center'}>
                  <Avatar marginRight={'.5rem'} size={'sm'} src={Model?.responsavel.avatar} name={Model?.responsavel.nome}/>
                  <Flex textAlign={'start'} flexDir={'column'}>
                    <span>{Model?.responsavel.nome}</span>
                    {Model?.responsavel.nomeArea &&
                    <strong>{Model?.responsavel.nomeArea}</strong>
                    }
                  </Flex>
                </Flex>
              </Flex>
            </>
        }
      </ModalBody>

      <ModalFooter>
        <OutlineMetaButton onClick={onRequestClose} size='md' color='c6'>Voltar</OutlineMetaButton>
      </ModalFooter>
    </Modal>
  )
}
