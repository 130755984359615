/* eslint-disable @typescript-eslint/no-unused-vars */
import Roboto from '../../../../../assets/fonts/Roboto-Bold.ttf'

import {
  Defs,
  Document,
  Font,
  Image as PDFImage,
  LinearGradient,
  Page,
  Path,
  Stop,
  StyleSheet,
  Svg,
  Text,
  View
} from '@react-pdf/renderer'
import React, { useEffect, useState } from 'react'
import { iCorTabela, iDiscursiva, IGrafico, iLinhaTabela, iMediaAbsoluta, iTabela } from '../..'
import { iColaboradorBase, iCompetencia, iCompetenciaPDI, iEscala, iNomeId } from '../../../../../interfaces'
import { ArrayToChunks, PropInvalida } from '../../../../../Utils/Helper'

interface iModel {
  pesquisaId: string
  avaliado: iColaboradorBase
  nomePesquisa: string
  notaMaximaEscala: number
  totalAvaliadores: number
  totalRespostas: number
  mediaGeral: number
  participantes: number[]
  graficoHierarquia: IGrafico
  graficoIndividual: IGrafico
  discursivas: iDiscursiva[]
  recomendacoes: iCompetenciaPDI[]
  tabela: iTabela
  graficoComparativo: IGrafico
  graficoComparativoAutoavaliacao: IGrafico
  filtro: iNomeId[]
  comparativoId?: string
  pdf?: iPDF
  cores: iCorTabela[]
}

interface iPDF {
  escala: iEscala
  competencias: iCompetencia[]
  melhorCompetencia: iCompetencia
  textoDeConclusao?: string
}

interface iPdfProps {
  Grafico1?: string
  Grafico2?: string
  temColuna: (tipo: string) => boolean
  temWorkShop: boolean
  model: iModel
  tipoPesquisa: string
  medias: iMediaAbsoluta[]
}

export const RelatorioPDF: React.FC<iPdfProps> = ({
  Grafico1,
  Grafico2,
  temColuna,
  temWorkShop,
  model,
  tipoPesquisa,
  medias
}) => {
  const [Is90Graus, setIs90Graus] = useState(false)
  const urlDefault = 'https://sigah.blob.core.windows.net/assets/icones/Icones-SISTEMA-01.png'

  function Header(Titulo: string): any {
    return (
      <View style={{ backgroundColor: '#CC42A2', paddingLeft: 8 }}>
        <View style={{ backgroundColor: '#1961E0', paddingLeft: 8 }}>
          <View style={{ backgroundColor: '#66046B', paddingLeft: 8 }}>
            <View
              style={{
                backgroundColor: 'rgb(227, 227, 227)',
                paddingTop: 16,
                paddingBottom: 16,
                paddingHorizontal: 32
              }}
            >
              <Text style={{ fontSize: 16, color: '#203864' }}>
                {tipoPesquisa} - {model?.nomePesquisa}
              </Text>
              <Text
                style={{
                  fontSize: 24,
                  color: '#203864',
                  fontWeight: 'semibold'
                }}
              >
                {Titulo}
              </Text>
            </View>
          </View>
        </View>
      </View>
    )
  }

  function Footer(): any {
    return (
      <View
        style={{
          backgroundColor: '#66046B',
          paddingVertical: 4,
          alignItems: 'center'
        }}
      >
        <View>
          <PDFImage
            style={{
              height: 35,
              objectFit: 'contain'
            }}
            source="https://sigah.blob.core.windows.net/onboard/0acc742b-1a78-4d9e-9bfd-26b6c1f46c19.png"
          />
        </View>
      </View>
    )
  }

  function ObterSvgPorParticipante(Titulo: string): any {
    if (Titulo === 'Autoavaliação') {
      return (
        <PDFImage
          style={{
            objectFit: 'contain'
          }}
          src={'https://sigah.blob.core.windows.net/onboard/2cc77da2-54e1-44e6-9dc5-f2af4e34121e.png'}
        />
      )
    }
    if (Titulo === 'Liderado') {
      return (
        <PDFImage
          src={'https://sigah.blob.core.windows.net/onboard/efec41e6-c698-4847-af1a-5fbdb56d2dec.png'}
        />
      )
    }
    if (Titulo === 'Gestor') {
      return (
        <PDFImage
          src={'https://sigah.blob.core.windows.net/onboard/9aa7241d-0425-4d31-b956-4be1df45e594.png'}
        />
      )
    }
    if (Titulo === 'Alta liderança') {
      return (
        <PDFImage
          src={'https://sigah.blob.core.windows.net/onboard/5ffcbe97-7929-41ff-9f29-21128d9eccda.png'}
        />
      )
    }
    if (Titulo === 'Par') {
      return (
        <PDFImage
          src={'https://sigah.blob.core.windows.net/onboard/4a59583f-e8c4-4f87-99c7-2756462e7bd0.png'}
        />
      )
    }
    if (Titulo === 'Outro') {
      return (
        <PDFImage
          src={'https://sigah.blob.core.windows.net/onboard/de510452-2eb6-4ba9-878f-b26b3299fcc3.png'}
        />
      )
    }
  }

  function ObterNomeParticipante(tipo: number): string {
    if (tipo === 0) {
      return 'Autoavaliação'
    } else if (tipo === 1) {
      return 'Gestor'
    } else if (tipo === 2) {
      return 'Par'
    } else if (tipo === 3) {
      return 'Liderado'
    } else if (tipo === 4) {
      return 'Outro'
    } else if (tipo === 6) {
      return 'Alta liderança'
    }
    return 'Não identificado'
  }

  function ObterLogo(): string {
    const logo = localStorage.getItem('logo') ?? ''
    if (PropInvalida(logo)) {
      return 'https://sigah.blob.core.windows.net/onboard/f99445d7-d98d-48ad-9cdb-a443a9f636a2.png'
    }

    if (logo.includes('https://blob-hml.icecloud.com.br') || logo.includes('https://blob.icecloud.com.br')) {
      return logo.replace('https://blob-hml.icecloud.com.br', 'https://sigah.blob.core.windows.net/sigah').replace('https://blob.icecloud.com.br', 'https://sigah.blob.core.windows.net/sigah')
    } else if (logo?.includes('https://sigah.arquiteturahumana.com.br')) {
      return logo.replace('https://sigah.arquiteturahumana.com.br', 'https://sigah.blob.core.windows.net/sigah')
    }
    return logo
  }

  function FormatarTabela(texto: string): string {
    const quebrado = texto.split(' ')
    const notNumber = isNaN(parseInt(quebrado[quebrado.length - 2]))
    if (texto.includes('Autodesenvolvimento') && model.graficoHierarquia.dados.length > 4) {
      return `Autodesenvolvi- mento ${notNumber ? '' : quebrado[quebrado.length - 2]}`
    }
    if (texto.includes('Comprometimento') && model.graficoHierarquia.dados.length > 5) {
      return `Comprometi- mento ${notNumber ? '' : quebrado[quebrado.length - 2]}`
    }
    return texto
  }

  function VerificarCorV2(eixo: string, nota: string): string {
    const dado = model.cores.find(e => e.eixo === eixo)
    if (dado) {
      return dado.notasPositivas.some(r => r.toFixed(2) === nota?.replace(',', '.')) ? '#00AA00' : dado.notasNegativas.some(r => r.toFixed(2) === nota?.replace(',', '.')) ? '#F2994A' : '#1A202C'
    }
    return '#1A202C'
  }

  function VerificarCorRecomendacao(nota: string, index: number): string {
    const dado = model.cores.find(e => e.eixo === 'semAutoavaliacao')
    if (dado) {
      return dado.notasPositivas.some(r => r.toFixed(2) === nota?.replace(',', '.')) ? '#00AA00' : dado.recomendacoes.some(e => e === index) ? '#F2994A' : '#1A202C'
    }
    return '#1A202C'
  }

  useEffect(() => {
    setIs90Graus(model.graficoHierarquia.dados.length === 2)
  }, [model])

  Font.register({
    family: 'Roboto',
    fonts: [{
      src: Roboto
    }]
  })

  const styles = StyleSheet.create({
    DefaultPage: {
      paddingTop: 16,
      flexDirection: 'column',
      justifyContent: 'space-between'
    },
    table: {
      display: 'flex',
      width: 'auto',
      borderStyle: 'solid',
      borderWidth: 1,
      borderRightWidth: 0,
      borderBottomWidth: 0
    },
    tableRow: {
      margin: 'auto',
      flexDirection: 'row'
    },
    tableCol: {
      width: `${(100 / (model.cores.length + (!Is90Graus ? 1 : 0)))}%`,
      borderStyle: 'solid',
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
      fontFamily: 'Roboto',
      fontWeight: 'bold'
    },
    tableHeaderCol: {
      width: `${(100 / (model.cores.length + (!Is90Graus ? 1 : 0)))}%`,
      borderStyle: 'solid',
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
      fontFamily: 'Roboto',
      fontWeight: 'bold',
      color: 'white',
      backgroundColor: '#66046B'
    },
    tableCell: {
      margin: 'auto',
      fontSize: 10,
      padding: 5,
      fontFamily: 'Roboto',
      fontWeight: 'bold'
    },
    tableCellCompetencia: {
      fontSize: 10,
      padding: 5,
      fontFamily: 'Roboto',
      fontWeight: 'bold',
      backgroundColor: '#66046B32'
    },
    tableCellQuestao: {
      fontSize: 10,
      padding: 5,
      fontFamily: 'Roboto',
      fontWeight: 'bold',
      backgroundColor: '#66046B15'
    }
  })

  Font.registerHyphenationCallback((e) => {
    if (e.includes('http') && e.length > 100) {
      const arr: string[] = []
      for (let i = 0; i < e.length; i += 100) {
        arr.push(e.substring(i, i + 100))
      }
      return arr
    }

    if (e.includes('Flexibilidade/Adaptabilidade')) {
      return e.split('/')
    }

    if (e.includes('Autodesenvolvimento')) {
      return ['Autodesenvolvi', 'mento']
    }

    if (e.length === 1) {
      return [e, '']
    }
    return [e]
  })

  function GerarTabela(): iLinhaTabela[][] {
    const tabela = [...model.tabela.linhas]
    const linhas: iLinhaTabela[] = []
    tabela.forEach((e) => {
      linhas.push(e)
      if (e.questoes.length > 1) {
        e.questoes.forEach((q, i2) => {
          linhas.push({
            competencia: `${e.competencia} ${(1 + i2)} >_<`,
            autoAvaliacao: q.autoAvaliacao,
            mediaSemAuto: q.mediaSemAuto,
            altalideranca: q.altalideranca,
            gestor: q.gestor,
            liderados: q.liderados,
            outros: q.outros,
            pares: q.pares,
            questoes: []
          })
        })
      }
    })

    linhas.push({
      competencia: 'Média geral',
      autoAvaliacao: medias[0].mediaAutoAval.toFixed(2),
      mediaSemAuto: medias[0].mediaSemAuto.toFixed(2),
      altalideranca: medias[0].mediaAltaLideranca.toFixed(2),
      gestor: medias[0].mediaGestor.toFixed(2),
      liderados: medias[0].mediaLiderados.toFixed(2),
      outros: medias[0].mediaOutros.toFixed(2),
      pares: medias[0].mediaPares.toFixed(2),
      questoes: []
    })
    return ArrayToChunks(linhas, calcularChunk())
  }

  function calcularChunk(): number {
    let arrayToChunkLimit = 0
    model.pdf?.competencias.forEach((e) => {
      if (model.graficoHierarquia.dados.length < 4) {
        if (e.nome.length < 22) {
          arrayToChunkLimit = (arrayToChunkLimit + 2.5)
        } else if (e.nome.length < 44) {
          arrayToChunkLimit = (arrayToChunkLimit + 1.3)
        } else {
          arrayToChunkLimit = (arrayToChunkLimit + 1)
        }
      }
      e.questoes.forEach((a) => {
        if (model.graficoHierarquia.dados.length >= 4) {
          if (e.nome.length < 22) {
            arrayToChunkLimit = (arrayToChunkLimit + 2.5)
          } else if (e.nome.length < 44) {
            arrayToChunkLimit = (arrayToChunkLimit + 1.3)
          } else {
            arrayToChunkLimit = (arrayToChunkLimit + 1)
          }
        }
      })
      if (model.graficoHierarquia.dados.length >= 4) {
        if (e.nome.length < 22) {
          arrayToChunkLimit = (arrayToChunkLimit + 1.5)
        } else if (e.nome.length < 44) {
          arrayToChunkLimit = (arrayToChunkLimit + 0.75)
        } else {
          arrayToChunkLimit = (arrayToChunkLimit + 0.3)
        }
      }
      e.questoes.forEach((a) => {
        if (model.graficoHierarquia.dados.length >= 4) {
          if (e.nome.length < 22) {
            arrayToChunkLimit = (arrayToChunkLimit + 1.5)
          } else if (e.nome.length < 44) {
            arrayToChunkLimit = (arrayToChunkLimit + 0.75)
          } else {
            arrayToChunkLimit = (arrayToChunkLimit + 0.3)
          }
        }
      })
    })
    if (((model.pdf?.competencias?.length ?? 0) < arrayToChunkLimit * 0.2)) {
      return 6
    } else if ((model.pdf?.competencias?.length ?? 0) < arrayToChunkLimit * 0.3) {
      return 9
    } else if ((model.pdf?.competencias?.length ?? 0) < arrayToChunkLimit * 0.4) {
      return 11
    } else {
      return 13
    }
  }

  return (
    <Document>
      <Page style={{ justifyContent: 'space-between' }}>
        <View>
          <View style={{ marginBottom: 80, marginTop: 28 }}>
            <Text
              style={{
                fontSize: 16,
                color: '#203864',
                textAlign: 'center',
                marginBottom: 4
              }}
            >
              {tipoPesquisa}
            </Text>
            <Text
              style={{ fontSize: 24, color: '#203864', textAlign: 'center' }}
            >
              {model?.nomePesquisa}
            </Text>
          </View>
          <View>
            <Text
              style={{ fontSize: 24, color: '#203864', textAlign: 'center' }}
            >
              Relatório individual
            </Text>
            <Text
              style={{ fontSize: 24, color: '#203864', textAlign: 'center' }}
            >
              {model?.avaliado.nome}
            </Text>
          </View>

          <View
            style={{
              height: 140,
              width: 160,
              right: -350,
              top: 185,
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <View style={{
              height: 105,
              width: 120,
              justifyContent: 'center',
              alignItems: 'center'
            }}
            >
              {
                (localStorage.getItem('logo') !== undefined) && (
                  <PDFImage
                    style={{ objectFit: 'contain' }}
                    src={ObterLogo()}
                  />
                )
              }
            </View>
          </View>
          <Svg
            width="595"
            height="621"
            style={{ zIndex: 1, position: 'absolute', top: 108 }}
          >
            <Path
              d="M484.497 425.884C469.305 437.261 450.44 444 430 444C397.265 444 368.568 426.716 352.535 400.776L0 620.727L4.31549e-06 522L345.549 386.963C341.325 376.469 339 365.006 339 353C339 302.742 379.742 262 430 262C444.309 262 457.846 265.302 469.891 271.187L594.5 0V98.7269L482.455 278.63C505.773 295.108 521 322.276 521 353C521 372.847 514.646 391.21 503.862 406.166L594.749 465V563.727L484.497 425.884Z"
              fill="#66046B"
            />
            <Path
              d="M510.134 353C510.134 397.257 474.257 433.134 430 433.134C385.743 433.134 349.866 397.257 349.866 353C349.866 308.743 385.743 272.866 430 272.866C474.257 272.866 510.134 308.743 510.134 353Z"
              fill="white"
            />
          </Svg>
        </View>
        <View
          style={{
            backgroundColor: '#66046B',
            paddingVertical: 4,
            alignItems: 'center'
          }}
        >
          <View>
            <PDFImage
              style={{
                height: 35,
                objectFit: 'contain'
              }}
              source="https://sigah.blob.core.windows.net/onboard/0acc742b-1a78-4d9e-9bfd-26b6c1f46c19.png"
            />
          </View>
        </View>
      </Page>

      <Page style={styles.DefaultPage}>
        <View>
          {Header('O que é?')}
          <View style={{ paddingLeft: 32, paddingRight: 32 }}>
            <Text
              style={{
                textAlign: 'left',
                paddingVertical: 32,
                fontSize: 16,
                color: '#203864'
              }}
            >
              Você está recebendo o seu relatório individual. Nele encontrará informações sobre a percepção dos stakeholders selecionados, além de dicas de como utilizar essas informações para seguir com o seu autodesenvolvimento.
            </Text>

            <View>
              <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                <View style={{ marginRight: 32, width: '80', height: '80' }}>
                  <PDFImage
                    src={'https://sigah.blob.core.windows.net/onboard/7881fcaf-9e97-4169-acfb-f099512d6c3f.png'}
                  />
                </View>
                <View>
                  <Text
                    style={{
                      marginBottom: 4,
                      fontWeight: 'bold',
                      fontSize: 16,
                      color: '#4578D6'
                    }}
                  >
                    Pra que serve a pesquisa de percepção?
                  </Text>
                  <Text
                    style={{
                      fontSize: 12,
                      width: '80%',
                      color: '#203864',
                      lineHeight: 1.4
                    }}
                  >
                    Essa pesquisa mensura como você é percebido em algumas competências. Ela compara a percepção de diferentes níveis da organização em relação a você, como por exemplo:{' '}
                    {model?.participantes
                      .filter((r) => r !== 0)
                      .map(
                        (e) =>
                          ` ${ObterNomeParticipante(e)}${temColuna('Autoavaliação') ? ',' : '.'}`
                      )}
                    {temColuna('Autoavaliação') && ' e também a sua própria percepção (autoavaliação).'}
                  </Text>
                </View>
              </View>

              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  marginTop: 32
                }}
              >
                <View style={{ marginRight: 32, width: '80', height: '80' }}>
                  <PDFImage
                    src={'https://sigah.blob.core.windows.net/onboard/f776947a-1430-4de3-9823-0cc37d8db337.png'}
                  />
                </View>
                <View>
                  <Text
                    style={{
                      marginBottom: 4,
                      fontWeight: 'bold',
                      fontSize: 16,
                      color: '#4578D6'
                    }}
                  >
                    Mas afinal, o que é percepção?
                  </Text>
                  <Text
                    style={{
                      fontSize: 12,
                      width: '80%',
                      color: '#203864',
                      lineHeight: 1.4
                    }}
                  >
                    Percepção é a realidade aos olhos do outro, ou seja, é como você é percebido pelas outras pessoas, como é interpretado, compreendido ou a impressão que gera. Muitas vezes podemos não concordar com um resultado, porém para que a pesquisa contribua para o seu desenvolvimento é essencial que respeite as avaliações contidas aqui, pois elas refletem a opinião e as interpretações das pessoas sobre suas atitudes (mesmo que às vezes sejam diferentes das suas intenções). Dessa forma, para que o processo seja valioso você deve estar disposto aos feedbacks e se permitir refletir sobre eles.
                  </Text>
                </View>
              </View>

              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  marginTop: 32
                }}
              >
                <View style={{ marginRight: 32, width: '80', height: '80' }}>
                  <PDFImage
                    src={'https://sigah.blob.core.windows.net/onboard/c515809b-81dc-450a-8992-e20477b7e75f.png'}
                  />
                </View>
                <View>
                  <Text
                    style={{
                      marginBottom: 4,
                      fontWeight: 'bold',
                      fontSize: 16,
                      color: '#4578D6'
                    }}
                  >
                    O que devo fazer depois de receber esse relatório?
                  </Text>
                  <Text
                    style={{
                      fontSize: 12,
                      width: '80%',
                      color: '#203864',
                      lineHeight: 1.4
                    }}
                  >
                    Este material é apenas o começo da sua trajetória de
                    autodesenvolvimento:
                  </Text>
                  <Text
                    style={{
                      fontSize: 12,
                      width: '80%',
                      color: '#203864',
                      lineHeight: 1.4
                    }}
                  >
                    1. Você deve ler o relatório inteiro, analisar os gráficos, as notas e as competências.{' '}
                    {temWorkShop
                      ? 'Você contará com um workshop de 3' +
                      ' horas para compreender e facilitar a leitura. Será complementar' +
                      ' seu entendimento deste material.'
                      : ''}
                  </Text>
                  <Text
                    style={{
                      fontSize: 12,
                      width: '80%',
                      color: '#203864',
                      lineHeight: 1.4
                    }}
                  >
                    2. Depois agende uma conversa com seu gestor para falar sobre seus pontos fortes e como pode desenvolvê-los ainda mais e também sobre seus pontos de melhoria. Seu gestor poderá apresentar situações práticas para que você atue de maneira diferente e gere percepções ainda melhores.
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>

        <View style={{ paddingLeft: 32, paddingRight: 32 }}>
          <View
            style={{
              width: '100%',
              backgroundColor: '#D4B22A',
              flexDirection: 'row',
              color: 'white',
              padding: 16,
              borderRadius: 16,
              alignItems: 'center'
            }}
          >
            <View
              style={{
                flexDirection: 'column',
                width: '100%',
                textAlign: 'right'
              }}
            >
              <Text
                style={{ fontStyle: 'bold', fontSize: 16, marginBottom: 4 }}
              >
                Dicas de ouro
              </Text>
              <Text style={{ fontSize: 12 }}>
                Receber feedbacks é um presente para o seu desenvolvimento,
                tenha gratidão a todos que se dedicaram à sua pesquisa. Pratique
                o positivismo! Orgulhe-se dos princípios que teve notas altas e
                reflita sobre como fortalece-los ainda mais. Não se prenda
                apenas aos seus pontos de desenvolvimento, valorize seus pontos
                fortes!
              </Text>
            </View>
            <View style={{ marginLeft: 16 }}>
              <PDFImage
                style={{ width: '60', objectFit: 'contain' }}
                src={'https://sigah.blob.core.windows.net/onboard/70d0bcda-5290-4cef-8376-31c184eec5b2.png'}
              />

            </View>
          </View>
        </View>
        {Footer()}
      </Page>

      <Page style={styles.DefaultPage}>
        <View>
          {Header('Avaliadores e escala')}
          <View style={{ paddingLeft: 48, paddingRight: 48, marginBottom: 32, paddingTop: 32 }}>
            <View
              style={{
                flexDirection: 'row',
                marginBottom: 32,
                alignItems: 'center'
              }}
            >
              <Svg width="13" height="13">
                <Path
                  d="M1.05 12L0 10.95L4.95 6L0 1.05L1.05 0L7.05 6L1.05 12ZM7.375 12L6.325 10.95L11.275 6L6.325 1.05L7.375 0L13.375 6L7.375 12Z"
                  fill="#2C4D8A"
                />
              </Svg>

              <Text
                style={{
                  fontSize: 20,
                  fontWeight: 'bold',
                  paddingLeft: 16,
                  color: '#2C4D8A'
                }}
              >
                Seus avaliadores são
              </Text>
            </View>

            <View
              style={{
                flexDirection: 'row',
                flexWrap: 'wrap'
              }}
            >
              {model?.participantes.map((e, i) => {
                return (
                  <View
                    key={i}
                    style={{
                      paddingHorizontal: 32,
                      marginBottom: 16,
                      flexDirection: 'row',
                      alignItems: 'center'
                    }}
                  >
                    <View style={{ width: '40', height: '40' }}>
                      {ObterSvgPorParticipante(ObterNomeParticipante(e))}
                    </View>

                    <Text style={{ paddingLeft: 16, color: '#203864' }}>
                      {ObterNomeParticipante(e)}
                    </Text>
                  </View>
                )
              })}
            </View>
          </View>
        </View>

        {
          (model.pdf) && (
            <View>
              <View>
                <View
                  style={{
                    paddingTop: 40,
                    paddingBottom: 40,
                    paddingHorizontal: 48,
                    backgroundColor: '#4578D6',
                    color: 'white'
                  }}
                >
                  <View
                    style={{
                      flexDirection: 'row',
                      marginBottom: 16,
                      alignItems: 'center'
                    }}
                  >
                    <Svg width="13" height="13">
                      <Path
                        d="M1.05 12L0 10.95L4.95 6L0 1.05L1.05 0L7.05 6L1.05 12ZM7.375 12L6.325 10.95L11.275 6L6.325 1.05L7.375 0L13.375 6L7.375 12Z"
                        fill="white"
                      />
                    </Svg>
                    <Text
                      style={{ fontSize: 20, fontWeight: 'bold', paddingLeft: 16 }}
                    >
                      Escala da avaliação
                    </Text>
                  </View>

                  <View style={{ width: '100%', alignItems: 'center' }}>
                    <View style={{ alignItems: 'flex-start' }}>
                      <View
                        style={{
                          flexDirection: 'row',
                          alignItems: 'center',
                          marginBottom: 8
                        }}
                      >
                        <Svg width="23" height="19">
                          <Path
                            d="M8.26253 11.1875H6.53753C6.21985 11.1875 5.96253 11.4532 5.96253 11.7812V18.9062C5.96253 19.2343 6.21985 19.5 6.53753 19.5H8.26253C8.58022 19.5 8.83753 19.2343 8.83753 18.9062V11.7812C8.83753 11.4532 8.58022 11.1875 8.26253 11.1875ZM3.66253 14.75H1.93753C1.61985 14.75 1.36253 15.0157 1.36253 15.3438V18.9062C1.36253 19.2343 1.61985 19.5 1.93753 19.5H3.66253C3.98022 19.5 4.23753 19.2343 4.23753 18.9062V15.3438C4.23753 15.0157 3.98022 14.75 3.66253 14.75ZM12.8625 7.625H11.1375C10.8198 7.625 10.5625 7.8907 10.5625 8.21875V18.9062C10.5625 19.2343 10.8198 19.5 11.1375 19.5H12.8625C13.1802 19.5 13.4375 19.2343 13.4375 18.9062V8.21875C13.4375 7.8907 13.1802 7.625 12.8625 7.625ZM17.4625 4.0625H15.7375C15.4198 4.0625 15.1625 4.3282 15.1625 4.65625V18.9062C15.1625 19.2343 15.4198 19.5 15.7375 19.5H17.4625C17.7802 19.5 18.0375 19.2343 18.0375 18.9062V4.65625C18.0375 4.3282 17.7802 4.0625 17.4625 4.0625ZM22.0625 0.5H20.3375C20.0198 0.5 19.7625 0.765703 19.7625 1.09375V18.9062C19.7625 19.2343 20.0198 19.5 20.3375 19.5H22.0625C22.3802 19.5 22.6375 19.2343 22.6375 18.9062V1.09375C22.6375 0.765703 22.3802 0.5 22.0625 0.5Z"
                            fill="white"
                          />
                        </Svg>
                        <Text
                          style={{
                            fontSize: 20,
                            fontWeight: 'semibold',
                            marginLeft: 16
                          }}
                        >
                          {model?.pdf.escala.nome}
                        </Text>
                      </View>
                      {model?.pdf.escala.respostas?.map((e, i) => {
                        return (
                          <View
                            key={i}
                            style={{
                              flexDirection: 'row',
                              alignItems: 'center',
                              marginBottom: 8
                            }}
                          >
                            <View
                              style={{
                                height: 4,
                                width: 4,
                                backgroundColor: 'white',
                                borderRadius: 2,
                                marginRight: 8
                              }}
                            />
                            <Text style={{ fontSize: 14 }}>
                              {e.resposta + ' ' + e.peso.toString().split('-1')[0]}
                            </Text>
                          </View>
                        )
                      })}
                    </View>
                  </View>
                </View>
                <View
                  style={{
                    backgroundColor: '#CC42A2',
                    height: 16,
                    width: '100%',
                    marginBottom: 10
                  }}
                />
              </View>

              {Footer()}
            </View>
          )
        }
      </Page>

      {
        model.pdf && ArrayToChunks(model.pdf.competencias.filter(e => !e.discursiva), 5)?.map((e, i) => {
          return (
            <Page key={i} style={styles.DefaultPage}>
              <View>
                {Header('Competências utilizadas')}
                <View
                  style={{
                    paddingTop: 16,
                    paddingHorizontal: 32
                  }}
                >
                  <View
                    style={{
                      flexDirection: 'column'
                    }}
                  >
                    {e.map((r: iCompetencia, i2) => {
                      return (
                        <View key={i2}>
                          <View
                            style={{
                              flexDirection: 'row',
                              alignItems: 'center'
                            }}
                          >
                            <View
                              style={{
                                flexDirection: 'column',
                                alignItems: 'center'
                              }}
                            >
                              <PDFImage
                                src={r.icone.replace('Competencias-', 'Icones-SISTEMA-').replace('svg', 'png') ?? urlDefault}
                                style={{ width: 50, height: 50 }}
                              />
                              <Text
                                style={{
                                  marginTop: 8,
                                  width: 90,
                                  fontSize: 12,
                                  fontWeight: 'semibold',
                                  textAlign: 'center'
                                }}
                              >
                                {r.nome}
                              </Text>
                            </View>
                            <View style={{ flexDirection: 'column', width: '100%', left: 25 }}>
                              {r.questoes.map((questao, i3) => {
                                return (
                                  <Text
                                    key={i3}
                                    style={{
                                      fontSize: 12,
                                      width: '80%',
                                      textAlign: 'left',
                                      marginTop: 8
                                    }}
                                  >
                                    {`${(i3 + 1)}. ` + questao.enunciado}
                                  </Text>
                                )
                              })}
                            </View>
                          </View>
                          <View
                            style={{
                              height: 3,
                              width: '100%',
                              backgroundColor: '#E3E3E3',
                              marginBottom: 16,
                              marginTop: 16
                            }}
                          />
                        </View>
                      )
                    })}
                  </View>
                </View>
              </View>
              {Footer()}
            </Page>
          )
        })
      }

      {!Is90Graus
        ? (
          <>
            <Page style={styles.DefaultPage}>
              <View>
                {Header('Gráfico individual')}
                <View
                  style={{
                    paddingTop: 16,
                    paddingLeft: 32,
                    paddingRight: 32
                  }}
                >
                  {
                    (Grafico1) && (
                      <PDFImage style={{ marginBottom: 16 }} src={Grafico1} />
                    )
                  }
                </View>
              </View>

              {Footer()}
            </Page>
            <Page style={styles.DefaultPage}>
              <View>
                {Header('Notas por competência e tipo de avaliador')}
                <View
                  style={{
                    paddingTop: 16,
                    paddingLeft: 32,
                    paddingRight: 32
                  }}
                >
                  {
                    (Grafico2) && (
                      <PDFImage style={{ marginBottom: 16 }} src={Grafico2} />
                    )
                  }
                </View>
              </View>

              {Footer()}
            </Page>
          </>
          )
        : (
          <Page style={styles.DefaultPage}>
            <View>
              {Header('Gráficos')}
              <View style={{ paddingVertical: 32, paddingHorizontal: 32 }}>
                <Text
                  style={{ textAlign: 'left', fontSize: 16, color: '#203864' }}
                >{model.pdf?.textoDeConclusao}</Text>
              </View>
              <View
                style={{
                  paddingTop: 16,
                  paddingLeft: 32,
                  paddingRight: 32
                }}
              >
                {Grafico2 && (
                  <PDFImage src={Grafico2} />
                )}
                {
                  (model.recomendacoes.length === 0) && (
                    <Text style={{ fontWeight: 'bold', fontSize: 16, textAlign: 'center' }}>Favor alinhar os pontos de desenvolvimento com seu gestor</Text>
                  )
                }
              </View>
            </View>

            {Footer()}
          </Page>
          )
      }

      {
        GerarTabela().map((chunck, index) => {
          return (
            <Page key={index} orientation="landscape" style={styles.DefaultPage}>
              <View>
                {Header('Notas por hierarquia')}

                <View style={{ paddingLeft: 32, paddingRight: 32, marginTop: 10 }}>
                  <View style={styles.table}>
                    <View style={styles.tableRow}>
                      <View style={styles.tableHeaderCol}>
                        <Text style={styles.tableCell}>Competencia</Text>
                      </View>
                      {temColuna('Autoavaliação') && (
                        <View style={styles.tableHeaderCol}>
                          <Text style={styles.tableCell}>Autoavaliação</Text>
                        </View>
                      )}

                      {temColuna('Gestor') && (
                        <View style={styles.tableHeaderCol}>
                          <Text style={styles.tableCell}>Gestor</Text>
                        </View>
                      )}

                      {temColuna('Pares') && (
                        <View style={styles.tableHeaderCol}>
                          <Text style={styles.tableCell}>Pares</Text>
                        </View>
                      )}

                      {temColuna('Liderados') && (
                        <View style={styles.tableHeaderCol}>
                          <Text style={styles.tableCell}>Liderados</Text>
                        </View>
                      )}

                      {temColuna('Outros') && (
                        <View style={styles.tableHeaderCol}>
                          <Text style={styles.tableCell}>Outros</Text>
                        </View>
                      )}

                      {temColuna('Alta liderança') && (
                        <View style={styles.tableHeaderCol}>
                          <Text style={styles.tableCell}>Alta liderança</Text>
                        </View>
                      )}

                      {(!Is90Graus) &&
                        <View style={styles.tableHeaderCol}>
                          <Text style={styles.tableCell}>Média do avaliado(a) sem autoavaliação</Text>
                        </View>
                      }
                    </View>

                    {
                      chunck.map((e, i) => {
                        return (
                          <View style={{
                            // backgroundColor: i % 2 ? 'rgb(227, 227, 227)' : 'white',
                            margin: 'auto',
                            flexDirection: 'row'
                          }}
                            key={i}
                          >
                            <View style={styles.tableCol}>
                              <Text style={e.competencia.includes('>_<') ? styles.tableCellQuestao : styles.tableCellCompetencia}>{FormatarTabela(e.competencia.replace('>_<', ''))}</Text>
                            </View>

                            {temColuna('Autoavaliação') && (/[0-9]/.test(e.competencia) || e.competencia.includes('Média geral')
                              ? <View style={styles.tableCol}>
                                  <Text style={{
                                    margin: 'auto',
                                    fontSize: 10
                                  }}>{e.autoAvaliacao}</Text>
                                </View>
                              : <View style={styles.tableCol}>
                                  <Text style={{
                                    margin: 'auto',
                                    fontSize: 10,
                                    color: VerificarCorV2('autoavaliacao', e.autoAvaliacao)
                                  }}>{e.autoAvaliacao}</Text>
                                </View>)
                              }

                            { temColuna('Gestor') && (/[0-9]/.test(e.competencia) || e.competencia.includes('Média geral')
                              ? <View style={styles.tableCol}>
                                  <Text style={{
                                    margin: 'auto',
                                    fontSize: 10
                                  }}>{e.gestor}</Text>
                                </View>
                              : <View style={styles.tableCol}>
                                  <Text style={{
                                    margin: 'auto',
                                    fontSize: 10,
                                    color: VerificarCorV2('gestor', e.gestor ?? '')
                                  }}>{e.gestor}</Text>
                                </View>)
                              }

                            {temColuna('Pares') && (/[0-9]/.test(e.competencia) || e.competencia.includes('Média geral')
                              ? <View style={styles.tableCol}>
                                  <Text style={{
                                    margin: 'auto',
                                    fontSize: 10
                                  }}>{e.pares}</Text>
                                </View>
                              : <View style={styles.tableCol}>
                                  <Text style={{
                                    margin: 'auto',
                                    fontSize: 10,
                                    color: VerificarCorV2('par', e.pares ?? '')
                                  }}>{e.pares}</Text>
                                </View>)
                              }

                            {temColuna('Liderados') &&
                              (/[0-9]/.test(e.competencia) || e.competencia.includes('Média geral')
                                ? <View style={styles.tableCol}>
                                <Text style={{
                                  margin: 'auto',
                                  fontSize: 10
                                }}>{e.liderados}</Text>
                              </View>
                                : <View style={styles.tableCol}>
                                  <Text style={{
                                    margin: 'auto',
                                    fontSize: 10,
                                    color: VerificarCorV2('subordinado', e.liderados ?? '')
                                  }}>{e.liderados}</Text>
                                </View>)
                              }

                            {temColuna('Outros') && (
                              /[0-9]/.test(e.competencia) || e.competencia.includes('Média geral')
                                ? <View style={styles.tableCol}>
                                <Text style={{
                                  margin: 'auto',
                                  fontSize: 10,
                                  padding: 1
                                }}>{e.outros}</Text>
                              </View>
                                : <View style={styles.tableCol}>
                                  <Text style={{
                                    margin: 'auto',
                                    fontSize: 10,
                                    padding: 1,
                                    color: VerificarCorV2('outro', e.outros ?? '')
                                  }}>{e.outros}</Text>
                                </View>)
                              }

                            {temColuna('Alta liderança') &&
                              (/[0-9]/.test(e.competencia) || e.competencia.includes('Média geral')
                                ? <View style={styles.tableCol}>
                                <Text style={{
                                  margin: 'auto',
                                  fontSize: 10,
                                  padding: 1
                                }}>{e.altalideranca}</Text>
                              </View>
                                : <View style={styles.tableCol}>
                                  <Text style={{
                                    margin: 'auto',
                                    fontSize: 10,
                                    padding: 1,
                                    color: VerificarCorV2('altaLideranca', e.altalideranca ?? '')
                                  }}>{e.altalideranca}</Text>
                                </View>)
                              }

                            {
                              (!Is90Graus) && (/[0-9]/.test(e.competencia) || e.competencia.includes('Média geral')
                                ? <View style={styles.tableCol}>
                                    <Text style={{
                                      margin: 'auto',
                                      fontSize: 10,
                                      padding: 1
                                    }}>{e.mediaSemAuto}</Text>
                                  </View>
                                : <View style={styles.tableCol}>
                                    <Text style={{
                                      margin: 'auto',
                                      fontSize: 10,
                                      padding: 1,
                                      color: VerificarCorRecomendacao(e.mediaSemAuto ?? '', i)
                                    }}>{e.mediaSemAuto}</Text>
                                  </View>
                              )
                            }
                          </View>
                        )
                      })
                    }
                    {/* {
                      medias.map((e, i) => {
                        return (

                            <View style={{
                              backgroundColor: i % 2 ? 'rgb(227, 227, 227)' : 'white',
                              margin: 'auto',
                              flexDirection: 'row'
                            }}
                              key={i}
                            >
                              <View style={styles.tableCompetenciaCol}>
                                <Text style={styles.tableCellCompetencia}>Média geral</Text>
                              </View>

                              {temColuna('Autoavaliação') &&
                                <View style={styles.tableCol}>
                                  <Text style={{
                                    margin: 'auto',
                                    fontSize: 10
                                  }}>{e.mediaAutoAval}</Text>
                                </View>}

                              {temColuna('Gestor') &&
                                <View style={styles.tableCol}>
                                  <Text style={{
                                    margin: 'auto',
                                    fontSize: 10
                                  }}>{e.mediaGestor}</Text>
                                </View>}

                              {temColuna('Pares') &&
                                <View style={styles.tableCol}>
                                  <Text style={{
                                    margin: 'auto',
                                    fontSize: 10
                                  }}>{e.mediaPares}</Text>
                                </View>}

                              {temColuna('Liderados') &&
                                <View style={styles.tableCol}>
                                  <Text style={{
                                    margin: 'auto',
                                    fontSize: 10
                                  }}>{e.mediaLiderados}</Text>
                                </View>}

                              {temColuna('Outros') &&
                                <View style={styles.tableCol}>
                                  <Text style={{
                                    margin: 'auto',
                                    fontSize: 10,
                                    padding: 1
                                  }}>{e.mediaOutros}</Text>
                                </View>}

                              {temColuna('Alta liderança') &&
                                <View style={styles.tableCol}>
                                  <Text style={{
                                    margin: 'auto',
                                    fontSize: 10,
                                    padding: 1
                                  }}>{e.mediaAltaLideranca}</Text>
                                </View>}

                              {
                                (!Is90Graus) && (
                                  <View style={styles.tableCol}>
                                    <Text style={{
                                      margin: 'auto',
                                      fontSize: 10,
                                      padding: 1
                                    }}>{e.mediaSemAuto}</Text>
                                  </View>
                                )
                              }
                            </View>
                        )
                      })
                    } */}
                  </View>
                </View>
              </View>
              {Footer()}
            </Page>
          )
        })
      }

      {
        (model && model?.discursivas?.length > 0) && (
          <Page style={styles.DefaultPage}>
            <View>
              {Header('Comentários')}

              <View style={{ paddingTop: 16, paddingLeft: 32, paddingRight: 32, height: '100%' }}>
                {model?.discursivas.map((e, i) => {
                  return (
                    <View
                      key={i}
                      wrap={false}
                      style={{
                        paddingTop: 10,
                        padding: 6,
                        marginTop: i === 0 ? 0 : 8,
                        backgroundColor: 'rgb(240, 240, 240)',
                        color: 'rgb(20, 34, 61)'
                      }}
                    >
                      <Text style={{ fontWeight: 'black', fontSize: 12 }}>
                        {e.titulo}
                      </Text>
                      <Text style={{ fontSize: 10 }}>
                        {e.texto}
                      </Text>
                    </View>
                  )
                })}
              </View>
            </View>
            {Footer()}
          </Page>
        )
      }

      {
        (Is90Graus && model && model?.recomendacoes.length > 0) && (
          <Page style={styles.DefaultPage}>
            <View>
              {Header('Recomendações')}
              <Text style={{ fontWeight: 'bold', marginVertical: 16, fontSize: 16, paddingHorizontal: 32 }}>Veja abaixo algumas competências com oportunidades de melhoria</Text>
              <View style={{ flexDirection: 'column', paddingHorizontal: 32 }}>
                {
                  model?.recomendacoes.map((e, i) => {
                    return (
                      <View key={i} style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 8 }}>
                        <View style={{ width: 4, height: 4, borderRadius: 2, backgroundColor: 'black', marginRight: 8 }} />
                        <Text style={{ fontWeight: 'bold', fontSize: 12 }}>{e.nome}</Text>
                      </View>
                    )
                  })
                }
              </View>
            </View>
            {Footer()}
          </Page>
        )
      }

      {
        (!Is90Graus) && (
          <Page style={styles.DefaultPage}>
            <View>
              {Header('Conclusão')}
              <View style={{ paddingLeft: 32, paddingRight: 32, paddingTop: 32 }}>
                <View
                  style={{
                    paddingBottom: 32
                  }}
                >

                  <Text
                    style={{
                      textAlign: 'left',
                      fontSize: 16,
                      color: '#14223d'
                    }}
                  >
                    {model?.pdf?.textoDeConclusao}
                  </Text>
                </View>

                <View style={{ marginBottom: 32 }}>
                  <Text
                    style={{
                      fontWeight: 'bold',
                      fontSize: 16,
                      marginBottom: 16,
                      color: '#14223D'
                    }}
                  >
                    Sua competência melhor avaliada foi
                  </Text>

                  <View
                    style={{
                      flexDirection: 'row',
                      width: '100%',
                      marginBottom: 16
                    }}
                  >
                    <View
                      style={{
                        backgroundColor: '#F0F0F0',
                        borderBottomLeftRadius: 24,
                        borderTopLeftRadius: 24,
                        padding: 16
                      }}
                    >
                      <View
                        style={{
                          alignItems: 'center'
                        }}
                      >
                        <PDFImage
                          src={model?.pdf?.melhorCompetencia?.icone.replace('Competencias-', 'Icones-SISTEMA-').replace('svg', 'png') ?? urlDefault}
                          style={{ width: 50, height: 50 }}
                        />
                        <Text
                          style={{
                            marginTop: 8,
                            width: 100,
                            textAlign: 'center',
                            fontSize: 14,
                            fontWeight: 'bold'

                          }}
                        >
                          {model.pdf?.melhorCompetencia?.nome}
                        </Text>
                      </View>
                    </View>

                    <View
                      style={{
                        width: '80%',
                        backgroundColor: '#5E82C5',
                        padding: 16,
                        borderBottomRightRadius: 24,
                        borderTopRightRadius: 24,
                        flexDirection: 'row',
                        justifyContent: 'space-between'
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 12,
                          color: 'white',
                          width: '100%',
                          textAlign: 'left'
                        }}
                      >
                        {model.pdf?.melhorCompetencia?.descricao}
                      </Text>
                      <View style={{ justifyContent: 'flex-end' }}>
                        <View style={{ width: '27', height: '38' }}>
                          <PDFImage
                            src={'https://sigah.blob.core.windows.net/onboard/0630e4ba-0e40-4b60-b15d-006e286dbf09.png'}
                          />
                        </View>
                      </View>
                    </View>
                  </View>
                </View>

                <View style={{ marginBottom: 32 }}>
                  <Text
                    style={{
                      fontWeight: 'bold',
                      fontSize: 16,
                      color: '#14223D'
                    }}
                  >
                    Veja abaixo algumas competências com oportunidades de melhoria
                  </Text>

                  <View
                    style={{
                      flexDirection: 'row',
                      width: '100%',
                      flexWrap: 'wrap',
                      justifyContent: 'space-between'
                    }}
                  >
                    {model?.recomendacoes.map((e, i) => {
                      return (
                        <View
                          key={i}
                          wrap={false}
                          style={{
                            flexDirection: 'row',
                            width: model?.recomendacoes.length === 1 ? '100%' : model?.recomendacoes.length === 3 && i === 2 ? '100%' : '47%',
                            marginTop: 16
                          }}
                        >
                          <View
                            style={{
                              backgroundColor: '#F0F0F0',
                              borderBottomLeftRadius: 24,
                              borderTopLeftRadius: 24,
                              padding: 16
                            }}
                          >
                            <View
                              style={{
                                alignItems: 'center'
                              }}
                            >

                              <PDFImage
                                src={e.avatar.replace('Competencias-', 'Icones-SISTEMA-').replace('svg', 'png') ?? urlDefault}
                                style={{ width: 50, height: 50 }}
                              />
                              <Text
                                style={{
                                  margin: 8,
                                  width: 100,
                                  textAlign: 'center',
                                  fontSize: 14,
                                  fontWeight: 'bold'
                                }}
                              >
                                {e.nome}
                              </Text>
                            </View>
                          </View>

                          <View
                            style={{
                              width: '80%',
                              backgroundColor: '#D196A1',
                              padding: 16,
                              borderBottomRightRadius: 24,
                              borderTopRightRadius: 24,
                              justifyContent: 'space-between'
                            }}
                          >
                            <Text
                              style={{
                                fontSize: 12,
                                fontWeight: 'bold',
                                color: 'white',
                                textAlign: 'left'
                              }}
                            >
                              {(model.recomendacoes.length > 2 && e.pergunta.length > 117) ? `${e.pergunta.substring(0, 117)}...` : e.pergunta}
                            </Text>
                            <View
                              style={{
                                justifyContent: 'flex-end',
                                flexDirection: 'row',
                                marginTop: 4
                              }}
                            >
                              <View style={{ width: '35', height: '18' }}>
                                <PDFImage
                                  src={'https://sigah.blob.core.windows.net/onboard/60cb693d-8350-4328-bbe0-1efa1499a8eb.png'}
                                />
                              </View>
                            </View>
                          </View>
                        </View>
                      )
                    })}
                  </View>
                </View>
              </View>
            </View>

            {Footer()}
          </Page>
        )
      }

      <Page style={{ justifyContent: 'space-between' }}>
        <View style={{ marginBottom: 44, marginTop: 28 }}>
          <Text
            style={{
              fontSize: 16,
              color: '#203864',
              textAlign: 'center',
              marginBottom: 4
            }}
          >
            {tipoPesquisa}
          </Text>
          <Text style={{ fontSize: 24, color: '#CC42A2', textAlign: 'center', fontWeight: 'demibold' }}>
            {model?.nomePesquisa}
          </Text>
        </View>

        <View
          style={{
            paddingLeft: 32,
            paddingRight: 32,
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <View style={{
            height: 105,
            width: 120,
            justifyContent: 'center',
            alignItems: 'center'
          }}
          >
            {
              (localStorage.getItem('logo')) && (
                <PDFImage
                  style={{ objectFit: 'contain' }}
                  src={ObterLogo()}
                />
              )
            }
          </View>

          <View style={{ flexDirection: 'column' }}>
            <Text style={{ width: 400, fontWeight: 'bold', fontSize: 16 }}>
              {model?.avaliado.nome}, reúna-se com a sua
            </Text>

            <Text style={{ width: 400, fontWeight: 'bold', fontSize: 16 }}>
              liderança e elaborem um plano de ação para que você potencialize o seu desenvolvimento
            </Text>
          </View>

        </View>

        <View style={{ position: 'absolute', top: 749, left: 195 }}>
          <PDFImage
            style={{ objectFit: 'contain', height: 75 }}
            src="https://sigah.blob.core.windows.net/onboard/2b335600-31c6-4d9a-a233-b2200fa5751d.png"
          />

        </View>
        <View style={{ zIndex: 1 }}>
          <Svg width="595" height="483">
            <Path
              d="M595 483V0.5L350 259L278.5 204.5L0 483H595Z"
              fill="#1961E0"
            />
            <Path
              d="M444.196 38.9558L595 1L582.416 156.019L540.257 120.313L355.828 305.432L278.566 249.634L43.1016 483H0.0765076V437L272.183 167.615L349.161 223.217L492.218 79.6269L444.196 38.9558Z"
              fill="url(#paint0_linear_9439_22199)"
            />
            <Defs>
              <LinearGradient
                id="paint0_linear_9439_22199"
                x1={0}
                x2={0}
                y1={10}
                y2={10}
              >
                <Stop offset="0" stopColor="#1961E0" />
                <Stop offset="1" stopColor="#CC42A2" />
              </LinearGradient>
            </Defs>
          </Svg>
        </View>
      </Page>
    </Document>
  )
}
