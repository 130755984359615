/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  BoxHeader, BoxIntegracao, Column,
  FileBox, FileBoxSelecionada, LabelFile,
  LabelSelecionado, BoxBody
} from './styles'
import { FaDownload, FaFilePdf, FaFolder, FaMinusCircle } from 'react-icons/fa'
import { useEffect, useState } from 'react'
import { Button, Flex, FormControl, FormLabel, Input } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { iItemIntegracao } from '../../../../../../interfaces'
import { appApi } from '../../../../../../services/appApi'

interface ITipoCardIntegracaoPDF {
  position: number
  id: string
  ItemIntegracao: iItemIntegracao
  onDelete: (id: string) => void
  onUpdateInput: (item: iItemIntegracao) => void
}
export const CardPdf: React.FC<ITipoCardIntegracaoPDF> = ({ id, ItemIntegracao, position, onDelete, onUpdateInput }) => {
  const nav = useNavigate()

  const [Titulo, setTitulo] = useState('')
  const [Arquivo, setArquivo] = useState('')
  const [Tamanho, setTamanho] = useState('')
  const [NomeArquivo, setNomeArquivo] = useState('')

  useEffect(() => {
    setTitulo(ItemIntegracao.titulo)
    if (ItemIntegracao.arquivo !== null) {
      if (ItemIntegracao.arquivo !== undefined) {
        setArquivo(ItemIntegracao.arquivo.uri)
        setTamanho(ItemIntegracao.arquivo.size)
        setNomeArquivo(ItemIntegracao.arquivo.name)
      }
    }
  }, [])

  const AtualizarArquivo = (event: any): void => {
    const formdata = new FormData()
    formdata.append('request', event.target.files[0])
    appApi.post(`Imersao/${id}/Item/${ItemIntegracao.id}/uploadfile`, formdata)
      .then(resposnse => {
        setTamanho(resposnse.data.size)
        setArquivo(resposnse.data.uri)
        setNomeArquivo(resposnse.data.name)
      }).catch(e => console.log(e))
  }

  return (
    <BoxIntegracao>
      <BoxHeader>
        <span>{position}. Arquivo de PDF</span>
        <FaMinusCircle onClick={() => onDelete(ItemIntegracao.id)}/>
      </BoxHeader>
      <BoxBody>
        <FormControl>
          <FormLabel fontSize={'lg'}>Título</FormLabel>
          <Input
            value={Titulo}
            borderColor={'var(--Gray4)'}
            onChange={(e) => { setTitulo(e.target.value) }}
            type="text"
            placeholder='Propósito, missão, visão, valores'
            onBlur={() => onUpdateInput({
              id: ItemIntegracao.id,
              titulo: Titulo,
              descricao: ItemIntegracao.descricao,
              tipo: ItemIntegracao.tipo
            })}
          />
        </FormControl>

        {
          Arquivo.length < 3
            ? <FileBox>
              <FaDownload color='var(--c7)' size={35} />
              <h3>Arraste e solte os arquivos para fazer o envio</h3>
              <span>(PDF)</span>
              <input id={ItemIntegracao.id} type={'file'} onChange={(e) => { AtualizarArquivo(e) }} />
              <LabelFile theme={'4578D6'} htmlFor={ItemIntegracao.id}><FaFolder />Selecionar arquivo</LabelFile>
            </FileBox>
            : <FileBoxSelecionada>
              <Flex>
                {
                  <FaFilePdf size={45} color={'var(--Vermelho2)'} />
                }

                <Column className='dale'>
                  <span>{NomeArquivo}</span>
                  <small>Tamanho: {Tamanho}</small>
                </Column>
              </Flex>
              <div>
                <input id={ItemIntegracao.id} type={'file'} onChange={(e) => { AtualizarArquivo(e) }} />
                <LabelSelecionado htmlFor={ItemIntegracao.id}><FaFolder />Selecionar arquivo</LabelSelecionado>
              </div>
            </FileBoxSelecionada>
        }

        <Flex justifyContent={'end'}>
          <Button
            fontWeight={'500'}
            borderRadius={'12px'}
            bg={'var(--Rosa)'}
            _hover={{ bg: 'var(--Rosa)' }}>
          <a href={ItemIntegracao.arquivo?.uri}
          >Baixar arquivo em PDF</a></Button>
        </Flex>
      </BoxBody>
    </BoxIntegracao>
  )
}
