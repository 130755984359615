import { Flex } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { MetaButton } from '../../../components/Buttons/MetaButton'
import { iItemIntegracao } from '../../../interfaces'
import { appApi } from '../../../services/appApi'
import { LayoutFullAnswer } from '../../Layouts/LayoutFullAnswer'
import { PDFImersao } from '../Components/Pdf'
import { TextoImersao } from '../Components/Texto'
import { VideoImersao } from '../Components/Video'
import { NavElipse } from './styles'

type ParamProps = {
  conviteId: string
}

export const VisualizarImersao: React.FC = () => {
  const { conviteId } = useParams<ParamProps>()

  const [Switch, setSwitch] = useState(0)
  const [Imersao, setImersao] = useState<iItemIntegracao[]>([])
  const [isRespondido, setisRespondido] = useState(false)

  function GetImersoesById(): void {
    appApi.get(`ImersaoExterna/${conviteId as string}/Item`)
      .then(response => {
        setImersao(response.data)
      }).catch(err => console.error(err))
  }

  function PostVisualizar(): void {
    setisRespondido(true)
    appApi.post(`ImersaoExternaConvite/${conviteId as string}/Visualizar`)
      .then(res => console.log(res))
      .catch(err => console.log(err))
  }

  useEffect(() => {
    GetImersoesById()
  }, [])

  return (
    <LayoutFullAnswer isRespondido={isRespondido} titulo='Imersão'>
      {
        Imersao.map((e: iItemIntegracao, i) => {
          if (e.tipo === 1) {
            return (
              <VideoImersao
                key={i}
                item={e}
                isVisible={i === Switch}
              />
            )
          } else if (e.tipo === 2) {
            return (
              <TextoImersao
                key={i}
                item={e}
                isVisible={i === Switch}
              />
            )
          } else if (e.tipo === 3) {
            return (
              <PDFImersao
                key={i}
                isVisible={i === Switch}
                uri={e.arquivo?.uri}
              />
            )
          }
          return null
        })
      }

      <NavElipse>
        {
          Imersao.map((e: iItemIntegracao, i) => {
            return (
              <div
                key={i}
                title={e.titulo}
                onClick={() => setSwitch(i)}
                style={{ background: Switch === i ? 'var(--c7)' : 'var(--c5)' }}>
              </div>
            )
          })
        }
      </NavElipse>

      <Flex justifyContent={'center'}>
        {Switch !== 0 && <MetaButton
          onClick={() => setSwitch(Switch - 1)}
          bg='c5'
          size='md'
          styles={{ marginRight: '2rem' }}
        >Voltar</MetaButton>
        }

        {
          Switch === Imersao.length - 1
            ? <MetaButton
              onClick={PostVisualizar}
              bg='v2' size='md'
            >Finalizar imersão</MetaButton>
            : <MetaButton
                onClick={() => setSwitch(Switch + 1)}
                bg='v2' size='md'
              >Próximo</MetaButton>
        }
      </Flex>
    </LayoutFullAnswer>
  )
}
