import { useEffect, useState } from 'react'
import { FaPlus } from 'react-icons/fa'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import { Body } from '../../Layouts/Body'
import { MetaButton } from '../../../components/Buttons/MetaButton'
import { OutlineMetaButton } from '../../../components/Buttons/OutlineMetaButton'
import { iEtapaColaborador, iNomeId, iPerfilColaborador, iPostProcessoOnboard, iProcessoOnboard, iPutDesligamento } from '../../../interfaces'
import { appApi } from '../../../services/appApi'
import { BoardBox } from './Components/BoardBox'
import { CardOnboard } from './Components/CardOnboard'
import { ContainerTrilha } from './Components/ContainerTrilha'
import { Main, MainColumn, ColumnTitle, ArrowButton, SectionOnboard } from './styles'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { ModalAdicionarEtapa } from '../ConfigurarTrilha/Components/ModalAdicionarEtapa'
import { PerfilColaborador } from '../../../components/PerfilColaborador/indesx'
import { Flex, Tab, TabList, TabPanel, TabPanels, Tabs, Text, useToast } from '@chakra-ui/react'
import { Button } from '../../../components/Button'
import { Acao } from './Components/Acao'
import { ModalSucess } from './Components/ModalSucess'
import { TabelaAprovacao } from './Components/TabelaAprovacao'
import { TabelaEmAndamento } from './Components/TabelaEmAndamento'
import { FindUser } from '../../../components/FindBox'
import { ModalDelete } from '../../../components/ModalDelete'
import { PopUpDesligarColaborador } from '../../Organograma/Desenvolvimento/Colaboradores/Components/PopUpDesligarColaborador'
import { useQuery } from '../../../Utils/Helper'

interface iAcao {
  id: string
  dataInicio: string
  dataFim: string
  hora: string
  assunto: string
  titulo: string
  descricao: string
  motivo: string
  comentario?: string
  solicitarAjustes: boolean
}

interface iDadoTabelaPDI {
  id: string
  dataFim: string
  origem: string
  totalAcoes: number
}

interface iDadoTabelaAndamento extends iDadoTabelaPDI {
  totalConcluidas: number
  ultimaAvaliacao?: number
  temAcaoAtrasada: boolean
}

interface iTabelasPDIs {
  emAndamento: iDadoTabelaAndamento[]
  aprovacao: iDadoTabelaPDI[]
}

interface iAtualizacaoPDI {
  id: string
  comentario?: string
}

interface iPDIProviderProps {
  Acoes: iAcao[]
  ExluirAcao: (id: string) => void
  SolicitarAjustes: () => void
  AprovarPDI: () => void
  AtualizarAcao: (acao: iAcao) => void
}

type iLocationProp = {
  colaboradorId: string
  pdiId: string
}

function usePDIState(pathname: string, nav: any, reload: () => void, pdiId?: string): iPDIProviderProps {
  const [Acoes, setAcoes] = useState<iAcao[]>([])

  function InternalReset(): void {
    reload()
    nav(`/${pathname.split('/').splice(1, 3).join('/')}`)
  }

  function ExluirAcao(id: string): void {
    appApi.delete(`PDI/${pdiId as string}/Acao/${id}`)
      .then(() => {
        const copy = [...Acoes.filter(e => e.id !== id)]
        setAcoes([])
        setAcoes(copy)
        if (copy.length === 0) {
          InternalReset()
        }
      })
      .catch(err => console.log(err))
  }

  function SolicitarAjustes(): void {
    const form: iAtualizacaoPDI[] = Acoes.filter(r => r.solicitarAjustes).map((e) => {
      return {
        id: e.id,
        comentario: e.comentario
      }
    })

    appApi.post(`PDI/${pdiId as string}/Acao/SolicitarAjuste`, form)
      .then(() => {
        const copy = [...Acoes.filter(e => !e.solicitarAjustes)]
        setAcoes([])
        setAcoes(copy)
        if (copy.length === 0) {
          InternalReset()
        }
      })
      .catch(err => console.log(err))
  }

  function AprovarPDI(): void {
    const form: string[] = Acoes.filter(r => !r.solicitarAjustes).map((e) => {
      return e.id
    })

    appApi.post(`PDI/${pdiId as string}/Acao/Aprovar`, form)
      .then(() => {
        const copy = [...Acoes.filter(e => e.solicitarAjustes)]
        setAcoes([])
        setAcoes(copy)
        if (copy.length === 0) {
          InternalReset()
        }
      })
      .catch(err => console.log(err))
  }

  function AtualizarAcao(acao: iAcao): void {
    const copy = [...Acoes]
    const pos = copy.findIndex(e => e.id === acao.id)
    if (pos !== -1) {
      copy[pos] = acao
      setAcoes(copy)
    }
  }

  function ObterAcoesPorPdiId(): void {
    appApi.get(`PDI/${pdiId as string}/Acao?status=1`)
      .then(res => setAcoes(res.data))
      .catch(err => console.log(err))
  }

  useEffect(() => {
    if (pdiId) {
      ObterAcoesPorPdiId()
    }
  }, [pdiId])

  return { Acoes: Acoes, AprovarPDI: AprovarPDI, AtualizarAcao: AtualizarAcao, ExluirAcao: ExluirAcao, SolicitarAjustes: SolicitarAjustes }
}

export const VisulaizarTrilhaColaborador: React.FC = () => {
  const { colaboradorId } = useParams<iLocationProp>()
  const query = useQuery()
  const nav = useNavigate()
  const toast = useToast()
  const { pathname } = useLocation()

  const [PDIs, setPDIs] = useState<iTabelasPDIs>()
  const [ProcessoEmAndamento, setProcessoEmAndamento] = useState<iProcessoOnboard>()
  const [ProcessosFinalizados, setProcessosFinalizados] = useState<iProcessoOnboard[]>([])
  const [Colaborador, setColaborador] = useState<iPerfilColaborador>({
    avatar: '',
    email: '',
    id: '',
    lider: {
      avatar: '',
      nome: ''
    },
    liderados: [],
    nome: '',
    nomeCargo: '',
    nomeDepartamento: '',
    pares: [],
    celular: ''
  })
  const [Rh, setRh] = useState<iNomeId[]>([])
  const [ResponsavelId, setResponsavelId] = useState<string>()

  const [ModalDesligamentoIsOpen, setModalDesligamentoIsOpen] = useState(false)
  const [ModalAddEtapaIsOpen, setModalAddEtapaIsOpen] = useState(false)
  const [ModalSucessIsOpen, setModalSucessIsOpen] = useState(false)

  const [View, setView] = useState('0')

  const { Acoes, AprovarPDI, AtualizarAcao, ExluirAcao, SolicitarAjustes } = usePDIState(pathname, nav, getPDIS, query.get('pdiId') ?? undefined)

  const [ModalDeleteEtapaIsOpen, setModalDeleteEtapaIsOpen] = useState(false)
  const [ModalAcaoIsOpen, setModalAcaoIsOpen] = useState(false)
  const [Id, setId] = useState<string>()

  function onOpenModalDeleteEtapa(id: string): void {
    setModalDeleteEtapaIsOpen(true)
    setId(id)
  }

  function onCloseModalDeleteEtapa(): void {
    setModalDeleteEtapaIsOpen(false)
    setId(undefined)
  }

  function onOpenModalDeleteAcao(id: string): void {
    setModalAcaoIsOpen(true)
    setId(id)
  }

  function onCloseModalDeleteAcao(): void {
    setModalAcaoIsOpen(false)
    setId(undefined)
  }

  function getTrilha(): void {
    appApi.get(`ProcessoOnboard/Colaborador/${colaboradorId as string}`)
      .then(response => {
        if (response.status === 200) {
          setProcessoEmAndamento(response.data)
        }
      }).catch(err => {
        console.log(err)
        setProcessoEmAndamento(undefined)
      })
  }

  function onDeleteEtapa(): void {
    appApi.delete(`EtapaColaborador/${ProcessoEmAndamento?.id as string}/Etapa/${Id ?? ''}`)
      .then(getTrilha)
      .catch(err => console.log(err))
  }

  function onConcludeEtapa(Id: string): void {
    appApi.patch(`EtapaColaborador/${ProcessoEmAndamento?.id as string}/Etapa/${Id}/Concluir`)
      .then(res => console.log(res)).then(() => {
        getTrilha()
        ObterProcessos()
      }).catch(err => console.log(err))
  }

  function onUpdateVisibilidade(Id: string): void {
    appApi.patch(`EtapaColaborador/${ProcessoEmAndamento?.id as string}/Etapa/${Id}/Visibilidade`)
      .then(res => console.log(res)).then(getTrilha).catch(err => console.log(err))
  }

  function ObterProcessos(): void {
    appApi.get(`ProcessoOnboard/${colaboradorId as string}/Processos`)
      .then(res => setProcessosFinalizados(res.data))
      .catch(err => console.log(err))
  }

  function CriarProcesso(form: iPostProcessoOnboard): void {
    appApi.post('ProcessoOnboard', form).then(() => {
      getTrilha()
      nav(`/Rh/VisualizarPerfil/${colaboradorId ?? ''}`)
      toast({
        title: 'Trilha cadastrada com sucesso',
        status: 'success',
        isClosable: false,
        position: 'top-right'
      })
    }).catch(err => console.log(err))
  }

  function InterromperOnboard(form: iPutDesligamento): void {
    appApi
      .put(`Colaborador/${colaboradorId ?? ''}/Desligar`, form)
      .then(() => {
        if (form.manterAcesso) {
          ObterProcessos()
          getColaboador()
          setProcessoEmAndamento(undefined)
          nav('?criar=true')
          setModalDesligamentoIsOpen(false)
        } else {
          toast({
            title: 'Colaborador desligado com sucesso',
            status: 'success',
            position: 'top-right',
            isClosable: false
          })
          nav('/Colaborador')
        }
      }).catch(err => {
        setModalDesligamentoIsOpen(false)
        console.log(err)
      })
  }

  function getColaboador(): void {
    appApi.get(`Colaborador/${colaboradorId as string}/Perfil`)
      .then(response => setColaborador(response.data))
      .catch(err => console.log(err))
  }

  function getPDIS(): void {
    appApi.get(`PDI/Perfil/${colaboradorId as string}`)
      .then(res => setPDIs(res.data))
      .catch(err => console.log(err))
  }

  function Load(): void {
    getTrilha()
    ObterProcessos()
    getColaboador()
  }

  function ObterRh(): void {
    appApi.get('Colaborador/Rh')
      .then(res => setRh(res.data))
      .catch(err => console.log(err))
  }

  function SelecionarResponsavel(id: string): void {
    if (id === '') {
      setResponsavelId(undefined)
    } else {
      setResponsavelId(id)
    }
  }

  function AtualizarResponsavel(): void {
    appApi.put(`ProcessoOnboard/${ProcessoEmAndamento?.id as string}/Responsavel/?responsavelId=${ResponsavelId as string}`)
      .then(() => {
        setResponsavelId(undefined)
        toast({
          title: 'Responsável pelo onboarding atualizado com sucesso',
          status: 'success',
          isClosable: false,
          position: 'top-right'
        })
      })
      .catch(err => {
        console.log(err)
        toast({
          title: 'Erro ao atualizar o responsável pelo onboarding',
          status: 'error',
          isClosable: false,
          position: 'top-right'
        })
      })
  }

  useEffect(() => {
    Load()
    getPDIS()
    ObterRh()
  }, [colaboradorId])

  useEffect(() => {
    if (query.get('pdiId')) {
      setView('1')
    } else {
      setView('0')
    }
  }, [query])

  return (
    <Body>
      <ModalDelete
        isOpen={ModalAcaoIsOpen}
        message='Deseja realmente excluir esta ação?'
        onRequestClose={onCloseModalDeleteAcao}
        onConfirm={() => ExluirAcao(Id ?? '')}
      />

      <ModalDelete
        isOpen={ModalDeleteEtapaIsOpen}
        message='Deseja realmente excluir esta etapa?'
        onRequestClose={onCloseModalDeleteEtapa}
        onConfirm={onDeleteEtapa}
      />

      <ModalSucess
        isOpen={ModalSucessIsOpen}
        onRequestClose={() => setModalSucessIsOpen(false)}
      />

      <ModalAdicionarEtapa
        ProcessoId={ProcessoEmAndamento?.id}
        colabId={colaboradorId as string}
        OnCloseModal={() => setModalAddEtapaIsOpen(false)}
        isOpen={ModalAddEtapaIsOpen}
        tipo={ProcessoEmAndamento?.trilha.categoria === 'Offboarding' ? 3 : undefined}
      />

      {ModalDesligamentoIsOpen &&
        <PopUpDesligarColaborador
          onDesligar={InterromperOnboard}
          onClose={() => setModalDesligamentoIsOpen(false)}
          mensagemAlternativa='Gostaria de adicionar uma trilha de offboarding para acompanhar esse deligamento?'
        />
      }

      <Main>
        <PerfilColaborador
          Colaborador={Colaborador}
        />

        <MainColumn padding='1rem 1rem' width='60%'>
          <Tabs defaultIndex={query.get('pdiId') ? 1 : 0}>
            <TabList>
              <Tab color={'black'} bg={'none'}>Onboarding</Tab>
              <Tab color={'black'} bg={'none'}>PDI</Tab>
            </TabList>

            <TabPanels>
              <TabPanel>
                {
                  (ProcessoEmAndamento) && (
                    <>
                      {ProcessoEmAndamento.trilha.categoria !== 'Offboarding' &&
                        <Flex justifyContent={'end'}>
                          <OutlineMetaButton
                            color='terc3'
                            size='sm'
                            margin='1rem 1rem'
                            onClick={() => setModalDesligamentoIsOpen(true)}
                          ><AiOutlineCloseCircle className='IconLeft' />Desligar colaborador</OutlineMetaButton>

                        </Flex>}

                      <ColumnTitle>
                        {
                          (ProcessoEmAndamento.trilha !== null) && (
                            <h3>{ProcessoEmAndamento.trilha.categoria} - {ProcessoEmAndamento.trilha.nome}</h3>
                          )
                        }
                        <Flex>
                          <OutlineMetaButton
                            color='v2'
                            size='sm'
                            margin='1rem 0rem'
                            onClick={() => setModalAddEtapaIsOpen(true)}
                          ><FaPlus className='IconLeft' />Adicionar etapa</OutlineMetaButton>
                        </Flex>
                      </ColumnTitle>

                      <Flex alignItems={'start'} gap='1rem' margin='1rem 0'>
                        <Flex flexDir={'column'}>
                          <Text fontSize={'1.125rem'}
                            fontWeight={'500'} marginBottom={'.5rem'}
                          >Responsável do RH por este onboarding:
                          </Text>
                          <FindUser
                            selecionado={ProcessoEmAndamento.responsavelId}
                            onChoice={SelecionarResponsavel}
                            lista={Rh}
                          />
                        </Flex>
                        <Button
                          isDisabled={ResponsavelId === undefined}
                          VarColor='Green1'
                          marginTop={'2rem'}
                          onClick={AtualizarResponsavel}
                        >Atualizar</Button>
                      </Flex>

                      <Flex flexDir={'column'}>
                        {
                          ProcessoEmAndamento.etapas.map((e: iEtapaColaborador, i) => {
                            return (
                              <BoardBox
                                key={i}
                                etapa={e}
                                email={Colaborador.email ?? ''}
                                processoId={ProcessoEmAndamento.id}
                                colaboradorId={colaboradorId as string}
                                isLast={i === ProcessoEmAndamento.etapas.length - 1}
                                onDeleteEtapa={onOpenModalDeleteEtapa}
                                onConcludeEtapa={onConcludeEtapa}
                                onUpdateVisibility={onUpdateVisibilidade}
                              />
                            )
                          })
                        }
                      </Flex>
                    </>
                  )
                }

                {
                  (!ProcessoEmAndamento && !query.get('criar')) && (
                    <ArrowButton>
                      <MetaButton bg='v2' size='lg' onClick={() => nav('?criar=true')}>Começar novo onboarding</MetaButton>
                    </ArrowButton>
                  )
                }

                {
                  (query.get('criar') && !ProcessoEmAndamento) && (
                    <ContainerTrilha
                      colaboradorId={colaboradorId as string}
                      onCancel={() => nav(-1)}
                      onCreate={CriarProcesso}
                      rh={Rh}
                      temOnboard={ProcessosFinalizados.length > 0}
                      dataDesligamento={Colaborador.dataDesligamento}
                    />
                  )
                }

                {
                  (!query.get('criar') && ProcessosFinalizados.length > 0) && (
                    <Flex flexDir={'column'}>
                      <h3>Onboardings realizados</h3>
                      <SectionOnboard>
                        {
                          ProcessosFinalizados.map((e, i) => {
                            return (
                              <CardOnboard
                                key={i}
                                processo={e}
                              />
                            )
                          })
                        }
                      </SectionOnboard>
                    </Flex>
                  )
                }
              </TabPanel>
              <TabPanel padding={'1rem 0'}>
                {
                  (View === '0') && (
                    <Flex gap='1rem' flexDirection='column' padding={'0 1rem'}>
                      {PDIs && PDIs?.emAndamento?.length <= 0 && (
                        <ArrowButton >
                          <MetaButton bg='v2' size='lg' onClick={() => nav('/Desempenho/PDI/Criar')}>Começar novo PDI</MetaButton>
                        </ArrowButton>
                      )}
                      {
                        (PDIs && PDIs?.aprovacao.length > 0) && (
                          <Flex flexDir={'column'}>
                            <h3>PDI aguardando aprovação</h3>
                            <Flex marginTop={'.5rem'} flexDir={'column'}>
                              <TabelaAprovacao slice={4} dados={PDIs?.aprovacao} />
                            </Flex>
                          </Flex>
                        )
                      }
                      {
                        (PDIs && PDIs?.emAndamento.length > 0) && (
                          <Flex flexDir={'column'}>
                            <h3>PDI em andamento</h3>
                            <Flex marginTop={'.5rem'} flexDir={'column'}>
                              <TabelaEmAndamento slice={4} dados={PDIs.emAndamento} />
                            </Flex>
                          </Flex>
                        )
                      }
                    </Flex>
                  )
                }

                {
                  (View === '1') && (
                    <Flex flexDir={'column'}>
                      <Button
                        width={'fit-content'}
                        VarColor='Azul'
                        size={'md'}
                        marginBottom='1rem'
                        onClick={() => nav(`/${pathname.split('/').splice(1, 3).join('/')}`)}
                      >Visualizar PDIs</Button>

                      <h3>Aprovando PDI</h3>
                      <Flex marginTop={'1rem'} flexDir={'column'} gap='1rem'>
                        {
                          Acoes.map((e, i) => {
                            return (
                              <Acao
                                key={i}
                                onRemove={onOpenModalDeleteAcao}
                                onUpdate={AtualizarAcao}
                                acao={e}
                              />
                            )
                          })
                        }
                        <Flex gap={'1rem'}>
                          <Button
                            VarColor='Azul' onClick={AprovarPDI}
                          >Aprovar PDI</Button>
                          <Button
                            onClick={SolicitarAjustes}
                            isDisabled={Acoes.find(e => e.solicitarAjustes) === undefined}
                            VarColor='Rosa'
                          >Solicitar ajustes</Button>
                        </Flex>
                      </Flex>
                    </Flex>
                  )
                }
              </TabPanel>
            </TabPanels>
          </Tabs>
        </MainColumn>
      </Main>
    </Body>
  )
}
