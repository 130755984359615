import { CSSProperties } from 'react'
import { Container } from './styles'

interface ContainerProps{
  styles?: CSSProperties
}

export const OutlineGreyBox: React.FC<ContainerProps> = ({ styles, children }) => {
  return (
        <Container style={styles}>
            {children}
        </Container>
  )
}
