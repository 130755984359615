import { Box, Button, Flex, VStack, Text, HStack, Select } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { FaBan, FaBriefcase, FaCheck, FaEye, FaFileCsv, FaSync, FaUsers } from 'react-icons/fa'
import { useNavigate, useParams } from 'react-router-dom'
import { appApi, urlApi } from '../../services/appApi'

import { Body } from '../Layouts/Body'
// import imgPerfil from '../../assets/pi/perfil.png'//

import { TabelaAderencia } from './components/TabelaAderencia'
import { IJobTarget } from './JobTarget/Create'

type INavProps = {
  jobId: string
}

interface IperfilBa{
  id?: string
  nome: string
  dataPesquisa: Date
  perfil: string
  urlImgPerfil?: string
}
interface IperfilDto{
  perfil: IperfilBa
  outrosCargos: IOutrosCargos[]
}

interface IOutrosCargos{
  nome: string
  jobId: string
}
interface IJobPerfis{
  jobTarget: IJobTarget
  numeroPerfis: number
  numeroPerfisAdaptativos: number
  numeroPerfisAderentes: number
  numeroPerfisNaoAderentes: number
  perfis: IperfilDto[]
}

export const DetalhesCargo: React.FC = () => {
  const navigate = useNavigate()
  const { jobId } = useParams<INavProps>()
  const [paginaAtual, setPaginaAtual] = useState(0)
  const [numeroPaginas, setNumeroPaginas] = useState(0)
  const [filtro, setFiltro] = useState('1')
  const [isLoading, setIsLoading] = useState(true)

  const [model, setModel] = useState<IJobPerfis>()
  function getJobTargets(jobId: string): void {
    appApi.get<IJobPerfis>(`AssessmentPI/JobTarget/${jobId}/perfis/?index=${paginaAtual}&filtro=${filtro}`)
      .then(res => {
        if (res.status === 200) {
          console.log(res.data)
          setModel(res.data)
          const numeroP = Math.ceil(res.data.numeroPerfis / 50)
          setNumeroPaginas(numeroP > 1 ? numeroP - 1 : 1)
          setIsLoading(false)
        }
      })
      .catch(err => { console.log(err); setIsLoading(false) })
  }

  function obterTotal(): number {
    return (model?.numeroPerfis ?? 0) + (model?.numeroPerfisNaoAderentes ?? 0)
  }
  useEffect(() => {
    console.log(jobId)
    setPaginaAtual(0)
    if (jobId) {
      getJobTargets(jobId ?? '')
    }
    setIsLoading(false)
  }, [jobId])

  useEffect(() => {
    setIsLoading(true)
    getJobTargets(jobId ?? '')
  }, [paginaAtual, filtro])

  useEffect(() => {
    console.log(`carregando:${isLoading ? 'Sim' : 'nao'}`)
  }, [isLoading])

  function obterNumeroPerfis(): number {
    switch (filtro) {
      case '1':
        return model?.numeroPerfisAderentes ?? 0

      case '2':
        return model?.numeroPerfisAdaptativos ?? 0

      case '3':
        return model?.numeroPerfis ?? 0

      case '4':
        return model?.numeroPerfisNaoAderentes ?? 0

      default:
        return 0
    }
  }

  return (<Body>

<Flex justify='center'>
<Box width='70rem' maxWidth='90vw' bg='white' borderRadius='lg'>

<Button
onClick={() => navigate('/AssessmentPi/Dashboard')}
bg='var(--c6)' width='5rem' height='2.5rem' size='xs' m={3}>Voltar</Button>
<Flex justifyContent='space-between'>

<VStack alignItems='start' p='4'>
<HStack>
<FaBriefcase size='1.5rem'/>
<Text fontWeight='bold' fontSize='1.8rem' >{model?.jobTarget.nome}</Text>
</HStack>

<HStack>
<FaCheck color='var(--Green2)' size='1.5rem' />
<Text ml={2} color='var(--Green2)' fontSize='1.1rem' >Perfis aderentes: {model?.jobTarget.perfisAderentes.map((s, i) => `${s}${i !== model.jobTarget.perfisAderentes.length - 1 ? ',' : ''} `)}</Text>
</HStack>
<HStack>
<FaSync color='var(--Blue1)' size='1.5rem'/>
<Text color='var(--a1)' fontSize='1.1rem' ml={2}>Perfis adaptativos: {model?.jobTarget.perfisAdaptativos.map((s, i) => `${s}${i !== model.jobTarget.perfisAdaptativos.length - 1 ? ',' : ''}`)}</Text>
</HStack>
</VStack>
<HStack mr={3}>
<Button onClick={() => { window.location.href = `${urlApi}/AssessmentPI/JobTarget/${jobId ?? ''}/csv/?filtro=${3}` }}
 bg='var(--Rosa)' width='10rem' height='2.5rem' size='xs' m={1}><Box mx={3}><FaFileCsv/></Box> Exportar CSV</Button>
<Button onClick={() => navigate(`/AssessmentPi/JobTarget/Edit/${jobId ?? ''}`)}
 bg='var(--a2)' width='7rem' height='2.5rem' size='xs' m={1}>Editar cargo</Button>
</HStack>

</Flex>

<Flex justifyContent='space-around' wrap='wrap' gap='1rem' >
  <Box width='15rem' height='8rem' border='2px' borderColor='var(--Roxo)' borderRadius='xl'>
      <VStack>
        <Text mt={2} color='var(--Roxo)'>Total de perfis coletados</Text>
        <HStack m={0} p={0}>
          <FaUsers color='var(--Roxo)' size='2.5rem'/>
          <Text fontSize='5xl' color='var(--Roxo)'>{obterTotal() }</Text>
        </HStack>
      </VStack>
  </Box>

  <Box width='15rem' height='8rem' border='2px' borderColor='var(--Green2)' borderRadius='xl'>
      <VStack>
        <Text mt={2} color='var(--Green2)'>Perfis aderentes</Text>
        <HStack m={0} p={0}>
          <FaCheck color='var(--Green2)' size='2.5rem'/>
          <Text fontSize='5xl' color='var(--Green2)'>{model?.numeroPerfisAderentes}</Text>
        </HStack>
      </VStack>
  </Box>

  <Box width='15rem' height='8rem' border='2px' borderColor='var(--Blue1)' borderRadius='xl'>
      <VStack>
        <Text mt={2} color='var(--Blue1)'>Perfis adaptativos</Text>
        <HStack m={0} p={0}>
          <FaSync color='var(--Blue1)' size='2.5rem'/>
          <Text fontSize='5xl' color='var(--Blue1)'>{model?.numeroPerfisAdaptativos}</Text>
        </HStack>
      </VStack>
  </Box>

  <Box width='15rem' height='8rem' border='2px' borderColor='var(--Yellow)' borderRadius='xl'>
      <VStack>
        <Text mt={2} color='var(--Yellow)'>Perfis não aderentes</Text>
        <HStack m={0} p={0}>
          <FaBan color='var(--Yellow)' size='2.5rem'/>
          <Text fontSize='5xl' color='var(--Yellow)'>{model?.numeroPerfisNaoAderentes}</Text>
        </HStack>
      </VStack>
  </Box>

</Flex>
<Flex mx={8} mt={8}>
  <HStack color='var(--c7)' mr={4}>
  <FaEye/><Text>Exibir</Text>
    </HStack>
<Select width='18rem'
  bg='#fcfbf4'
  borderColor='tomato'
  color='var(--c7)'
  onChange={(value) => {
    setPaginaAtual(0)
    setFiltro(value.currentTarget.value)
  }}
>
<option value='1'>Perfis aderentes</option>
<option value='2'>Perfis adaptativos</option>
<option value='3'>Perfis aderentes e adaptativos</option>
<option value='4'>Perfis não aderentes</option>

</Select>
</Flex>
<TabelaAderencia
isLoading={isLoading}
prev={() => { setPaginaAtual(paginaAtual > 0 ? paginaAtual - 1 : 0) }}
next={() => { setPaginaAtual(paginaAtual < numeroPaginas ? paginaAtual + 1 : numeroPaginas) }}
numeroPaginas={ Math.ceil((obterNumeroPerfis() ?? 0) / 50)} paginaAtual={paginaAtual} titulo={'Nome da Tabela'}
job={model}
/>
</Box>

    </Flex>
  </Body>)
}
