import { NavBtn, StandBtn } from './styles'

interface IBtnNextNav {
  Caminho?: string
  Parametro?: any
  OnClick?: () => void
  isNav: boolean
  Size: string
}
export const GreenButton: React.FC<IBtnNextNav> = ({ OnClick, Caminho, Size, children, Parametro, isNav, ...rest }) => {
  if (isNav) {
    return (
    <NavBtn heigth={Size + 'HeigthBtn'} padding={Size + 'PaddingBtn'} size={Size + 'FontBtn'} onClick={OnClick} {...rest} to={{ pathname: Caminho }} state={Parametro}>{children}</NavBtn>
    )
  }

  return (
    <StandBtn heigth={Size + 'HeigthBtn'} padding={Size + 'PaddingBtn'} size={Size + 'FontBtn'} onClick={OnClick} {...rest}>{children}</StandBtn>
  )
}
