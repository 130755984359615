import styled from 'styled-components'

export const Header = styled.div<{hasTitle?: boolean}>`
display: flex;
justify-content: space-between;
padding: 1rem;

${({ hasTitle }) => hasTitle && `
    border-bottom: 2px solid var(--c4);
`}

h3,span{
color:#2C4D8A;
}

span{
    margin-top: .3rem;  
}

svg{
    color: var(--a2);
    cursor: pointer;
}
`

export const Container = styled.div<{width?: string}>`
width: ${props => props.width};
`
