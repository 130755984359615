import { Flex } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { MetaButton } from '../../../components/Buttons/MetaButton'
import { iItemIntegracao } from '../../../interfaces'
import { appApi } from '../../../services/appApi'
import { ColaboradorChecklist } from '../Components/Checklist'
import { ModalSucess } from '../Components/ModalSucess'
import { PDFImersao } from '../Imersao/Components/Pdf'
import { TextoImersao } from '../Imersao/Components/Texto'
import { VideoImersao } from '../Imersao/Components/Video'
import { NavElipse } from './styles'

type navProps = {
  interacaoId: string
  etapaId: string
}

interface iChekIntegracao {
  texto: string
  check: Boolean
  observacaoIson: Boolean
  observacao?: string
}

interface iRespostaChecklist {
  itemId: string
  respostas: iChekIntegracao[]
}
export const IntegracaoColaborador: React.FC = () => {
  const { etapaId, interacaoId } = useParams<navProps>()
  const nav = useNavigate()

  const [Switch, setSwitch] = useState(0)

  const [Integracao, setIntegracao] = useState<iItemIntegracao[]>([])
  const [PopUpisOpen, setPopUpisOpen] = useState(false)

  const [Respostas, setRespostas] = useState<iRespostaChecklist[]>([])

  function Get(): void {
    appApi.get(`Integracao/${interacaoId as string}`)
      .then(response => {
        setIntegracao(response.data.itensIntegracao)
      }).catch(err => console.error(err))
  }

  function onRequestClosePopUp(): void {
    setPopUpisOpen(false)
    nav('/Colaborador')
  }

  function ConcluirEtapa(): void {
    appApi.patch(`EtapaColaborador/${etapaId as string}/Integracao/Concluir`, Respostas)
      .then(res => {
        setPopUpisOpen(true)
      })
      .catch(err => console.log(err))
  }

  function UpdateRespostaChecklist(resposta: iChekIntegracao[], itemId: string): void {
    const copy = [...Respostas]
    const pos = copy.findIndex(e => e.itemId === itemId)
    if (pos !== -1) {
      copy[pos].respostas = resposta
      setRespostas(copy)
    } else {
      setRespostas(oldArray => [...oldArray,
        {
          itemId: itemId,
          respostas: resposta
        }])
    }
  }

  useEffect(() => {
    Get()
  }, [])

  return (
    <>
      <ModalSucess
        isOpen={PopUpisOpen}
        onRequestClose={onRequestClosePopUp}
        onConfirm={onRequestClosePopUp}
        Titulo={'Integração'}
      />
      {
        Integracao.map((e: iItemIntegracao, i) => {
          if (e.tipo === 0) {
            return (
              <ColaboradorChecklist
                key={i}
                itemIntegracao={e}
                isVisible={i === Switch}
                UpdateResposta={UpdateRespostaChecklist}
              />
            )
          } else if (e.tipo === 1) {
            return (
              <VideoImersao
                key={i}
                Descricao={e.descricao}
                Titulo={e.titulo}
                Url={e.arquivo?.uri}
                isVisible={i === Switch}
              />
            )
          } else if (e.tipo === 2) {
            return (
              <TextoImersao
                key={i}
                Titulo={e.titulo}
                Descricao={e.descricao}
                Url={e.arquivo?.uri}
                isVisible={i === Switch}
              />
            )
          } else if (e.tipo === 3) {
            return (
              <PDFImersao
                key={i}
                isVisible={i === Switch}
                uri={e.arquivo?.uri}
              />
            )
          }
          return null
        })
      }

      <NavElipse>
        {
          Integracao.map((e: iItemIntegracao, i) => {
            return (
              <div
                key={i}
                title={e.titulo}
                onClick={() => setSwitch(i)}
                style={{ background: Switch === i ? 'var(--c7)' : 'var(--c5)' }}>
              </div>
            )
          })
        }
      </NavElipse>

      <Flex justifyContent={'center'}>
        {
          Switch === 0
            ? <MetaButton onClick={() => nav('/Colaborador')} bg='c5' size='md' styles={{ marginRight: '2rem' }}>Voltar</MetaButton>
            : <MetaButton onClick={() => setSwitch(Switch - 1)} bg='c5' size='md' styles={{ marginRight: '2rem' }}>Voltar</MetaButton>
        }

        {
          Switch === Integracao.length - 1
            ? <MetaButton onClick={ConcluirEtapa} bg='v2' size='md'>Finalizar</MetaButton>
            : <MetaButton onClick={() => setSwitch(Switch + 1)} bg='v2' size='md'>Próximo</MetaButton>
        }
      </Flex>
    </>
  )
}
