import { BodyCard, Container, NavButton } from './styles'
import { HTMLAttributes } from 'react'

interface iProps extends HTMLAttributes<HTMLDivElement> {
  titulo: string
  descricao: string
  theme: string
}

export const Card: React.FC<iProps> = ({ descricao, titulo, theme, ...rest }) => {
  return (
        <Container {...rest} theme={theme}>
            <h3>{titulo}</h3>
            <BodyCard>
                <span>{descricao}</span>
            </BodyCard>
            <div>
                <NavButton>
                    <span>+</span>Adicionar
                </NavButton>
            </div>
        </Container>
  )
}
