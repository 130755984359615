import styled from 'styled-components'

export const Container = styled.table`
width: 100%;
height: fit-content;
padding: 1rem;
margin-top: 1rem;
border-spacing: 0 .25rem;
font-family: 'Roboto','sans-serif';
thead{
    tr{
        font-weight: 700;
        font-size: 0.75rem;
        /* gray/600 */
        color: #4A5568;
    }

    th:first-child{
        padding-left: 4rem;
        text-align: start;
    }
    th:last-child{
        text-align: end;
        padding-right: 4rem;
    }
}

tbody{
    tr{
        font-weight: 500;
        font-size: 0.875rem;
        /* gray/700 */
        color: #2D3748;
        th:first-child{
            padding-left: 4rem;
            text-align: start;
        }

        th:last-child{
            font-weight: 400;
            font-size: 0.875rem;
            text-align: right;
            /* gray/700 */
            color: #2D3748;
            padding-right: 4rem;
        }
    }


    tr:nth-child(odd) {
        background-color: #EDF2F7;
    }

    tr,th{
        border-collapse: collapse;
        padding: .5rem;
    }
}
`
