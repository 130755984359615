import { useEffect, useState } from 'react'
import { Body } from '../../../Layouts/Body'
import { MetaButton } from '../../../../components/Buttons/MetaButton'
import { CreateColaboradorModal } from '../../../../components/ModalCreateColaborador'
import { UpdateColaboradorModal } from '../../../../components/ModalUpdateColaborador'
import { appApi } from '../../../../services/appApi'
import { NavBar } from '../Components/NavBar'
import { EmployeeTable } from './Components/EmployeeTable'
import { IEmployee, iPostColaborador, iPutColaborador, iPutDesligamento } from '../../../../interfaces'
import { useNavigate } from 'react-router-dom'
import { Flex, Image, Input, InputGroup, InputLeftElement, useToast } from '@chakra-ui/react'
import { parseJwt } from '../../../../services/token'
import { useAuth } from '../../../../contexts/AuthContext'
import { FaSearch } from 'react-icons/fa'
import { PopUpDesligarColaborador } from './Components/PopUpDesligarColaborador'

interface iNavLink {
  to: string
  linkTitle: string
  exact?: boolean
}

export const ColaboradoresDesenvolvimento: React.FC = () => {
  const { permitirFuncao } = useAuth()
  const toast = useToast()
  const nav = useNavigate()
  const [ModalDesligamentoIsOpen, setModalDesligamentoIsOpen] = useState(false)
  const [ModalCreateIsOpen, setModalCreateIsOpen] = useState(false)
  const [ModalUpdateIsOpen, setModalUpdateIsOpen] = useState(false)

  const [Colaboradores, setColaboradores] = useState<IEmployee[]>([])
  const [EditColaborador, setEditColaborador] = useState<IEmployee>()

  const [Search, setSearch] = useState('')

  function onOpenModalDesligamento(obj: IEmployee): void {
    setModalDesligamentoIsOpen(true)
    setEditColaborador(obj)
  }

  function onCloseModalDesligamento(): void {
    setModalDesligamentoIsOpen(false)
    setEditColaborador(undefined)
  }

  function ObterRotas(): iNavLink[] {
    const rotas: iNavLink[] = []
    if (parseJwt().adm === 'True' || permitirFuncao('Organograma', 'visao-geral')) {
      rotas.push({
        to: '/Organograma/Dashboard',
        linkTitle: 'Visão geral'
      })
    }

    if (parseJwt().adm === 'True' || permitirFuncao('Organograma', 'areas')) {
      rotas.push({
        to: '/Organograma/Departamentos',
        linkTitle: 'Áreas'
      })
    }

    if (parseJwt().adm === 'True' || permitirFuncao('Organograma', 'funcoes')) {
      rotas.push({
        to: '/Organograma/Cargos',
        linkTitle: 'Funções'
      })
    }

    if (parseJwt().adm === 'True' || permitirFuncao('Organograma', 'colaboradores')) {
      rotas.push({
        to: '/Organograma/Colaboradores',
        linkTitle: 'Colaboradores'
      })
    }
    return rotas
  }

  function onRequestModalUpdateClose(): void {
    setModalUpdateIsOpen(false)
    setEditColaborador(undefined)
  }

  function onCreateColaborador(form: iPostColaborador): void {
    appApi.post('Colaborador', form).then(res => {
      toast({
        title: 'Colaborador cadastrado com sucesso',
        status: 'success',
        position: 'top-right',
        isClosable: false
      })
      nav(`/Rh/VisualizarPerfil/${res.data as string}`)
    }).catch(({ response }) => {
      console.log(response)
      toast({
        title: response.data,
        status: 'error',
        position: 'top-right',
        isClosable: false
      })
    })
  }

  function onUpdateColaborador(form: iPutColaborador): void {
    appApi.put(`Colaborador/${EditColaborador?.id ?? ''}`, form).then(() => {
      toast({
        title: 'Colaborador atualizado com sucesso',
        status: 'success',
        position: 'top-right',
        isClosable: false
      })
      setModalUpdateIsOpen(false)
      getColaboradores()
    }).catch(({ response }) => {
      toast({
        title: response.data,
        status: 'error',
        position: 'top-right',
        isClosable: false
      })
    }
    )
  }

  function onOpenEditModal(obj: IEmployee): void {
    setModalUpdateIsOpen(true)
    setEditColaborador(obj)
  }

  function getColaboradores(): void {
    appApi.get('Colaborador').then(res => {
      if (res.status === 200) {
        const data: IEmployee[] = res.data
        setColaboradores(data)
      }
    }).catch(err => console.log(err))
  }

  function DesligarColaborador(form: iPutDesligamento): void {
    appApi.put(`Colaborador/${EditColaborador?.id ?? ''}/Desligar`, form)
      .then(() => {
        if (form.manterAcesso) {
          nav(`/Rh/VisualizarPerfil/${EditColaborador?.id ?? ''}?criar=true`)
        } else {
          toast({
            title: 'Colaborador desligado com sucesso',
            status: 'success',
            position: 'top-right',
            isClosable: false
          })
          onCloseModalDesligamento()
          getColaboradores()
        }
      })
      .catch(err => console.log(err))
  }

  useEffect(() => {
    getColaboradores()
  }, [])

  return (
    <Body>
      <Flex flexDir={'column'} marginTop={'2rem'}>
        {ModalDesligamentoIsOpen &&
          <PopUpDesligarColaborador
            onClose={onCloseModalDesligamento}
            onDesligar={DesligarColaborador}
          />
        }

        {
          (ModalCreateIsOpen) && (
            <CreateColaboradorModal
              isOpen={ModalCreateIsOpen}
              onCreateEmployee={onCreateColaborador}
              onRequestClose={() => setModalCreateIsOpen(false)}
            />
          )
        }

        {
          (ModalUpdateIsOpen) && (
            <UpdateColaboradorModal
              isOpen={ModalUpdateIsOpen}
              onUpdateEmployee={onUpdateColaborador}
              onRequestClose={onRequestModalUpdateClose}
              colaborador={EditColaborador}
            />
          )
        }

        <NavBar
          title="Organograma"
          navLinks={ObterRotas()}
        />

        {parseJwt().adm === 'True' &&
        <Flex marginTop={'1rem'} justifyContent={'end'}>
          <MetaButton onClick={() => setModalCreateIsOpen(true)} bg='v2' size='md'>Novo colaborador</MetaButton>
        </Flex>}

        <Flex marginTop={'1rem'}>
          <InputGroup width={'17rem'}>
            <InputLeftElement
              pointerEvents='none'
              children={<FaSearch color='var(--Gray4)' />}
            />
            <Input
              onChange={(e) => setSearch(e.target.value)}
              borderColor={'var(--Gray4)'}
              type='text'
              placeholder='Busca'
              bg={'white'}
            />
          </InputGroup>
        </Flex>
        {
          (Colaboradores) && (
            <section>
              <EmployeeTable
                onDelete={onOpenModalDesligamento}
                onEdit={onOpenEditModal}
                employeeList={Colaboradores.filter((e) => {
                  if (Search === '') {
                    return e
                  } else if (e.nome.toLowerCase().includes(Search.toLowerCase()) || e.sobrenome.toLowerCase().includes(Search.toLowerCase()) || e.email.toLowerCase().includes(Search.toLowerCase()) || e.cargo?.nome.toLowerCase().includes(Search.toLowerCase()) || e.departamento?.nome.toLowerCase().includes(Search.toLowerCase())) {
                    return e
                  } else {
                    return null
                  }
                })}
              />
            </section>
          )
        }

        {
          (Colaboradores.filter(e => e.nome.toLowerCase().includes(Search.toLowerCase()) || e.email.toLowerCase().includes(Search.toLowerCase()) || e.cargo?.nome.toLowerCase().includes(Search.toLowerCase()) || e.departamento?.nome.toLowerCase().includes(Search.toLowerCase())).length === 0) && (
            <Flex alignItems={'center'} color={'var(--Azul)'} justifyContent={'center'}>
              <Image className='sah_Img' height={'20rem'} src='https://sigah.blob.core.windows.net/onboard/bbb6990f-d313-488a-af99-5383c9bd1c82.png' />
              <h3>Nenhum colaborador encontrado</h3>
            </Flex>
          )
        }
      </Flex>
    </Body>
  )
}
