import { Flex } from '@chakra-ui/react'
import { WhiteBox } from '../../../../components/WhiteBox'
import { useAuth } from '../../../../contexts/AuthContext'
import { Body } from '../../../Layouts/Body'
import { TabelaPermissoes } from '../Components/TabelaPermissoes'

export const GerenciarPermissoes: React.FC = () => {
  const { Permissoes } = useAuth()

  return (
        <Body>
            <h2>Permissões dos usuários</h2>
            <WhiteBox margin='1rem'>
                <Flex flexDir={'column'} gap={'2rem'}>
                    <Flex flexDir={'column'}>
                        <h3>Jornada</h3>
                        <TabelaPermissoes permissao={Permissoes.permissoes.filter(e => e.modulo === 'Jornada')} />
                    </Flex>

                    <Flex flexDir={'column'}>
                        <h3>Desempenho</h3>
                        <TabelaPermissoes permissao={Permissoes.permissoes.filter(e => e.modulo === 'Desempenho')} />
                    </Flex>

                    <Flex flexDir={'column'}>
                        <h3>Engajamento</h3>
                        <TabelaPermissoes permissao={Permissoes.permissoes.filter(e => e.modulo === 'Engajamento')} />
                    </Flex>

                    <Flex flexDir={'column'}>
                        <h3>Organograma</h3>
                        <TabelaPermissoes permissao={Permissoes.permissoes.filter(e => e.modulo === 'Organograma')} />
                    </Flex>
                </Flex>
            </WhiteBox>
        </Body>
  )
}
