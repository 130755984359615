import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Flex, Input, InputGroup, InputLeftElement, Radio, RadioGroup, Stack, Text, useToast } from '@chakra-ui/react'
import { useEffect, useRef, useState } from 'react'
import { FaAngleDoubleLeft, FaAngleDoubleRight, FaSearch, FaUsers } from 'react-icons/fa'
import { MdChecklist } from 'react-icons/md'
import { Button } from '../../../../../components/Button'
import { FindUser } from '../../../../../components/FindBox'
import { iCardEtapa, iGrupoTrilha, iNomeId, iPostProcessoOnboard } from '../../../../../interfaces'
import { appApi } from '../../../../../services/appApi'
import { converterData, PropInvalida } from '../../../../../Utils/Helper'
import { CardOnboard } from '../../../ConfigurarTrilha/Components/CardOnboard'
import { Descricao } from '../../../Trilhas/Components/Card/styles'
import { BoxCards, Card, ContainerBody, DivPadding, ButtonMenu } from './styles'

interface iProps {
  colaboradorId: string
  onCreate: (form: iPostProcessoOnboard) => void
  onCancel: () => void
  rh: iNomeId[]
  dataDesligamento?: string
  temOnboard: boolean
}

export const ContainerTrilha: React.FC<iProps> = ({ colaboradorId, rh, dataDesligamento, temOnboard, onCreate, onCancel }) => {
  const toast = useToast()
  const [Scroll, setScroll] = useState(1042)
  const [Trilhas, setTrilhas] = useState<iGrupoTrilha[]>([])
  const [CategoriaSelecionada, setCategoriaSelecionada] = useState(0)
  const [TrilhaId, setTrilhaId] = useState<string>()
  const [InputPesquisa, setInputPesquisa] = useState('')
  const [DataInicio, setDataInicio] = useState<string>()
  const [ResponsavelId, setResponsavelId] = useState<string>()
  const [IsLoading, setIsLoading] = useState(false)
  const [Etapas, setEtapas] = useState<iCardEtapa[]>([])
  const [ResponsavelIsInValid, setResponsavelIsInValid] = useState(false)

  const [Ingresso, setIngresso] = useState('0')

  const $divScroll = useRef<HTMLDivElement>(null)

  function getCards(): void {
    appApi.get('GrupoTrilha')
      .then(response => {
        setTrilhas(response.data)
      }).catch(err => console.error(err))
  }

  function onCreateInternal(form: iPostProcessoOnboard): void {
    setResponsavelIsInValid(false)
    if (form.responsavelId === '') {
      setResponsavelIsInValid(true)
      toast({
        title: 'Há campos sem preenchimento',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return
    }
    setIsLoading(true)
    onCreate(form)
  }

  function ScrollDireita(): void {
    if ($divScroll) {
      setScroll(1042 + Scroll)
      const TamanhoDiv: number = $divScroll.current?.clientWidth ? $divScroll.current.clientWidth : 0
      if (Scroll > TamanhoDiv) {
        setScroll(TamanhoDiv)
      }
      $divScroll.current?.scroll(Scroll, 0)
    }
  }

  function ScrollEsquerda(): void {
    if ($divScroll) {
      setScroll(Scroll - 1042)
      if (Scroll < 0 || Scroll === 0) {
        setScroll(0)
      }
      $divScroll.current?.scroll(Scroll, 0)
    }
  }

  function SelecionarResponsavel(id: string): void {
    setResponsavelId(id)
  }

  function ObterEtapas(): void {
    appApi.get(`Etapa?idTrilha=${TrilhaId ?? ''}`)
      .then(response => { setEtapas(response.data) })
      .catch(e => console.log(e))
  }

  useEffect(() => {
    getCards()
  }, [])

  useEffect(() => {
    if (TrilhaId) {
      ObterEtapas()
    }
  }, [TrilhaId])

  useEffect(() => {
    if (CategoriaSelecionada === 3) {
      setIngresso('1')
    }
  }, [CategoriaSelecionada])

  useEffect(() => {
    if (dataDesligamento) {
      setCategoriaSelecionada(3)
      setDataInicio(converterData(dataDesligamento).toISOString().split('T')[0])
    }
  }, [dataDesligamento])

  return (
    <>
      <Flex color='var(--a3)'>
        <h3>Adicionar trilha de:</h3>
      </Flex>

      <Flex marginTop={'1rem'} justifyContent={'center'}>
        {!dataDesligamento && !temOnboard &&
        <ButtonMenu
          selected={CategoriaSelecionada === 0}
          onClick={() => setCategoriaSelecionada(0)}
        >Onboarding</ButtonMenu>}

        {!dataDesligamento &&
          <ButtonMenu
          selected={CategoriaSelecionada === 1}
          onClick={() => setCategoriaSelecionada(1)}
          margin='0rem 1rem'
          >Desenvolvimento</ButtonMenu>
        }

        {dataDesligamento &&
          <ButtonMenu
            selected={CategoriaSelecionada === 3}
            onClick={() => setCategoriaSelecionada(3)}
          >Offboarding</ButtonMenu>
        }
      </Flex>

      <BoxCards>
        <Flex justifyContent={'space-between'}>
          {
            TrilhaId
              ? <h4>Trilha selecionada</h4>
              : <h4>Selecione uma trilha</h4>
          }

          <InputGroup width={'15rem'}>
            <InputLeftElement
              pointerEvents='none'
              children={<FaSearch color='var(--Gray4)' />}
            />
            <Input
              borderColor={'var(--Gray4)'}
              type='text'
              placeholder='Pesquisa'
              value={InputPesquisa}
              onChange={(e) => setInputPesquisa(e.target.value)}
            />
          </InputGroup>
        </Flex>

        <Flex>
          {
            (Trilhas.filter((val: iGrupoTrilha) => {
              let categoria = val.categoria
              if (categoria === 2) {
                categoria = 1
              }
              if ((categoria !== CategoriaSelecionada) || val.countEtapas === 0) {
                return null
              }
              if (InputPesquisa === '') {
                return val
              } else if (val.nome.toLowerCase().includes(InputPesquisa.toLowerCase())) {
                return val
              }
              return ''
            }).length > 3) && (
              <DivPadding onClick={ScrollEsquerda} onMouseOver={ScrollEsquerda}>
                <FaAngleDoubleLeft className='Esquerda' size={28} />
              </DivPadding>
            )
          }

          <ContainerBody ref={$divScroll}>
            {
              Trilhas.filter((val: iGrupoTrilha) => {
                let categoria = val.categoria
                if (categoria === 2) {
                  categoria = 1
                }
                if (categoria !== CategoriaSelecionada || (categoria !== 0 && categoria !== 3 && val.countEtapas === 0) || ((categoria === 0 || categoria === 3) && val.countEtapas === 1)) {
                  return null
                }
                if (InputPesquisa === '') {
                  return val
                } else if (val.nome.toLowerCase().includes(InputPesquisa.toLowerCase())) {
                  return val
                }
                return ''
              }).map((e: iGrupoTrilha, i) => {
                return (
                  <Card
                    key={i}
                    theme={e.cor}
                    selecionado={e.id === TrilhaId}
                    onClick={() => setTrilhaId(e.id)}
                  >
                    <Text fontSize={'1rem'} fontWeight={'bold'}>{e.nome}</Text>
                    <Flex flexDir={'column'} gap='.5rem'>
                      <Descricao><MdChecklist fontSize={24} /><strong>{e.countEtapas}</strong> Etapas</Descricao>
                      <Descricao><FaUsers fontSize={24} /><strong>{e.countProcessos}</strong> Porcessos ativos</Descricao>
                    </Flex>
                  </Card>
                )
              })
            }
          </ContainerBody>

          {
            (Trilhas.filter((val: iGrupoTrilha) => {
              let categoria = val.categoria
              if (categoria === 2) {
                categoria = 1
              }
              if ((categoria !== CategoriaSelecionada) || val.countEtapas === 0) {
                return null
              }
              if (InputPesquisa === '') {
                return val
              } else if (val.nome.toLowerCase().includes(InputPesquisa.toLowerCase())) {
                return val
              }
              return ''
            }).length > 3) && (
              <DivPadding onClick={ScrollDireita} onMouseOver={ScrollDireita}>
                <FaAngleDoubleRight className='Direita' size={28} />
              </DivPadding>
            )
          }

        </Flex>
      </BoxCards>

      <Flex flexDir={'column'} gap='1rem'>
        <Accordion allowToggle>
          <AccordionItem isDisabled={!TrilhaId}>
            <h2>
              <AccordionButton border={0} _focus={{ border: 0 }} _hover={{ bg: 'none' }} bg={'none'} color='var(--Azul)'>
                <Box as="span" flex='1' textAlign='left'>
                  Pré visualizar trilha
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <Flex flexDir={'column'}>
                {
                  Etapas.map((e: iCardEtapa, i): any => {
                    return (
                      <CardOnboard
                        key={i}
                        etapa={e}
                        isLast={i === Etapas.length - 1}
                        EtapaAnterior={!PropInvalida(e.etapaPai) ? Etapas[i - 1].nome : undefined}
                      />
                    )
                  })
                }
              </Flex>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
        <RadioGroup value={Ingresso} onChange={(e) => setIngresso(e)}>
          <Stack direction={'column'}>
            <Radio isDisabled={CategoriaSelecionada === 3} value={'0'}>Iniciar onboarding de acordo com a data de ingresso</Radio>
            <Radio value={'1'}>{CategoriaSelecionada === 3 ? 'Atualizar data de desligamento' : 'Iniciar com uma data personalizada'}</Radio>
          </Stack>
        </RadioGroup>
        {
          (Ingresso === '1') && (
            <Input
              width={'15rem'}
              margin={'0 1rem'}
              type={'date'}
              onChange={(e) => setDataInicio(e.target.value)}
              borderColor='var(--Gray4)'
              value={DataInicio}
            />
          )
        }
      </Flex>

      <Flex flexDir={'column'} margin='1rem 0'>
        <Text fontSize={'1.125rem'} fontWeight={'500'} marginBottom={'.5rem'}>Responsável do RH por este onboarding:</Text>
        <FindUser
          isInvalid={ResponsavelIsInValid}
          onChoice={SelecionarResponsavel}
          lista={rh}
        />
      </Flex>

      <Flex justifyContent={'end'} gap='1rem'>
        <Button VarColor='c5' size='lg' onClick={onCancel}>Cancelar</Button>
        <Button isLoading={IsLoading} VarColor='v2' size='lg' onClick={() => onCreateInternal({
          colaboradorId: colaboradorId,
          trilhaId: TrilhaId ?? '',
          data: DataInicio,
          responsavelId: ResponsavelId ?? ''
        })}>Adicionar onboarding</Button>
      </Flex>
    </>
  )
}
