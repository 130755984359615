import styled from 'styled-components'

export const Container = styled.div`
display: flex;
flex-direction: column;
max-width: 34.375rem;
`

export const FlexIcon = styled.div`
display: flex;
margin-bottom: 1rem;
strong{
    color:var(--terc3);
    font-size: 1rem;
}

svg,h3{
    color: var(--a3);
}

svg{
    margin-right: .5rem;
    min-width: 2.5rem;
}

small{
font-family: 'Roboto','sans-serif';
font-weight: 700;
font-size: 0.875rem;
color: var(--a5);
}
`
