import { MdMenu } from 'react-icons/md'
import { Body } from '../../Layouts/Body'
import { WhiteBox } from '../../../components/WhiteBox'
import { QuestionBox, ResumoBox } from './styles'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { appApi } from '../../../services/appApi'
import { Flex, useToast } from '@chakra-ui/react'
import { ModalDelete } from '../../../components/ModalDelete'
import { Button } from '../../../components/Button'
import { FaEye } from 'react-icons/fa'

export interface iModel {
  id: string
  totalCompetencias: number
  totalQuestoes: number
  nomeEscala: string
  status: number
  questoes: Questao[]
}

export interface Questao {
  id: string
  avatar: string
  pergunta: string
  nomeCompetencia: string
}

export const VisualizarFormulario: React.FC = () => {
  const toast = useToast()
  const path = useLocation().pathname
  const { pesquisaId } = useParams()

  const [Formulario, setFormulario] = useState<iModel>({
    id: '',
    nomeEscala: '',
    questoes: [],
    status: 0,
    totalCompetencias: 0,
    totalQuestoes: 0
  })
  const nav = useNavigate()

  const [AprovarFormularioIsOpen, setAprovarFormularioIsOpen] = useState(false)

  function AprovarFormulario(): void {
    appApi.put(`Formulario/${Formulario.id}/Aprovar`).then(() => {
      if (path.includes('Trilha') || path.includes('Processo')) {
        nav(`${path.replace('VisualizarFormulario', 'EnviarPesquisa')}`)
      } else {
        nav(`${path.replace('VisualizarFormulario', 'Participantes')}`)
      }
    }
    ).catch(() => {
      toast({
        title: 'Não foi inserida nenhuma questão no formulário',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 3000
      })
    })
  }

  function getFormulario(): void {
    appApi.get(`FormularioPesquisa/${pesquisaId as string}/Visualizar`).then(resp => {
      setFormulario(resp.data)
    }).catch(err => console.log(err))
  }

  useEffect(() => {
    getFormulario()
  }, [])

  return (
    <Body>
      <ModalDelete
        isOpen={AprovarFormularioIsOpen}
        message='Após aprovar o formulário você não poderá mais editá-lo. Deseja continuar?'
        onRequestClose={() => setAprovarFormularioIsOpen(false)}
        onConfirm={AprovarFormulario}
      />

      <Flex justifyContent={'space-between'} margin='2rem 0rem'>
        <Flex flexDir={'column'} justifyContent='end'>
          <h1>Verifique e aprove o formulário</h1>
        </Flex>
        <ResumoBox>
          <h3>Resumo do formulário</h3>
          <span>Escala selecionada:<strong> {Formulario.nomeEscala}</strong></span>
          <span>Competências selecionadas:<strong> {Formulario.totalCompetencias}</strong></span>
          <span>Total de questões:<strong> {Formulario.totalQuestoes}</strong></span>
        </ResumoBox>
      </Flex>

      <WhiteBox>
        <h2 style={{ color: '#2C4D8A' }}>Essas são todas as questões selecionadas</h2>
        {/* <SubtitleMd>Você pode alterar a ordem das questões arrastando-as pelo canto esquerdo</SubtitleMd> */}
        <section style={{ marginTop: '1rem' }}>
          {
            Formulario.questoes.map((e: Questao, index) => {
              return (
                <div key={index}>
                  <QuestionBox>
                    <Flex>
                      <MdMenu size={30} />
                    </Flex>
                    <Flex flexDir={'column'} minWidth='9rem' maxWidth='9rem' alignItems={'center'} textAlign='center' margin='0rem 1rem'>
                      <img src={e.avatar} />
                      <small>{e.nomeCompetencia}</small>
                    </Flex>
                    <span>{e.pergunta}</span>
                  </QuestionBox>
                </div>
              )
            })
          }

        </section>
        <Flex>
          <Button
            leftIcon={<FaEye/>}
            onClick={() => nav({ pathname: 'Previsualizar' }, { state: { pesquisaId: pesquisaId } })}
            VarColor='vg3' size={'lg'}
          >Pré-visualizar</Button>
        </Flex>
      </WhiteBox>
      <Flex marginTop={'1rem'} justifyContent={'end'} gap={'1rem'}>
        {
          (Formulario.status === 2) && (
            <Button VarColor='c6' size='lg' onClick={() => nav(-1)}>Voltar</Button>
          )
        }

        {
          (path.includes('Trilha')) && (
            <Button
              size='lg'
              VarColor='Azul'
              onClick={() => nav(`${path.replace('VisualizarFormulario', 'Formularios')}`)}
            >Alterar formulário selecionado</Button>
          )
        }

        {
          (Formulario.status !== 2) && (
            <>
              <Button
                VarColor='c6'
                size='lg'
                onClick={() => nav(`${path.replace('VisualizarFormulario', 'Formulario')}`)}
              >Editar formulário</Button>
              <Button
                size='lg'
                VarColor='v2'
                onClick={() => setAprovarFormularioIsOpen(true)}
                isDisabled={Formulario.questoes.length === 0}
              >Aprovar formulário</Button>
            </>
          )
        }

        {
          (Formulario.status === 2) && (

            (!path.includes('Trilha') && !path.includes('Processo')) && (
              <Button
                size='lg'
                VarColor='v2'
                onClick={() => nav(`${path.replace('VisualizarFormulario', 'Participantes')}`)}
              >Visualizar participantes</Button>
            )

          )
        }

        {
          (Formulario.status === 2 && (path.includes('Trilha') || path.includes('Processo'))) && (

            <Button
              size='lg'
              VarColor='v2'
              onClick={() => nav(`${path.replace('VisualizarFormulario', 'EnviarPesquisa')}`)}
            >Configurar envio da pesquisa</Button>
          )
        }
      </Flex>
    </Body>
  )
}
