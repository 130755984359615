import { Flex, useToast } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { MdOutlinePictureAsPdf, MdOutlineLibraryAddCheck, MdOutlineVideoLibrary, MdOutlineCollections } from 'react-icons/md'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Body } from '../../../Layouts/Body'
import { GreyButton } from '../../../../components/Buttons/GreyButton'
import { GreenButton } from '../../../../components/Buttons/GreenButton'
import { TimeLineOnboard } from '../../../../components/TimeLineOnboard'
import { WhiteBox } from '../../../../components/WhiteBox'
import { iItemIntegracao, iEtapa } from '../../../../interfaces'
import { appApi } from '../../../../services/appApi'
import { BodyCardRoxo, BoxIntegracao, CardRoxo, Title, Main } from './styles'
import { CreateEtapaBox } from '../../../../components/CreateEtapaBox'
import { CardVideo } from './Components/CardVideo'
import { CardImagem } from './Components/CardImagem'
import { CardPdf } from './Components/CardPdf'
import { CardChecklist } from './Components/Checklist'
import { Button } from '../../../../components/Button'
import { FaEye } from 'react-icons/fa'
import { ValidarEtapa } from '../../../../Utils/Helper'
type LocationProps = {
  trilhaId: string
  imersaoId: string
  etapaId?: string
  processoId?: string
}

export const ModifyIntegracao: React.FC = () => {
  const toast = useToast()
  const { pathname } = useLocation()
  const navigate = useNavigate()

  const { imersaoId, trilhaId, etapaId, processoId } = useParams<LocationProps>()
  const [integracoes, setIntegracoes] = useState<iItemIntegracao[]>([] as iItemIntegracao[])

  const [EditEtapa, setEditEtapa] = useState<iEtapa>()

  const [Selecionar, setSelecionar] = useState(false)

  function AdicionarEtapa(form: iEtapa): void {
    if (!ValidarEtapa(form)) {
      toast({
        title: 'Preencha os campos de etapa',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return
    }
    const url = trilhaId ? `IntegracaoEtapa/${trilhaId}` : processoId ? `IntegracaoEtapaColaborador/${processoId}` : ''

    appApi.post(`${url}?integracaoId=${imersaoId ?? ''}`, form).then(() => {
      toast({
        title: 'Etapa cadastrada com sucesso',
        status: 'success',
        isClosable: false
      })
      navigate(-1)
    }).catch(err => {
      console.error(err)
      toast({
        title: 'Erro ao cadastrar a etapa',
        status: 'error',
        isClosable: false
      })
    })
  }

  function GetIntegracoesById(): void {
    appApi.get(`Integracao/${imersaoId ?? ''}`)
      .then(response => {
        setIntegracoes(response.data.itensIntegracao)
      }).catch(err => console.error(err))
  }

  function CreateItemIntegracao(Tipo: number): void {
    appApi.post(`Integracao/${imersaoId ?? ''}/Item`, {
      titulo: '',
      descricao: '',
      tipo: Tipo
    })
      .then(() => { GetIntegracoesById() }).catch(err => console.error(err))
  }

  function DeltarItemIntegracao(Id: string): void {
    const filterRes = [...integracoes.filter(e => e.id !== Id)]
    appApi.delete(`Integracao/${imersaoId ?? ''}/Item/${Id}`)
      .then(() => { setIntegracoes([]); setIntegracoes(filterRes) }).catch(err => console.error(err))
  }

  function AtualizarItemIntegracao(item: iItemIntegracao): void {
    const idItem: string = item.id
    const form: Omit<iItemIntegracao, 'id'> = {
      titulo: item.titulo,
      descricao: item.descricao,
      tipo: item.tipo,
      checklist: item.checklist
    }
    appApi.put(`Integracao/${imersaoId ?? ''}/Item/${idItem}`, form).catch(e => console.log(e))
  }

  function AtualizarEtapa(form: iEtapa): void {
    if (!ValidarEtapa(form)) {
      toast({
        title: 'Preencha os campos de etapa',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return
    }
    const url = trilhaId
      ? `IntegracaoEtapa/${etapaId as string}`
      : processoId
        ? `IntegracaoEtapaColaborador/${etapaId ?? ''}/ProcessoOnboard/${processoId}`
        : ''
    appApi.put(`${url}/`, form).then(() => {
      toast({
        title: 'Etapa atualizada com sucesso',
        status: 'success',
        isClosable: false
      })
      if (trilhaId) {
        navigate(`/Jornada/Configuracao/Trilha/${trilhaId}`)
      } else {
        navigate(`/Rh/VisualizarPerfil/${processoId as string}`)
      }
    }).catch(() => {
      toast({
        title: 'Não foi possível atualizar a etapa',
        status: 'error',
        isClosable: false
      })
    })
  }

  function ObterEtapaEdit(): void {
    const url = trilhaId ? `Etapa/${etapaId ?? ''}` : processoId ? `EtapaColaborador/${etapaId ?? ''}/ProcessoOnboard/${processoId ?? ''}` : ''
    appApi.get(url)
      .then(res => {
        const data: iEtapa = res.data
        setEditEtapa(data)
      }).catch(err => console.log(err))
  }

  useEffect(() => {
    if (imersaoId !== undefined) {
      GetIntegracoesById()
    }
    if (etapaId !== undefined) {
      ObterEtapaEdit()
    }
  }, [imersaoId])

  return (
      <Body>
        <Main>
          <TimeLineOnboard hide={processoId !== undefined} />
          {
            !Selecionar
              ? <WhiteBox>
                <Title>
                  <h1>Lista de integração do colaborador em onboarding</h1>
                  <span>Aqui você pode adicionar vídeos, imagens, documentos ou até um check list para acompanhar o que deve ou foi realizado na integração do colaborador</span>
                </Title>
                <Flex display={'grid'} gap={'1rem 0rem'}>
                  {
                    integracoes.map((e, i) => {
                      if (e.tipo === 0) {
                        return (
                          <CardChecklist
                            key={e.id}
                            id={imersaoId ?? ''}
                            position={i + 1}
                            ItemIntegracao={e}
                            onDelete={DeltarItemIntegracao}
                            onUpdateInput={AtualizarItemIntegracao}
                          />
                        )
                      } else if (e.tipo === 1) {
                        return (
                          <CardVideo
                            key={e.id}
                            id={imersaoId ?? ''}
                            position={i + 1}
                            ItemIntegracao={e}
                            onDelete={DeltarItemIntegracao}
                            onUpdateInput={AtualizarItemIntegracao}
                          />
                        )
                      } else if (e.tipo === 2) {
                        return (
                          <CardImagem
                            key={e.id}
                            position={i + 1}
                            id={imersaoId ?? ''}
                            ItemIntegracao={e}
                            onDelete={DeltarItemIntegracao}
                            onUpdateInput={AtualizarItemIntegracao}
                          />
                        )
                      } else if (e.tipo === 3) {
                        return (
                          <CardPdf
                            key={e.id}
                            position={i + 1}
                            id={imersaoId ?? ''}
                            ItemIntegracao={e}
                            onDelete={DeltarItemIntegracao}
                            onUpdateInput={AtualizarItemIntegracao}
                          />
                        )
                      }
                      return null
                    })
                  }
                </Flex>
                <h2>Adicione mais arquivos</h2>
                <BoxIntegracao>
                  <BodyCardRoxo>
                    <CardRoxo onClick={() => { CreateItemIntegracao(0) }}>
                      <MdOutlineLibraryAddCheck size={42} />
                      <span>Adicionar um check list</span>
                    </CardRoxo>

                    <CardRoxo onClick={() => { CreateItemIntegracao(1) }}>
                      <MdOutlineVideoLibrary size={42} />
                      <span>Adicionar um vídeo</span>
                    </CardRoxo>

                    <CardRoxo onClick={() => { CreateItemIntegracao(2) }}>
                      <MdOutlineCollections size={42} />
                      <span>Adicionar uma imagem e texto</span>
                    </CardRoxo>

                    <CardRoxo onClick={() => { CreateItemIntegracao(3) }}>
                      <MdOutlinePictureAsPdf size={42} />
                      <span>Adicionar um arquivo PDF</span>
                    </CardRoxo>
                  </BodyCardRoxo>
                </BoxIntegracao>
                <Flex justifyContent={'end'} marginTop={'1rem'} gap={'1rem'}>
                  <GreyButton
                    Size='lg'
                    isNav={true}
                  >Voltar</GreyButton>
                  <Button
                    VarColor='Rosa'
                    size={'lg'}
                    leftIcon={<FaEye />}
                    fontWeight={'500'}
                    onClick={() => navigate(pathname + '/Previsualizar')}
                    marginRight='1rem'
                  >
                    Pré-visualização completa da integração
                  </Button>
                  <GreenButton Size='lg' OnClick={() => setSelecionar(true)} isNav={false}>Salvar integração</GreenButton>
                </Flex>
              </WhiteBox>
              : <>
                {
                  EditEtapa === undefined
                    ? <CreateEtapaBox
                      keyWord='integração'
                      onGreenAction={AdicionarEtapa}
                      onGreyAction={() => setSelecionar(false)}
                      EditEtapa={EditEtapa}
                    />
                    : <CreateEtapaBox
                      keyWord='integração'
                      onGreenAction={AtualizarEtapa}
                      onGreyAction={() => setSelecionar(false)}
                      EditEtapa={EditEtapa}
                      ignoreId={etapaId}
                    />
                }
              </>
          }

        </Main>
      </Body>
  )
}
