import { useEffect, useState } from 'react'
import { Container } from './styles'
import { iItemIntegracao } from '../../../../../../../interfaces'
import { appApi } from '../../../../../../../services/appApi'
import { FaCheck } from 'react-icons/fa'
import { Flex } from '../../../../../../../styles/styledGlobal'
interface ITipoCardConfigImersao {
  ImersaoId: string
}

export const CardImersao: React.FC<ITipoCardConfigImersao> = ({ ImersaoId }) => {
  const [Itens, setItem] = useState<iItemIntegracao[]>([])

  useEffect(() => {
    ObterImersao()
  }, [])

  function ObterImersao(): void {
    appApi.get(`Imersao/${ImersaoId}`)
      .then(response => { setItem(response.data.itensIntegracao) }).catch(err => console.error(err))
  }

  function ObterNomeTipo(Tipo: number): string {
    if (Tipo === 1) {
      return 'Vídeo'
    } else if (Tipo === 2) {
      return 'Texto'
    } else {
      return 'PDF'
    }
  }

  return (
    <Container>
      <h3>Lista de itens adicionados</h3>
      {
        Itens.map((e: iItemIntegracao, i): any => {
          return (
            <Flex key={i}>
              <FaCheck color='var(--v2)' style={{ marginRight: '.5rem' }}/>
              <span>{ObterNomeTipo(e.tipo)}:  {e.titulo}</span>
            </Flex>
          )
        })
      }
    </Container>
  )
}
