import { Button, Flex, Menu, MenuButton, MenuList } from '@chakra-ui/react'
import { FaChevronDown, FaCloudDownloadAlt, FaEye, FaFileCsv, FaMapMarkerAlt, FaTachometerAlt, FaTrash, FaUsers } from 'react-icons/fa'
import { MdSend } from 'react-icons/md'
import { useLocation, useNavigate } from 'react-router-dom'
import { StyledMenuItem } from '../../../../../components/NavBar/Components/StyledMenuItem'
import { iCardPesquisa } from '../../../../../interfaces'
import { urlApi } from '../../../../../services/appApi'
import { parseJwt } from '../../../../../services/token'
import { PropInvalida } from '../../../../../Utils/Helper'
import { ColumnText, PreViewParticipante, RadiusAmarelo, GridContainer } from './styles'

interface CardProps {
  pesquisa: iCardPesquisa
  onDelete: () => void
}

export const CardPesquisa: React.FC<CardProps> = ({ pesquisa, onDelete }) => {
  const { pathname } = useLocation()

  const navigate = useNavigate()
  const redirectPage = (url: string): any => { window.location.href = url }

  return (
        <PreViewParticipante>
            <RadiusAmarelo></RadiusAmarelo>
            <GridContainer>
                <ColumnText>
                    <h4>{pesquisa.nomePesquisa}</h4>
                </ColumnText>

                {
                    (!PropInvalida(pesquisa.baseCiclo) && pesquisa.baseCiclo?.includes('Data de ingresso do avaliado')) && (
                        <ColumnText>
                            <h4>Base ciclo</h4>
                            <span>{pesquisa.baseCiclo}</span>
                        </ColumnText>
                    )
                }

                {
                     (PropInvalida(pesquisa.baseCiclo)) && (
                        <ColumnText>
                            <h4>Início</h4>
                            <span>{pesquisa.dataInicio !== null ? pesquisa.dataInicio : 'Não definido'}</span>
                        </ColumnText>
                     )
                }

                {
                    (PropInvalida(pesquisa.ciclo) && PropInvalida(pesquisa.baseCiclo)) && (
                        <ColumnText>
                            <h4>Término</h4>
                            <span>{pesquisa.dataFim !== null ? pesquisa.dataFim : 'Não definido'}</span>
                        </ColumnText>
                    )
                }

                {
                    (!PropInvalida(pesquisa.ciclo)) && (
                        <ColumnText>
                            <h4>Ciclo</h4>
                            <span>{pesquisa.ciclo}</span>
                        </ColumnText>
                    )
                }

                <Flex alignItems={'center'}>
                    <FaMapMarkerAlt size={35} style={{ marginRight: '.5rem', color: 'var(--a5)' }} />
                    <ColumnText>
                        <h3>{pesquisa.totalAvaliadores ?? 0}</h3>
                        <span>Avaliadores</span>
                    </ColumnText>
                </Flex>
            </GridContainer>
            <Flex alignItems={'center'} marginRight={'1rem'}>
                <Menu>
                    <MenuButton
                        px={4}
                        height={'2.5rem'}
                        borderRadius='lg'
                        transition='all 0.2s'
                        _hover={{ bg: 'var(--vg4)' }}
                        bg={'var(--vg4)'}
                        fontWeight={'700'}
                        fontSize={'1rem'}
                        as={Button}
                        rightIcon={<FaChevronDown />}
                        margin={'0rem 0rem 0rem 1rem'}
                        _focus={{ bg: 'var(--vg4)' }}
                        _active={{ bg: 'var(--vg4)' }}
                    >Opções
                    </MenuButton>
                    <MenuList bg={'white'} borderRadius={'.5rem'} boxShadow={'0px 2px 8px rgba(0, 0, 0, 0.25)'}>
                        {
                            (pesquisa.dataInicio !== null || (pesquisa.ciclo && pesquisa.enviada)) && (
                                <StyledMenuItem
                                    onClick={() => navigate(`${pathname.replace('Gestao', 'ResultadoPesquisa')}/${pesquisa.id}`)}
                                >
                                    <FaTachometerAlt style={{ marginRight: '1rem' }} />
                                    Visualizar resultados

                                </StyledMenuItem>
                            )
                        }
                        {
                            (pesquisa.dataInicio !== null && parseJwt().adm === 'True') && (
                                <StyledMenuItem

                                    onClick={() =>
                                      redirectPage(`${urlApi}/AvaliacaoPesquisa/${pesquisa.id}/statusCsv/?token=${localStorage.getItem('token') as string}`)
                                    }
                                >
                                    <FaCloudDownloadAlt style={{ marginRight: '1rem' }} />
                                    Baixar status preenchimento

                                </StyledMenuItem>
                            )
                        }

                        {
                            (parseJwt().adm === 'True') && (
                                <StyledMenuItem
                                    onClick={() => redirectPage(`${urlApi}/AvaliacaoPesquisa/${pesquisa.id}/basePesquisaCsv/?token=${localStorage.getItem('token') as string}`)}
                                >
                                    <FaFileCsv style={{ marginRight: '1rem' }} />
                                    Baixar CSV resultado
                                </StyledMenuItem>
                            )
                        }

                        {
                            (pesquisa.processoOnboard !== true && pesquisa.formularioAprovado && !pesquisa.encerrada && !pesquisa.configurada && parseJwt().adm === 'True') && (
                                <StyledMenuItem
                                    onClick={() => navigate(`${pathname.replace('Gestao', `Participantes/${pesquisa.id}`)}`)}
                                >
                                    <FaUsers style={{ marginRight: '1rem' }} />
                                    Gestão de participantes
                                </StyledMenuItem>
                            )
                        }

                        {
                            (pesquisa.processoOnboard !== true && pesquisa.formularioAprovado && !pesquisa.encerrada && pesquisa.configurada && parseJwt().adm === 'True') && (
                                <StyledMenuItem
                                    onClick={() => navigate(`${pathname.replace('Gestao', `Participantes/${pesquisa.id}/Editar`)}`)}
                                >
                                    <FaUsers style={{ marginRight: '1rem' }} />
                                    Gestão de participantes
                                </StyledMenuItem>
                            )
                        }

                        {
                            (pesquisa.processoOnboard === true && pesquisa.formularioAprovado && parseJwt().adm === 'True') && (
                                <StyledMenuItem
                                    onClick={() => navigate(`${pathname.replace('Gestao', `Participantes/${pesquisa.id}/Processo`)}`)}
                                >
                                    <FaUsers style={{ marginRight: '1rem' }} />
                                    Gestão de participantes
                                </StyledMenuItem>
                            )
                        }

                        {
                            (!pesquisa.encerrada && pesquisa.enviada && !pesquisa.processoOnboard && parseJwt().adm === 'True') && (
                                <StyledMenuItem
                                    onClick={() => navigate(`${pathname.replace('Gestao', 'EnviarPesquisa')}/${pesquisa.id}/Editar`)}
                                >
                                    <MdSend style={{ marginRight: '1rem' }} />
                                    Configurações de envio
                                </StyledMenuItem>
                            )
                        }

                        {
                            (pesquisa.formularioAprovado && pesquisa.processoOnboard !== true && !pesquisa.encerrada && !pesquisa.configurada && !pesquisa.enviada && parseJwt().adm === 'True') && (
                                <StyledMenuItem
                                    onClick={() => navigate(`${pathname.replace('Gestao', 'EnviarPesquisa')}/${pesquisa.id}`)}
                                >
                                    <MdSend style={{ marginRight: '1rem' }} />
                                    Configurações de envio
                                </StyledMenuItem>
                            )
                        }

                        {
                            (!pesquisa.encerrada && pesquisa.formularioAprovado && pesquisa.processoOnboard === true && parseJwt().adm === 'True') && (
                                <StyledMenuItem
                                    onClick={() => navigate(`${pathname.replace('Gestao', 'EnviarPesquisa')}/${pesquisa.id}/Processo/Editar`)}
                                >
                                    <MdSend style={{ marginRight: '1rem' }} />
                                    Configurações de envio
                                </StyledMenuItem>
                            )
                        }

                        {
                            (pesquisa.formularioId !== null && parseJwt().adm === 'True') && (
                                <StyledMenuItem
                                    onClick={() => navigate(`${pathname.replace('Gestao', `VisualizarFormulario/${pesquisa.id}`)}`)}
                                >
                                    <FaEye style={{ marginRight: '1rem' }} />
                                    Visualizar formulário
                                </StyledMenuItem>
                            )
                        }

                        {
                            (!pesquisa.formularioAprovado && pesquisa.formularioId !== null && parseJwt().adm === 'True') && (
                                <StyledMenuItem
                                    onClick={() => navigate(`${pathname.replace('Gestao', 'VisualizarFormulario')}/${pesquisa.id}`)}
                                >
                                    <MdSend style={{ marginRight: '1rem' }} />
                                    Aprovar formulário
                                </StyledMenuItem>
                            )
                        }

                        {/* <StyledMenuItem
                            onClick={() => { }}
                            disabled
                        >
                            <FaCopy style={{ marginRight: '1rem' }} />
                            Duplicar pesquisa
                        </StyledMenuItem> */}

                        {
                            ((pesquisa.formularioId === null || pesquisa.formularioId === undefined) && parseJwt().adm === 'True') && (
                                <StyledMenuItem
                                    onClick={() => navigate(`${pathname.replace('Gestao', 'Formularios')}/${pesquisa.id}`)}
                                >
                                    <FaTachometerAlt style={{ marginRight: '1rem' }} />
                                    Selecionar formulário
                                </StyledMenuItem>
                            )
                        }

                        {
                            (parseJwt().adm === 'True') && (
                                <StyledMenuItem
                                    onClick={onDelete}
                                >
                                    <FaTrash style={{ marginRight: '1rem' }} />
                                    Excluir pesquisa
                                </StyledMenuItem>
                            )
                        }
                    </MenuList>
                </Menu>
            </Flex>
        </PreViewParticipante>
  )
}
