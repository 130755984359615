import styled from 'styled-components'

export const StyledText = styled.span`
background-color: ${props => props.theme};
padding: .5rem;
color: white;
padding: 4px, 16px, 4px, 16px;
border-radius: 1rem;
position: relative;
font-weight: 500;
top: -8%;
font-size:.75rem;
`

export const StyledContainer = styled.div`
border-radius: 50%;
border:12px solid ${props => props.theme};
background: white;
padding:.2rem;
span{
    margin: 0rem;
}
`

export const StyledName = styled.span`
font-weight: 500;
font-size: .75rem;
`
