/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-floating-promises */
import { useEffect } from 'react'
import ApexCharts from 'apexcharts'

interface iChartProps{
  id: string
  val: number
  MediaEngajamento?: boolean
}

export const GaugeChart: React.FC<iChartProps> = ({ id, val, MediaEngajamento }) => {
  function ObterCor(val: number): string {
    if (MediaEngajamento) {
      if (val <= 3) {
        return 'var(--Red)'
      } else if (val <= 6) {
        return 'var(--Orange)'
      } else if (val <= 8) {
        return 'var(--Azul)'
      } else {
        return 'var(--Green3)'
      }
    } else {
      if (val <= 30) {
        return 'var(--Red)'
      } else if (val <= 60) {
        return 'var(--Orange)'
      } else if (val <= 80) {
        return 'var(--Azul)'
      } else {
        return 'var(--Green3)'
      }
    }
  }

  function GerarGrafico(): void {
    const options = {
      series: [MediaEngajamento ? val * 10 : val],
      chart: {
        type: 'radialBar',
        offsetY: -20,
        sparkline: {
          enabled: true
        }
      },
      plotOptions: {
        radialBar: {
          startAngle: -90,
          endAngle: 90,
          track: {
            background: '#e7e7e7',
            strokeWidth: '97%',
            margin: 5, // margin is in pixels
            dropShadow: {
              enabled: true,
              top: 2,
              left: 0,
              color: '#999',
              opacity: 1,
              blur: 2
            }
          },
          dataLabels: {
            name: {
              show: false
            },
            value: {
              show: true,
              fontSize: '22px',
              offsetY: -2,
              formatter: function (val: any) {
                if (!MediaEngajamento) {
                  return val.toFixed(2) + '%'
                } else {
                  return (val / 10).toFixed(2)
                }
              }
            }
          }
        }
      },
      fill: {
        colors: [ObterCor(val)]
      }
    }

    const chart = new ApexCharts(document.querySelector(`#${id}`), options)
    chart.render()
  }

  useEffect(() => {
    GerarGrafico()
  }, [])

  return (
        <div id={id}></div>
  )
}
