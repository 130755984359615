import styled from 'styled-components'

export const Container = styled.div`
display: flex;
background: #${props => props.theme};
color: var(--c1);
border-radius: 0.625rem;
flex-direction: column;
text-align: start;
width: 23.3rem;
height: 14rem;
padding: 1rem;
margin-bottom: 1rem;

small{
    font-family: 'Roboto','sans-serif';
}

div:last-child{
    margin-top: .5rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

&:hover{
    border: solid var(--terc6);
    cursor: pointer;
    transform: translate(0rem,-0.375rem);
    transition: all 0.2s ease;
    box-shadow: 0rem 0.375rem 0.5rem rgba(0, 0, 0, 0.25);
}
`

export const NavButton = styled.a`
    background: var(--terc6);
    padding: 0.5rem;
    border-radius: 0.625rem;
    font-weight: bold;
    font-family: 'Roboto','sans-serif';

    span{
        margin-right: 0.5rem;
    }
`

export const BodyCard = styled.div`
max-height: 7.25rem;
min-height: 7.25rem;
`
