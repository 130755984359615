import styled from 'styled-components'
import { Column, Flex } from '../../../../../styles/styledGlobal'

export const EmployContainer = styled(Column)`
justify-content:space-between;
padding: 1rem;
border-radius: 0.625rem;
box-shadow: var(--SombraBackground);
width: 23rem;
`

export const EmployeHeader = styled(Flex)`
    justify-content: space-between;
    align-items: center;
    height: 4.5rem;
    span,small{
        font-weight: 600;
        color: var(--a3);
    }

    small{
        font-size: 0.875rem;
    }
`

export const EmployeBody = styled(Flex)`
    margin:1rem 0rem .5rem 0rem;
    align-items: center;
    justify-content: center;
`

export const PerfilContainer = styled(Column)`
text-align: center;
    img{
        width: 7.688rem;
        height: 2.063rem;
    }
`

export const ColumnNotas = styled(Column)`
    padding-left:.5rem;
    margin-left:.5rem;

    small{
        font-weight: bold;
    }

    small,span{
        color: var(--c7);
    }

    span{
        font-weight: 100;
    }
`
