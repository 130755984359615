/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Flex, Image, useCallbackRef, Text, toast, useToast } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import Cropper from 'react-easy-crop'
import { FaAngleRight } from 'react-icons/fa'
import { Button } from '../../../../../components/Button'
import { MetaButton } from '../../../../../components/Buttons/MetaButton'
import { ModalBase } from '../../../../../components/ModalBase'
import { getCroppedImg, iCrop } from '../../../../../components/PerfilColaborador/Components/ModalCrop/cropImage'
import { VideoPlayer } from '../../../../../components/VideoPlayer'
import { iModalPadrao } from '../../../../../interfaces'
import { appApi } from '../../../../../services/appApi'
import { ObterToken } from '../../../../../Utils/Helper'
import { InputFile, Label } from '../../../../Usuario/Empresa/Configuracao/components/ModalCrop/styles'
import { ModalSucessPrimeiroAceso } from '../ModalSucessPrimeiroAcesso'
import { BoxGenero, Container, GreyBox } from './styles'

interface iModalPrimeiroAcesso extends iModalPadrao {
  onConcluir: (pronome: string, avatar?: string) => void
  isOpen: boolean
}

export const ModalPrimeiroAcesso: React.FC<iModalPrimeiroAcesso> = ({ OnCloseModal, isOpen, onConcluir }) => {
  if (!isOpen) {
    return null
  }

  const [Switch, setSwitch] = useState(1)

  const [PopUpisOpen, setPopUpisOpen] = useState(false)

  const [TituloVideo, setTituloVideo] = useState('')
  const [DescricaoVideo, setDescricaoVideo] = useState('')
  const [Arquivo, setArquivo] = useState('')
  const [Rotacao, setRotacao] = useState(0)
  const [TextoBoasVindas, setTextoBoasVindas] = useState('')
  const [Pronome, setPronome] = useState('')
  const [Model, setModel] = useState<iCrop>({
    height: 0,
    width: 0,
    x: 0,
    y: 0
  })
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [aspecto, setAspecto] = useState(1)
  const onCropComplete = useCallbackRef((croppedArea, croppedAreaPixels) => {
    setModel(croppedAreaPixels)
  }, [])
  const [file, setFile] = useState('')
  const [lastFile, setLastFile] = useState('')
  const [Nome, setNome] = useState('')
  const [Base64, setBase64] = useState<string>()
  const toast = useToast()

  const AtualizarArquivo = (event: any): void => {
    const formdata = new FormData()
    formdata.append('request', event.target.files[0])
    appApi.post(`UploadFile?url=${file ?? ''}`, formdata).then(resposnse => {
      setFile(resposnse.data.uri)
      setNome(resposnse.data.name)
    }).catch(e => console.log(e))
  }

  function getBase64(): void {
    getCroppedImg(file ?? '', Model)
      .then(res => setBase64(res))
      .catch(err => console.log(err))
  }

  function Salvar(): void {
    const file = DataURIToBlob(Base64 ?? '')
    const formData = new FormData()

    formData.append('request', file, Nome)

    appApi.post(`UploadFile?url=${file ?? ''}`, formData, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: ObterToken()
      }
    }).then(res => setLastFile(res.data.uri))
      .catch(e => console.log(e))
  }

  function DataURIToBlob(dataURI: string): any {
    const splitDataURI = dataURI.split(',')
    const byteString = splitDataURI[0].includes('base64') ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
    const mimeString = splitDataURI[0].split(':')[1].split(';')[0]

    const ia = new Uint8Array(byteString.length)
    for (let i = 0; i < byteString.length; i++) { ia[i] = byteString.charCodeAt(i) }

    return new Blob([ia], { type: mimeString })
  }

  function SalvarImagem(tipo: boolean): void {
    getBase64()
    if (tipo) {
      toast({
        title: 'Imagem recortada com sucesso',
        position: 'top',
        description: '',
        status: 'success',
        duration: 9000,
        isClosable: false
      })
    }
  }

  useEffect(() => {
    if (Base64) {
      Salvar()
    }
  }, [Base64])

  function AtualizarPronome(pronome: string): void {
    setPronome(pronome)
    setSwitch(3)
  }

  function TrocarView(Tipo: number): any {
    if (Tipo === 1) {
      return (
      <Flex marginBottom={'1rem'}>
        <Flex maxHeight={'15rem'} maxWidth={'15rem'} marginRight={'2rem'}>
          <Image width={'100%'} height='100%' objectFit={'scale-down'} src={localStorage.getItem('logo') ?? ''} />
        </Flex>
        <Flex flexDir={'column'} justifyContent='center'>
          <h3>{TextoBoasVindas}</h3>
          <MetaButton
            onClick={() => setSwitch(Switch + 1)}
            styles={{ width: 'fit-content', marginTop: '1rem' }}
            size='md' bg='v2'>Avançar <FaAngleRight className='IconRight' />
          </MetaButton>
        </Flex>
      </Flex>
      )
    } else if (Tipo === 2) {
      return (
      <Flex flexDir='column' justifyContent={'center'} alignItems={'center'}>
        <span style={{ color: 'var(--a3)', fontSize: '22px', fontFamily: 'Poppins' }}>Antes de começar, gostaríamos de saber como você gostaria de ser chamado:</span>
        <BoxGenero>
          <span onClick={() => AtualizarPronome('Ele/Dele')}>Ele/Dele</span>
          <span onClick={() => AtualizarPronome('Ela/Dela')}>Ela/Dela</span>
          <span onClick={() => AtualizarPronome('Elu/Delu')}>Elu/Delu</span>
          <span onClick={() => AtualizarPronome('Ile/Dile')}>Ile/Dile</span>
        </BoxGenero>
      </Flex>
      )
    } else if (Tipo === 3) {
      return (
      <Flex flexDir='column' justifyContent={'center'} alignItems={'center'}>
        <h1>Vamos colocar uma foto de perfil?</h1>
        <Flex marginTop='1rem' flexDir={'column'}>
          <Flex position={'relative'} height={'20rem'}>
            {file === '' &&
              <Flex alignItems={'center'} width='100%' justifyContent={'center'}>
                <Label htmlFor='inp'>Clique aqui para selecionar imagem</Label>
                <InputFile
                  id='inp'
                  type={'file'}
                  onChange={(e) => { AtualizarArquivo(e) }}
                />
              </Flex>
            }
            {file && file.length > 3 &&
              <Flex flexDir={'column'}>
                <Flex width={'15rem'} zIndex={100}>

                  <Cropper
                    image={file}
                    crop={crop}
                    zoom={zoom}
                    rotation={Rotacao}
                    aspect={aspecto}
                    onCropChange={setCrop}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoom}
                    cropShape={'round'}
                  />
                </Flex>
              </Flex>
            }
          </Flex>
          <Flex flexDir={'column'} padding={'1rem'} alignItems='center' justifyContent='center'>
            {file && file.length > 3 &&
              <Flex flexDir={'column'} gap='.2rem'>
                <Flex alignItems={'center'} gap='.5rem'>
                  <Text>Zoom</Text>
                  <input
                    type={'range'}
                    min={1}
                    max={15}
                    step={0.01}
                    value={zoom}
                    onChange={(e) => setZoom(Number(e.target.value))}
                    aria-labelledby="Zoom"
                  />
                </Flex>

                <Flex flexDir={'column'} gap='.5rem'>
                  <Flex alignItems={'center'}>
                    <Text>Rotação</Text>
                    <input
                      aria-labelledby="Rotation"
                      step={1}
                      min={'0'}
                      max='360'
                      type={'range'}
                      onChange={(e) => setRotacao(parseInt(e.target.value))}
                    />
                  </Flex>
                </Flex>

                <Flex alignItems={'center'} gap='.5rem'>
                  <Flex direction={'column'}>

                  </Flex>
                </Flex>
              </Flex>
            }
          </Flex>
        </Flex>
      </Flex>
      )
    } else if (Tipo === 4) {
      return (
      <Flex height={'25rem'} width={' 40rem'} flexDir={'column'}>
        <VideoPlayer tituloPersonalizado={<strong>{TituloVideo}</strong>} descricao={DescricaoVideo} url={Arquivo} />
      </Flex>
      )
    }
  }

  function ObterPrimeiroAcessoById(): void {
    appApi.get('PrimeiroAcesso/ByEmpresaId').then(response => {
      setTituloVideo(response.data.videoIntroducao.titulo)
      setDescricaoVideo(response.data.videoIntroducao.descricao)
      setArquivo(response.data.videoIntroducao.arquivo.uri)
      setTextoBoasVindas(response.data.textoBoasVindas)
    }).catch(e => console.log(e))
  }

  function InternalConcluir(): void {
    onConcluir(Pronome, lastFile)
    setPopUpisOpen(true)
    SalvarImagem(false)
  }

  useEffect(() => {
    ObterPrimeiroAcessoById()
  }, [])

  return (
  <>
    <ModalSucessPrimeiroAceso
      isOpen={PopUpisOpen}
      onRequestClose={OnCloseModal}
    />

    {
      (!PopUpisOpen) && (
        <ModalBase disabledHeader isOpen onClose={OnCloseModal}>
          <Container>
            <>
              {
                TrocarView(Switch)
              }
            </>
            {
              (Switch !== 1) && (
                <Flex margin={'1rem 0rem'} gap='2rem'>
                  <MetaButton onClick={() => setSwitch(Switch - 1)} bg='c5' size='md'>Voltar</MetaButton>
                  {
                    (Switch !== 2) && (
                      <>
                        {
                          ((Switch === 4 && Arquivo.length > 3) || (Arquivo.length === 0 && Switch === 3))
                            ? <Flex>
                                <MetaButton marginR='2rem' size='md' bg = 'Rosa'onClick={() => SalvarImagem(true)}>Salvar imagem</MetaButton>
                                <MetaButton onClick={InternalConcluir} bg='v2' size='md'>Finalizar</MetaButton>
                              </Flex>
                            : <MetaButton onClick={() => setSwitch(Switch + 1)} bg='v2' size='md'>Próximo</MetaButton>
                          }
                </>
                    )
            }

          </Flex>
              )
              }

          <Flex className='navElipse'>
            <div onClick={() => setSwitch(1)} style={{ background: Switch === 1 ? 'var(--c7)' : 'var(--c5)' }}></div>
            <div onClick={() => setSwitch(2)} style={{ background: Switch === 2 ? 'var(--c7)' : 'var(--c5)' }}></div>
            <div onClick={() => setSwitch(3)} style={{ background: Switch === 3 ? 'var(--c7)' : 'var(--c5)' }}></div>
            {
              (Arquivo.length > 3) && (
                <div onClick={() => setSwitch(4)} style={{ background: Switch === 4 ? 'var(--c7)' : 'var(--c5)' }}></div>
              )
            }
          </Flex>
        </Container>
          </ModalBase>
      )
      }
    </>
  )
}
