import { Avatar, AvatarBadge, Box, Button, Flex, Input, InputGroup, InputRightElement, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, Tooltip, useDisclosure, useToast } from '@chakra-ui/react'
import { FaAdjust, FaEdit, FaPlayCircle, FaPlus, FaSearch, FaStopCircle, FaSuitcase, FaUserPlus } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import { Body } from '../Layouts/Body'

import { SigahApi, appApi } from '../../services/appApi'
import { useRef, useEffect, useState } from 'react'
import { IEmpresaUsuario } from './interfaces'
import { iPermissao } from '../../contexts/AuthContext'

interface iUsuario{
  nome: string
  email: string
  senha: string
  eid: string

}

export const CentralComando: React.FC = () => {
  const initialRef = useRef(null)
  const navigate = useNavigate()
  const [Model, setModel] = useState<IEmpresaUsuario[]>([])
  const [Permissoes, setPermissoes] = useState<iPermissao[]>([])
  const [loadA, setLoadA] = useState(true)
  const [loadB, setLoadB] = useState(true)
  const [busca, setBusca] = useState('')
  const [novoUsuario, setNovoUsuario] = useState<iUsuario>({ eid: '', email: 'ah@empresa.com', nome: 'TI SAH', senha: '@!Humana2k23' })
  const toast = useToast()
  useEffect(() => {
    carregarDados()
  }, [])
  function carregarDados(): void {
    setLoadA(true)
    setLoadB(true)
    SigahApi.get('/Adm/GetEmpresas')
      .then(res => {
        setModel(res.data)
        setTimeout(() => {
          setLoadB(false)
        }, 6000)
      })
      .catch(res => {
        setLoadB(false)
      })
    appApi.get('/Permissoes/GetAll')
      .then(res => {
        setPermissoes(res.data)
        setTimeout(() => {
          setLoadA(false)
        }, 2000)
      })
      .catch(res => {
        setLoadA(false)
      })
  }

  function tratarData(data: string): string {
    const quebra = data.split('T')
    const d = quebra[0].split('-')
    return `${d[2]}/${d[1]}/${d[0]}`
  }
  function obterModulos(empresaId: string, modulo: string): Boolean {
    const config = Permissoes.find(p => p.empresaId === empresaId)

    if (!config) {
      if (modulo === 'e') return true
      else { return false }
    }

    switch (modulo) {
      case 'j':
        return config.jornada

      case 'd':
        return config.desempenho

      case 'e':
        return config.engajamento

      default:
        return false
    }
  }

  function ativarEmpresa(id: string): void {
    SigahApi.get(`/Adm/AtivarEmpresaExt?eid=${id}`)
      .then(res => {
        toast({
          title: 'Status da empresa alterado com sucesso',
          position: 'top-right',
          variant: 'left-accent',
          status: 'success',
          duration: 9000,
          isClosable: false
        })
        carregarDados()
      })
      .catch(res => {
        toast({
          title: 'Não foi possível mudar o status da empresa tente mais tarde',
          position: 'top-right',
          variant: 'left-accent',
          status: 'error',
          duration: 9000,
          isClosable: false
        })
      })
  }
  function abrirModal(veid: string): void {
    setNovoUsuario({ ...novoUsuario, eid: veid })
    onOpen()
  }
  const { isOpen, onOpen, onClose } = useDisclosure()
  function adicionarUsuario(eid: string): void {
    SigahApi.post('/Adm/CriarUsuarioExt', { eid, nome: novoUsuario.nome, email: novoUsuario.email, senha: novoUsuario.senha })
      .then(res => {
        toast({
          title: 'Usuário criado com sucesso',
          position: 'top-right',
          variant: 'left-accent',
          status: 'success',
          duration: 9000,
          isClosable: false
        })
        carregarDados()
        onClose()
      })
      .catch(res => {
        toast({
          title: 'Não foi possível criar o usuario',
          description: 'O email já está em uso',
          position: 'top-right',
          variant: 'left-accent',
          status: 'error',
          duration: 9000,
          isClosable: false
        })
      })
  }

  return (
  <Body isLoading={loadA && loadB}>

<Modal initialFocusRef={initialRef} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Novo usuário administrativo</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Nome</Text>
            <Input onChange={(e) => setNovoUsuario({ ...novoUsuario, nome: e.target.value })} ref={initialRef} placeholder='Nome' value={novoUsuario.nome} />
            <Text>Email</Text>
            <Input onChange={(e) => setNovoUsuario({ ...novoUsuario, email: e.target.value })} placeholder='Email' value={novoUsuario.email}/>
            <Text>Senha</Text>
            <Input onChange={(e) => setNovoUsuario({ ...novoUsuario, senha: e.target.value })} placeholder='Senha' value={novoUsuario.senha}/>

          </ModalBody>

          <ModalFooter>
            <Button variant='ghost' colorScheme='blue' mr={3} onClick={onClose}>
              Cancelar
            </Button>
            <Button onClick={() => adicionarUsuario(novoUsuario.eid)} colorScheme={'green'}>Adicionar</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

  <Flex direction={'column'} padding={'1rem'} borderRadius={'2xl'} boxShadow={'base'} backgroundColor={'white'}>

  <h1>Clientes</h1>
  <Flex my={'1rem'} justifyContent={'space-between'}>

  <Button size={'sm'} backgroundColor={'var(--Azul)'} leftIcon={<FaPlus/>}
  onClick={() => navigate('/CentralComando/Criar')}>Adicionar empresa</Button>
  <InputGroup width={'15rem'} size={'sm'}>
  <Input placeholder='nome, cnpj' onChange={(e) => setBusca(e.target.value)} />
  <InputRightElement
  children={<FaSearch/>}
  />
  </InputGroup>
  </Flex>

<Flex my={'0.5rem'} justifyContent={'space-between'} >
<Text fontSize={'xs'} width='20rem' >Nome da empresa</Text>
<Text fontSize={'xs'} width='5rem' textAlign={'center'} >Vigência</Text>
<Text fontSize={'xs'} textAlign={'center'}>Usuários</Text>
<Text fontSize={'xs'} width={'4.8rem'} textAlign={'center'}>Módulos</Text>
<Box mr={'1rem'}>
  <FaAdjust opacity={0}/>
  </Box>
</Flex>
{Model.filter((f) => {
  if (busca === '') return f
  else if (f.empresa.nome?.toLocaleLowerCase().includes(busca.toLocaleLowerCase()) || f.empresa.razaoSocial?.toLocaleLowerCase().includes(busca.toLocaleLowerCase()) ||
  f.empresa?.cnpj.toLocaleLowerCase().includes(busca.toLocaleLowerCase())) return f
  else return null
}).length > 0 && Model.filter((f) => {
  if (busca === '') return f
  else if (f.empresa.nome?.toLocaleLowerCase().includes(busca.toLocaleLowerCase()) || f.empresa.razaoSocial?.toLocaleLowerCase().includes(busca.toLocaleLowerCase()) ||
  f.empresa.cnpj?.toLocaleLowerCase().includes(busca.toLocaleLowerCase())) return f
  else return null
}).map((e, i) => (
  <Box key={e.empresa.id} mb={'0.5rem'} minHeight={'3rem'}>
<Flex direction={'column'} borderBottom={'1px'} borderTop={'1px'} py={''}>
<Flex alignItems={'center'} justifyContent={'space-between'} borderBottom={'1px'} borderTop={'1px'} py={'1rem'}>
<Flex alignItems={'center'} width='20rem'>
<Avatar mr={'0.5rem'} my={'0.5rem'} width={'2rem'} height={'2rem'} icon={<FaSuitcase/>}>
    {e.empresa.ativo && <AvatarBadge boxSize='0.75em' bg='green.500' /> }
    {!e.empresa.ativo && <AvatarBadge boxSize='0.75em' bg='red.500' /> }
  </Avatar>
  <Text fontSize={'xs'} >{e.empresa.nome}</Text>
</Flex>
<Text fontSize={'xs'} width='5rem'>{e.empresa.contrato ? tratarData(e.empresa.contrato.fim) : 'FREE'}</Text>
<Flex alignItems={'center'}>
<Avatar mr={'0.5rem'} my={'0.5rem'} width={'1.5rem'} height={'1.5rem'}>

  </Avatar>
  <Text fontSize={'xs'} >{e.usuarios.length} usuarios</Text>
</Flex>
<Flex width={'4.8rem'} justifyContent={'space-between'}>
<Tooltip hasArrow label='Jornada' bg='blue.400'>
  <Avatar backgroundColor={obterModulos(e.empresa.id, 'j') ? 'var(--Azul)' : 'var(--c4)'} width={'1.5rem'} height={'1.5rem'} name='J'></Avatar>
  </Tooltip>
<Tooltip hasArrow label='Desempenho' bg='var(--Rosa)'>
  <Avatar backgroundColor={obterModulos(e.empresa.id, 'd') ? 'var(--Rosa)' : 'var(--c4)'}width={'1.5rem'} height={'1.5rem'} name='D'></Avatar>
  </Tooltip>
<Tooltip hasArrow label='Engajamento' bg={'var(--Roxo)'}>
  <Avatar backgroundColor={obterModulos(e.empresa.id, 'e') ? 'var(--Roxo)' : 'var(--c4)'}width={'1.5rem'} height={'1.5rem'} name='E'></Avatar>
  </Tooltip>
  </Flex>
<Box mr={'1rem'}>
<Tooltip hasArrow label={!e.empresa.ativo ? 'Ativar empresa' : 'Bloquear empresa'} bg='red.600'>
<Button ml={'0.5rem'} colorScheme={e.empresa.ativo ? 'red' : 'green'} variant='outline'
onClick={() => { ativarEmpresa(e.empresa.id) }}>
{e.empresa.ativo && (<FaStopCircle/>) }
{!e.empresa.ativo && (<FaPlayCircle/>) }
</Button>
</Tooltip>
<Tooltip hasArrow label='Editar empresa' bg='teal.400'>

<Button onClick={() => navigate(`/CentralComando/Editar/${e.empresa.id}`)} ml={'0.5rem'} colorScheme='teal' variant='outline'>
    <FaEdit/>
  </Button>
</Tooltip>
  <Tooltip hasArrow label='Criar usuário administrativo' bg='linkedin.400'>

<Button onClick={() => abrirModal(e.empresa.id)} ml={'0.5rem'} colorScheme='linkedin' variant='outline'>
    <FaUserPlus/>
  </Button>
  </Tooltip>
  </Box>
</Flex>

</Flex>

</Box>))
}

</Flex>
  </Body>
  )
}
