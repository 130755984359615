import styled from 'styled-components'
import { Column, Flex } from '../../../../../styles/styledGlobal'

export const ModalBody = styled(Column)`
padding: 1rem;
width: 61.563rem;
`

export const ModalHeader = styled(Flex)`
align-items:center;
justify-content: space-between;
border-bottom: 0.063rem solid var(--c4);
padding: 1rem;

h2{
    color:var(--a3);
    font-size: 1.5rem;
}

svg{
    color:var(--a2);
    cursor:pointer;
}
`

export const InputLabel = styled(Column)<{marginBottom?: string}>`
margin-bottom: ${props => props.marginBottom};
label{
  font-family: 'Roboto','sans-serif';
  font-weight: 500;
  font-size: 1.25rem;
  color: var(--a3);
}


input,textarea{
  font-family: 'Roboto','sans-serif';
  font-weight: 400;
  font-size: 1.125rem;
  color: var(--a5);
  background: var(--c2);
}

textarea{
  outline: 0;
  height: 11.063rem;
  padding: .5rem;
  border-radius: .5rem;
  resize: none;
}

textarea:focus {
  border: 0.063rem solid var(--v2);
}
`

export const ModalFooter = styled(Flex)`
justify-content: end;
padding: 1rem;
border-top: 0.063 solid var(--c4);

a:first-child{
  margin-right: 1rem;
}
`
