/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Body } from '../../Layouts/Body'
import { ModalDelete } from '../../../components/ModalDelete'
import { iCardPesquisa } from '../../../interfaces'
import { appApi } from '../../../services/appApi'
import { CardPesquisa } from './Components/CardPesquisa'
import { Flex, FormControl, FormLabel, Input, InputGroup, InputLeftElement, Select } from '@chakra-ui/react'
import { FaSearch } from 'react-icons/fa'
import { Button } from '../../../components/Button'
import { ImgSah } from '../../../components/ImgSah'
import { parseJwt } from '../../../services/token'

export const GestaoPesquisas: React.FC = () => {
  const { pathname } = useLocation()
  const nav = useNavigate()
  const [Pesquisas, setPesquisas] = useState<iCardPesquisa[]>([])
  const [SearchText, setSearchText] = useState('')
  const [Tipo, setTipo] = useState('')
  const [Filter, setFilter] = useState('0')

  const [IsLoading, setIsLoading] = useState(false)

  const [ModalDeleteIsOpen, setModalDeleteIsOpen] = useState(false)
  const [PesqusiaId, setPesquisaId] = useState('')

  const ct = pathname.includes('Desempenho') ? 'AnaliseDesempenho' : pathname.includes('Percepcao') ? 'Percepcao' : ''
  const Titulo = pathname.includes('Desempenho') ? 'desempenho por competência' : pathname.includes('Percepcao') ? 'percepção' : ''

  function FilterBy(tipo: number): void {
    const arr = [...Pesquisas]

    if (tipo === 0) {
      arr.sort(function (a, b) {
        return new Date(b.dataCriacao) > new Date(a.dataCriacao) ? 1 : -1
      })
    } else if (tipo === 1) {
      arr.sort(function (a, b) {
        return new Date(b.dataCriacao) > new Date(a.dataCriacao) ? 1 : -1
      })
      arr.reverse()
    } else if (tipo === 2) {
      arr.sort((a, b) => {
        return a.nomePesquisa.toLowerCase() > b.nomePesquisa.toLowerCase() ? 1 : -1
      })
    } else if (tipo === 3) {
      arr.sort((a, b) => {
        return a.nomePesquisa.toLowerCase() > b.nomePesquisa.toLowerCase() ? 1 : -1
      })
      arr.reverse()
    }
    setPesquisas(arr)
  }

  function getPesquisas(): void {
    setIsLoading(true)
    appApi.get(`Pesquisa${ct}`)
      .then(response => {
        setPesquisas(response.data)
        setIsLoading(false)
      })
      .catch(err => console.log(err))
  }

  function DeletePesquisa(): void {
    appApi.delete(`PesquisaPercepcao/${PesqusiaId}`)
      .then(getPesquisas)
      .catch(err => console.log(err))
  }

  function OnOpenModalDelete(Id: string): void {
    setPesquisaId(Id)
    setModalDeleteIsOpen(true)
  }

  function OnCloseModalDelete(): void {
    setPesquisaId('')
    setModalDeleteIsOpen(false)
  }

  useEffect(() => {
    getPesquisas()
  }, [pathname])

  return (
    <Body isLoading={IsLoading}>
      <ModalDelete
        isOpen={ModalDeleteIsOpen}
        message='Você deseja realmente excluir esta pesquisa?'
        onConfirm={DeletePesquisa}
        onRequestClose={OnCloseModalDelete}
      />
      <Flex justifyContent={'space-between'} margin='1rem 0rem'>
        <h1>Gestão de pesquisas de {Titulo}</h1>
        {
          (parseJwt().adm === 'True') && (
            <Flex>
              <Button
                VarColor='Azul'
                size={'lg'}
                onClick={() => nav(`${pathname.replace('Gestao', 'Criar')}`)}
              >Criar nova pesquisa</Button>
            </Flex>
          )
        }
      </Flex>
      <Flex alignItems={'end'} marginBottom='1rem' justifyContent={'space-between'}>
        <Flex>
          {
            (!pathname.includes('Desempenho')) && (
              <FormControl width={'15rem'} marginRight={'1rem'}>
                <FormLabel fontSize={'xl'}>Tipo da pesquisa</FormLabel>
                <Select onChange={(e) => setTipo(e.target.value)} value={Tipo} bg={'white'} borderColor={'var(--Gray4)'}>
                  <option value={''}>Todas</option>
                  <option value={'false'}>Nova</option>
                  <option value={'true'}>Vinculada a um processo de onboard</option>
                </Select>
              </FormControl>
            )
          }
        </Flex>

      <Flex>
          {
            (pathname.includes('Desempenho')) && (
                <FormControl width={'50rem'} marginRight={'1rem'}>
                  <FormLabel fontSize={'xl'}>Ordenação</FormLabel>
                  <Select bg={'white'} borderColor={'var(--Gray4)'} value={Filter} onChange={(e) => {
                    setFilter(e.target.value)
                    if (e.target.value === '0') {
                      FilterBy(0)
                    } else if (e.target.value === '1') {
                      FilterBy(1)
                    } else if (e.target.value === '2') {
                      FilterBy(2)
                    } else if (e.target.value === '3') {
                      FilterBy(3)
                    }
                  }} width={'30%'} size='md'>
                    <option value={'0'}>Pesquisa mais recente</option>
                    <option value={'1'}>Pesquisa mais antiga</option>
                    <option value={'2'}>A-Z</option>
                    <option value={'3'}>Z-A</option>
                  </Select>
                </FormControl>
            )
          }
      </Flex>

        <InputGroup width={'30%'}>
          <InputLeftElement
            pointerEvents='none'
            children={<FaSearch color='var(--Gray4)' />}
          />
          <Input
            bg={'white'}
            onChange={(e) => setSearchText(e.target.value)}
            borderColor={'var(--Gray4)'}
            type='text'
            placeholder='Pesquisa'
          />
        </InputGroup>
      </Flex>

      <Flex flexDir={'column'} gap='1rem'>
        {
          Pesquisas.filter(e => (e.processoOnboard && Tipo === 'true') || (e.processoOnboard === null && Tipo === 'false') || (Tipo === ''))
            .filter((e: iCardPesquisa) => {
              if (SearchText === '' || e.nomePesquisa.toLowerCase().includes(SearchText.toLowerCase())) {
                return e
              }
              return null
            }).map((p: iCardPesquisa, i) => {
              return (
              <CardPesquisa
                key={i}
                pesquisa={p}
                onDelete={() => OnOpenModalDelete(p.id)}
              />
              )
            })
        }
      </Flex>

      {
        (Pesquisas.length === 0 && !IsLoading) && (
          <ImgSah entidade='pesquisa'>
            {
              (parseJwt().adm === 'True') && (
                <Button
                  VarColor='Azul'
                  size={'lg'}
                  onClick={() => nav(`${pathname.replace('Gestao', 'Criar')}`)}
                >Criar nova pesquisa</Button>
              )
            }
          </ImgSah>
        )
      }
    </Body>
  )
}
