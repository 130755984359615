import { Box, Flex } from '@chakra-ui/react'
import { ReactNode } from 'react'
import './styles.css'
export interface IOnSelectProps{
  valor: string
  selecionado: boolean
  adaptativo?: boolean
}
interface IBotaoPerfilProps{
  selecionado: boolean
  adaptativo: boolean
  valor: string
  children: ReactNode
  onSelect?: (data: IOnSelectProps) => any
}
export const BotaoPerfil: React.FC<IBotaoPerfilProps> = ({ children, onSelect, adaptativo, ...props }) => {
  // const [selecionado, setSelecionado] = useState(props.selecionado)

  function handleSelect(): void {
    // setSelecionado(!selecionado)
    if (onSelect) {
      onSelect({ valor: props.valor, selecionado: !props.selecionado })
    }
  }

  return (
    <Box width='100px' height='50px'
onClick={() => { handleSelect() }}
border='4px' borderColor={props.selecionado ? adaptativo ? 'var(--Blue1)' : 'var(--Green1)' : 'white'}
transition='ease-in-out' transitionDuration='0.4s' _hover={{ borderColor: props.selecionado ? 'var(--Red)' : adaptativo ? 'var(--Blue1)' : 'var(--Green1)' }}
>
  <Flex justifyContent='center' className='imgp'>

{children}
</Flex>
</Box>
  )
}
