import styled from 'styled-components'
import { Flex, Column } from '../../styles/styledGlobal'

export const ModalBody = styled.div`
width: 40rem;
display: grid;
gap:1rem;
select{
  height: 2.3rem;
  padding: .5rem;
  outline: 0;
}
`

export const ModalContent = styled.div`
padding: 45px;
height: fit-content;
max-height: 40rem;
overflow-y: auto;
max-width: 75rem;
border-radius: 0.625rem;
::-webkit-scrollbar {
  width: .5rem;
}
/* heigth */
::-webkit-scrollbar {
  height: 0.375rem;
}
/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 0.625rem;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
`

export const ModalFooter = styled(Flex)`
margin-top: 2rem;
justify-content: end;
`

export const ModalHeader = styled(Column)`
align-items: center;
h2{
  margin-bottom: 1rem;
}
`

export const DashedBox = styled.label`
display: flex;
flex-direction: column;
cursor: pointer;
align-items: center;
border: 1px solid var(--a4);
border-radius: .5rem;
border-style: dashed;
width: 160px;
padding: 1rem;
color: var(--c7);
background: var(--c2);
small{
  margin-top: 1rem;
  text-align: center;
  font-size: 12px;
  line-height: 100%;
}

img{
  width: 60px;
  height: 60px;
  border-radius: 30px;
}

input[type=file]{
  display:none;
}
`

export const TitleTipoUser = styled.label`
font-size: var(--mdLabel);
color: var(--a4);
`
