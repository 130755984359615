import styled from 'styled-components'

export const ContainerMax = styled.div`
display: flex;
justify-content: center;
margin-top: 2rem;
`

export const Container = styled.div<{isLoading?: boolean}>`
display: flex;
justify-content: center;
flex-direction: column;
width: 75rem;

${({ isLoading }) => isLoading && `
    opacity:.5;
  `}
`
