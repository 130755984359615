import { Button, ButtonProps, MenuButton } from '@chakra-ui/react'

interface iStyledProps extends ButtonProps {
  disabled?: boolean
}

export const StyledMenuButton: React.FC<iStyledProps> = ({ onClick, children, disabled, ...rest }) => {
  return (
        <MenuButton
          px={4}
          height={'2.5rem'}
          borderRadius='lg'
          transition='all 0.2s'
          _hover={{ bg: 'var(--Rosa)' }}
          bg={'none'}
          fontWeight={'700'}
          fontSize={'1rem'}
          as={Button}
          _focus={{ bg: 'var(--Rosa)' }}
          _active={{ bg: 'var(--Rosa)' }}
          {...rest}
          onClick={onClick}
        >
            {children}
        </MenuButton>
  )
}
