import { Button, Flex } from '@chakra-ui/react'
import { Link } from '../../../../Imersao/Components/Pdf/styles'

interface iPDFProps {
  isVisible: boolean
  uri?: string
}

export const PDFImersao: React.FC<iPDFProps> = ({ isVisible, uri }) => {
  if (!isVisible) {
    return null
  }
  return (
    <>
      <Flex justifyContent={'center'} marginTop={'2rem'} >
        <span>Um arquivo PDF foi disponibilizado</span>
      </Flex>

      <Flex justifyContent={'center'} margin={'0.75rem 0'} >
        <p>Clique <Link href={uri}>aqui</Link> para realizar o download</p>
      </Flex>

      <Flex justifyContent={'center'} margin={'1rem 0'}>
        <Button
          width={'13rem'}
          fontWeight={'500'}
          borderRadius={'12px'}
          bg={'var(--Rosa)'}
          _hover={{ bg: 'var(--Rosa)' }}>
          <a href={uri}
          >Baixar arquivo em PDF</a>
        </Button>
      </Flex>
    </>
  )
}
