import styled from 'styled-components'

export const Main = styled.div`
margin-top: 2rem;

section{
    margin-top: 4rem;
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 2rem 4rem;
}
`
