import { ButtonProps, Button as ChakraButton } from '@chakra-ui/react'

interface iButtonProps extends ButtonProps{
  VarColor?: string
}
export const Button: React.FC<iButtonProps> = ({ VarColor, children, ...rest }) => {
  return (
        <ChakraButton
            {...rest}
            borderRadius={'12px'}
            filter='auto'
            _hover={{
              brightness: '102%',
              boxShadow: 'var(--SombraBtns)',
              transition: 'var(--transitionBtns);'
            }}
            bg={VarColor && `var(--${VarColor})`
        }>{children}</ChakraButton>
  )
}
