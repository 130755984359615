import { Main } from './styles'
import { iItemIntegracao } from '../../../../interfaces'
import { Flex } from '@chakra-ui/react'

interface iTextProps{
  item: iItemIntegracao
  isVisible?: boolean
}

export const TextoImersao: React.FC<iTextProps> = ({ item, isVisible }) => {
  if (!isVisible) {
    return null
  }

  return (
    <Main>
        <img src={item.arquivo?.uri}/>
        <Flex flexDir={'column'}>
            <h2>{item.titulo}</h2>
              <span dangerouslySetInnerHTML={{ __html: item.descricao ?? '' }}></span>
        </Flex>
    </Main>
  )
}
