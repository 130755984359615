/* eslint-disable @typescript-eslint/no-floating-promises */
import { useEffect, useMemo } from 'react'
import ApexCharts from 'apexcharts'

interface iChartProps{
  id: string
  Data: iHeartSeries[]
}

interface iHeartSeries{
  nome: string
  dados: iDados[]
}

interface iDados{
  x: string
  y: string
}

export const HeartChart: React.FC<iChartProps> = ({ id, Data }) => {
  const series = useMemo(
    () =>
      Data.map((dado) => ({
        name: dado.nome,
        data: dado.dados
      })),
    [Data]
  )

  function getSeries(): any {
    return series
  }

  function GerarGrafico(): void {
    const options = {
      series: series,
      // labels: ['-100% à -30%', '-30% à 30%', '30% à 100%', 'Não preenchidos'],
      // colors: ['#EB5757', '#FFBF2A', '#27AE60', 'a9b9b0'],
      chart: {
        height: 350,
        width: 800,
        type: 'heatmap'
      },
      plotOptions: {
        heatmap: {
          min: -100,
          max: 100,
          shadeIntensity: 0.3,
          radius: 0,
          useFillColorAsStroke: true,
          reverseNegativeShade: true,
          colorScale: {
            ranges: [{
              from: -100,
              to: -30,
              name: '-100% à -30%',
              color: '#EB5757'
            },
            {
              from: -30,
              to: 30,
              name: '-30% à 30%',
              color: '#FFBF2A'
            },
            {
              from: 30,
              to: 100,
              name: '30% à 100%',
              color: '#27AE60'
            },
            {
              from: -110,
              to: -101,
              name: 'Não avaliado',
              color: '#a9b9b0'
            }
            ]
          }
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        width: 1
      },
      title: {
        text: 'Mapa de calor por área',
        offsetX: 300
      },
      tooltip: {
        enabled: true,
        y: {
          formatter: function (val: any) {
            console.log(val)
            if (val < -100) {
              return 'n/a'
            } else {
              return `${val as string}%`
            }
          }
        }
      }
    }
    const chart = new ApexCharts(document.querySelector(`#${id}`), options)
    chart.render()

    chart.updateSeries(getSeries())
  }

  useEffect(() => {
    GerarGrafico()
  }, [Data])

  return (
        <div id={id}></div>
  )
}
