import { Box, Checkbox, Flex, FormControl, FormLabel, Input, InputGroup, InputRightElement, Text, useToast } from '@chakra-ui/react'
import { useState, useEffect } from 'react'
import { Button } from '../../../../components/Button'
import { WhiteBox } from '../../../../components/WhiteBox'
import { Body } from '../../../Layouts/Body'
import { ModalUpdateDemografia } from './components/ModalUpdateDemografia'
import { appApi, SigahApi } from '../../../../services/appApi'
import { FaSave } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import { DeleteElementByPosition, ObterToken, PropInvalida } from '../../../../Utils/Helper'
import { ModalCrop } from './components/ModalCrop'
import { FindUser } from '../../../../components/FindBox'
import { iNomeId } from '../../../../interfaces'
import { MdClose } from 'react-icons/md'

interface iQuantidadeDemografia {
  id: string
  nome: string
  quantidade: number
}

interface iModel {
  nome: string
  logo: string
  totalFuncionarios: number
  email: string
  telefone: string
  seguimento: string
  demografias: iQuantidadeDemografia[]
  demografiaId: string
}

interface iResponsavel {
  colaboradorId: string
  tipoResponsabilidade: number
}

interface iConfigEmpresa {
  responsaveis: iResponsavel[]
  liberarResultado: boolean
}

export const ConfiguracaoEmpresa: React.FC = () => {
  const toast = useToast()
  const nav = useNavigate()

  const [Model, setModel] = useState<iModel>({
    logo: '',
    demografiaId: '',
    nome: '',
    totalFuncionarios: 0,
    email: '',
    seguimento: '',
    telefone: '',
    demografias: []
  })

  const [Colaboradores, setColaboradores] = useState<iNomeId[]>([])

  const [Config, setConfig] = useState<iConfigEmpresa>({
    liberarResultado: false,
    responsaveis: []
  })
  const [ColaboradorId, setColaboradorId] = useState<string>()

  const [ModalUpdateIsOpen, setModalUpdateIsOpen] = useState(false)
  const [ModalCropIsOpen, setModalCropIsOpen] = useState(false)

  function onCloseEditDemografia(): void {
    setModalUpdateIsOpen(false)
  }

  function UpdateFuncionarioDemografia(total: string, index: number): void {
    const demografias = [...Model.demografias]
    demografias[index].quantidade = total === '' ? 0 : parseInt(total)
    setModel({ ...Model, demografias: demografias })
  }

  function getConfig(): void {
    getResponsaveis()
    SigahApi.get('api/Onboard/Empresa/Perfil', {
      headers: {
        authorization: ObterToken()
      }
    })
      .then(res => setModel(res.data))
      .catch(err => console.log(err))
  }

  function getResponsaveis(): void {
    appApi.get('Empresa/Configuracao')
      .then(res => setConfig(res.data))
      .catch(err => console.log(err))
  }

  function PutConfig(): void {
    appApi.put('Empresa/Configuracao', Config)
      .catch(err => console.log(err))

    SigahApi.put('api/Onboard/Empresa/Perfil', Model, {
      headers: {
        authorization: ObterToken()
      }
    })
      .then(() => {
        toast({
          title: 'Configuração atualizada com sucesso',
          status: 'success',
          isClosable: false,
          position: 'top-right'
        })
      })
      .catch(err => console.log(err))
  }

  function PutLiberarResultado(): void {
    appApi.patch('Empresa/PesquisaPercepcao/LiberarResultado')
      .then(() => {
        setConfig({ ...Config, liberarResultado: !Config.liberarResultado })
      })
      .catch(err => console.log(err))
  }

  function getColaboradores(): void {
    appApi.get('Colaborador/Select')
      .then(res => setColaboradores(res.data))
      .catch(err => console.log(err))
  }

  function SelecionarResponsavel(id: string, tipo: number): void {
    if (!PropInvalida(id)) {
      setColaboradorId(id)
      setConfig({
        ...Config,
        responsaveis: [...Config.responsaveis, {
          colaboradorId: id,
          tipoResponsabilidade: tipo
        }]
      })
      setColaboradorId(undefined)
    }
  }

  function DeletarResponsavel(index: number): void {
    setConfig({ ...Config, responsaveis: DeleteElementByPosition(Config.responsaveis, index) })
  }

  useEffect(() => {
    getColaboradores()
    getConfig()
  }, [])

  return (
    <Body>
      {
        ModalCropIsOpen && (
          <ModalCrop
            isOpen={ModalCropIsOpen}
            onRequestClose={() => setModalCropIsOpen(false)}
          />
        )
      }

      {
        ModalUpdateIsOpen &&
        <ModalUpdateDemografia
          isOpen={ModalUpdateIsOpen}
          onClose={onCloseEditDemografia}
          demografiaId={Model.demografiaId}
          reload={getConfig}
        />
      }
      <h2>Perfil da empresa</h2>
      <WhiteBox margin='.5rem 0 0 0'>
        <Flex onClick={() => console.log(ColaboradorId)} marginBottom={'1rem'} justifyContent='end'>
          <Button size={'lg'} VarColor='Rosa' onClick={() => nav('/PrimeiroAcesso')}>Primeiro acesso</Button>
        </Flex>
        <Flex alignItems={'center'} flexDir={'column'} width={'100%'}>
          <h2>{Model.nome}</h2>
          <Box borderRadius={'lg'} padding='1rem' border='1px solid var(--c3)' margin={'1rem 0'}>
            <img title={Model.nome} src={Model.logo} />
          </Box>
        </Flex>

        <Flex justifyContent={'center'}>
          <Button VarColor='Azul' onClick={() => setModalCropIsOpen(true)}>Trocar logo</Button>
        </Flex>

        <Flex flexDir={'column'}>
          <h3>Dados gerais</h3>
          <FormControl alignItems={'center'} as={Flex}>
            <FormLabel margin={'0'}>Total de funcionários</FormLabel>
            <Input
              value={Model.totalFuncionarios}
              onChange={(e) => setModel({ ...Model, totalFuncionarios: parseInt(e.target.value) })}
              marginLeft={'.5rem'}
              placeholder='Digite um número' type={'number'} width={'15rem'} borderColor={'var(--Gray4)'}
            />
          </FormControl>

          <FormControl alignItems={'center'} as={Flex} gap='1rem' marginTop={'1rem'}>
            <Checkbox borderColor={'var(--Gray4)'} onChange={PutLiberarResultado} isChecked={Config.liberarResultado} />
            <FormLabel margin={'0'}>Liberar resultados pesquisa percepção/desempenho automaticamente</FormLabel>
          </FormControl>

          <Flex margin={'1rem 0'} alignItems={'center'}>
            <span>Quantidade de funcionários por Área</span>
            <Button onClick={() => setModalUpdateIsOpen(true)} VarColor='Azul' marginLeft={'1rem'}>Editar demografia(grupo de análise)</Button>
          </Flex>

          <Flex gap={'1rem'} flexDir={'column'}>
            <Flex gap={'1rem'} flexWrap='wrap' alignItems={'end'}>
              {
                Model.demografias.map((e, i) => {
                  return (
                    <FormControl maxWidth={'15rem'} key={e.id}>
                      <FormLabel>{e.nome}</FormLabel>
                      <Input
                        type={'number'}
                        onChange={(e) => UpdateFuncionarioDemografia(e.target.value, i)}
                        value={e.quantidade ?? ''} placeholder='Digite um número' borderColor={'var(--Gray4)'}
                      />
                    </FormControl>
                  )
                })
              }
            </Flex>
          </Flex>

          <Flex margin={'1rem 0'} flexDir={'column'}>
            <h3>Responsáveis pelos chamados do RH</h3>
            <Flex marginTop={'.5rem'} gap='1rem' flexDir={'column'}>
              {
                Config.responsaveis.filter(e => e.tipoResponsabilidade === 0).map((item, i) => {
                  return (
                    <InputGroup key={i} width={'100%'}>
                      <InputRightElement
                        cursor={'pointer'}
                        children={<MdClose onClick={() => DeletarResponsavel(i)} color='var(--terc3)' />}
                      />
                      <Input
                        key={i}
                        value={Colaboradores.find(r => r.id === item.colaboradorId)?.nome ?? ''}
                        borderColor={'var(--Gray4)'}
                        placeholder='Digite um email'
                        readOnly
                      />
                    </InputGroup>
                  )
                })
              }

              <FindUser
                lista={Colaboradores.filter(r => !Config.responsaveis.some(t => t.colaboradorId === r.id))}
                onChoice={(e) => SelecionarResponsavel(e, 0)}
                selecionado={ColaboradorId}
                placeholder='Digite o nome de algum colaborador'
              />
            </Flex>
          </Flex>

          <Flex margin={'1rem 0'} flexDir={'column'}>
            <h3>Responsável por pesquisa percepção</h3>
            <Flex marginTop={'.5rem'} gap='1rem' flexDir={'column'}>
              <FindUser
                lista={Colaboradores}
                onChoice={(e) => {
                  if (!PropInvalida(e)) {
                    setConfig({
                      ...Config,
                      responsaveis: [...Config.responsaveis, {
                        colaboradorId: e,
                        tipoResponsabilidade: 1
                      }]
                    })
                  } else {
                    setConfig({ ...Config, responsaveis: DeleteElementByPosition(Config.responsaveis, Config.responsaveis.findIndex(r => r.tipoResponsabilidade === 1)) })
                  }
                }}
                selecionado={Config.responsaveis.find(r => r.tipoResponsabilidade === 1)?.colaboradorId ?? undefined}
                placeholder='Digite o nome de algum colaborador'
              />
            </Flex>
          </Flex>

          <Flex flexDir={'column'} gap={'1rem'}>
            <Flex>
              <Text fontSize={'1.125rem'} fontWeight={'500'} width={'7rem'}>Email</Text>
              <Input
                onChange={(e) => setModel({ ...Model, email: e.target.value })}
                marginLeft={'1rem'} value={Model.email} borderColor={'var(--Gray4)'}
              />
            </Flex>
            <Flex>
              <Text fontSize={'1.125rem'} fontWeight={'500'} width={'7rem'}>Telefone</Text>
              <Input
                onChange={(e) => setModel({ ...Model, telefone: e.target.value })}
                marginLeft={'1rem'} value={Model.telefone} borderColor={'var(--Gray4)'}
              />
            </Flex>
            <Flex>
              <Text fontSize={'1.125rem'} fontWeight={'500'} width={'7rem'}>Seguimento</Text>
              <Input
                onChange={(e) => setModel({ ...Model, seguimento: e.target.value })}
                marginLeft={'1rem'} value={Model.seguimento} borderColor={'var(--Gray4)'}
              />
            </Flex>
          </Flex>
        </Flex>
        <Flex justifyContent={'end'} marginTop={'1rem'}>
          <Button
            leftIcon={<FaSave />}
            onClick={PutConfig}
            VarColor='Green1'
            width={'fit-content'}
            size={'lg'}
          >Salvar alterações</Button>
        </Flex>
      </WhiteBox>
    </Body>
  )
}
