/* eslint-disable @typescript-eslint/no-unused-vars */
import { Flex } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { Button } from '../../../../../../../../components/Button'
import { iColaboradorSquad, ISquad } from '../../../../../../../../interfaces'
import { appApi } from '../../../../../../../../services/appApi'
import { ModalSquad } from '../ModalSquad'
import { SquadCard } from '../SquadCard'

interface iSquadContainerProps {
  squad: ISquad
  reload: () => void
}

interface iAssociacao {
  tagId: string
  cor: string
  gestor: boolean
  nome: string
}

interface iPostColaboradorSquad {
  squadId: string
  papelId: string
}

export const SquadContainer: React.FC<iSquadContainerProps> = ({ squad, reload }) => {
  const [ModalIsOpen, setModalIsOpen] = useState(false)

  const [InternalArray, setInternalArray] = useState<iAssociacao[]>([])

  const cores = ['#1961E0', '#CC42A2', '#6FCF97', '#56CCF2', '#F2C94C', '#BB6BD9', '#27AE60', '#2D9CDB', '#F2994A', '#9B51E0']

  function DefinirCores(): void {
    const arr: iAssociacao[] = []
    let pos = 1
    squad.colaboradores.forEach((e) => {
      const tag = e.hierarquiaSquads.find(e => e.tagsPaiId?.find(r => r === squad.id) !== undefined)
      if (tag !== undefined) {
        const buscado = arr.find(r => r.tagId === tag.id)
        if (buscado === undefined) {
          arr.push({
            cor: tag.gestor ? cores[0] : cores[pos],
            tagId: tag.id,
            gestor: tag.gestor,
            nome: tag.texto
          })
          pos++
        }
      }
    })

    arr.sort((a, b) => {
      return a.nome.toLowerCase() > b.nome.toLowerCase() ? 1 : -1
    })

    setInternalArray(arr)

    const colaboradores: iColaboradorSquad[] = []

    arr.forEach((e) => {
      const colabs = squad.colaboradores.filter(r => r.hierarquiaSquads.find(t => t.id === e.tagId) !== undefined)
      if (colabs.length > 0 && e.gestor) {
        colabs.forEach((c) => {
          colaboradores.push(c)
        })
      }
    })

    arr.forEach((e) => {
      const colabs = squad.colaboradores.filter(r => r.hierarquiaSquads.find(t => t.id === e.tagId) !== undefined)
      if (colabs.length > 0 && !e.gestor) {
        colabs.forEach((c) => {
          colaboradores.push(c)
        })
      }
    })

    const naoCadastrado = squad.colaboradores.filter(e => colaboradores.find(r => r.id === e.id) === undefined)
    naoCadastrado.forEach((e) => {
      colaboradores.push(e)
    })

    squad.colaboradores = colaboradores
  }

  function AdicionarColaboradorNoSquad(colaboradorId: string, model: iPostColaboradorSquad): void {
    appApi.post(`Colaborador/${colaboradorId}/Squad`, model)
      .then(() => {
        setModalIsOpen(false)
        reload()
      })
      .catch(err => console.log(err))
  }

  useEffect(() => {
    DefinirCores()
  }, [squad])

  return (
    <Flex flexDir={'column'} borderRadius={'1rem'} padding={'1rem'} borderStyle={'dashed'} border={'2px dashed var(--Azul)'}>
      {
        (ModalIsOpen) && (
          <ModalSquad
            isOpen={ModalIsOpen}
            onConfirm={AdicionarColaboradorNoSquad}
            onRequestClose={() => setModalIsOpen(false)}
            squad={squad}
          />
        )
      }
      <Flex justifyContent={'space-between'} marginBottom={'1rem'}>
        <h3>{squad.nome}</h3>
        <Button onClick={() => setModalIsOpen(true)} VarColor='Azul'>Adicionar</Button>
      </Flex>
      <Flex gap={'1rem'} flexWrap={'wrap'}>
        {
          squad.colaboradores.map((e, i) => {
            const tag = e.hierarquiaSquads.find(e => e.tagsPaiId?.find(r => r === squad.id) !== undefined)
            return (
              <SquadCard cor={InternalArray.find(c => c.tagId === tag?.id)?.cor ?? ''} nomeTag={tag?.texto ?? ''} colaborador={e} key={i} />
            )
          })
        }
      </Flex>
    </Flex>
  )
}
