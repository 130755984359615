import styled from 'styled-components'
import { Column } from '../../../styles/styledGlobal'

export const ChartTitle = styled(Column)`
width: 100%;
`

export const TableTurnOver = styled.table`
width: 100%;
&,th, td {
  border: 0.063rem solid var(--c6);
  border-collapse: collapse;
  font-family: 'Roboto','sans-serif';
}

th:first-child{
    min-width: 183px;
    width:183px;
}

th,td{
    padding: .5rem 1rem .5rem 1rem;
}
`
