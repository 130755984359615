import { Checkbox, Flex, FormControl, Input, Radio, RadioGroup, Stack, Text, useToast } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Body } from '../../../Layouts/Body'
import { TimeLineOnboard } from '../../../../components/TimeLineOnboard'
import { WhiteBox } from '../../../../components/WhiteBox'
import { iEtapa, iReuniaoInput } from '../../../../interfaces'
import { appApi } from '../../../../services/appApi'
import { SubtitleDiv, Main, LabelTitle } from './styles'
import { CreateEtapaBox } from '../../../../components/CreateEtapaBox'
import { ValidarEtapa } from '../../../../Utils/Helper'
import { Button } from '../../../../components/Button'
import { EditorTexto } from '../../../../components/EditorTexto/Index'

type LocationProps = {
  trilhaId: string
  etapaId?: string
  processoId?: string
}

interface iEtapaReuniao {
  etapa: iEtapa
  reuniao: iReuniaoInput
}

export const Reuniao: React.FC = () => {
  const toast = useToast()
  const navigate = useNavigate()
  const { trilhaId, etapaId, processoId } = useParams<LocationProps>()

  const [Model, setModel] = useState<iReuniaoInput>({
    responsavel: 0,
    tipo: 0,
    titulo: '',
    participantes: []
  })

  const [Salvar, setSalvar] = useState(false)
  const [EditEtapa, setEditEtapa] = useState<iEtapa>()

  const isCheck = (value: string): boolean => Model.participantes.some(r => r === parseInt(value))

  const handleCheckboxParticipanteClick = (value: string): void => {
    if (value !== '6') {
      if (Model.participantes.some(e => e.toString() === value)) {
        const participantes = [...Model.participantes].filter(e => e.toString() !== value)
        setModel({ ...Model, participantes: participantes })
      } else {
        const participantes = [...Model.participantes]
        participantes.push(parseInt(value))
        setModel({ ...Model, participantes: participantes })
      }
    } else {
      if (!Model.participantes.some(e => e === 6)) {
        setModel({ ...Model, participantes: [0, 1, 2, 3, 4, 5, 6] })
      } else {
        setModel({ ...Model, participantes: [] })
      }
    }
  }

  function AdicionarReuniao(param: iEtapa): void {
    if (!ValidarEtapa(param)) {
      toast({
        title: 'Preencha os campos de etapa',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return
    }
    const url = trilhaId ? `ReuniaoEtapa/${trilhaId}` : processoId ? `ReuniaoEtapaColaborador/${processoId}` : ''
    appApi.post(`${url}/`, { reuniao: Model, etapa: param }).then(() => {
      toast({
        title: 'Reunião cadastrada com sucesso',
        status: 'success',
        isClosable: false
      })
      navigate(-1)
    }).catch(() => {
      toast({
        title: 'Erro ao cadastrar reunião',
        status: 'error',
        isClosable: false
      })
    })
  }

  function ObterReuniao(): void {
    const url = trilhaId ? `ReuniaoEtapa/${etapaId ?? ''}` : processoId ? `ReuniaoEtapaColaborador/${etapaId ?? ''}/ProcessoOnboard/${processoId}` : ''
    appApi.get(url).then(response => {
      const data: iEtapaReuniao = response.data
      setModel(data.reuniao)
      setEditEtapa(data.etapa)
    }).catch(error => console.log(error))
  }

  function AtualizarEtapa(param: iEtapa): void {
    if (!ValidarEtapa(param)) {
      toast({
        title: 'Preencha os campos de etapa',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return
    }

    const url = trilhaId
      ? `ReuniaoEtapa/${etapaId ?? ''}`
      : processoId
        ? `ReuniaoEtapaColaborador/${etapaId ?? ''}/ProcessoOnboard/${processoId}`
        : ''
    appApi.put(`${url}/`, { reuniao: Model, etapa: param }).then(() => {
      toast({
        title: 'Reunião atualizada com sucesso',
        status: 'success',
        isClosable: false
      })
      navigate(-1)
    }).catch(() => {
      toast({
        title: 'Erro ao atualizar reunião',
        status: 'error',
        isClosable: false
      })
    })
  }

  function ContinuarConfiguracao(): void {
    if (Model.titulo.length < 5) {
      toast({
        title: 'O assunto da reunião deve conter no mínimo 5 caracteres',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return
    }

    if (Model.participantes.length <= 0) {
      toast({
        title: 'A reunião deve conter no mínimo 1 participante',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return
    }

    setSalvar(true)
  }

  useEffect(() => {
    if (etapaId !== undefined) {
      ObterReuniao()
    }
  }, [etapaId])

  return (
    <Body>
      <Main>
        <TimeLineOnboard hide={processoId !== undefined} />
        {
          !Salvar
            ? <>
              <h1>Reunião</h1>
              <WhiteBox>
                <FormControl marginBottom={'2rem'}>
                  <LabelTitle>Assunto da reunião</LabelTitle>
                  <Input
                    borderColor={'var(--Gray4)'}
                    value={Model.titulo}
                    onChange={(e) => { setModel({ ...Model, titulo: e.target.value }) }}
                    placeholder='Assunto da reunião'
                    fontWeight='500'
                  />
                </FormControl>

                <Flex flexDir={'column'} marginBottom={'2rem'}>
                  <SubtitleDiv>
                    <LabelTitle>Responsável pela reunião</LabelTitle>
                    <span>Essa pessoa definirá a data e poderá alterar os participantes</span>
                  </SubtitleDiv>

                  <Flex flexDir={'column'}>
                    <RadioGroup
                      onChange={(e) => setModel({ ...Model, responsavel: parseInt(e) })}
                      value={Model.responsavel.toString()}
                    >
                      <Stack direction='row'>
                        <Radio value='0'>Rh</Radio>
                        <Radio value='1'>Gestor</Radio>
                        <Radio value='2'>Outros</Radio>
                      </Stack>
                    </RadioGroup>
                    {
                      (Model.responsavel === 2) && (
                        <Input
                          marginTop={'.5rem'}
                          borderColor={'var(--Gray4)'}
                          value={Model.emailResponsavel}
                          type={'text'}
                          width={'20rem'}
                          onChange={(e) => setModel({ ...Model, emailResponsavel: e.target.value })}
                          placeholder='Digite o email do responsável'
                        />
                      )
                    }
                  </Flex>
                </Flex>

                <Flex flexDir={'column'} gap='.5rem' marginBottom={'2rem'}>
                  <LabelTitle>Participantes</LabelTitle>
                  <Stack direction={'row'}>
                    <Checkbox
                      borderColor={'var(--Gray4)'}
                      isChecked={isCheck('1')}
                      onChange={(e) => handleCheckboxParticipanteClick(e.target.value)}
                      value='1'>Colaborador
                    </Checkbox>

                    <Checkbox
                      isChecked={isCheck('0')}
                      borderColor={'var(--Gray4)'}
                      onChange={(e) => handleCheckboxParticipanteClick(e.target.value)}
                      value='0'
                    >RH</Checkbox>

                    <Checkbox
                      borderColor={'var(--Gray4)'}
                      isChecked={isCheck('2')}
                      onChange={(e) => handleCheckboxParticipanteClick(e.target.value)}
                      value='2'>Líder</Checkbox>

                    <Checkbox
                      isChecked={isCheck('3')}
                      borderColor={'var(--Gray4)'}
                      onChange={(e) => handleCheckboxParticipanteClick(e.target.value)}
                      value='3'>Pares</Checkbox>

                    <Checkbox
                      isChecked={isCheck('4')}
                      borderColor={'var(--Gray4)'}
                      onChange={(e) => handleCheckboxParticipanteClick(e.target.value)}
                      value='4'>Subordinados</Checkbox>

                    <Checkbox
                      isChecked={isCheck('5')}
                      borderColor={'var(--Gray4)'}
                      onChange={(e) => handleCheckboxParticipanteClick(e.target.value)}
                      value='5'>Alta liderança</Checkbox>

                    <Checkbox
                      isChecked={isCheck('6')}
                      borderColor={'var(--Gray4)'}
                      onChange={(e) => handleCheckboxParticipanteClick(e.target.value)}
                      value='6'>Todos</Checkbox>
                  </Stack>
                  <Text fontSize={'sm'} fontWeight={500}>O responsável poderá alterar os participantes</Text>
                </Flex>

                <Flex flexDir={'column'} marginBottom={'2rem'}>
                  <LabelTitle>Local</LabelTitle>
                  <RadioGroup
                    onChange={(e) => setModel({ ...Model, tipo: parseInt(e) })}
                    value={Model.tipo.toString()}
                  >
                    <Stack direction='row'>
                      <Radio value='0'>Online</Radio>
                      <Radio value='1'>Presencial</Radio>
                      <Radio value='2'>Definido pelo responsável pela reunião</Radio>
                    </Stack>

                    {
                      (Model.tipo === 0 || Model.tipo === 1) && (
                        <Input
                          marginTop={'.5rem'}
                          borderColor={'var(--Gray4)'}
                          value={Model.local ?? ''}
                          type={'text'}
                          width={'30rem'}
                          placeholder='Digite o local em que a reunião vai acontecer'
                          onChange={(e) => setModel({ ...Model, local: e.target.value })}
                        />
                      )
                    }
                  </RadioGroup>
                </Flex>

                <div>
                  <SubtitleDiv>
                    <LabelTitle>Descrição</LabelTitle>
                    <span>Digite um texto com informações importantes ou pauta que deve ser enviado para os participantes</span>
                  </SubtitleDiv>
                  <EditorTexto placeholder='Descreva a pauta da sua reunião' initialValue='' onChange={(e: any) => { setModel({ ...Model, descricao: e }) }} />
                </div>
              </WhiteBox>
              <Flex justifyContent={'end'} marginTop={'1rem'} gap={'1rem'}>
                <Button
                  size='lg'
                  VarColor='c6'
                  onClick={() => navigate(-1)}
                >Voltar</Button>
                {
                  !etapaId
                    ? <Button size='lg' VarColor='v2' onClick={ContinuarConfiguracao}>Salvar e continuar</Button>
                    : <Button size='lg' VarColor='v2' onClick={ContinuarConfiguracao}>Continuar</Button>
                }
              </Flex>
            </>
            : <>
              {
                EditEtapa === undefined
                  ? <CreateEtapaBox
                    keyWord='reunião'
                    onGreenAction={AdicionarReuniao}
                    onGreyAction={() => setSalvar(false)}
                    EditEtapa={EditEtapa}
                  />
                  : <CreateEtapaBox
                    keyWord='reunião'
                    onGreenAction={AtualizarEtapa}
                    onGreyAction={() => setSalvar(false)}
                    EditEtapa={EditEtapa}
                    ignoreId={etapaId}
                  />
              }
            </>
        }
      </Main>
    </Body>
  )
}
