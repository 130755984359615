import { Flex, Input } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { FaEdit, FaEllipsisV, FaEye, FaTrash } from 'react-icons/fa'
import { GreenButton } from '../../../../../components/Buttons/GreenButton'
import { MetaButton } from '../../../../../components/Buttons/MetaButton'
import { OutlineMetaButton } from '../../../../../components/Buttons/OutlineMetaButton'
import { useFormulario } from '../../../../../contexts/FormularioContext'
import { iCompetencia, iModalPadrao } from '../../../../../interfaces'
import { DropMenu, Menu } from '../CardCompetencia/styles'
import { ModalCriarCompetencia } from '../ModalCriarCompetencia'
import { ModalPreVizualizarQuestao } from '../ModalPreVizualizarQuestoes'
import { ModalBody, ModalFooter, ImgVariant } from './styles'
import { ModalBase } from '../../../../../components/ModalBase'
import { SelectFormControl } from '../../../../../components/SelectFormControl'

export const ModalTodasCompetencias: React.FC<iModalPadrao> = ({ OnCloseModal, isOpen }) => {
  const { SelectCompetencia, DeletarCompetencia, ctxFormulario, ctxCompetencias } = useFormulario()

  const [TxtPesquisa, setTxtPesquisa] = useState('')
  const [CompetenciaId, setCompetenciaId] = useState('')
  const [Competencias, setCompetencias] = useState<iCompetencia[]>([])

  const [CompetenciaEditId, setCompetenciaEditId] = useState<string>()

  const [PreviewisOpen, setPreviewisOpen] = useState(false)
  const [EditisOpen, setEditisOpen] = useState(false)

  function orderByName(isDesc: boolean): void {
    const arr = [...Competencias]
    arr.sort((a, b) => {
      return a.nome.localeCompare(b.nome, 'pt-BR')
    })
    if (isDesc) arr.reverse()
    setCompetencias(arr)
  }

  useEffect(() => {
    setCompetencias(ctxCompetencias)
  }, [ctxCompetencias])

  return (
    <ModalBase
      isOpen={isOpen as boolean}
      Titulo='Competências'
      onClose={OnCloseModal}
      Width='75rem'
    >
      <ModalPreVizualizarQuestao
        isOpen={PreviewisOpen}
        CompetenciaId={CompetenciaId}
        OnCloseModal={() => setPreviewisOpen(false)}
      />

      <ModalCriarCompetencia
        OnCloseModal={() => { setEditisOpen(false); setCompetenciaEditId(undefined) }}
        isOpen={EditisOpen}
        CompetenciaId={CompetenciaEditId}
      />

      <Flex onClick={() => console.log(ctxCompetencias)} justifyContent={'space-between'} alignItems='end' margin={'1rem'}>
        <SelectFormControl
          width={'18rem'}
          label='Ordenar por'
          onChange={(e) => {
            if (e.target.value === '0') {
              orderByName(false)
            } else if (e.target.value === '1') {
              orderByName(true)
            }
          }}
        >
          <option value={'0'}>A-Z</option>
          <option value={'1'}>Z-A</option>
        </SelectFormControl>

        <Flex>
          <Input
            position={'inherit'}
            onChange={(e) => setTxtPesquisa(e.target.value)}
            borderColor={'var(--Gray4)'}
            type='text'
            placeholder='Buscar Competências'
            bg={'white'}
            width={'17rem'}
          />
        </Flex>
      </Flex>

      <ModalBody>
        <section>
          {
            Competencias.filter(e => !e.discursiva).filter((e: iCompetencia) => {
              if (TxtPesquisa === '') {
                return e
              } else if (e.nome.toLowerCase().includes(TxtPesquisa.toLowerCase())) {
                return e
              }
              return null
            }).map((e: iCompetencia, i) => {
              return (
                <Flex key={i} className='VariantColor' justifyContent={'space-between'}>
                  <Flex>
                    <ImgVariant borderColor={e.doSistema ? 'a4' : 'a1'} src={e.icone} />
                    <Flex flexDirection={'column'}>
                      <h4>{e.nome}</h4>
                      <small>{e.doSistema ? 'Padrão' : e.dataCriacao}</small>
                    </Flex>
                  </Flex>

                  <Flex alignItems={'center'} width={'24.75rem'}>
                    <MetaButton
                      bg='vg3'
                      onClick={() => { setCompetenciaId(e.id); setPreviewisOpen(true) }}
                      size='sm'><FaEye className='IconLeft'
                      />Pré-visualizar questões</MetaButton>
                    {
                      ctxFormulario.selecoes.find(r => r.competenciaId === e.id) === undefined
                        ? <GreenButton OnClick={() => SelectCompetencia(e.id)} Size='sm' isNav={false}>Adicionar</GreenButton>
                        : <OutlineMetaButton color='v1' size='sm'>Adicionado</OutlineMetaButton>
                    }
                    {
                      (!e.doSistema) && (
                        <Flex position={'relative'} justifyContent={'end'}>
                          <Menu>
                            <FaEllipsisV color='var(--a3)' />
                            <Flex width={'6rem'}>
                              <DropMenu>
                                <li onClick={() => {
                                  setEditisOpen(true)
                                  setCompetenciaEditId(e.id)
                                }} style={{ color: 'var(--terc10)' }}><FaEdit /> Editar</li>
                                <li onClick={(() => { DeletarCompetencia(e.id) })} style={{ color: 'var(--terc3)' }}><FaTrash /> Excluir</li>
                              </DropMenu>
                            </Flex>
                          </Menu>
                        </Flex>
                      )
                    }
                  </Flex>
                </Flex>
              )
            })
          }
        </section>
      </ModalBody>
      <ModalFooter>
        <GreenButton Size='md' isNav={false} OnClick={() => OnCloseModal()}>Continuar</GreenButton>
      </ModalFooter>
    </ModalBase>
  )
}
