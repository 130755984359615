import {
  BoxIntegracao, BoxHeader, BoxBody
}
  from './styles'
import { FaCheck, FaEye, FaMinusCircle, FaPen, FaPlus } from 'react-icons/fa'
import { Alert, AlertIcon, Checkbox, Flex, FormControl, FormLabel, Input, InputGroup, InputRightElement } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { iItemChecklist, iItemIntegracao } from '../../../../../../interfaces'
import { useState, useEffect } from 'react'
import { Button } from '../../../../../../components/Button'

interface iCardProps {
  position: number
  id: string
  ItemIntegracao: iItemIntegracao
  onDelete: (id: string) => void
  onUpdateInput: (item: iItemIntegracao) => void
}

export const CardChecklist: React.FC<iCardProps> = ({ ItemIntegracao, id, position, onDelete, onUpdateInput }) => {
  const nav = useNavigate()
  const [ItensChek, setItemCheck] = useState<iItemChecklist[]>([])
  const [EditDescription, setEditDescription] = useState(false)
  const [Titulo, setTitulo] = useState('')
  const [Descricao, setDescricao] = useState('Marque apenas o que foi feito ou realizado até o momento, caso queira, você pode adicionar uma observação')

  function AddNewItem(): void {
    const copy = [...ItensChek]
    copy.push({ check: false, texto: '' })
    setItemCheck(copy)
    onUpdateInput({
      id: ItemIntegracao.id,
      tipo: ItemIntegracao.tipo,
      titulo: ItemIntegracao.titulo,
      descricao: ItemIntegracao.descricao,
      checklist: copy
    })
  }

  function RemoveItemArray(index: number): void {
    const array = [...ItensChek].filter((e: iItemChecklist, i) => {
      if (i !== index) {
        return e
      }
      return null
    })
    setItemCheck(array)
    onUpdateInput({
      id: ItemIntegracao.id,
      tipo: ItemIntegracao.tipo,
      titulo: ItemIntegracao.titulo,
      descricao: ItemIntegracao.descricao,
      checklist: array
    })
  }

  function UpdateTextoItemArray(index: number, Texto: string): void {
    const array = [...ItensChek]
    array[index].texto = Texto
    setItemCheck(array)
  }

  useEffect(() => {
    if (ItemIntegracao.descricao !== undefined) {
      setDescricao(ItemIntegracao.descricao)
    }
    setTitulo(ItemIntegracao.titulo)

    if (ItemIntegracao.checklist !== undefined) {
      setItemCheck(ItemIntegracao.checklist)
    }
  }, [])

  return (
    <BoxIntegracao>
      <BoxHeader>
        <span>{position}. Check list</span>
        <FaMinusCircle onClick={() => onDelete(ItemIntegracao.id)} />
      </BoxHeader>

      <BoxBody>
        <FormControl marginBottom={'1rem'}>
          <FormLabel fontSize={'xl'}>Título</FormLabel>
          <Input
            borderColor={'var(--Gray4)'}
            value={Titulo}
            onChange={(e) => { setTitulo(e.target.value) }}
            onBlur={() => onUpdateInput({
              id: ItemIntegracao.id,
              tipo: ItemIntegracao.tipo,
              titulo: Titulo,
              descricao: Descricao,
              checklist: ItensChek
            })}
            type="text" placeholder='Digite o titulo'
          />
        </FormControl>

        <FormControl fontSize={'xl'} marginBottom={'1rem'}>
          <FormLabel fontSize={'xl'}>Caso queira, edite a instrução para o preenchimento do check list pelo colaborador</FormLabel>

          <InputGroup width={'100%'}>
            {
              EditDescription
                ? <InputRightElement
                  children={<FaCheck onClick={() => setEditDescription(false)} color='var(--v2)' />}
                />
                : <InputRightElement
                  children={<FaPen onClick={() => setEditDescription(true)} color='var(--terc2)' />}
                />
            }

            <Input
              value={Descricao}
              onChange={(e) => { setDescricao(e.target.value) }}
              onBlur={() => onUpdateInput({
                id: ItemIntegracao.id,
                tipo: ItemIntegracao.tipo,
                titulo: Titulo,
                descricao: Descricao,
                checklist: ItensChek
              })}
              borderColor={'var(--Gray4)'}
              type='text'
              readOnly={!EditDescription}

            />
          </InputGroup>
        </FormControl>

        <FormLabel fontSize={'xl'}>Coloque abaixo os itens que são entregáveis a um colaborador em onboarding</FormLabel>
        <Flex gap={'1rem'} flexDirection={'column'}>
          {
            ItensChek?.map((e: iItemChecklist, i) => {
              return (
                <Flex key={i}>
                  <Checkbox size={'lg'} marginRight={'1rem'} borderColor={'var(--Gray4)'} />
                  <InputGroup width={'100%'}>
                    <InputRightElement
                      children={<FaMinusCircle cursor={'pointer'} onClick={() => RemoveItemArray(i)} color='var(--terc3)' />}
                    />

                    <Input
                      value={e.texto}
                      onBlur={(r) => onUpdateInput({
                        id: ItemIntegracao.id,
                        tipo: ItemIntegracao.tipo,
                        titulo: Titulo,
                        descricao: Descricao,
                        checklist: ItensChek
                      })}
                      onChange={(r) => { UpdateTextoItemArray(i, r.target.value) }}
                      borderColor={'var(--Gray4)'}
                      type='text'
                      placeholder='Digite o nome'
                    />
                  </InputGroup>
                </Flex>
              )
            })
          }
          <Button width={'fit-content'} VarColor='v2' onClick={AddNewItem} leftIcon={<FaPlus />}>Adicionar checkbox</Button>
        </Flex>

        <Alert status='info' fontFamily={'Roboto'} borderRadius={'0.5rem'} margin={'1.5rem 0'} fontWeight={500} color={'var(--a3)'}>
          <AlertIcon />
          Se o checkbox não for selecionado você receberá uma notificação por e-mail com um aviso sobre a  a informação sem o check do colaborador
        </Alert>
      </BoxBody>
      <Flex justifyContent={'end'}>
        <Button
          leftIcon={<FaEye />}
          fontWeight={'500'}
          borderRadius={'12px'}
          VarColor={'Rosa'}
          onClick={() => nav(`Item/${ItemIntegracao.id}`)}
        >Pré-visualizar</Button>
      </Flex>
    </BoxIntegracao>
  )
}
