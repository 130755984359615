import { Flex } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { FaAngleRight, FaEye } from 'react-icons/fa'
import { MdCollections, MdOutlineVideoLibrary, MdPictureAsPdf } from 'react-icons/md'
import { useNavigate, useParams } from 'react-router-dom'
import { Button } from '../../../components/Button'
import { WhiteBox } from '../../../components/WhiteBox'
import { iItemIntegracao } from '../../../interfaces'
import { appApi } from '../../../services/appApi'
import { Body } from '../../Layouts/Body'
import { ButtonOption } from './Components/ButtonOption'
import { CardImagem } from './Components/CardImagem'
import { CardPdf } from './Components/CardPdf'
import { CardVideo } from './Components/CardVideo'
import { Paisagemimg } from './Components/Svg/Paisagemimg'
import { Pdfimg } from './Components/Svg/Pdfimg'
import { Videoimg } from './Components/Svg/Videoimg'

type iParam = {
  imersaoId: string
}

export const CriarImersao: React.FC = () => {
  const nav = useNavigate()
  const param = useParams<iParam>()
  const [Itens, setItens] = useState<iItemIntegracao[]>([])

  function getItens(): void {
    appApi.get(`ImersaoExterna/${param.imersaoId as string}/Item`)
      .then(res => setItens(res.data))
      .catch(err => console.log(err))
  }

  function onUpdateInput(item: iItemIntegracao): void {
    const idItem: string = item.id
    const form: Omit<iItemIntegracao, 'id' | 'checklist'> = {
      titulo: item.titulo,
      descricao: item.descricao,
      tipo: item.tipo
    }

    appApi.put(`ImersaoExterna/${param.imersaoId as string}/Item/${idItem}`, form)
      .catch(e => console.log(e))
  }

  function DeletarItem(id: string): void {
    appApi.delete(`Imersao/${param.imersaoId as string}/Item/${id}`)
      .then(response => {
        if (response.status === 200) {
          const imersoes = [...Itens.filter(e => e.id !== id)]
          setItens([])
          setItens(imersoes)
        }
      }).catch(err => console.error(err))
  }

  function CreateItem(Tipo: number): void {
    appApi.post(`Imersao/${param.imersaoId as string}/Item`, {
      titulo: '',
      descricao: '',
      tipo: Tipo
    }).then(getItens).catch(err => console.error(err))
  }

  useEffect(() => {
    getItens()
  }, [])

  return (
    <Body>
      <WhiteBox>
        <h1>Jornada {'>'} Imersão cultural</h1>
        <Flex flexDir={'column'} margin={'2rem 0rem'}>
          <h3 style={{ color: 'var(--a3)' }}>Imersão cultural</h3>
          <span style={{ color: 'var(--a3)', fontWeight: '400' }}>Adicione vídeos, fotos, apresentações ou
            artigos para apresentar aos colaboradores.
            Você também pode configurar um evento para aprofundar a
            cultura da sua organização com seus colaboradores.
          </span>
        </Flex>
        <Flex display={'grid'} gap={'1rem 0rem'}>
          {
            Itens.map((e, i) => {
              if (e.tipo === 1) {
                return (
                  <CardVideo
                    key={e.id}
                    position={i + 1}
                    id={param.imersaoId as string}
                    ItemIntegracao={e}
                    onDelete={DeletarItem}
                    onUpdateInput={onUpdateInput}
                  />
                )
              } else if (e.tipo === 2) {
                return (
                  <CardImagem
                    key={e.id}
                    position={i + 1}
                    id={param.imersaoId as string}
                    ItemIntegracao={e}
                    onDelete={DeletarItem}
                    onUpdateInput={onUpdateInput}
                  />
                )
              } else if (e.tipo === 3) {
                return (
                  <CardPdf
                    key={e.id}
                    position={i + 1}
                    id={param.imersaoId as string}
                    ItemIntegracao={e}
                    onDelete={DeletarItem}
                    onUpdateInput={onUpdateInput}
                  />
                )
              }
              return null
            })
          }
        </Flex>

        <Flex flexDirection={'column'}>
          <h3 style={{ color: 'var(--a3)' }}>Adicione mais arquivos</h3>
          <Flex gap={'1rem 3rem'} flexWrap={'wrap'} marginTop={'.5rem'} borderRadius={'1rem'} padding={'2rem'} border={'3px solid var(--c4)'} justifyContent={'center'}>
            <ButtonOption
              onClick={() => CreateItem(1)}
              Icon={<Videoimg />}
              HoverIcon={<MdOutlineVideoLibrary color='white' size={60} />}
            >Adicionar um vídeo</ButtonOption>
            <ButtonOption
              onClick={() => CreateItem(2)}
              Icon={<Paisagemimg />}
              HoverIcon={<MdCollections color='white' size={60} />}
            >Adicionar uma imagem e texto</ButtonOption>
            <ButtonOption
              onClick={() => CreateItem(3)}
              Icon={<Pdfimg />}
              HoverIcon={<MdPictureAsPdf color='white' size={60} />}
            >Adicionar um arquivo PDF</ButtonOption>
          </Flex>
        </Flex>
        <Flex gap={'0rem 2rem'} marginTop='1rem' justifyContent={'end'}>
          <Button
            VarColor='c6'
            size={'lg'}
            fontWeight={'500'}
            onClick={() => nav(-1)}
          >
            Voltar
          </Button>
          <Button
            VarColor='Rosa'
            size={'lg'}
            leftIcon={<FaEye />}
            fontWeight={'500'}
            onClick={() => nav('Previsualizar')}
          >
            Pré-visualização completa da imersão cultural
          </Button>
          <Button
            VarColor='Green2'
            size={'lg'}
            rightIcon={<FaAngleRight />}
            fontWeight={'500'}
            onClick={() => nav('Convites')}
          >
            Iniciar imersão
          </Button>
        </Flex>
      </WhiteBox>
    </Body>
  )
}
