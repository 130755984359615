/* eslint-disable @typescript-eslint/restrict-template-expressions */

import { Flex, Tooltip } from '@chakra-ui/react'
import { CircleDiv, CircleCard, Container, CardDiv, BackgroundCard, BodyCard, FlexElipse, EditMenu } from './styles'
import { CardReuniao } from '../CardReuniao'
import { CardIntegracao } from '../CardIntegracao'
import { CardPesquisa } from '../CardPesquisa'
import { CardPesquisaStackholder } from '../CardPesquisaStackholder'
import { CardImersao } from '../CardImersao'
import { CardPrimeiroAcesso } from '../CardPrimeiroAcesso'
import { iCardEtapa, iInteracaoEtapa } from '../../../../../interfaces'
import { FaAngleDown, FaAngleUp, FaPen, FaTrash } from 'react-icons/fa'
import { useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { ObterCorEtapaPeloTipo, ObterCorFundoEtapaPeloTipo, PropInvalida } from '../../../../../Utils/Helper'
import { CardEnps } from '../CardEnps'
import { parseJwt } from '../../../../../services/token'
import { CardReuniaoOne } from '../CardReuniaoOne'
import { CardPDI } from '../CardPDI'

interface iCardOboard {
  etapa: iCardEtapa
  EtapaAnterior?: string
  isLast: boolean
  onDeleteEtapa?: () => void
  trilhaPadrao?: boolean
}

export const CardOnboard: React.FC<iCardOboard> = ({ etapa, EtapaAnterior, isLast, trilhaPadrao, onDeleteEtapa }) => {
  const { trilhaId } = useParams<{ trilhaId: string }>()

  const [isOpen, setisOpen] = useState(false)

  function ObterComponentePeloTipoEtapa(key: number): any {
    if (PropInvalida(etapa.interacoes)) return ''

    if (etapa.interacoes[0].tipo === 0) {
      return (
        <CardPrimeiroAcesso key={key} />
      )
    } else if (etapa.interacoes[0].tipo === 1) {
      return (
        <CardIntegracao key={key} IntegracaoId={etapa.interacoes[0].idExterno} />
      )
    } else if (etapa.interacoes[0].tipo === 2) {
      return (
        <CardReuniao key={key} ReuniaoId={etapa.interacoes[0].idExterno} />
      )
    } else if (etapa.interacoes[0].tipo === 3) {
      return (
        <CardImersao key={key} ImersaoId={etapa.interacoes[0].idExterno} />
      )
    } else if (etapa.interacoes[0].tipo === 4) {
      return (
        <CardPesquisa
          Quando={etapa.tempoInicio}
          key={key} pesquisaId={etapa.interacoes[0].idExterno}
          EtapaAnterior={EtapaAnterior}
        />
      )
    } else if (etapa.interacoes[0].tipo === 5) {
      return (
        <CardPesquisaStackholder key={key}
          pesquisaId={etapa.interacoes[0].idExterno}
          trilhaId={trilhaId ?? ''}
        />
      )
    } else if (etapa.interacoes[0].tipo === 6 && (parseJwt().adm === 'True' || parseJwt().role !== '0')) {
      return (
        <CardEnps pesquisaId={etapa.interacoes[0].idExterno} key={key}
        />
      )
    } else if (etapa.interacoes[0].tipo === 7) {
      return (
        <CardReuniaoOne
          key={key}
          reuniaoId={etapa.interacoes[0].idExterno}
        />
      )
    } else if (etapa.interacoes[0].tipo === 9) {
      return (
        <CardPDI key={key} etapaId={etapa.id}/>
      )
    }
  }

  function ObterRedirectPeloTipo(): string {
    if (PropInvalida(etapa.interacoes)) return ''
    const rota = '/Jornada/Configuracao/Trilha'
    if (etapa.tipo === 0) {
      return '/PrimeiroAcesso'
    } else if (etapa.tipo === 1) {
      return `${rota}/${trilhaId ?? ''}/Integracao/${etapa.interacoes[0].idExterno}/Editar/${etapa.id}`
    } else if (etapa.tipo === 2) {
      return `${rota}/${trilhaId ?? ''}/Reuniao/Editar/${etapa.id}`
    } else if (etapa.tipo === 3) {
      return `${rota}/${trilhaId ?? ''}/Imersao/${etapa.interacoes[0].idExterno}/Editar/${etapa.id}`
    } else if (etapa.tipo === 4) {
      return `${rota}/${trilhaId ?? ''}/PesquisaInterna/Editar/${etapa.id}`
    } else if (etapa.tipo === 5) {
      return `/Rh/PesquisaPercepcao/Editar/${etapa.id}/Trilha/${trilhaId ?? ''}`
    } else if (etapa.tipo === 6) {
      return `${rota}/${trilhaId ?? ''}/eNps/Editar/${etapa.id}`
    } else if (etapa.tipo === 7) {
      return `${rota}/${trilhaId ?? ''}/ReuniaoOne/Configuracao/${etapa.id}`
    } else if (etapa.tipo === 9) {
      return `${rota}/${trilhaId ?? ''}/PDI/${etapa.id}`
    }
    return ''
  }

  function DefinirTextoCirculo(): string {
    if (!PropInvalida(etapa.etapaPai)) {
      return 'Após a anterior'
    } else if (!PropInvalida(etapa.tempoInicio)) {
      return `${etapa.tempoInicio} ${etapa.tempoInicio === 1 ? 'dia' : 'dias'}`
    } else if (etapa.tipo === 8) {
      return 'Dia do desliga-mento'
    } else if (!PropInvalida(etapa.tempoAntesDesligamento)) {
      return `${etapa.tempoAntesDesligamento} ${etapa.tempoAntesDesligamento === 1 ? 'dia' : 'dias antes'}`
    } else if (!PropInvalida(etapa.tempoAposDesligamento)) {
      return `${etapa.tempoAposDesligamento} ${etapa.tempoAposDesligamento === 1 ? 'dia' : 'dias após'}`
    } else {
      return ''
    }
  }

  return (
    <Container>
      <div>
        <CircleDiv>
          <CircleCard
            fontSize={etapa.tempoInicio === null ? '0.875' : '1'}
          >
            <span>{DefinirTextoCirculo()}</span>
          </CircleCard>
          {
            (!isLast) && (
              <hr />
            )
          }
        </CircleDiv>
        <FlexElipse color={ObterCorEtapaPeloTipo(etapa.tipo)}>
          <div></div>
          <div></div>
          <div></div>
        </FlexElipse>
        <BackgroundCard isOpen={isOpen} theme={ObterCorFundoEtapaPeloTipo(etapa.tipo)}>

          <CardDiv onClick={() => setisOpen(PropInvalida(etapa.interacoes) ? false : !isOpen)} theme={ObterCorEtapaPeloTipo(etapa.tipo)}>
            <Flex justifyContent={'space-between'}>
              <Flex alignItems={'center'}>
                <h3>{etapa.nome}</h3>
              </Flex>
              {!PropInvalida(etapa.interacoes) &&
                <>
                  {
                    !isOpen
                      ? <FaAngleDown size={'1.5rem'} style={{ marginLeft: '1rem', position: 'relative', top: '40%' }} />
                      : <FaAngleUp size={'1.5rem'} style={{ marginLeft: '1rem', position: 'relative', top: '40%' }} />
                  }
                </>}
            </Flex>
            <span>{etapa.descricao}</span>
          </CardDiv>

          <BodyCard isOpen={isOpen}>
            {
              !PropInvalida(etapa.interacoes) && etapa.interacoes.map((obj: iInteracaoEtapa, i) => {
                return ObterComponentePeloTipoEtapa(i)
              })
            }
          </BodyCard>
        </BackgroundCard>
        {!PropInvalida(etapa.interacoes) && ((trilhaPadrao === null || trilhaPadrao === false)) && (

          <EditMenu isOpen={isOpen}>
            <Tooltip
              padding='2'
              borderRadius='4'
              placement='top'
              color={'white'}
              background={'var(--a4)'}
              label='Clique para editar a etapa'
              hasArrow arrowSize={15}>
              <Link to={ObterRedirectPeloTipo()}><FaPen style={{ marginLeft: '1rem' }} /></Link>
            </Tooltip>
            {
              etapa.interacoes[0].tipo > 0 &&
              <Tooltip padding='2'
                borderRadius='4'
                placement='top'
                color={'white'}
                background={'var(--a4)'}
                label='Clique para excluir a etapa'
                hasArrow arrowSize={15}>
                <a onClick={onDeleteEtapa}><FaTrash /></a>
              </Tooltip>
            }
          </EditMenu>
        )}
      </div>
    </Container >
  )
}
