import { Flex } from '@chakra-ui/react'
import { FaClipboard, FaCopy, FaEnvelope, FaEye, FaPen, FaRegPlayCircle, FaTrash, FaUser } from 'react-icons/fa'
import { MdOutlineStopCircle } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import { iIntegracao } from '../../../../../interfaces'
import { OutlineButton } from '../OutlineButton'

interface iCardProps {
  Imersao: iIntegracao
  onHabilitar: (id: string) => void
  onDelete: (id: string) => void
  onCopy: (id: string) => void
}

export const CardImersao: React.FC<iCardProps> = ({ Imersao, onHabilitar, onDelete, onCopy }) => {
  const nav = useNavigate()
  return (
        <Flex border={'2px solid var(--Azul)'} padding={'1rem'} borderRadius={'24px'}>
            <Flex justifyContent={'space-between'} width={'100%'}>
                <Flex flexDir={'column'}>
                    <Flex alignItems={'center'} height={'fit-content'} marginBottom={'.8rem'}>
                        <FaClipboard size={25} style={{ marginRight: '.5rem' }} color="var(--a2)" />
                        <h4>{Imersao.nome}</h4>
                    </Flex>
                    {Imersao.dataCriacao && <span>Criação: {Imersao.dataCriacao}</span>}
                </Flex>
                <Flex flexDir={'column'} alignItems={'end'} justifyContent={'end'}>
                    <Flex fontWeight={'400'} color={'black'}>
                        <FaUser color='var(--Gray2)' style={{ marginRight: '.5rem' }} />
                        <span>Disparos: {Imersao.disparos}</span>
                    </Flex>
                    <Flex fontWeight={'400'} color={'black'} margin={'1rem 0rem'}>
                        <FaEye color='var(--Green2)' style={{ marginRight: '.5rem' }} />
                        <span>Visualizações: {Imersao.visualizacoes}</span>
                    </Flex>
                    <Flex>
                        {Imersao.habilitado &&
                            <OutlineButton
                                varColor='Green2'
                                leftIcon={<FaEnvelope />}
                                onClick={() => nav(`${Imersao.id}/Convites`)}
                            >
                                Gestão de envios
                            </OutlineButton>
                        }
                        <OutlineButton onClick={() => onCopy(Imersao.id)} margin={'0rem 1rem'} varColor='Gray3' leftIcon={<FaCopy />}>
                            Duplicar
                        </OutlineButton>
                        <OutlineButton
                            varColor='Blue1'
                            leftIcon={<FaPen />}
                            onClick={() => nav(Imersao.id)}
                        >
                            Editar imersão
                        </OutlineButton>
                        {
                            Imersao.habilitado
                              ? <OutlineButton onClick={() => onHabilitar(Imersao.id)} margin={'0rem 1rem'} varColor='Rosa' leftIcon={<MdOutlineStopCircle />}>
                                    Desabilitar
                                </OutlineButton>
                              : <OutlineButton onClick={() => onHabilitar(Imersao.id)} margin={'0rem 1rem'} varColor='v2' leftIcon={<FaRegPlayCircle />}>
                                    Habilitar
                                </OutlineButton>
                        }
                        <OutlineButton onClick={() => onDelete(Imersao.id)} varColor='Red' leftIcon={<FaTrash />}>
                            Excluir
                        </OutlineButton>
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
  )
}
