import { Box, Button, Flex, Text } from '@chakra-ui/react'
import { Body } from '../../Layouts/Body'
import { CardCargo } from './components/CardCargo'
import { useNavigate } from 'react-router-dom'
import { appApi } from '../../../services/appApi'
import { useEffect, useState } from 'react'
import { IJobTarget } from './Create'
export const DashBoardJobTarget: React.FC = () => {
  const navigate = useNavigate()
  const [JobTargets, setJobTargets] = useState<IJobTarget[]>([])
  function getJobTargets(): void {
    appApi.get<IJobTarget[]>('AssessmentPI/JobTarget/Dashboard')
      .then(res => {
        if (res.status === 200) {
          setJobTargets(res.data)
          console.log(res.data)
          // setChamados(res.data)
        }
      })
      .catch(err => console.log(err))
  }

  useEffect(() => {
    getJobTargets()
  }, [])

  return (
  <Body>
<Flex justify='center'>
<Box width='70rem' maxWidth='90vw' bg='white' borderRadius='xl' shadow='xl' p={4}>
<Flex justifyContent='space-between'>
  <Text fontWeight='bold' fontSize='2rem' >Aderência comportamental</Text>
  <Button
  onClick={() => navigate('/AssessmentPi/JobTarget/Create')}
  size='lg' borderRadius='2xl' backgroundColor='var(--Azul)'>Novo cargo</Button>
</Flex>
  <Text fontWeight='bold' my={4} fontSize='1.3rem' >Cargos criados: {JobTargets.length}</Text>

{JobTargets.map(s => (<CardCargo nome={s.nome} jobId={s.id ?? ''}
key={s.id}
percentualPopulacao={s.percentualPopulacaoAderente}
percentualPopulacaoadap={s.percentualPopulacaoAdaptativa}
adaptativos={s.numeroAdaptativos}
aderentes={s.numeroAderentes}
naoAderentes={s.numeroNaoAderentes}

perfis={s.perfisAderentes}
perfisAdaptativos={s.perfisAdaptativos}
/>))}

</Box>
  </Flex>
  </Body>
  )
}
