/* eslint-disable @typescript-eslint/no-misused-promises */
import { Flex, Input } from '@chakra-ui/react'
import { FaAngleRight } from 'react-icons/fa'
import QRCode from 'react-qr-code'
import { Button } from '../../../../../../components/Button'
import { urlLocal } from '../../../../../../services/appApi'
import { ModalBase } from '../../../../../../components/ModalBase'
import { TextContainer } from './styles'

interface iModalProps{
  pesquisaId: string
  isOpen: boolean
  onClose: () => void
}

export const ModalLinkAberto: React.FC<iModalProps> = ({ pesquisaId, isOpen, onClose }) => {
  const Text = `${urlLocal as string}/PI/Abertura/${pesquisaId}`
  return (
        <ModalBase
            Titulo='Link aberto'
            Width='65rem'
            isOpen={isOpen}
            onClose={onClose}
        >
        <Flex padding={'1rem'} flexDir={'column'}>
            <Flex alignItems={'center'} borderRadius={'1rem'} bg={'var(--c3)'} padding={'1.5rem'}>
                <Flex bg={'white'} padding={'1rem'} marginRight={'1rem'}>
                    <QRCode value={Text}/>
                </Flex>
                <TextContainer>
                    <strong></strong>
                    <span>
                        Com o link aberto, você pode distribuir o
                        <strong> QR CODE</strong> pela empresa e para os colaboradores especificados para responder
                        a pesquisa. Vale ressaltar que essa opção possibilita a cada colaborador
                        <strong> responder a pesquisa quantas vezes quiser</strong>. Se a intenção for que cada pessoa
                        responda apenas uma vez, recomendamos que use um dos outros três
                        métodos de envio.
                    </span>
                </TextContainer>
            </Flex>
            <Flex margin={'1rem 0'} alignItems={'center'}>
                <Input
                    borderColor={'var(--Gray4)'}
                    value={Text}
                    width={'20rem'}
                    marginRight={'2rem'}
                    readOnly
                />

                <Button
                    VarColor='vg3'
                    rightIcon={<FaAngleRight/>}
                    onClick={async () => await navigator.clipboard.writeText(Text)}
                >Copiar link</Button>
            </Flex>
            <Flex padding={'0 1rem'} justifyContent={'end'}>
                <Button VarColor='Green1' size={'lg'} onClick={onClose}>Continuar</Button>
            </Flex>
        </Flex>
        </ModalBase>
  )
}
