import styled from 'styled-components'
import { Column, Flex } from '../../../styles/styledGlobal'

export const Main = styled.div`
display: flex;
justify-content: space-between;
flex-wrap: wrap;
`

export const MainColumn = styled(Column)<{padding: string, width: string}>`
padding: ${props => props.padding};
width: ${props => props.width};
box-shadow: var(--SombraBackground);
background: white;
`

export const ColumnTitle = styled(Column)`
margin: 1rem 0rem;

h2{
    color:var(--a3);
}
`

export const ArrowButton = styled(Flex)`
padding: 0rem 0rem 1rem 0rem;
justify-content: center;
border-bottom: 2px solid var(--c5);
border-bottom-style: dashed;
margin-bottom: 1.5rem;
`

export const SectionOnboard = styled(Column)`
& > div {
    margin-top: 1rem;
}
`
