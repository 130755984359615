import styled from 'styled-components'
import { Flex } from '../../../../styles/styledGlobal'

export const Container = styled.div`
`

export const BtnPerfil = styled.button`
border: 0.125rem solid var(--${props => props.theme});
color: var(--${props => props.theme});
border-radius: 0.625rem;
background: none;
font-size: 0.875rem;
font-family: 'Roboto','sans-serif';
font-weight: bold;
height: 1.875rem;
display: flex;
justify-content: center;
align-items: center;

&:hover{
    background: var(--${props => props.theme});
    color: white;
}
`

export const CardParticipante = styled(Flex)`
align-items: center;
background: var(--c2);
border-radius: 1rem;
border: 0.125rem solid var(--v3);
width: 10.8rem;
padding: 0.5rem;
`

export const ImgAssociado = styled.img`
width:2.5rem;
height:2.5rem;
border-radius: 1.25rem;
margin-right: .5rem;
object-fit: cover;
`
