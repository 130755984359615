import { Flex, FormControl, FormLabel, Radio, RadioGroup, Stack, Text, useToast } from '@chakra-ui/react'
import { useState, useEffect } from 'react'
import { FaAngleLeft, FaEye, FaWrench } from 'react-icons/fa'
import { useNavigate, useParams } from 'react-router-dom'
import { Button } from '../../../components/Button'
import { CreateEtapaBox } from '../../../components/CreateEtapaBox'
import { EditorTexto } from '../../../components/EditorTexto/Index'
import { WhiteBox } from '../../../components/WhiteBox'
import { iEtapa } from '../../../interfaces'
import { appApi } from '../../../services/appApi'
import { ValidarEtapa } from '../../../Utils/Helper'
import { Body } from '../../Layouts/Body'

interface iModel {
  tipoResponsavel: number
  textoCriacao: string
}

export const EtapaPDI: React.FC = () => {
  const toast = useToast()
  const nav = useNavigate()
  const { trilhaId, etapaId, processoId } = useParams<{ trilhaId: string, etapaId: string, processoId: string }>()
  const [Model, setModel] = useState<iModel>({
    tipoResponsavel: 0,
    textoCriacao: ''
  })

  const [Etapa, setEtapa] = useState<iEtapa>({})
  const [IsLoading, setIsLoading] = useState(false)

  function CriarEtapa(): void {
    const url = trilhaId ? `PDIEtapa/${trilhaId}` : processoId ? `PDIEtapaColaborador/${processoId}` : ''

    if (!ValidarEtapa(Etapa)) {
      toast({
        title: 'Preencha os campos de etapa',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return
    }
    appApi.post(url, {
      etapa: Etapa,
      pdi: Model
    })
      .then(() => {
        toast({
          title: 'Etapa criada com sucesso',
          status: 'success',
          isClosable: false,
          position: 'top-right',
          duration: 4000
        })
        nav(-1)
      })
      .catch(res => console.log(res))
  }

  function AtualizarEtapa(): void {
    const url = trilhaId ? `PDIEtapa/${etapaId ?? ''}` : processoId ? `PDIEtapaColaborador/${processoId}/Etapa/${etapaId ?? ''}` : ''
    if (!ValidarEtapa(Etapa)) {
      toast({
        title: 'Preencha os campos de etapa',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return
    }
    appApi.put(url, {
      etapa: Etapa,
      pdi: Model
    })
      .then(() => {
        toast({
          title: 'Etapa atualizada com sucesso',
          status: 'success',
          isClosable: false,
          position: 'top-right',
          duration: 4000
        })
        nav(-1)
      })
      .catch(res => console.log(res))
  }

  function ObterEtapa(): void {
    const url = trilhaId ? `PDIEtapa/${etapaId ?? ''}` : processoId ? `PDIEtapaColaborador/${processoId}/Etapa/${etapaId ?? ''}` : ''

    appApi.get(url)
      .then(res => {
        setEtapa(res.data.etapa)
        setIsLoading(true)
        setModel(res.data.pdi)
        setTimeout(() => setIsLoading(false), 1000)
      })
      .catch(err => console.log(err))
  }

  function ResetTextoPadrao(): void {
    const texto = '<p><strong>Olá, [NOME DO RESPONSAVEL]!<br><br>A pesquisa do(a) [NOME DO AVALIADO] foi concluída e você já pode iniciar o cadastro do PDI para o desenvolvimento dele!<br><br>É muito simples, basta acessa o link com os resultados do avaliado e Clicar no botão “Adicionar PDI”, criar as ações expecificando o que, por que e como ele deve trabalhar cada uma das competências definidas.<br><br>Você tem até o dia [DATA FIM] para cadastrar o PDI.<br><br>Link dos resultados da pesquisa do(a) [NOME DO AVALIADO]:<br><br>[LINK]<br><br><br>Se tiver qualquer dúvida pode entrar em contato com nosso RH.<br><br>Atenciosamente,<br>Equipe [NOME DA EMPRESA] </strong></p>'
    setIsLoading(true)
    setModel({ ...Model, textoCriacao: texto })
    setTimeout(() => setIsLoading(false), 1000)
  }

  useEffect(() => {
    if (etapaId) {
      ObterEtapa()
    } else {
      ResetTextoPadrao()
    }
  }, [])

  return (
        <Body>
            <Flex>
                <h1>PDI</h1>
            </Flex>
            <WhiteBox margin='1rem 0'>
                <Text fontWeight={'700'} fontSize='1.75rem' mb={'1rem'} color={'var(--Gray1)'}>Configuração da etapa</Text>
                <FormControl>
                    <FormLabel margin={0} color={'var(--Gray1)'}>Quem irá inserir o PDI do colaborador?</FormLabel>
                    <Text color={'var(--Gray3)'}>Essa pessoa receberá um e-mail para subir o PDI assim que os resultados forem liberados</Text>
                    <RadioGroup
                        margin={'.8rem'}
                        value={Model?.tipoResponsavel.toString()}
                        onChange={(e) => setModel({ ...Model, tipoResponsavel: parseInt(e) })}
                    >
                        <Stack gap={'2rem'} direction={'row'}>
                            <Radio borderColor={'var(--Gray4)'} value={'0'}>Gestor</Radio>
                            <Radio borderColor={'var(--Gray4)'} value={'1'}>Colaborador</Radio>
                            <Radio borderColor={'var(--Gray4)'} value={'2'}>RH</Radio>
                        </Stack>
                    </RadioGroup>
                    <CreateEtapaBox
                        variant='Etapa'
                        keyWord='PDI'
                        EditEtapa={Etapa}
                        onModelChange={(e) => setEtapa(e)}
                        isPdi
                    />
                </FormControl>
            </WhiteBox>

            <WhiteBox margin='1rem 0rem'>
                <Flex justifyContent={'space-between'}>
                    <div style={{ maxWidth: '55rem' }}>
                        <h3>Texto para criação do PDI</h3>
                        <Text fontWeight={'500'} fontSize='0.875rem'>Esse é o texto que será enviado ao e-mail do responsável por subir o PDI do colaborador</Text>
                    </div>
                </Flex>

                <EditorTexto
                    isLoading={IsLoading}
                    initialValue={Model.textoCriacao}
                    onChange={(e: string) => setModel({ ...Model, textoCriacao: e })}
                />
                <Flex marginTop='1rem' justifyContent={'space-between'}>
                    <Button leftIcon={<FaEye />} VarColor='vg3'>Pré-visualização do encerramento </Button>
                    <Flex gap={'1rem'}>
                        <Button
                            VarColor={'c6'}
                            leftIcon={<FaWrench />}
                            onClick={() => ResetTextoPadrao()}
                        >Restaurar texto padrão</Button>
                    </Flex>
                </Flex>
            </WhiteBox>
            <Flex gap={'1rem'} justifyContent={'end'} padding={'0 1rem 0 0'}>
                <Button size={'lg'} VarColor='c6' onClick={() => nav(-1)} leftIcon={<FaAngleLeft />}>Voltar</Button>
                {!etapaId && <Button size={'lg'} VarColor='v2' onClick={CriarEtapa}>Criar etapa</Button>}
                {etapaId && <Button size={'lg'} VarColor='v2' onClick={AtualizarEtapa}>Atualizar etapa</Button>}
            </Flex>
        </Body>
  )
}
