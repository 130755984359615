import styled from 'styled-components'

export const Container = styled.div`
display: flex;
flex-direction: column;
font-family: 'Roboto','sans-serif';

h3{
    margin-bottom: 0.625rem;
    color: var(--a3); 
}

span{
    color: var(--a5); 
font-weight: 600;
margin-bottom: .5rem;
}
`
