/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { Alert, AlertDescription, AlertIcon, Box, Flex, FormControl, FormLabel, Image, Input, Select, Tab, TabList, TabPanel, TabPanels, Tabs, Text } from '@chakra-ui/react'
import { FaClipboardList } from 'react-icons/fa'
import { Body } from '../../Layouts/Body'
import { InfoBox, Titulo } from './styles'
import { RadarChartJs } from './components/RadarChart'
import { useLocation, useParams } from 'react-router-dom'
import { appApi } from '../../../services/appApi'
import { useEffect, useState } from 'react'
import { NavLine } from '../../../components/NavLine'
import { ColumnChart } from './components/ColumnChart'
import { iCompetenciaPDI, iEscala, iNomeId } from '../../../interfaces'
import IconeAvaliadores from '../../../assets/IconeTrilha.svg'
import { TableDiv } from '../ResultadoIndividual/styles'
import { Button } from '../../../components/Button'
import { Recomendacao } from './components/Recomendacao'
import { TabelaCompetencias } from './components/TabelaCompetencias'
import { PropInvalida } from '../../../Utils/Helper'
import { ImgSah } from '../../../components/ImgSah'
import { TabelaColaboradores } from './components/TabelaColaboradores'

interface IDadoGrafico {
  medias: number[]
  nome: string
}

interface iLinhaTabela {
  competencia: string
  autoAvaliacao: string
  gestor: string
  pares: string
  gestores: string
  outros: string
  liderados: string
  altalideranca: string
  mediaSemAuto: string
}

interface iTabela {
  linhas: iLinhaTabela[]
}

interface IGrafico {
  competencias: string[]
  dados: IDadoGrafico[]
}

interface iGraficoColuna {
  eixoX: string[]
  medias: number[]
}

interface iModel {
  nomePesquisa: string
  notaMaximaEscala: number
  totalAvaliadores: number
  totalRespostas: number
  mediaGeral: number
  mediaAutoavaliacao: number
  graficoRadar: IGrafico
  graficoIndividual: IGrafico
  graficoColuna: iGraficoColuna
  tabela: iTabela
  tabelaReferencia?: string[]
  filtroDepartamento: iNomeId[]
  filtroCargo: iNomeId[]
  escala: iEscala
  ciclica: boolean
  recomendacoes: iCompetenciaPDI[]
}

interface iTeste {
  colaboradoresCompetencia: iColaboradoresCompetencia[]
}

export interface iColaboradoresCompetencia {
  nome: string[]
  nota: number[]
}
interface iModelFiltro {
  departamentoId?: string
  cargoId?: string
  gestor?: string
  dataInicio?: string
  dataFim?: string
}

export const ResultadoGeral: React.FC = () => {
  const { pesquisaId } = useParams<{ pesquisaId: string }>()
  const [Model, setModel] = useState<iModel>()
  const [ModelFiltro, setModelFiltro] = useState<iModelFiltro>()
  const [GraficoHierarquia, setGraficoHierarquia] = useState(true)
  const [open, setOpen] = useState<number[]>([])
  const toggleOpen = (e: number): void => {
    console.log(e)

    if (open.some(valor => valor === e)) {
      setOpen(open.filter(valor => valor !== e))
    } else {
      setOpen([...open, e])
    }
  }
  const [Teste, setTeste] = useState<iTeste>({
    colaboradoresCompetencia: [{ nome: ['Lucas', 'Leonardo', 'Marcelo'], nota: [10, 5, 3.5] }, { nome: ['Lucas'], nota: [3.5] }, { nome: ['Leonardo', 'Marcelo'], nota: [5, 3.5] }]
  })
  const [IsLoading, setIsLoading] = useState(false)

  const { pathname } = useLocation()

  function GerarQuery(): string {
    let query = ''
    if (ModelFiltro?.departamentoId) {
      query = `departamentoId=${ModelFiltro.departamentoId}`
    }

    if (ModelFiltro?.cargoId) {
      query = query + `${query.length > 0 ? '&' : ''}cargoId=${ModelFiltro.cargoId}`
    }

    if (ModelFiltro?.gestor) {
      query = query + `${query.length > 0 ? '&' : ''}gestor=${ModelFiltro.gestor === '0'}`
    }

    if (ModelFiltro?.dataInicio) {
      query = query + `${query.length > 0 ? '&' : ''}dataInicio=${ModelFiltro.dataInicio}`
    }

    if (ModelFiltro?.dataFim) {
      query = query + `${query.length > 0 ? '&' : ''}dataFim=${ModelFiltro.dataFim}`
    }
    return query
  }

  function getResultado(): void {
    setIsLoading(true)
    appApi.get(`AvaliacaoPesquisa/${pesquisaId as string}/ResultadoGeral?${GerarQuery()}`)
      .then(res => {
        setModel(res.data)
        setIsLoading(false)
      })
      .catch(err => console.log(err))
  }

  function TemColuna(tipo: string): boolean {
    if (Model?.graficoRadar !== undefined) {
      const test = Model?.graficoRadar.dados.find(e => e.nome === tipo) !== undefined
      return test
    } else {
      return false
    }
  }

  useEffect(() => {
    getResultado()
  }, [])

  return (
    <Body isLoading={IsLoading}>
      <Flex margin={'1rem 0'}>
        <NavLine options={[
          {
            nome: 'Gestão de pesquisas',
            path: `${pathname.substring(0, 36).replace('ResultadoGeral', 'Gestao')}`
          },
          {
            nome: Model?.nomePesquisa ?? '',
            path: -1
          },
          {
            nome: 'Resultado geral',
            path: ''
          }
        ]} />
      </Flex>

      <Flex alignItems={'center'} justifyContent={'space-between'} flexWrap='wrap'>
        <Flex width={'50%'} flexDir={'column'}>
          <h1>{Model?.nomePesquisa}</h1>
        </Flex>
      </Flex>

      <Flex marginTop={'1rem'} gap={'1rem'} alignItems='end'>
        <FormControl>
          <FormLabel fontSize={'xl'}>Área</FormLabel>
          <Select isDisabled={!PropInvalida(ModelFiltro?.cargoId ?? '')} onChange={(e) => setModelFiltro({ ...ModelFiltro, departamentoId: e.target.value })} bg={'white'} borderColor='var(--Gray4)'>
            <option value={''}>Todos</option>
            {
              Model?.filtroDepartamento.map((e) => {
                return (
                  <option value={e.id} key={e.id}>{e.nome}</option>
                )
              })
            }
          </Select>
        </FormControl>

        <FormControl>
          <FormLabel fontSize={'xl'}>Cargo</FormLabel>
          <Select isDisabled={!PropInvalida(ModelFiltro?.departamentoId ?? '')} value={ModelFiltro?.cargoId} onChange={(e) => setModelFiltro({ ...ModelFiltro, cargoId: e.target.value })} bg={'white'} borderColor='var(--Gray4)'>
            <option value={''}>Todos</option>
            {
              Model?.filtroCargo.map((e) => {
                return (
                  <option value={e.id} key={e.id}>{e.nome}</option>
                )
              })
            }
          </Select>
        </FormControl>

        <FormControl>
          <FormLabel fontSize={'xl'}>Gestor</FormLabel>
          <Select value={ModelFiltro?.gestor} onChange={(e) => setModelFiltro({ ...ModelFiltro, gestor: e.target.value })} bg={'white'} borderColor='var(--Gray4)'>
            <option value={''}>Todos</option>
            <option value={'0'}>Sim</option>
            <option value={'1'}>Não</option>
          </Select>
        </FormControl>

      {
        (Model?.ciclica) && (
          <>
            <FormControl>
              <FormLabel fontSize={'xl'}>Data de início</FormLabel>
              <Input value={ModelFiltro?.dataInicio} onChange={(e) => setModelFiltro({ ...ModelFiltro, dataInicio: e.target.value })} bg={'white'} borderColor='var(--Gray4)' type={'date'} />
            </FormControl>

            <FormControl>
              <FormLabel fontSize={'xl'}>Data de fim</FormLabel>
              <Input value={ModelFiltro?.dataFim} onChange={(e) => setModelFiltro({ ...ModelFiltro, dataFim: e.target.value })} bg={'white'} borderColor='var(--Gray4)' type={'date'} />
            </FormControl>
          </>
        )
      }

        <Flex>
          <Button onClick={getResultado} VarColor='Azul' size={'md'}>Filtrar</Button>
        </Flex>
      </Flex>

      <Flex margin={'1rem 0'} gap={'1rem'}>
        <InfoBox>
          <FaClipboardList size={40} />
          <div>
          <Text align={'start'} marginBottom={'-0.5rem'} fontSize={'2rem'} fontFamily={'Roboto'} fontWeight={'bold'} >{Model?.totalRespostas}</Text>
          <span>Respostas</span>
          </div>
        </InfoBox>
        <InfoBox>
          <Flex width={'2rem'}>
            <Image src={IconeAvaliadores} />
          </Flex>
          <div>
            <Text align={'start'} marginBottom={'-0.5rem'} fontSize={'2rem'} fontFamily={'Roboto'} fontWeight={'bold'} >{Model?.totalAvaliadores}</Text>
            <span>Avaliadores</span>
          </div>
        </InfoBox>

        <InfoBox style={{ flexDirection: 'column' }}>
          <h2>{Model?.mediaGeral.toFixed(2)}</h2>
          <span>Média dos stakeholders</span>
        </InfoBox>

        <InfoBox style={{ flexDirection: 'column' }}>
          <h2>{Model?.mediaAutoavaliacao.toFixed(2)}</h2>
          <span>Média da autoavaliação</span>
        </InfoBox>
      </Flex>

      {
        (Model?.totalRespostas ?? -1 > 0) && (
          <Tabs marginTop={'1rem'} variant='enclosed'>
          <TabList >
            <Tab backgroundColor={'white'} color={'var(--c6)'}>Gráfico</Tab>
            <Tab backgroundColor={'white'} color={'var(--c6)'}>Tabela</Tab>
            <Tab backgroundColor={'white'} color={'var(--c6)'}>Competências</Tab>
            {/* <Tab backgroundColor={'white'} color={'var(--c6)'}>Potenciais de melhoria</Tab> */}
          </TabList>
          <TabPanels background={'white'} borderBottomLeftRadius='1rem' borderBottomRightRadius='1rem'>
            <TabPanel>
              <Flex>
                {
                  (GraficoHierarquia) && (
                    <Flex flexDir={'column'} width='100%'>
                      <Flex justifyContent={'space-between'}>
                        <Titulo>Gráfico de hierarquia</Titulo>
                        <Button onClick={() => setGraficoHierarquia(!GraficoHierarquia)} VarColor='Azul'>Alterar tipo do gráfico</Button>
                      </Flex>
                      <Flex justifyContent={'center'} width='100%'>
                        <Flex width={'60%'}>
                          <RadarChartJs
                            competencias={Model?.graficoRadar.competencias ?? []}
                            dados={Model?.graficoRadar.dados ?? []}
                            notaMaximaEscala={Model?.notaMaximaEscala ?? 0}
                            stepSize={((Model?.escala?.respostas[0]?.peso ?? 0) - (Model?.escala?.respostas[1]?.peso ?? 0)) ?? 0}
                          />
                        </Flex>
                      </Flex>
                    </Flex>
                  )
                }

                {
                  (!GraficoHierarquia) && (
                    <Flex flexDir={'column'} width='100%'>
                      <Flex justifyContent={'space-between'}>
                        <Titulo>Gráfico individual</Titulo>
                        <Button onClick={() => setGraficoHierarquia(!GraficoHierarquia)} VarColor='Azul'>Alterar tipo do gráfico</Button>
                      </Flex>
                      <Flex justifyContent={'center'} width='100%'>
                        <Flex width={'60%'}>
                          <RadarChartJs
                            competencias={Model?.graficoIndividual.competencias ?? []}
                            dados={Model?.graficoIndividual.dados ?? []}
                            notaMaximaEscala={Model?.notaMaximaEscala ?? 0}
                            stepSize={((Model?.escala?.respostas[0]?.peso ?? 0) - (Model?.escala?.respostas[1]?.peso ?? 0)) ?? 0}
                          />
                        </Flex>
                      </Flex>
                    </Flex>
                  )
                }
              </Flex>
            </TabPanel>

            <TabPanel>
              <Titulo>Tabela dos resultados por hierarquia</Titulo>
              <TableDiv>
                <table>
                  <thead>
                    <tr>
                      <th>Competências</th>
                      {TemColuna('Autoavaliações') && <th>Autoavaliações</th>}
                      {TemColuna('Gestores') && <th>Gestores</th>}
                      {TemColuna('Pares') && <th>Pares</th>}
                      {TemColuna('Liderados') && <th>Liderados</th>}
                      {TemColuna('Lideres') && <th>Lideres</th>}
                      {TemColuna('Outros') && <th>Outros</th>}
                      {TemColuna('Alta liderança') && <th>Alta liderança</th>}
                      <th>Média geral sem autoavaliação</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      Model?.tabela.linhas.map((e, i) => {
                        return (
                          <tr key={i}>
                            <td>{e.competencia}</td>
                            {TemColuna('Autoavaliações') && <td>{e.autoAvaliacao}</td>}
                            {TemColuna('Gestores') && <td>{e.gestor}</td>}
                            {TemColuna('Pares') && <td>{e.pares}</td>}
                            {TemColuna('Liderados') && <td>{e.liderados}</td>}
                            {TemColuna('Lideres') && <td>{e.gestores}</td>}
                            {TemColuna('Outros') && <td>{e.outros}</td>}
                            {TemColuna('Alta liderança') && <td>{e.altalideranca}</td>}
                            <td>{e.mediaSemAuto}</td>
                          </tr>
                        )
                      })
                    }

                  </tbody>

                </table>
              </TableDiv>
            </TabPanel>

            <TabPanel>
              <Titulo>Média das competências</Titulo>
              {
                (Model?.graficoColuna) && (
                  <Flex flexDir={'column'}>
                    <ColumnChart id='asdasd' Grafico={Model?.graficoColuna} />
                    {
                      (Model.tabelaReferencia) && (
                        <Flex marginLeft={'1rem'} flexDir={'column'} gap={'1rem'}>
                          {
                            Model.tabelaReferencia.map((e, i) => {
                              return (
                                <Text key={i}>{e}</Text>
                              )
                            })
                          }
                        </Flex>
                      )
                    }
                  </Flex>
                )
              }
              {
                (Model?.graficoColuna.medias.every(e => e === 0)) && (
                  <Alert marginBottom={'1rem'} borderRadius={'8px'} justifyContent='space-between' status='warning'>
                    <Flex>
                      <AlertIcon color={'#FF9900'} />
                      <AlertDescription fontWeight={'500'}>Os avaliadores ainda não responderam a pesquisa</AlertDescription>
                    </Flex>
                  </Alert>
                )
              }
            </TabPanel>

            <TabPanel>
              <Titulo onClick={() => console.log(Model)}>Competências com maiores potenciais de melhoria</Titulo>
              <Flex margin={'1rem 0'} gap={'1rem'}>
                {
                  Model?.recomendacoes.slice(0, 3).map((e, i) => {
                    return (
                      <div style={{ cursor: 'pointer' }} onClick={() => toggleOpen(i)}>
                        <Recomendacao key={i} pos={i + 1} recomendacao={e} />
                      </div>
                    )
                  })
                }
              </Flex>
              {(Teste?.colaboradoresCompetencia !== undefined) && (open.map((e) => {
                console.log(e)
                return (
                  <Flex border={'1px solid var(--Gray4)'} flexDirection={'column'} borderRadius={'1rem'} marginBottom={'1rem'}>
                    <span onClick={() => toggleOpen(e)} style={{ textAlign: 'right', marginRight: '.6rem', marginTop: '.3rem', fontSize: '10pt', fontWeight: '600', cursor: 'pointer' }}>x</span>
                    <Flex alignItems={'center'} gap={'1rem'} marginLeft={'1.4rem'}> <img src={Model?.recomendacoes[e].avatar} width={'35px'} height={'35px'}/> <h3 style={{ color: 'var(--c7)' }}> {Model?.recomendacoes[e].nome} </h3></Flex>
                    <TabelaColaboradores dados={Teste?.colaboradoresCompetencia} index={e}/>
                  </Flex>
                )
              }))}
              {
                (Model && Model?.recomendacoes.length > 3) && (
                  <Flex border={'1px solid var(--Gray4)'} borderRadius={'1rem'}>
                    <TabelaCompetencias dados={Model?.recomendacoes.slice(3, Model.recomendacoes.length) ?? []} />
                  </Flex>
                )
              }
            </TabPanel>
          </TabPanels>
          </Tabs>
        )
      }

      {
        (Model?.totalRespostas === 0) && (
          <ImgSah textoPersonalizado='Não encontramos resultados com os filtros selecionados'/>
        )
      }
    </Body>
  )
}
