import styled from 'styled-components'

export const IconColumn = styled.div`
display: flex;
flex-direction: column;

svg{
margin-right:0.5rem;
height:2.188rem;
width:2.188rem;
}

span{
    color: var(--a3);
    font-size: 1.125rem;
}

div:not(:last-child){
    margin-bottom: 2rem;
}

div{
    align-items: center;
    display: flex;
}
`
