import styled from 'styled-components'
import { Column, Flex } from '../../../../../styles/styledGlobal'

interface iProps{
  isOpen?: boolean
  disable?: boolean
}

export const CircleDiv = styled(Column)`
align-items: center;
hr{
    height: 100%;
    width: .5rem;
    background: var(--terc6);
    border: none;
}
`

export const CardDiv = styled(Column)<iProps>`
    justify-content: center;
    border-radius:2.5rem;
    background: #${props => props.theme};
    box-shadow: var(--SombraBackground);
    color: white;
    cursor: pointer;
    min-height: 80px;
    padding-left: 2em;
    padding-right: 2rem;
    &:hover{
        box-shadow: var(--SombraBtns);
        filter: brightness(.9);
    }

    ${({ disable }) => disable && `
       pointer-events:none;
    `}
`

export const BackgroundCard = styled(Column)<iProps>`
    background:#${props => props.theme};
    border-radius: 2.5rem;
    margin-bottom: 2rem;
    overflow-y: hidden;
    transition: max-height .7s;
    max-height:80px;
    border-bottom-left-radius: 2.5rem;
    border-bottom-right-radius:2.5rem;

    ${({ isOpen }) => isOpen && `
        transition: max-height 1.2s;
        max-height: 100rem;
        border-bottom-left-radius:2rem;
        border-bottom-right-radius:2rem;
    `}
`

export const BodyCard = styled(Column)<iProps>`
    padding: 1.5rem 2rem 2rem 2rem;
    pointer-events: none;
    ${({ isOpen }) => isOpen && `
       pointer-events: unset;
    `}
`

export const CircleCard = styled.div`
background: var(--c1);
color: var(--a3);
border: solid var(--terc6);
border-width: 0.3rem;
box-sizing: border-box;
min-height: 5rem;
width: 5rem;
border-radius: 2.5rem;
display: flex;
justify-content:center ;
text-align: center;
align-items: center;
font-family: 'Poppins';
font-weight: 800;
`

export const FlexElipse = styled.div`
display: flex;
height: 5.313rem;
align-items: center;
margin-left: .5rem;

div{
    margin-right:.3rem;
}

div:nth-child(1){
    height: 0.5rem;
    width: 0.5rem;
    background: var(--c4);
    border-radius: .5rem;
}

div:nth-child(2){
    height: 0.625rem;
    width: 0.625rem;
    background: var(--c6);
    border-radius: 0.625rem;
}

div:nth-child(3){
    height: .75rem;
    width: .75rem;
    background:#${props => props.color};
    border-radius: .75rem;
}
`

export const EditMenu = styled.div<iProps>`
display: flex;
position: relative;
left: -5rem;
transition: all 0.9s ease;
opacity: 0%;
pointer-events:none;
margin-left:.5rem;
flex-direction: column;
max-height: 0;
overflow-y: hidden;
overflow-x: hidden;

${({ isOpen }) => isOpen && `
    pointer-events:unset ;
    left: 0rem;
    opacity: 100%;
    max-height: 20rem;
`}

svg{
    margin-left: 0rem!important;
}

a{
    justify-content: center;
    align-items: center;
    margin-right: 1rem;
    height: 2.5rem;
    width: 2.5rem;
    display: flex;
    border-radius: 2.5rem;
    cursor: pointer;
    position: relative;
    top: 1rem;
}

a[data-bt="edit"]{
    border: 2px solid var(--a1);
    color: var(--a1);

    &:hover{
        background: var(--a1);
        color: white;
    }
}

a[data-bt="trash"]{
    border: 2px solid var(--terc1);
    color: var(--terc1);
    margin: 1rem 0rem;
    &:hover{
        background: var(--terc1);
        color: white;
    }
}


a[data-bt="check"]{
    border: 2px solid var(--v2);
    color: var(--v2);

    &:hover{
        background: var(--v2);
        color: white;
    }
}

a[data-bt="eye"]{
    border: 2px solid var(--vg3);
    color: var(--vg3);
    margin-top: 1rem;
    &:hover{
        background: var(--vg3);
        color: white;
    }
}
`

export const FlexBetween = styled(Flex)`
justify-content:space-between;
svg{
    margin-left: 1rem;
    position: relative;
    top: 40%;
}
`
