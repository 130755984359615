export const Videoimg: React.FC = () => {
  return (
    <svg width="60" height="60" viewBox="0 0 20 20" fill="none">
      <path d="M2 4H0V18C0 19.1 0.9 20 2 20H16V18H2V4ZM18 0H6C4.9 0 4 0.9 4 2V14C4 15.1 4.9 16 6 16H18C19.1 16 20 15.1 20 14V2C20 0.9 19.1 0 18 0ZM18 14H6V2H18V14ZM10 3.5V12.5L16 8L10 3.5Z" fill="url(#paint0_linear_963_7220)" />
      <defs>
        <linearGradient id="paint0_linear_963_7220" x1="0" y1="10" x2="20" y2="10" gradientUnits="userSpaceOnUse">
          <stop stopColor="#1961E0" />
          <stop offset="1" stopColor="#66046B" />
        </linearGradient>
      </defs>
    </svg>
  )
}
