import styled from 'styled-components'

interface IContentProps {
  isActive: boolean
}

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Content = styled.div<IContentProps>`
  border: 1px dashed var(--a4);
  border-radius: 0.25rem;
  padding: 0.5rem 1rem 1rem;
  outline: 0;

  main {
    display: flex;
    justify-content: center;
  }
`
