import styled from 'styled-components'

export const OutlineContainer = styled.div`
width: 100%;
border-radius: 20px;
padding: 1rem;
display: flex;
flex-direction: column;
border: 1px solid var(--${props => props.theme});
`

export const Subtitulo = styled.span`
font-weight: 700;
color: var(--Gray1);
`

export const CardPDI = styled.div<{width?: string}>`
display: flex;
align-items: center;
background: var(--c2);
border-radius: 1rem;
border: 0.125rem solid var(--Azul);
width: 20rem;
padding: 1rem;

${({ width }) => width && `
    width:${width};
  `}
`
