import { FaAngleRight } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import { Container } from './styles'

interface iNav {
  nome: string
  path?: any
}

interface iNavLineProps {
  options: iNav[]
}

export const NavLine: React.FC<iNavLineProps> = ({ options }) => {
  const nav = useNavigate()
  return (
    <Container>
      {
        options.map((e, i) => {
          if (options.length - 1 === i) {
            return (
              <span key={i}>{e.nome}</span>
            )
          } else {
            return (
              <div key={i}>
                <span onClick={() => nav(e.path)}>{e.nome}</span>
                <FaAngleRight />
              </div>
            )
          }
        })
      }
    </Container>
  )
}
