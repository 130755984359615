import { useEffect, useState } from 'react'
import { iImg } from '../../../../../interfaces'
import { appApi } from '../../../../../services/appApi'
import { Description, Main, VideoBox, WelcomeContainer } from './styles'
import { Flex, Image } from '@chakra-ui/react'

export const CardPrimeiroAcesso: React.FC = () => {
  const [Arquivo, setArquivo] = useState<iImg>({
    name: '', size: '', uri: ''
  })

  const [TextoBoasVindas, setTextoBoasVindas] = useState('')

  useEffect(() => {
    get()
  }, [])
  const get = (): void => {
    appApi.get('PrimeiroAcesso/ByEmpresaId').then(response => {
      if (response.status === 200) {
        setArquivo(response.data.videoIntroducao.arquivo)
        setTextoBoasVindas(response.data.textoBoasVindas)
      }
    }).catch(e => console.log(e))
  }
  return (
        <Main>
            <h3>Texto de boas vindas</h3>
            <WelcomeContainer>
              <Flex maxHeight={'8rem'} maxWidth={'8rem'} margin={'1rem 1rem 0 0'}>
                <Image width={'100%'} height='100%' src={localStorage.getItem('logo') ?? ''}/>
              </Flex>
                <Description>{TextoBoasVindas}</Description>
            </WelcomeContainer>

            <h3>Vídeo introdutório</h3>
            <Flex>
                <VideoBox src={Arquivo.uri} />
                <Flex flexDir={'column'}>
                    <span>{Arquivo.name}</span>
                    <small>Tamanho:{Arquivo.size}</small>
                </Flex>
            </Flex>
        </Main>
  )
}
