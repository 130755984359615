import { MenuItem, MenuItemProps } from '@chakra-ui/react'

interface iStyledProps extends MenuItemProps{
  disabled?: boolean
}

export const StyledMenuItem: React.FC<iStyledProps> = ({ onClick, children, disabled }) => {
  return (
        <MenuItem
          _hover={{ borderLeft: '.2rem solid var(--Rosa)', color: 'var(--Roxo)' }}
          transition='all 0.2s'
          bg={'white'}
          fontWeight={'500'}
          fontFamily={'Roboto'}
          fontSize={'1rem'}
          padding={'1rem'}
          onClick={onClick}
          isDisabled={disabled}
        >
          {children}
        </MenuItem>
  )
}
