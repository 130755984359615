import { Flex } from '@chakra-ui/react'
import { MdClose } from 'react-icons/md'
import Modal from 'react-modal'
import { Header, Container } from './styles'
interface iModalProps {
  Titulo?: string
  Subtitulo?: string
  Width?: string
  disabledHeader?: boolean
  className?: string
  isOpen: boolean
  onClose: () => void
}

export const ModalBase: React.FC<iModalProps> = ({ isOpen, className, Titulo, Subtitulo, children, Width, disabledHeader, onClose }) => {
  return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            overlayClassName='react-modal-overlay'
            className={className ?? 'react-modal-content-scroll'}
        >
            <Container width={Width}>
                {!disabledHeader &&
                    <Header>
                        <Flex flexDirection={'column'}>
                            {Titulo && <h3>{Titulo}</h3>}
                            {Subtitulo && <span>{Subtitulo}</span>}
                        </Flex>
                        <Flex>
                            <MdClose size={24} onClick={onClose} />
                        </Flex>
                    </Header>
                }
                {children}
            </Container>
        </Modal>
  )
}
