import { Checkbox, Flex, FormControl, FormLabel, Grid, Input, Text, Textarea } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { FaCheckCircle, FaClock, FaMinusCircle, FaRegCommentDots, FaSpinner, FaUserTag } from 'react-icons/fa'
import { useLocation } from 'react-router-dom'
import { FindUser } from '../../../../components/FindBox'
import { iNomeId } from '../../../../interfaces'
import { OutlineContainer } from '../../Criar/styles'

interface iModel {
  id: string
  colaboradorAcao: boolean
  dataInicio?: string
  dataFim: string
  hora?: string
  assunto?: string
  competenciaId?: string
  titulo?: string
  descricao?: string
  motivo?: string
  status: number
  tipo: number
  temErro?: boolean
}

interface iAcaoProps {
  meuPDI: boolean
  acao: iModel
  competencias: iNomeId[]
  onRemove: (id: string) => void
  onUpdate: (acao: iModel) => void
}

export const AcaoPDI: React.FC<iAcaoProps> = ({ meuPDI, acao, competencias, onRemove, onUpdate }) => {
  const { pathname } = useLocation()

  const [Model, setModel] = useState<iModel>({
    id: '',
    colaboradorAcao: false,
    dataFim: '',
    assunto: '',
    tipo: 0,
    status: 0
  })

  function SelecionarCompetencia(id: string): void {
    onUpdate({
      colaboradorAcao: Model.colaboradorAcao,
      dataFim: Model.dataFim,
      id: Model.id,
      tipo: Model.tipo,
      dataInicio: Model.dataInicio,
      descricao: Model.descricao,
      motivo: Model.motivo,
      hora: Model.hora,
      titulo: Model.titulo,
      assunto: Model.assunto,
      competenciaId: id === '' ? undefined : id,
      status: Model.status
    })
  }

  useEffect(() => {
    setModel(acao)
  }, [acao])

  function TrocarVerbalização(texto: string): string {
    if (!meuPDI) {
      return texto
    }

    if (texto === 'O que fazer?') {
      return 'O que vou fazer'
    } else if (texto === 'Horas para dedicar') {
      return 'Quantas horas deseja dedicar'
    }

    return ''
  }

  const handleCheckColaboradorEditar = (value: boolean): void => {
    if (value) {
      onUpdate({
        id: Model.id,
        colaboradorAcao: value,
        assunto: Model.assunto,
        competenciaId: Model.competenciaId,
        dataFim: Model.dataFim,
        tipo: Model.tipo,
        status: Model.status
      })
    } else {
      onUpdate({
        colaboradorAcao: value,
        competenciaId: Model.competenciaId,
        dataFim: Model.dataFim,
        id: Model.id,
        tipo: Model.tipo,
        dataInicio: Model.dataInicio,
        descricao: Model.descricao,
        motivo: Model.motivo,
        hora: Model.hora,
        titulo: Model.titulo,
        assunto: Model.assunto,
        status: Model.status
      })
    }
  }

  return (
        <OutlineContainer
            className={(Model.status === 5 || Model.status === 6 || Model.status === 7) ? 'disabled' : ''}
            theme={acao.temErro ? 'Red' : 'Gray4'}
        >
            {
                (acao.status === 0) && (
                    <Flex marginBottom={'1rem'} justifyContent={'end'}>
                        <FaMinusCircle onClick={() => onRemove(Model.id)}
                            cursor={'pointer'}
                            size={20} color='var(--terc3)'
                        />
                    </Flex>
                )
            }

            {
                (Model.tipo === 0) && (
                    <Flex justifyContent={'center'}>
                        <Flex width={'100%'} flexDir={'column'} alignItems='center'>
                            <Text textAlign={'center'} marginBottom='1rem' fontWeight={'bold'} color='var(--Gray1)' fontSize={'1.25rem'}>Como gostaria de criar esta ação?</Text>
                            <Grid width={'50%'} gap={'2rem'} templateColumns='repeat(2, 1fr)'>
                                <Flex
                                    onClick={() => setModel({ ...Model, tipo: 1 })}
                                    color='var(--Azul)'
                                    cursor={'pointer'}
                                    _hover={{
                                      color: 'var(--c1)',
                                      bg: 'var(--Azul)'
                                    }}
                                    alignItems={'center'}
                                    border={'5px solid var(--Azul)'}
                                    gap='.3rem' borderRadius='1rem'
                                    padding={'.5rem'}>
                                    <FaUserTag size={80} />
                                    <Text
                                        textAlign={'center'}
                                        fontSize={'1.25rem'}
                                        fontWeight={'600'}
                                    >Através de uma competência</Text>
                                </Flex>

                                <Flex
                                    onClick={() => setModel({ ...Model, tipo: 2 })}
                                    color='var(--Azul)'
                                    cursor={'pointer'}
                                    _hover={{
                                      color: 'var(--c1)',
                                      bg: 'var(--Azul)'
                                    }}
                                    alignItems={'center'}
                                    border={'5px solid var(--Azul)'}
                                    gap='.3rem' borderRadius='1rem'
                                    padding={'.5rem'}>
                                    <FaRegCommentDots size={80} />
                                    <Text padding='1rem' textAlign={'center'} fontSize={'1.25rem'} fontWeight={'600'}>Assunto personalizado</Text>
                                </Flex>
                            </Grid>
                        </Flex>
                    </Flex>
                )
            }

            {
                (Model.tipo > 0) && (
                    <>
                        <Flex marginBottom={'1rem'} gap={'1rem'}>
                            {
                                (Model.tipo === 2) && (
                                    <FormControl>
                                        <FormLabel>Assunto a ser tratado</FormLabel>
                                        <Input
                                            placeholder='Digite um assunto'
                                            // isDisabled={pathname.includes('Editar')}
                                            onBlur={(e) => onUpdate({
                                              colaboradorAcao: Model.colaboradorAcao,
                                              competenciaId: Model.competenciaId,
                                              dataFim: Model.dataFim,
                                              id: Model.id,
                                              tipo: Model.tipo,
                                              dataInicio: Model.dataInicio,
                                              descricao: Model.descricao,
                                              motivo: Model.motivo,
                                              hora: Model.hora,
                                              titulo: Model.titulo,
                                              assunto: e.target.value,
                                              status: Model.status
                                            })}
                                            value={Model.assunto}
                                            onChange={(e) => setModel({ ...Model, assunto: e.target.value })}
                                            borderColor={'var(--Gray4)'}
                                        />
                                    </FormControl>
                                )
                            }

                            {
                                (Model.tipo === 1) && (
                                    <FormControl>
                                        <FormLabel>Competência vinculada</FormLabel>
                                        <FindUser
                                            placeholder='Digite uma competência'
                                            lista={competencias}
                                            onChoice={SelecionarCompetencia}
                                            isNotUser
                                            selecionado={Model.competenciaId}
                                        />
                                    </FormControl>
                                )
                            }

                            {
                                (!Model.colaboradorAcao) && (
                                    <FormControl>
                                        <FormLabel>Data para iniciar</FormLabel>
                                        <Input
                                            value={Model.dataInicio ?? ''}
                                            onChange={(e) => setModel({ ...Model, dataInicio: e.target.value })}
                                            type={'date'}
                                            borderColor={'var(--Gray4)'}
                                            onBlur={(e) => onUpdate({
                                              colaboradorAcao: Model.colaboradorAcao,
                                              competenciaId: Model.competenciaId,
                                              dataFim: Model.dataFim,
                                              id: Model.id,
                                              tipo: Model.tipo,
                                              dataInicio: e.target.value,
                                              descricao: Model.descricao,
                                              motivo: Model.motivo,
                                              hora: Model.hora,
                                              titulo: Model.titulo,
                                              assunto: Model.assunto,
                                              status: Model.status
                                            })}
                                        />
                                    </FormControl>
                                )
                            }

                            <FormControl>
                                <FormLabel>Data para finalizar</FormLabel>
                                <Input
                                    // isDisabled={pathname.includes('Editar')}
                                    type={'date'}
                                    borderColor={'var(--Gray4)'}
                                    value={Model.dataFim ?? ''}
                                    onChange={(e) => setModel({ ...Model, dataFim: e.target.value })}
                                    onBlur={(e) => onUpdate({
                                      colaboradorAcao: Model.colaboradorAcao,
                                      competenciaId: Model.competenciaId,
                                      dataFim: e.target.value,
                                      id: Model.id,
                                      tipo: Model.tipo,
                                      dataInicio: Model.dataInicio,
                                      descricao: Model.descricao,
                                      motivo: Model.motivo,
                                      hora: Model.hora,
                                      titulo: Model.titulo,
                                      assunto: Model.assunto,
                                      status: Model.status
                                    })}
                                />
                            </FormControl>

                            {
                                (!Model.colaboradorAcao) && (
                                    <FormControl>
                                        <FormLabel>{TrocarVerbalização('Horas para dedicar')}</FormLabel>
                                        <Flex alignItems={'center'}>
                                            <Input
                                                width={'6rem'}
                                                marginRight={'.5rem'}
                                                type={'number'}
                                                borderColor={'var(--Gray4)'}
                                                value={Model.hora ?? ''}
                                                onChange={(e) => setModel({ ...Model, hora: e.target.value })}
                                                onBlur={(e) => onUpdate({
                                                  id: Model.id,
                                                  colaboradorAcao: Model.colaboradorAcao,
                                                  competenciaId: Model.competenciaId,
                                                  dataFim: Model.dataFim,
                                                  tipo: Model.tipo,
                                                  dataInicio: Model.dataInicio,
                                                  descricao: Model.descricao,
                                                  motivo: Model.motivo,
                                                  hora: e.target.value,
                                                  titulo: Model.titulo,
                                                  assunto: Model.assunto,
                                                  status: Model.status
                                                })}
                                            />
                                            <span>Horas</span>
                                        </Flex>
                                    </FormControl>
                                )
                            }

                            {
                                (pathname.includes('Editar')) && (
                                    <Flex alignItems={'end'} marginRight='1rem'>
                                        {
                                            (Model.status === 5) && (
                                                <Flex alignItems={'center'} color='var(--Blue2)' flexDir={'column'}>
                                                    <FaSpinner size={40} />
                                                    <Text marginTop={'4px'} width={'max-content'} fontWeight={'bold'}>Em andamento</Text>
                                                </Flex>
                                            )
                                        }

                                        {
                                            (Model.status === 7) && (
                                                <Flex alignItems={'center'} color='var(--Green3)' flexDir={'column'}>
                                                    <FaCheckCircle size={40} />
                                                    <Text marginTop={'4px'} width={'max-content'} fontWeight={'bold'}>Concluído</Text>
                                                </Flex>
                                            )
                                        }

                                        {
                                            (Model.status === 6) && (
                                                <Flex alignItems={'center'} color='var(--Red)' flexDir={'column'}>
                                                    <FaClock size={40} />
                                                    <Text marginTop={'4px'} width={'max-content'} fontWeight={'bold'}>Atrasada</Text>
                                                </Flex>
                                            )
                                        }
                                    </Flex>
                                )
                            }
                        </Flex>
                        {
                            (!meuPDI && !pathname.includes('Editar')) && (
                                <Flex marginBottom={'1rem'}>
                                    <FormControl as={Flex}>
                                        <Checkbox
                                            isChecked={Model.colaboradorAcao}
                                            onChange={(e) => handleCheckColaboradorEditar(e.target.checked)}
                                            marginRight={'.5rem'} borderColor={'var(--Gray4)'}
                                        />
                                        <span>Colaborador deve inserir as ações</span>
                                    </FormControl>
                                </Flex>
                            )
                        }
                        {
                            (!Model.colaboradorAcao) && (
                                <Flex gap={'1rem'} flexDir={'column'}>
                                    <FormControl>
                                        <FormLabel>{TrocarVerbalização('O que fazer?')}</FormLabel>
                                        <Input
                                            value={Model.titulo ?? ''}
                                            onChange={(e) => setModel({ ...Model, titulo: e.target.value })}
                                            placeholder='Adicione um titulo curto' borderColor={'var(--Gray4)'}
                                            onBlur={(e) => onUpdate({
                                              colaboradorAcao: Model.colaboradorAcao,
                                              dataFim: Model.dataFim,
                                              competenciaId: Model.competenciaId,
                                              id: Model.id,
                                              tipo: Model.tipo,
                                              dataInicio: Model.dataInicio,
                                              descricao: Model.descricao,
                                              motivo: Model.motivo,
                                              hora: Model.hora,
                                              titulo: e.target.value,
                                              assunto: Model.assunto,
                                              status: Model.status
                                            })}
                                        />
                                    </FormControl>

                                    <FormControl>
                                        <FormLabel>Como será realizada essa ação?</FormLabel>
                                        <Textarea
                                            value={Model.descricao ?? ''}
                                            onChange={(e) => setModel({ ...Model, descricao: e.target.value })}
                                            placeholder='Adicione uma descrição detalhada da ação'
                                            borderColor={'var(--Gray4)'}
                                            onBlur={(e) => onUpdate({
                                              colaboradorAcao: Model.colaboradorAcao,
                                              dataFim: Model.dataFim,
                                              competenciaId: Model.competenciaId,
                                              id: Model.id,
                                              tipo: Model.tipo,
                                              dataInicio: Model.dataInicio,
                                              descricao: e.target.value,
                                              motivo: Model.motivo,
                                              hora: Model.hora,
                                              titulo: Model.titulo,
                                              assunto: Model.assunto,
                                              status: Model.status
                                            })}
                                        />
                                    </FormControl>

                                    <FormControl>
                                        <FormLabel>Qual o motivo para se trabalhar essa competência?</FormLabel>
                                        <Textarea
                                            value={Model.motivo ?? ''}
                                            onChange={(e) => setModel({ ...Model, motivo: e.target.value })}
                                            placeholder='Adicione uma descrição detalhada do motivo'
                                            borderColor={'var(--Gray4)'}
                                            onBlur={(e) => onUpdate({
                                              colaboradorAcao: Model.colaboradorAcao,
                                              dataFim: Model.dataFim,
                                              competenciaId: Model.competenciaId,
                                              id: Model.id,
                                              tipo: Model.tipo,
                                              dataInicio: Model.dataInicio,
                                              descricao: Model.descricao,
                                              motivo: e.target.value,
                                              hora: Model.hora,
                                              titulo: Model.titulo,
                                              assunto: Model.assunto,
                                              status: Model.status
                                            })}
                                        />
                                    </FormControl>
                                </Flex>
                            )
                        }
                    </>
                )
            }
        </OutlineContainer>
  )
}
