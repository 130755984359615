/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { Checkbox, Flex } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { FaCheck, FaFilter, FaPaperPlane } from 'react-icons/fa'
import { Button } from '../../../../../components/Button'
import { iConvite } from '../../../../../interfaces'
import { CircleText } from '../CircleText'
import { TableDiv } from './styles'

interface iTabelaprops {
  rows: iConvite[]
  selecionados?: string[]
  onCheck: (id: string, ids?: string[]) => void
  onSend: (id: string) => void
  isEdit?: boolean
}

export const TableConvites: React.FC<iTabelaprops> = ({ rows, isEdit, selecionados, onCheck, onSend }) => {
  const [Rows, setRows] = useState<iConvite[]>([])
  const [Selecionados, setSelecionados] = useState<string[]>([])
  const [CheckAll, setCheckAll] = useState(false)

  useEffect(() => {
    setRows(rows)
  }, [rows])

  useEffect(() => {
    if (selecionados !== undefined) { setSelecionados(selecionados) }
  }, [selecionados])

  function FilterBy(tipo: number): void {
    const arr = [...Rows]
    arr.sort((a, b) => {
      switch (tipo) {
        case 0:
          return a.nome.toLowerCase() > b.nome.toLowerCase() ? 1 : -1

        case 1:
          return a.email.toLowerCase() > b.email.toLowerCase() ? 1 : -1

        case 2:
          return a.area.toLowerCase() > b.area.toLowerCase() ? 1 : -1

        default:
          return a.nome.toLowerCase() > b.nome.toLowerCase() ? 1 : -1
      }
    })
    setRows(arr)
  }

  function SelecionarTodos(): void {
    onCheck('', Rows.map((e) => { return e.id }))
  }

  useEffect(() => {
    setCheckAll(Selecionados.length === rows.length)
  }, [Selecionados])

  return (
    <TableDiv>
      <table>
        <thead>
          <tr>
            {isEdit && <th>
              <Flex gap={'0.50rem'}>
              <span>Selecionar todos </span> <Button onClick={() => SelecionarTodos()} VarColor={CheckAll ? 'a4' : 'c4'} width={'0.5rem'} height={'0.8rem'} borderColor={'var(--a4)'} > <FaCheck color={'var(--a1)'} width={'1rem'} height={'1rem'}/></Button>
              </Flex></th>}
            <th onClick={() => FilterBy(0)}>
              <div>Nome<FaFilter /></div>
            </th>
            <th onClick={() => FilterBy(1)}>
              <div>Email<FaFilter /></div>
            </th>
            <th onClick={() => FilterBy(2)}>
              <div>Área<FaFilter /></div>
            </th>
            {!isEdit &&
              <th onClick={() => FilterBy(3)}>
                <div>Status<FaFilter /></div>
              </th>
            }
          </tr>
        </thead>
        <tbody>
          {
            Rows.map((e, i) => {
              return (
                <tr key={i}>
                  {isEdit && <td>
                    <Checkbox isChecked={Selecionados.find(r => r === e.id) !== undefined} onChange={() => onCheck(e.id)} borderColor={'var(--a4)'} />
                  </td>
                  }
                  <td>{e.nome}</td>
                  <td>{e.email}</td>
                  <td>{e.area}</td>
                  {!isEdit && <td>
                    <Flex justifyContent={'center'} gap={'1rem 1rem'} flexWrap={'wrap'}>
                      <CircleText fontsize='14px'
                        varColorCircle={e.status === 'Não enviado' ? 'c5' : e.status === 'Enviado' ? 'Rosa' : e.status === 'Visualizado' ? 'v2' : ''}
                        varColorText=''
                      >
                        {e.status}
                      </CircleText>
                      {
                        (e.status === 'Não enviado') && (
                          <Button
                            onClick={() => onSend(e.id)}
                            leftIcon={<FaPaperPlane />}
                            color={'var(--a5)'}
                            VarColor='Yellow5'
                          >Enviar</Button>
                        )
                      }

                      {
                        (e.status === 'Enviado') && (
                          <Button
                            onClick={() => onSend(e.id)}
                            leftIcon={<FaPaperPlane />}
                            color={'var(--a5)'}
                            VarColor='secundario2'
                          >Reenviar</Button>
                        )
                      }
                    </Flex>
                  </td>}
                </tr>
              )
            })
          }
        </tbody>
      </table>
    </TableDiv>
  )
}
