import { FormControl, FormLabel, Input, InputProps, Text } from '@chakra-ui/react'

interface iFormProps extends InputProps {
  label?: string
  errorMessage?: string
  error?: boolean
}

export const InputFormControl: React.FC<iFormProps> = ({ label, error, errorMessage, ...rest }) => {
  return (
    <FormControl width={'100%'} marginRight={'1rem'}>
      {label && <FormLabel color={'var(--Gray1)'} fontSize={'xl'}>{label}</FormLabel>}
      <Input {...rest} borderColor='var(--Gray4)' />
      {
        (error && errorMessage) && (
          <Text marginTop={'.5rem'} color={'var(--terc3)'}>{errorMessage}</Text>
        )
      }
    </FormControl>
  )
}
