import styled from 'styled-components'

export const Label = styled.label`
cursor: pointer;
text-decoration: underline;
`

export const InputFile = styled.input`
display: none;
`
