import styled from 'styled-components'

export const InfoBox = styled.div`
display: flex;
align-items: center;
background: var(--terc5);
justify-content: center;
color: var(--c7);
min-width: 10rem;
max-width: 16rem;
border-radius: 0.625rem;
padding: 1rem;
box-shadow: var(--SombraBtns);
span{
    margin-right:.5rem;
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 4.75rem;
}
`

export const AlertText = styled.span`
font-weight: 700;
font-size: 1.375rem;
color: var(--secundario5);
`

export const GridColumn = styled.div`
display: grid;
grid-template-columns: repeat(1,1fr);
gap: 1.5rem ;
`
