import { Avatar } from '@chakra-ui/react'
import React from 'react'
import { FaCheck, FaTrashAlt } from 'react-icons/fa'
import { FiEdit } from 'react-icons/fi'
import { MdBlock } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../../../../../contexts/AuthContext'
import { IEmployee } from '../../../../../../interfaces'
import { parseJwt } from '../../../../../../services/token'
import { PropInvalida } from '../../../../../../Utils/Helper'

import {
  AvatarColumn,
  Container,
  SmallColumn,
  SmallColumnHeader
} from './styles'

interface IEmployeeTableProps {
  employeeList: IEmployee[]
  onEdit: (employee: IEmployee) => void
  onDelete: (employee: IEmployee) => void
}

export const EmployeeTable: React.FC<IEmployeeTableProps> = ({
  employeeList,
  onEdit,
  onDelete
}) => {
  const { Permissoes } = useAuth()
  const nav = useNavigate()
  return (
    <Container>
      <table>
        <thead>
          <tr>
            <th> </th>
            <th>Nome</th>
            <th>Email</th>
            <th>Função</th>
            <th>Área</th>
            <SmallColumnHeader>Gestor</SmallColumnHeader>
            {parseJwt().adm === 'True' && <th> </th>}
          </tr>
        </thead>

        <tbody>
          {employeeList.map((employee) => (
            <tr key={employee.id} >
              <AvatarColumn onClick={() => {
                if (Permissoes.jornada) {
                  nav(`/Rh/VisualizarPerfil/${employee.id}`)
                }
              }}>
                <div>
                  <Avatar
                    cursor={'pointer'}
                    src={employee.avatar}
                    name={employee.nome}
                  />
                </div>
              </AvatarColumn>
              <td style={{ cursor: 'pointer' }} onClick={() => {
                if (Permissoes.jornada) {
                  nav(`/Rh/VisualizarPerfil/${employee.id}`)
                }
              }}>
                <strong>{employee.nome}</strong>
              </td>
              <td style={{ cursor: 'pointer' }} onClick={() => {
                if (Permissoes.jornada) {
                  nav(`/Rh/VisualizarPerfil/${employee.id}`)
                }
              }}>
                <span>{employee.email}</span>
              </td>
              <td>
                <span>{employee.cargo?.nome}</span>
              </td>
              <td>
                <span>{employee.departamento?.nome}</span>
              </td>
              <SmallColumn isActive={employee.gestorDeArea}>
                <FaCheck />
              </SmallColumn>
              {
                parseJwt().adm === 'True' &&
                <SmallColumn>
                  <div>
                    <button type="button" onClick={() => onEdit(employee)}>
                      <FiEdit />
                    </button>
                    {PropInvalida(employee.dataDesligamento) &&
                      <button type="button" onClick={() => onDelete(employee)}>
                        <FaTrashAlt size={24} />
                      </button>}

                    {!PropInvalida(employee.dataDesligamento) &&
                      <button type="button">
                        <MdBlock title='Colaborador etsá em processo de desligamento' style={{ opacity: '50%' }} size={30} />
                      </button>}
                  </div>
                </SmallColumn>
              }
            </tr>
          ))}
        </tbody>
      </table>
    </Container>
  )
}
