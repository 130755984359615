import { Tree } from 'react-organizational-chart'
import { DepartmentCard } from './components/DepartmentCard'
import { ChartNode } from './components/ChartNode'

import { Container, Content } from './styles'
import { IDepartment } from '../../../../../../interfaces'

interface IDepartmentsOrgChartProps {
  departmentTrees: IDepartment[]
  onOpenModalAddEmploye: (id: string) => void
  valueSelect: string
  currentArea: IDepartment
}

export const DepartmentsOrgChart: React.FC<IDepartmentsOrgChartProps> = ({
  departmentTrees,
  onOpenModalAddEmploye,
  valueSelect,
  currentArea
}) => {
  if (valueSelect === 'todos') {
    return (
          <Container>
            {departmentTrees.map((treeRoot) =>
              !treeRoot.departamentoFilhos?.length
                ? (
                <Content key={treeRoot.id} hasBrothers={departmentTrees.length > 1}>
                  <Tree
                    lineWidth="4px"
                    lineColor="#BDBDBD"
                    lineBorderRadius="4px"
                    label={<DepartmentCard onOpenModalAddEmploye={onOpenModalAddEmploye} department={treeRoot} />}
                  >
                    <></>
                  </Tree>
                </Content>
                  )
                : (
                <Content key={treeRoot.id} hasBrothers={departmentTrees.length > 1}>
                      <Tree
                        lineWidth="4px"
                        lineColor="#BDBDBD"
                        lineBorderRadius="4px"
                        label={<DepartmentCard onOpenModalAddEmploye={onOpenModalAddEmploye} department={treeRoot} />}
                      >
                        <ChartNode onOpenModalAddEmploye={onOpenModalAddEmploye} departments={treeRoot.departamentoFilhos}/>
                      </Tree>
                </Content>
                  )
            )}
    </Container>
    )
  } else {
    return (
        <Container>
              <Content key={currentArea?.id}>
                <Tree
                  lineWidth="4px"
                  lineColor="#BDBDBD"
                  lineBorderRadius="4px"
                  label={<DepartmentCard onOpenModalAddEmploye={onOpenModalAddEmploye} department={currentArea} />}
                >
                   <ChartNode onOpenModalAddEmploye={onOpenModalAddEmploye} departments={currentArea.departamentoFilhos ?? []} />
                </Tree>
              </Content>
    </Container>
    )
  }
}
