import { AtributoContainer } from '../AtributoContainer'
import { Container, ValueColumn } from './styles'

interface iAverageBoxProps{
  margin?: string
  url: string
  nome: string
  media: number
}
export const AvaregeBox: React.FC<iAverageBoxProps> = ({ margin, url, nome, media }) => {
  return (
        <Container margin={margin}>
            <AtributoContainer size='sm' fontSize='0.875rem' color='Preto' url={url}>
                {nome}
            </AtributoContainer>
            <ValueColumn>
              <span>Média</span>
              <h2>{media.toFixed(2)}</h2>
            </ValueColumn>
        </Container>
  )
}
