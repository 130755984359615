import { useEffect, useState } from 'react'
import { Container, FlexIcon } from './styles'
import { iReuniao } from '../../../../../../../interfaces'
import { appApi } from '../../../../../../../services/appApi'
import { FaClipboardList, FaCog, FaMapMarkerAlt, FaUserAlt } from 'react-icons/fa'
import { Flex } from '@chakra-ui/react'
import { Button } from '../../../../../../../components/Button'
import { useNavigate } from 'react-router-dom'
interface ITipoCardConfigReuniao {
  ReuniaoId: string
  processoId: string
}

export const CardReuniao: React.FC<ITipoCardConfigReuniao> = ({ ReuniaoId, processoId }) => {
  const nav = useNavigate()
  const [Reuniao, setReuniao] = useState<Omit<iReuniao, 'id'>>({ tipo: 0, responsavel: 0, titulo: '' })

  useEffect(() => {
    GetReuniaoPorId()
  }, [])

  function GetReuniaoPorId(): void {
    appApi.get(`Reuniao/${ReuniaoId}`)
      .then(response => { setReuniao(response.data) }).catch(err => console.error(err))
  }

  function ObterNomePeloTipo(Tipo: number): string {
    if (Tipo === 0) {
      return 'RH'
    } else if (Tipo === 1) {
      return 'Colaborador'
    } else if (Tipo === 2) {
      return 'Líder'
    } else if (Tipo === 3) {
      return 'Pares'
    } else if (Tipo === 4) {
      return 'Superiores'
    } else if (Tipo === 5) {
      return 'Alta liderança'
    } else {
      return ''
    }
  }

  return (
    <Container>
      <FlexIcon>
        <FaUserAlt size={30} />
        <div>
          <h3>Responsável</h3>
          <strong>{Reuniao.responsavel === 0 ? 'RH' : Reuniao.responsavel === 1 ? 'Gestor' : Reuniao.emailResponsavel}</strong>
        </div>
      </FlexIcon>

      <FlexIcon>
        <FaUserAlt size={30} />
        <div>
          <h3>Participantes</h3>
          {
            Reuniao.participantes !== undefined && Reuniao.participantes.length > 0
              ? <div>
                {
                  Reuniao.participantes?.map((e: any, i) => {
                    return (
                      <strong key={i}>{ObterNomePeloTipo(e)}{Reuniao.participantes !== undefined && i === Reuniao.participantes?.length - 1 ? '' : '/'}</strong>
                    )
                  })
                }
              </div>
              : <strong>O responsável ainda não selecionou os participantes</strong>
          }

        </div>
      </FlexIcon>

      <FlexIcon>
        <FaMapMarkerAlt size={30} />
        <div>
          <h3>Local da reunião</h3>
          <strong>{Reuniao.tipo === 2 ? 'Definido pelo responsável' : Reuniao.local}</strong>
        </div>
      </FlexIcon>

      {
        (Reuniao.descricao !== null) && (
          <>
            <FlexIcon>
              <FaClipboardList size={30} />
              <div>
                <h3>Descrição</h3>
                <small dangerouslySetInnerHTML={{ __html: Reuniao.descricao ?? '' }}></small>
              </div>
            </FlexIcon>
          </>
        )
      }
      {/* <FlexIcon>
        <MdInsertChart size={35} />
        <div>
          <h3>Apontamentos:</h3>
          <small>Aqui estará as observações levantadas pelo responsável ou pelo gestor caso esse faça parte dos participantes afim de registrar qualquer situação ocorrida, deixando armazenada para visualizações futuras e acompanhamentos.</small>
        </div>
      </FlexIcon> */}

      <Flex justifyContent={'center'}>
        <Button onClick={() => nav(`/Rh/Reuniao/Config/${ReuniaoId}/${processoId}`)} VarColor='Azul' rightIcon={<FaCog/>}>Configurar reunião</Button>
      </Flex>
    </Container>
  )
}
