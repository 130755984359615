import { v4 as uuidv4 } from 'uuid'
import { Avatar, Flex, FormControl, FormLabel, Radio, RadioGroup, Select, Stack, useToast } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Button } from '../../../components/Button'
import { FindUser } from '../../../components/FindBox'
import { WhiteBox } from '../../../components/WhiteBox'
import { iNomeId } from '../../../interfaces'
import { appApi } from '../../../services/appApi'
import { Body } from '../../Layouts/Body'
import { AcaoPDI } from '../components/Acao'
import { ModalSucess } from '../components/ModalSucess'
import { CardPDI, OutlineContainer, Subtitulo } from './styles'
import { parseJwt } from '../../../services/token'
import { ModalDelete } from '../../../components/ModalDelete'
import { FaPlus } from 'react-icons/fa'

interface iModelAcao {
  id: string
  colaboradorAcao: boolean
  dataInicio?: string
  dataFim: string
  hora?: string
  assunto?: string
  competenciaId?: string
  titulo?: string
  descricao?: string
  motivo?: string
  tipo: number
  status: number
  temErro?: boolean
}

interface iModel {
  status: number
  origem: number
  participante?: iAvaliadoPDI
  acoes: iModelAcao[]
}

interface iAvaliadoPDI {
  id: string
  nome: string
  area: string
  avatar: string
}

interface iPostModel {
  responsavelId?: string
  colaboradorId?: string
  origem: number
  acoes?: iPostAcao[]
}

interface iPostAcao {
  id: string
  assunto?: string
  competenciaId?: string
  dataInicio?: string
  dataFim: string
  horas?: number
  permitirAlteracao: boolean
  titulo: string
  descricao: string
  motivo: string
}

type iLocationProps = {
  pdiId: string
}

export const CriarPDI: React.FC = () => {
  const { adm, role } = parseJwt()
  const { pdiId } = useParams<iLocationProps>()
  const { pathname } = useLocation()
  const toast = useToast()
  const nav = useNavigate()
  const [Model, setModel] = useState<iModel>({
    origem: 0,
    acoes: [],
    status: 0
  })

  const [PostModel, setPostModel] = useState<iPostModel>({
    origem: 0
  })

  const [MeuPDI, setMeuPDI] = useState('1')
  const [ModalSucessIsOpen, setModalSucessIsOpen] = useState(false)
  const [ModalDeleteIsOpen, setModalDeleteIsOpen] = useState(false)
  const [AcaoId, setAcaoId] = useState<string>()

  const [Colaboradores, setColaboradores] = useState<iNomeId[]>([])
  const [Responsaveis, setResponsaveis] = useState<iNomeId[]>([])
  const [Competencias, setCompetencias] = useState<iNomeId[]>([])

  const [ResponsavelIsInvalid, setResponsavelIsInvalid] = useState(false)
  const [ColaboradorIsInvalid, setColaboradorIsInvalid] = useState(false)

  const handleCheckMeuPDI = (value: string): void => {
    setMeuPDI(value)
    if (value === '1') {
      setPostModel(
        {
          ...PostModel,
          colaboradorId: undefined,
          responsavelId: undefined
        })
    }
  }

  function SelecionarResponsavel(id: string): void {
    setPostModel({ ...PostModel, responsavelId: id })
  }

  function SelecionarParticipante(id: string): void {
    setPostModel({ ...PostModel, colaboradorId: id })
  }

  function ObterColaboradores(): void {
    appApi.get('PDI/Colaborador')
      .then(res => setColaboradores(res.data))
      .catch(err => console.log(err))
  }

  function ObterResponsaveis(): void {
    appApi.get('PDI/Colaborador?altaLideranca=true')
      .then(res => setResponsaveis(res.data))
      .catch(err => console.log(err))
  }

  function ObterCompetencias(): void {
    appApi.get('PDI/Competencia')
      .then(res => setCompetencias(res.data))
      .catch(err => console.log(err))
  }

  function AdicionarAcao(): void {
    const acoes = [...Model.acoes]
    acoes.push({
      id: uuidv4(),
      assunto: '',
      colaboradorAcao: false,
      dataFim: '',
      tipo: 0,
      status: 0
    })
    setModel({ ...Model, acoes: acoes })
  }

  function onOpenModalDelete(id: string): void {
    setModalDeleteIsOpen(true)
    setAcaoId(id)
  }

  function onCloseModalDelete(): void {
    setModalDeleteIsOpen(false)
    setAcaoId(undefined)
  }

  function RemoverAcao(id: string): void {
    const copy = [...Model.acoes.filter(e => e.id !== id)]
    setModel({ ...Model, acoes: [] })
    setModel({ ...Model, acoes: copy })
  }

  function AtualizarAcao(acao: iModelAcao): void {
    const copy = [...Model.acoes]
    const pos = copy.findIndex(e => e.id === acao.id)
    if (pos !== -1) {
      copy[pos] = acao
      setModel({ ...Model, acoes: copy })
    }
  }

  function onCloseModalSucess(): void {
    nav('/Desempenho/PDI/Dashboard/Individual')
  }

  function EnviarPDI(): void {
    setColaboradorIsInvalid(false)
    setResponsavelIsInvalid(false)

    if (VerificarErrosAcoes()) {
      toast({
        title: 'Busque por ações de cor vermelha e verifique se todos os campos foram preenchidos',
        status: 'error',
        isClosable: false,
        position: 'top-right'
      })
      return
    }

    if (PostModel.colaboradorId?.length === 0 && MeuPDI === '2') {
      setColaboradorIsInvalid(true)
      toast({
        title: 'Defina um colaborador para este PDI',
        status: 'error',
        isClosable: false,
        position: 'top-right'
      })
      return
    }

    if (PostModel.responsavelId?.length === 0 && MeuPDI === '2') {
      setResponsavelIsInvalid(true)
      toast({
        title: 'Defina um responsável para este PDI',
        status: 'error',
        isClosable: false,
        position: 'top-right'
      })
      return
    }

    const form: iPostModel = {
      colaboradorId: PostModel.colaboradorId,
      origem: PostModel.origem,
      responsavelId: PostModel.responsavelId,
      acoes: Model.acoes.map((e) => {
        const model: iPostAcao = {
          id: e.id,
          dataFim: e.dataFim,
          descricao: e.descricao ?? '',
          motivo: e.motivo ?? '',
          permitirAlteracao: MeuPDI === '1' ? true : e.colaboradorAcao,
          titulo: e.titulo ?? '',
          assunto: e.assunto,
          competenciaId: e.competenciaId,
          dataInicio: e.dataInicio,
          horas: parseInt(e.hora ?? '0')
        }
        return model
      })
    }

    appApi.post('PDI', form)
      .then(() => {
        toast({
          title: 'PDI cadastrado com sucesso',
          status: 'success',
          isClosable: false,
          position: 'top-right'
        })
        setModalSucessIsOpen(true)
      })
      .catch(err => console.log(err))
  }

  function SalvarSair(): void {
    if (Model.acoes?.length === 0) {
      toast({
        title: 'Não selecionou nenhuma ação',
        status: 'error',
        isClosable: false,
        position: 'top-right'
      })
      return
    }

    if (VerificarErrosAcoes()) {
      toast({
        title: 'Busque por ações de cor vermelha e verifique se todos os campos foram preenchidos',
        status: 'error',
        isClosable: false,
        position: 'top-right'
      })
      return
    }

    if (PostModel.colaboradorId?.length === 0 && MeuPDI === '2') {
      toast({
        title: 'Defina um colaborador para este PDI',
        status: 'error',
        isClosable: false,
        position: 'top-right'
      })
      return
    }

    if (PostModel.responsavelId?.length === 0 && MeuPDI === '2') {
      toast({
        title: 'Defina um responsável para este PDI',
        status: 'error',
        isClosable: false,
        position: 'top-right'
      })
      return
    }

    const form: iPostModel = {
      colaboradorId: PostModel.colaboradorId,
      origem: PostModel.origem,
      responsavelId: PostModel.responsavelId,
      acoes: Model.acoes.map((e) => {
        const model: iPostAcao = {
          id: e.id,
          dataFim: e.dataFim,
          descricao: e.descricao ?? '',
          motivo: e.motivo ?? '',
          permitirAlteracao: MeuPDI === '1' ? true : e.colaboradorAcao,
          titulo: e.titulo ?? '',
          assunto: e.assunto,
          competenciaId: e.competenciaId,
          dataInicio: e.dataInicio,
          horas: parseInt(e.hora ?? '0')
        }
        return model
      })
    }
    appApi.post('PDI/?salvar=true', form)
      .then(() => {
        toast({
          title: 'PDI salvo com sucesso',
          status: 'success',
          isClosable: false,
          position: 'top-right'
        })
        nav('/Desempenho/PDI/Dashboard/Individual')
      })
      .catch(err => console.log(err))
  }

  function AtualizarPDI(): void {
    if (VerificarErrosAcoes()) {
      toast({
        title: 'Busque por ações de cor vermelha e verifique se todos os campos foram preenchidos',
        status: 'error',
        isClosable: false,
        position: 'top-right'
      })
      return
    }

    const form: iPostAcao[] = Model.acoes.map((e) => {
      const model: iPostAcao = {
        id: e.id,
        dataFim: e.dataFim,
        descricao: e.descricao ?? '',
        motivo: e.motivo ?? '',
        permitirAlteracao: MeuPDI === '1' ? true : e.colaboradorAcao,
        titulo: e.titulo ?? '',
        assunto: e.assunto,
        competenciaId: e.competenciaId,
        dataInicio: e.dataInicio,
        horas: parseInt(e.hora ?? '0')
      }
      return model
    })

    appApi.put(`PDI/${pdiId as string}`, form)
      .then(() => {
        toast({
          title: 'Atualizações enviadas com sucesso',
          status: 'success',
          isClosable: false,
          position: 'top-right'
        })
        setModalSucessIsOpen(true)
      })
      .catch(err => console.log(err))
  }

  function AtualizarSair(): void {
    if (VerificarErrosAcoes()) {
      toast({
        title: 'Busque por ações de cor vermelha e verifique se todos os campos foram preenchidos',
        status: 'error',
        isClosable: false,
        position: 'top-right'
      })
    }
    const form: iPostAcao[] = Model.acoes.map((e) => {
      const model: iPostAcao = {
        id: e.id,
        dataFim: e.dataFim,
        descricao: e.descricao ?? '',
        motivo: e.motivo ?? '',
        permitirAlteracao: MeuPDI === '1' ? true : e.colaboradorAcao,
        titulo: e.titulo ?? '',
        assunto: e.assunto,
        competenciaId: e.competenciaId,
        dataInicio: e.dataInicio,
        horas: parseInt(e.hora ?? '0')
      }
      return model
    })

    appApi.put(`PDI/${pdiId as string}/?salvar=true`, form)
      .then(() => {
        toast({
          title: 'Atualizações enviadas com sucesso',
          status: 'success',
          isClosable: false,
          position: 'top-right'
        })
        setModalSucessIsOpen(true)
      })
      .catch(err => console.log(err))
  }

  function VerificarErrosAcoes(): boolean {
    const comErros = Model.acoes.filter(e =>
      e.dataFim === '' ||
      (pathname.includes('Editar') && e.dataFim === null) ||
      e.tipo === 0 ||
      (e.tipo === 1 && e.competenciaId === undefined) ||
      (e.tipo === 2 && (e.assunto === '' || e.assunto === undefined)) ||
      (!e.colaboradorAcao && (e.dataInicio === undefined || e.dataInicio === '')) ||
      (!e.colaboradorAcao && (e.hora === undefined || e.hora === ''))
    )
    const copy = [...Model.acoes]
    comErros.forEach((e) => {
      copy[copy.indexOf(e)] = { ...e, temErro: true }
      setModel({ ...Model, acoes: copy })
    })
    return comErros.length > 0
  }

  function ActionClick(sair: boolean): void {
    if (Model.acoes?.length === 0) {
      toast({
        title: 'Não selecionou nenhuma ação',
        status: 'error',
        isClosable: false,
        position: 'top-right'
      })
      return
    }

    const criar = pathname.includes('Criar')

    if (criar && !sair) {
      EnviarPDI()
    } else if (criar && sair) {
      SalvarSair()
    } else if (!criar && !sair) {
      AtualizarPDI()
    } else if (!criar && sair) {
      AtualizarSair()
    }
  }

  function ObterPDI(): void {
    appApi.get(`PDI/${pdiId as string}/Editar`)
      .then(res => setModel(res.data))
      .catch(err => console.log(err))
  }

  function ObterTituloBtn(): string {
    if (pathname.includes('Criar') && MeuPDI === '1') {
      return 'Enviar PDI para aprovação'
    } else if (pathname.includes('Criar') && MeuPDI === '2') {
      return 'Enviar PDI para ser cadastrado'
    } else if (pathname.includes('Editar')) {
      return 'Enviar atualização'
    }
    return ''
  }

  function EnviarPDIOnboard(): void {
    if (VerificarErrosAcoes()) {
      toast({
        title: 'Busque por ações de cor vermelha e verifique se todos os campos foram preenchidos',
        status: 'error',
        isClosable: false,
        position: 'top-right'
      })
      return
    }

    const form: iPostModel = {
      colaboradorId: PostModel.colaboradorId,
      origem: PostModel.origem,
      responsavelId: PostModel.responsavelId,
      acoes: Model.acoes.map((e) => {
        const model: iPostAcao = {
          id: e.id,
          dataFim: e.dataFim,
          descricao: e.descricao ?? '',
          motivo: e.motivo ?? '',
          permitirAlteracao: MeuPDI === '1' ? true : e.colaboradorAcao,
          titulo: e.titulo ?? '',
          assunto: e.assunto,
          competenciaId: e.competenciaId,
          dataInicio: e.dataInicio,
          horas: parseInt(e.hora ?? '0')
        }
        return model
      })
    }

    appApi.put(`PDIEtapaColaborador/${pdiId ?? ''}/DefinirAcoes`, form)
      .then(() => {
        toast({
          title: 'Acões cadastradas com sucesso',
          status: 'success',
          isClosable: false,
          position: 'top-right'
        })
        nav('/Colaborador')
      })
      .catch(r => console.log(r))
  }

  useEffect(() => {
    ObterCompetencias()
    ObterResponsaveis()
    if ((adm === 'True' || role === '1' || role === '2')) {
      ObterColaboradores()
    }
    if (pdiId) {
      ObterPDI()
    }
  }, [])

  useEffect(() => {
    setPostModel({ ...PostModel, origem: Model.origem })
  }, [Model])

  return (
    <Body>
      <ModalDelete
        isOpen={ModalDeleteIsOpen}
        message='Deseja realmente excluir esta ação?'
        onRequestClose={onCloseModalDelete}
        onConfirm={() => RemoverAcao(AcaoId ?? '')}
      />

      <ModalSucess
        isOpen={ModalSucessIsOpen}
        onRequestClose={onCloseModalSucess}
      >
        {
          (pathname.includes('Criar') && MeuPDI === '2') && (
            <>
              <h2>PDI enviado para o</h2>
              <h2>colaborador !</h2>
            </>

          )
        }

        {
          (pathname.includes('Criar') && MeuPDI === '1') && (
            <>
              <h2>PDI enviado para o</h2>
              <h2>aprovação !</h2>
            </>

          )
        }

        {
          (pathname.includes('Editar')) && (
            <>
              <h2>Atualização enviada!</h2>
            </>
          )
        }
      </ModalSucess>
      <WhiteBox>
        <Flex marginBottom={'1rem'}>
          <h1>Desempenho {'>'} {pathname.includes('Criar') ? 'Criando' : 'Editando'} PDI</h1>
        </Flex>
        <OutlineContainer theme={'Azul'}>
          {
            (pathname.includes('Criar')) && (
              <>
                <Flex gap={'2rem'} alignItems={'start'}>
                  <RadioGroup display='flex'
                    justifyContent={'center'}
                    height={'2.5rem'}
                    onChange={(e) => handleCheckMeuPDI(e)}
                    value={MeuPDI}
                    marginRight={'1rem'}
                  >
                    <Stack gap={'2rem'} direction='row'>
                      <Radio borderColor={'var(--Gray4)'} value='1'>Meu PDI</Radio>
                      {
                        (adm === 'True' || role === '1' || role === '2') && (
                          <Radio borderColor={'var(--Gray4)'} value='2'>PDI para o</Radio>
                        )
                      }
                    </Stack>
                  </RadioGroup>
                  {
                    (MeuPDI === '2') && (
                      <Flex>
                        <FindUser
                          placeholder='Selecione um colaborador'
                          lista={Colaboradores}
                          onChoice={SelecionarParticipante}
                          isInvalid={ColaboradorIsInvalid}
                        />
                      </Flex>
                    )
                  }
                </Flex>
                <Flex marginTop={'1rem'} gap={'1rem'} width='60%'>
                  <FormControl>
                    <FormLabel>Origem do PDI</FormLabel>
                    <Select
                      value={Model.origem.toString()}
                      onChange={(e) => setModel({ ...Model, origem: parseInt(e.target.value) })}
                      maxWidth={'20.725rem'} borderColor={'var(--Gray4)'}
                    >
                      <option value={'0'}>Pesquisa</option>
                      <option value={'1'}>Feedback</option>
                      <option value={'2'}>Outra</option>
                      {pathname.includes('Onboard') && <option value={'4'}>Onboard</option>}
                    </Select>
                  </FormControl>

                  {
                    (MeuPDI === '2') && (
                      <FormControl>
                        <FormLabel>Responsável pela aprovação</FormLabel>
                        <FindUser
                          placeholder='Selecione um colaborador'
                          lista={Responsaveis}
                          onChoice={SelecionarResponsavel}
                          isInvalid={ResponsavelIsInvalid}
                        />
                      </FormControl>
                    )
                  }
                </Flex>
              </>
            )
          }

          {
            (pathname.includes('Editar') || pathname.includes('Onboard')) && (
              <Flex gap={'1rem'}>
                {
                  (Model.participante) && (
                    <Flex flexDir={'column'}>
                      <FormLabel>Dono do PDI</FormLabel>
                      <CardPDI>
                        <Avatar size={'lg'} marginRight={'1rem'} src={Model.participante.avatar} name={Model.participante.nome} />
                        <Flex textAlign={'center'} flexDir={'column'}>
                          <span>{Model.participante.nome}</span>
                          <strong>{Model.participante.area}</strong>
                        </Flex>
                      </CardPDI>
                    </Flex>
                  )
                }

                <FormControl>
                  <FormLabel>Origem do PDI</FormLabel>
                  <Select isDisabled value={Model.origem} isReadOnly maxWidth={'20.725rem'} borderColor={'var(--Gray4)'}>
                    <option value={'1'}>Pesquisa</option>
                    <option value={'2'}>Feedback</option>
                    <option value={'3'}>Outra</option>
                  </Select>
                </FormControl>
              </Flex>
            )
          }

          {
            (Model.acoes.length > 0) && (
              <Flex flexDir={'column'} marginTop={'1rem'}>
                <Subtitulo>Descreva ações práticas que você irá realizar para trabalhar neste PDI</Subtitulo>
                <Flex marginTop={'.5rem'} flexDir={'column'} gap={'1rem'}>
                  {
                    Model.acoes.map((e, i) => {
                      return (
                        <AcaoPDI
                          key={i}
                          onUpdate={AtualizarAcao}
                          onRemove={onOpenModalDelete}
                          acao={e}
                          competencias={Competencias}
                          meuPDI={MeuPDI === '1'}
                        />
                      )
                    })
                  }
                </Flex>
              </Flex>
            )
          }

        <Flex marginTop={'1rem'}>
          <Button onClick={AdicionarAcao} leftIcon={<FaPlus />} VarColor='Azul'>Adicionar outra ação</Button>
        </Flex>

        </OutlineContainer>
        <Flex marginTop={'1rem'} gap={'1rem'} justifyContent='end'>
          {!pathname.includes('Onboard') && (
            <Button size={'lg'} VarColor={'Azul'} onClick={() => ActionClick(true)}>Salvar e sair</Button>
          )}
          {!pathname.includes('Onboard') && (
            <Button
              onClick={() => ActionClick(false)}
              size={'lg'}
              VarColor={'Green2'}
            >{ObterTituloBtn()}</Button>
          )}
          {pathname.includes('Onboard') &&
            <Button
              onClick={EnviarPDIOnboard}
              size={'lg'}
              VarColor={'Green2'}
            >Enviar PDI</Button>
          }
        </Flex>
      </WhiteBox>
    </Body>
  )
}
