import styled from 'styled-components'

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 4rem;

  div {
    min-width: 17rem;
    border-radius: 0.25rem;
    padding: 2rem;

    background: var(--c1);
    border-radius: .5rem;
    box-shadow: var(--SombraBackground);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    strong {
      font-size: 4rem;
      font-weight: bold;
      color: var(--blue-600);
    }

    span {
      font-size: 1rem;
      color: var(--gray-500);

      margin-top: 1rem;
    }
  }
`
