/* eslint-disable @typescript-eslint/no-unused-vars */
import { Flex } from '@chakra-ui/react'
import React from 'react'
import { MdOutlineZoomIn, MdOutlineZoomOut } from 'react-icons/md'

import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch'
import { Button } from '../Button'

export const ZoomContainer: React.FC = ({ children }) => {
  return (
    <TransformWrapper
    panning={{
      disabled: true
    }}
    initialScale={0.5}
    centerZoomedOut={true}
  >
    {({ zoomIn, zoomOut, resetTransform, ...rest }) => {
      return (

      <Flex flexDir={'column'} width='100%'>
        <Flex gap={'1rem'}>
          <Button VarColor='Rosa' onClick={() => zoomIn()} rightIcon={<MdOutlineZoomIn/>}>Zoom</Button>
          <Button VarColor='Rosa' onClick={() => zoomOut()} rightIcon={<MdOutlineZoomOut/>}>Zoom</Button>
          <Button VarColor='Rosa' onClick={() => resetTransform()}>Reset zoom</Button>
        </Flex>
        <TransformComponent>
            {children}
        </TransformComponent>
      </Flex>
      )
    }}
  </TransformWrapper>
  )
}
