/* eslint-disable @typescript-eslint/no-misused-promises */
import { useForm } from 'react-hook-form'
import { Alert, AlertDescription, Button, Flex, FormControl, FormLabel, Input, Tooltip, useToast } from '@chakra-ui/react'
import { WhiteBox } from '../../../components/WhiteBox'
import { Body } from '../../Layouts/Body'
import { useState } from 'react'
import { ValidarSenha } from '../../../Utils/Helper'
import { FaQuestionCircle } from 'react-icons/fa'
import { SigahApi } from '../../../services/appApi'
import { parseJwt } from '../../../services/token'
import { useNavigate } from 'react-router-dom'

interface iPostPassword {
  senhaAtual: string
  novaSenha: string
  comfirmSenha: string
}

export const AlterarSenha: React.FC = () => {
  const toast = useToast()
  const [Erros, setErrors] = useState<number[]>([])

  const nav = useNavigate()

  const { register, handleSubmit } = useForm()
  const onSubmit = (e: any): void => {
    const data: iPostPassword = e
    setErrors([])

    if (!ValidarSenha(data.novaSenha)) {
      toast({
        title: 'Nova senha inválida',
        status: 'error',
        isClosable: false,
        description: 'A senha deve conter uma letra maiscula, um número e no mínimo 6 caractéres.',
        position: 'top-right',
        duration: 4000
      })
      setErrors(oldArray => [...oldArray, 1])
      return
    }

    // if (!ValidarSenha(data.comfirmSenha)) {
    //   setErrors(oldArray => [...oldArray, 2])
    //   return
    // }

    if (data.comfirmSenha !== data.novaSenha) {
      setErrors(oldArray => [...oldArray, 1])
      setErrors(oldArray => [...oldArray, 2])
      toast({
        title: 'Nova senha inválida',
        status: 'error',
        isClosable: false,
        description: 'A nova senha e sua confirmação devem ser iguais.',
        position: 'top-right',
        duration: 4000
      })
      return
    }

    const form = {
      uid: parseJwt().uid,
      senhaAtual: data.senhaAtual,
      senhaNova: data.novaSenha
    }

    SigahApi.post('api/UsuarioExterno/AlterarSenha', form).then(() => {
      toast({
        title: 'Senha alterada com sucesso',
        status: 'success',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      nav('/Colaborador')
    }).catch(err => {
      toast({
        title: 'Nova senha inválida',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      console.log(err)
    }
    )
  }

  return (
        <Body>
            <WhiteBox>
                <Flex justifyContent={'center'}>
                    <Flex width={'40%'} flexDirection={'column'}>
                        <Flex marginBottom={'1rem'} alignItems={'center'}>
                            <h2 style={{ marginRight: '1rem' }}>Alterar senha</h2>
                            <Tooltip
                                padding='2'
                                borderRadius='4'
                                placement='bottom'
                                color={'white'}
                                background={'var(--a4)'}
                                label='A senha deve conter entre 8 e 16 caracteres'
                                hasArrow
                                arrowSize={15}>
                                <div>
                                    <FaQuestionCircle size={'1rem'} cursor={'pointer'} color='var(--a2)' />
                                </div>
                            </Tooltip>
                        </Flex>
                        {
                            Erros.length > 0 && (
                                <Alert margin={'1rem 0rem'} borderRadius={'md'} status='error'>
                                    <AlertDescription fontWeight={'semibold'}>O formulário possui campos inválidos</AlertDescription>
                                </Alert>
                            )
                        }

                        <form onSubmit={handleSubmit(onSubmit)}>
                            <FormControl marginBottom={'1rem'} isInvalid={Erros.includes(0)} >
                                <FormLabel>Senha atual</FormLabel>
                                <Input
                                    borderColor={'var(--Gray4)'}
                                    type={'password'}
                                    {...register('senhaAtual')}
                                />
                            </FormControl>

                            <FormControl marginBottom={'1rem'} isInvalid={Erros.includes(1)} >
                                <FormLabel>Nova senha</FormLabel>
                                <Input
                                    borderColor={'var(--Gray4)'}
                                    type={'password'}
                                    {...register('novaSenha')}
                                />
                            </FormControl>

                            <FormControl marginBottom={'1rem'} isInvalid={Erros.includes(2)} >
                                <FormLabel>Confirmar nova senha</FormLabel>
                                <Input
                                    borderColor={'var(--Gray4)'}
                                    type={'password'}
                                    {...register('comfirmSenha')}
                                />
                            </FormControl>

                            <Flex>
                                <Button
                                    type='submit'
                                    size={'md'}
                                    bg={'var(--Azul)'}
                                    _hover={{ bg: 'var(--Azul)' }}
                                    _active={{ bg: 'var(--Azul)' }}
                                >Atualizar senha</Button>
                            </Flex>
                        </form>
                    </Flex>
                </Flex>
            </WhiteBox>
        </Body>
  )
}
