import { iColaboradoresCompetencia } from '../..'
import { Container } from './styles'

interface iTabelaProps {
  dados: iColaboradoresCompetencia[]
  index: number
}

export const TabelaColaboradores: React.FC<iTabelaProps> = ({ dados, index }) => {
  return (
    <Container>
      <thead>
        <tr>
          <th>Colaboradores</th>
          <th>Nota média na competência</th>
        </tr>
      </thead>
      <tbody>
        {dados[index].nome.map((e, i) => {
          return (<tr key={i}><th>{e}</th><th>{dados[index].nota[i]}</th></tr>
          )
        })
          }
      </tbody>
    </Container>
  )
}
