import styled from 'styled-components'

export const InfoBox = styled.div`
display: flex;
border:.25rem solid var(--${props => props.theme});
align-items: center;
justify-content: center;
max-width: 30rem;
color: var(--${props => props.theme});
width: 14rem;
border-radius: 1rem;
padding: 1rem;
span{
    margin-right:.5rem;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 3.75rem;
}
`
