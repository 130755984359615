import { Flex } from '@chakra-ui/react'
import { Button } from '../../../components/Button'
import { WhiteBox } from '../../../components/WhiteBox'
import { iColaboradorBase, iNomeId } from '../../../interfaces'
import { Body } from '../../Layouts/Body'
import { Tabela } from './Components/Tabela'
import { ContainerStatusPesquisa, LiLink, OutlineContainer } from './styles'
import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { appApi } from '../../../services/appApi'
import { useAuth } from '../../../contexts/AuthContext'

interface StatusPesquisaPercepcao {
  totalEmAndamento: number
  totalFinalizadas: number
}

interface StatusTurnOver {
  turnOver: number
  totalOnboarding: number
  totalDesligados: number
  totalProcessos: number
}

interface iDadoTabela {
  id: string
  colaborador: iColaboradorBase
  tipo: string
  inicio?: string
  progresso: number
  concluido: boolean
}

interface iProximoEvento {
  id: string
  dataInicio: string
  nomeParticipante: string
  assunto: string
}

interface iInfoList {
  id: string
  nome: string
  tipo: number
}

interface iModel {
  statusPesquisaPercepcao: StatusPesquisaPercepcao
  statusTurnOver: StatusTurnOver
  pesquisasEmAndamento: iInfoList[]
  proximosEventos: iProximoEvento[]
  tabela: iDadoTabela[]
  pesquisaseNps: iNomeId[]
}

export const DashboardJornada: React.FC = () => {
  const { permitirFuncao } = useAuth()
  const nav = useNavigate()
  const [Model, setModel] = useState<iModel>()
  const [IsLoading, setIsLoading] = useState(false)

  function GetDashhboard(): void {
    setIsLoading(true)
    appApi.get('ProcessoOnboard/Jornada/Dashboard')
      .then(res => {
        setModel(res.data)
        setIsLoading(false)
      })
      .catch(err => console.log(err))
  }

  useEffect(() => {
    GetDashhboard()
  }, [])

  return (
        <Body isLoading={IsLoading}>
            <WhiteBox>
                <Flex marginBottom={'1rem'} justifyContent={'space-between'}>
                    <h1>Jornada {'>'} Pesquisa</h1>
                    <Flex gap={'1rem'}>
                        {permitirFuncao('Jornada', '1on1') && <Button size={'lg'} VarColor='Azul' onClick={() => nav('/Jornada/ReuniaoOne')}>Reunião 1:1</Button>}
                        {permitirFuncao('Jornada', 'imersão-cultural') && <Button size={'lg'} VarColor='Azul' onClick={() => nav('/Jornada/Imersao')}>Imersão cultural</Button>}
                        {permitirFuncao('Jornada', 'trilha') && <Button size={'lg'} VarColor='Azul' onClick={() => nav('/Jornada/Onboard?tipo=0')}>Trilha de onboarding</Button>}
                    </Flex>
                </Flex>

                <Flex justifyContent={'space-between'} gap='1rem'>
                    <Flex flexDir={'column'} width='70%'>
                        <Flex gap={'1rem'} marginBottom='1rem'>
                            <OutlineContainer style={{ justifyContent: 'space-between' }} theme={'Azul'}>
                                <h3>Pesquisas de percepção</h3>
                                <ContainerStatusPesquisa>
                                    <Flex flexDir={'column'}>
                                        <h2>{Model?.statusPesquisaPercepcao.totalEmAndamento}</h2>
                                        <span>Em andamento</span>
                                    </Flex>
                                    <hr />
                                    <Flex flexDir={'column'}>
                                        <h2>{Model?.statusPesquisaPercepcao.totalFinalizadas}</h2>
                                        <span>Finalizadas nos últimos 12 meses</span>
                                    </Flex>
                                </ContainerStatusPesquisa>
                                {permitirFuncao('Jornada', 'pesquisa-percepcao') && <Button size={'sm'} VarColor='Azul' onClick={() => nav('/Rh/PesquisaPercepcao/Gestao')}>Gerenciamento de pesquisas</Button>}
                            </OutlineContainer>

                            {/* <OutlineContainer style={{ justifyContent: 'space-between' }} theme={'Azul'}>
                                <h3>Turnover precoce</h3>
                                <ContainerStatusTurnOver>
                                    <Flex marginRight={'1rem'}>
                                        <h2>{Model?.statusTurnOver.turnOver.toFixed(2)}%</h2>
                                        <span>Últimos 12 meses</span>
                                    </Flex>
                                    <Flex>
                                        <span>Total de processos - {Model?.statusTurnOver?.totalProcessos}</span>
                                        <span>Onboardings concluídos - {Model?.statusTurnOver.totalOnboarding}</span>
                                        <span>Desligamentos - {Model?.statusTurnOver.totalDesligados}</span>
                                    </Flex>
                                </ContainerStatusTurnOver>
                                {permitirFuncao('Jornada', 'turnover') && <Button size={'sm'} VarColor='Azul' onClick={() => nav('/Jornada/TurnOver')}>Visualização completa</Button>}
                            </OutlineContainer> */}
                        </Flex>
                        {
                            (Model?.tabela && Model.tabela.length > 0) && (
                                <OutlineContainer theme={'Azul'}>
                                    <h3>Colaboradores em onboarding</h3>
                                    <Tabela dados={Model?.tabela} />
                                </OutlineContainer>
                            )
                        }
                    </Flex>
                    <Flex flexDir={'column'} gap='1rem' width='30%'>
                        {
                            (Model?.proximosEventos && Model.proximosEventos.length > 0) && (
                                <OutlineContainer theme={'Azul'}>
                                    <h3>Próximos eventos</h3>
                                    <ul>
                                        {
                                            Model?.proximosEventos.map((e) => {
                                              return (
                                                    <li key={e.id}><strong>{e.dataInicio}</strong> - {e.assunto} <strong>{e.nomeParticipante}</strong></li>
                                              )
                                            })
                                        }
                                    </ul>
                                </OutlineContainer>
                            )
                        }

                        {
                            (Model?.pesquisaseNps && Model.pesquisaseNps.length > 0) && (
                                <OutlineContainer theme={'Azul'}>
                                    <h3>eNPS</h3>
                                    <ul>
                                        {
                                            Model?.pesquisaseNps.map((e) => {
                                              return (
                                                    <LiLink onClick={() => nav(`/Jornada/eNps/Resultado/${e.id}`)} key={e.id}>{e.nome}</LiLink>
                                              )
                                            })
                                        }
                                    </ul>
                                </OutlineContainer>
                            )
                        }

                        {
                            (Model?.pesquisasEmAndamento && Model.pesquisasEmAndamento.length > 0) && (
                                <OutlineContainer theme={'Azul'}>
                                    <h3>Pesquisas em andamento</h3>
                                    <ul>
                                        {
                                            Model?.pesquisasEmAndamento.map((e) => {
                                              if (e.tipo === 0) {
                                                return (
                                                        <LiLink onClick={() => nav(`/Jornada/PesquisaInterna/Convites/${e.id}`)} key={e.id}>{e.nome}</LiLink>
                                                )
                                              } else if (e.tipo === 1) {
                                                return (
                                                        <LiLink onClick={() => nav(`/Rh/PesquisaPercepcao/ResultadoPesquisa/${e.id}`)} key={e.id}>{e.nome}</LiLink>
                                                )
                                              }
                                              return null
                                            })
                                        }
                                    </ul>
                                </OutlineContainer>
                            )
                        }
                    </Flex>
                </Flex>
            </WhiteBox>
        </Body>
  )
}
