import { Body } from '../../Layouts/Body'
import { Flex } from '@chakra-ui/react'
import { StyledList } from './styles'
import LogoSah from '../../../assets/LogoSah.png'

export const Privacidade: React.FC = () => {
  return (
    <Body>
    <Flex bgColor={'white'} flexDirection={'column'} border={'1px solid var(--Gray4)'} padding={'1rem'} >
        <Flex justifyContent={'start'} flexDir={'column'}>
    <img width={'530px'} height={'150px'} src={LogoSah}/>
    <h1>Política de privacidade</h1>
    </Flex>
    <br />
        <p><strong>Termo de Consentimento para Tratamento de Dados Pessoais</strong></p>
        <p>&nbsp;</p>
        <p>A <strong>ARQUITETURA HUMANA CONSULTORES LTDA</strong> atribui grande import&acirc;ncia ao seu direito &agrave; privacidade e &agrave; prote&ccedil;&atilde;o dos seus dados pessoais. Queremos que voc&ecirc; se sinta seguro de que ao tratar com o Site e Aplicativo <strong>SAH&reg;</strong>, seus dados pessoais est&atilde;o em boas m&atilde;os.</p>
        <p>&nbsp;</p>
        <p>A <strong>ARQUITETURA HUMANA CONSULTORES LTDA</strong> protege seus dados pessoais de acordo com a legisla&ccedil;&atilde;o aplic&aacute;vel e com nossas pol&iacute;ticas de privacidade de dados. Al&eacute;m disso, a <strong>ARQUITETURA HUMANA CONSULTORES LTDA</strong> toma as medidas t&eacute;cnicas e organizacionais adequadas para proteger seus dados pessoais contra tratamento n&atilde;o autorizado ou il&iacute;cito e/ou contra perda acidental, altera&ccedil;&atilde;o, divulga&ccedil;&atilde;o ou acesso, ou destrui&ccedil;&atilde;o ou dano acidental ou il&iacute;cito desses dados.</p>
        <p>&nbsp;</p>
        <p><strong>Compartilharemos seus dados pessoais com terceiros?</strong></p>
        <p>&nbsp;</p>
        <p>A <strong>ARQUITETURA HUMANA CONSULTORES LTDA</strong> fica autorizada a utilizar e compartilhar os dados pessoais do Titular com outros agentes de tratamento de dados, caso seja necess&aacute;rio para as finalidades listadas neste termo, observados os princ&iacute;pios e as garantias estabelecidas pela Lei n&ordm; 13.709/2018.</p>
        <p>&nbsp;</p>
        <p><strong>E os dados sens&iacute;veis?</strong></p>
        <p>&nbsp;</p>
        <p>Casos em que coletamos dados sens&iacute;veis (tamb&eacute;m conhecidos como categorias especiais), fazemos de acordo com os requisitos das leis de privacidade de dados e/ou solicitamos seu consentimento.</p>
        <p>O termo "dados sens&iacute;veis" se refere a v&aacute;rias categorias de dados pessoais identificadas por leis de privacidade de dados como aqueles dados que necessitam de tratamento especial, incluindo em algumas circunst&acirc;ncias a necessidade de obter seu consentimento expresso. Tais categorias incluem origem racial ou &eacute;tnica, opini&otilde;es pol&iacute;ticas, cren&ccedil;as religiosas, filos&oacute;ficas ou similares, filia&ccedil;&atilde;o a sindicatos, sa&uacute;de f&iacute;sica ou mental, dados biom&eacute;tricos ou gen&eacute;ticos, vida sexual ou orienta&ccedil;&atilde;o sexual, ou condena&ccedil;&otilde;es penais e delitos (incluindo informa&ccedil;&otilde;es sobre suspeitas de atividades criminais).</p>
        <p>&nbsp;</p>
        <p>A <strong>ARQUITETURA HUMANA CONSULTORES LTDA</strong> fica autorizada a tomar decis&otilde;es referentes ao tratamento e a realizar o tratamento dos seguintes dados pessoais do Titular:</p>
        <StyledList>
            <li>Nome completo.</li>
            <li>Data de nascimento.</li>
            <li>N&uacute;mero do Cadastro de Pessoas F&iacute;sicas (CPF).</li>
            <li>Estado civil.</li>
            <li>N&iacute;vel de instru&ccedil;&atilde;o ou escolaridade.</li>
            <li>N&uacute;meros de telefone, WhatsApp e endere&ccedil;os de e-mail.</li>
            <li>Nome de usu&aacute;rio e senha espec&iacute;ficos para uso dos servi&ccedil;os do Controlador.</li>
            <li>Comunica&ccedil;&atilde;o, verbal e escrita, mantida entre o Titular e o Controlador.</li>
        </StyledList>
        <p>&nbsp;</p>
        <p><strong>Finalidades do Tratamento dos Dados</strong></p>
        <p>O tratamento dos dados pessoais listados neste termo tem as seguintes finalidades:</p>
        <StyledList>
            <li>Realiza&ccedil;&atilde;o de pesquisas, question&aacute;rios e an&aacute;lises de dados obtidos pelas respostas dos usu&aacute;rios</li>
            <li>Possibilitar que o Controlador identifique e entre em contato com o Titular para fins de relacionamento comercial.</li>
            <li>Possibilitar que o Controlador envie ou forne&ccedil;a ao Titular seus produtos e servi&ccedil;os, de forma remunerada ou gratuita.</li>
            <li>Possibilitar que o Controlador estruture, teste, promova e fa&ccedil;a propaganda de produtos e servi&ccedil;os, personalizados ou n&atilde;o ao perfil do Titular.</li>
        </StyledList>
        <p>&nbsp;</p>
        <p><strong>E a seguran&ccedil;a de dados?</strong></p>
        <p>Tomamos precau&ccedil;&otilde;es de seguran&ccedil;a organizacional, f&iacute;sica e t&eacute;cnica para todos os dados pessoais que mantemos. Temos controles e pol&iacute;ticas relacionadas, procedimentos e orienta&ccedil;&otilde;es para manter estas medidas, levando em conta os riscos associados com as categorias de dados pessoais e o tratamento que realizamos.</p>
        <p>&nbsp;</p>
        <p><strong>ARQUITETURA HUMANA CONSULTORES LTDA</strong> informa que utiliza medidas de seguran&ccedil;a, t&eacute;cnicas e administrativas aptas a proteger os dados pessoais de acessos n&atilde;o autorizados e de situa&ccedil;&otilde;es acidentais ou il&iacute;citas de destrui&ccedil;&atilde;o, perda, altera&ccedil;&atilde;o, comunica&ccedil;&atilde;o ou qualquer forma de tratamento inadequado ou il&iacute;cito.</p>
        <p>Em conformidade ao art. 48 da Lei n&ordm; 13.709/2018, o Controlador comunicar&aacute; ao Titular e &agrave; Autoridade Nacional de Prote&ccedil;&atilde;o de Dados (ANPD) a ocorr&ecirc;ncia de incidente de seguran&ccedil;a que possa acarretar risco ou dano relevante ao Titular.</p>
        <p>&nbsp;</p>
        <p><strong>Por quanto tempo manteremos seus dados pessoais?</strong></p>
        <p>Manteremos os seus dados pessoais apenas pelo tempo necess&aacute;rio. Temos pol&iacute;ticas e procedimentos espec&iacute;ficos para reten&ccedil;&atilde;o e gest&atilde;o de registros de forma que os dados pessoais sejam removidos ap&oacute;s um per&iacute;odo razo&aacute;vel de acordo com os seguintes crit&eacute;rios de reten&ccedil;&atilde;o:</p>
        <p>Retemos seus dados desde que tenhamos uma rela&ccedil;&atilde;o em andamento com voc&ecirc; (especialmente caso voc&ecirc; tenha uma conta conosco).</p>
        <p>Manteremos os dados apenas enquanto a sua conta estiver ativa ou pelo per&iacute;odo necess&aacute;rio para lhe fornecer servi&ccedil;os.</p>
        <p>Retemos seus dados pelo per&iacute;odo necess&aacute;rio para cumprir as nossas obriga&ccedil;&otilde;es legais e contratuais globais.</p>
        <p>&nbsp;</p>
        <p><strong>Quais s&atilde;o seus direitos com rela&ccedil;&atilde;o ao tratamento dos seus dados pessoais?</strong></p>
        <p>Voc&ecirc; tem direito (nas circunst&acirc;ncias e sob as condi&ccedil;&otilde;es, e sujeito &agrave;s exce&ccedil;&otilde;es, estabelecidas na legisla&ccedil;&atilde;o aplic&aacute;vel) a:</p>
        <p>Solicitar acesso aos seus dados pessoais tratados por n&oacute;s: esse direito permite que voc&ecirc; saiba se mantemos seus dados pessoais e, caso afirmativo, que voc&ecirc; obtenha informa&ccedil;&otilde;es sobre esses dados e c&oacute;pia deles.</p>
        <p>Solicitar corre&ccedil;&atilde;o dos seus dados pessoais: esse direito permite que voc&ecirc; solicite a corre&ccedil;&atilde;o dos seus dados pessoais caso eles estejam imprecisos ou incompletos.</p>
        <p>Se opor ao tratamento de seus dados pessoais: esse direito permite que voc&ecirc; solicite que a <strong>ARQUITETURA HUMANA CONSULTORES LTDA</strong> deixe de tratar seus dados pessoais.</p>
        <p>Solicitar remo&ccedil;&atilde;o de seus dados pessoais: esse direito permite que voc&ecirc; solicite a remo&ccedil;&atilde;o dos seus dados pessoais, incluindo quando tais dados pessoais deixarem de ser necess&aacute;rios para fins pretendidos.</p>
        <p>Solicitar a restri&ccedil;&atilde;o do tratamento de seus dados pessoais: esse direito permite que voc&ecirc; solicite que a <strong>ARQUITETURA HUMANA CONSULTORES LTDA</strong> trate seus dados pessoais apenas em determinadas circunst&acirc;ncias, inclusive com seu consentimento.</p>
        <p>&nbsp;</p>
        <p><strong>Como usamos dados pessoais quando voc&ecirc; visita o Site e Aplicativo do Sistema SAH&reg;?</strong></p>
        <p>Al&eacute;m das informa&ccedil;&otilde;es expostas acima, descrevemos como usamos seus dados pessoais quando voc&ecirc; visita o site da <strong>Site e Aplicativo do Sistema SAH&reg;</strong>:</p>
        <p>A <strong>ARQUITETURA HUMANA CONSULTORES LTDA</strong> coleta dados pessoais em seus sites de duas maneiras: (1) diretamente (por exemplo, quando voc&ecirc; fornece dados pessoais ao responder uma pesquisa ou question&aacute;rio); e (2) indiretamente (por exemplo, atrav&eacute;s da tecnologia do nosso site).</p>
        <p>&nbsp;</p>
        <p><strong>Podemos coletar e tratar os seguintes dados pessoais:</strong></p>
        <p>Dados pessoais que voc&ecirc; fornece ao preencher fichas e pesquisas em nosso site. Isso inclui o cadastro para usar o site, inscrever-se para obter servi&ccedil;os, boletins de not&iacute;cias e alertas, inscrever-se para uma confer&ecirc;ncia ou solicitar um artigo t&eacute;cnico ou mais informa&ccedil;&otilde;es. P&aacute;ginas que coletam esse tipo de dados pessoais podem conter mais informa&ccedil;&otilde;es sobre o motivo pelo qual os dados pessoais s&atilde;o necess&aacute;rios e como eles ser&atilde;o usados. O fornecimento desses dados fica inteiramente a seu crit&eacute;rio.</p>
        <p>Caso voc&ecirc; entre em contato conosco, podemos manter um registro dessa correspond&ecirc;ncia.</p>
        <p>Pediremos que voc&ecirc; preencha question&aacute;rios que usamos para fins de pesquisa.</p>
        <p>Quaisquer postagens, coment&aacute;rios ou upload de outros conte&uacute;dos que voc&ecirc; fizer no site da <strong>ARQUITETURA HUMANA CONSULTORES LTDA</strong>.</p>
        <p>Nosso site coleta dados pessoais sobre seu computador, incluindo (quando dispon&iacute;vel) seu endere&ccedil;o de IP, sistema operacional e tipo de navegador para administra&ccedil;&atilde;o do sistema, filtrar tr&aacute;fego, pesquisar dom&iacute;nios de usu&aacute;rio e para relatar estat&iacute;sticas.</p>
        <p>Detalhes das suas visitas ao nosso site, as p&aacute;ginas que voc&ecirc; visita e os recursos que voc&ecirc; acessa ou baixa, incluindo, entre outros, dados de tr&aacute;fego, dados de localiza&ccedil;&atilde;o, weblogs e outros dados de comunica&ccedil;&atilde;o. Verifique o cap&iacute;tulo sobre Cookies abaixo para mais informa&ccedil;&otilde;es.</p>
        <p>&nbsp;</p>
        <p><strong>Como usamos os dados pessoais que coletamos em nossos sites?</strong></p>
        <p>Usamos dados pessoais para os fins descritos no cap&iacute;tulo &ldquo;Para quais fins e sob que bases legais usamos seus dados pessoais?&rdquo; acima, assim como para fornecer informa&ccedil;&otilde;es que voc&ecirc; solicita, tratar formul&aacute;rios de pesquisa online e para outros fins que descrevemos para voc&ecirc; quando os dados s&atilde;o coletados.</p>
        <p>&nbsp;</p>
        <p><strong>Como usamos cookies (e outras tecnologias de rastreamento)?</strong></p>
        <p>Analisamos suas informa&ccedil;&otilde;es de IP e navegador para determinar o que &eacute; mais eficaz em nosso site, para nos ajudar a identificar formas de melhor&aacute;-lo e eventualmente determinar como podemos adaptar nosso site para que ele proporcione uma experi&ecirc;ncia de usu&aacute;rio mais positiva e relevante.</p>
        <p>Verifique nossa Pol&iacute;tica de cookies para mais detalhes, inclusive para conhecer suas escolhas relativas a cookies em propagandas e m&iacute;dias sociais e para acesso ao gerenciador de permiss&atilde;o de cookies.</p>
        <p>&nbsp;</p>
        <p><strong>N&oacute;s mantemos bases de dados de Gest&atilde;o de Relacionamento com o Cliente (CRM)?</strong></p>
        <p>Como a maioria das empresas, a <strong>ARQUITETURA HUMANA CONSULTORES LTDA</strong> usa tecnologia de base de dados de gest&atilde;o de relacionamento com o cliente (CRM) para gerenciar e acompanhar nossas a&ccedil;&otilde;es de marketing. Nossas bases de dados de CRM incluem dados de funcion&aacute;rios individuais dos nossos clientes e de outras empresas com quem j&aacute; mantemos ou queremos desenvolver uma rela&ccedil;&atilde;o comercial. Os dados pessoais usados para esses fins incluem informa&ccedil;&otilde;es comerciais importantes, tais como: dados de contato, informa&ccedil;&otilde;es publicamente dispon&iacute;veis (ex. participa&ccedil;&atilde;o na diretoria, artigos publicados, comunicados de imprensa, suas postagens p&uacute;blicas em sites de m&iacute;dia social caso sejam relevantes para o objetivo do neg&oacute;cio), suas respostas aos e-mails direcionados (incluindo atividade na internet ap&oacute;s entrar em um link enviado por nossos e-mails), atividade no website de usu&aacute;rios registrados em nosso site, e outras informa&ccedil;&otilde;es relacionadas ao neg&oacute;cio inclu&iacute;das por profissionais da <strong>ARQUITETURA HUMANA CONSULTORES LTDA</strong> com base nas intera&ccedil;&otilde;es pessoais com voc&ecirc;. Caso voc&ecirc; deseje ser exclu&iacute;do das nossas bases de dados de CRM, entre em contato conosco.</p>
        <p>&nbsp;</p>
        <p><strong>T&eacute;rmino do Tratamento dos Dados</strong></p>
        <p>&nbsp;</p>
        <p><b>ARQUITETURA HUMANA CONSULTORES LTDA</b> poder&aacute; manter e tratar os dados pessoais do Titular durante todo o per&iacute;odo em que forem pertinentes ao alcance das finalidades listadas neste termo. Dados pessoais anonimizados, sem possibilidade de associa&ccedil;&atilde;o ao indiv&iacute;duo, poder&atilde;o ser mantidos por per&iacute;odo indefinido.</p>
        <p>O Titular poder&aacute; solicitar via e-mail ou correspond&ecirc;ncia ao Controlador, a qualquer momento, que sejam eliminados os dados pessoais n&atilde;o anonimizados do Titular. O Titular fica ciente de que poder&aacute; ser invi&aacute;vel ao Controlador continuar o fornecimento de produtos ou servi&ccedil;os ao Titular a partir da elimina&ccedil;&atilde;o dos dados pessoais.</p>
        <p>&nbsp;</p>
        <p><h6>Direito de Revoga&ccedil;&atilde;o do Consentimento</h6></p>
        <p>Este consentimento poder&aacute; ser revogado pelo Titular, a qualquer momento, mediante solicita&ccedil;&atilde;o via e-mail &agrave; <strong>ARQUITETURA HUMANA CONSULTORES LTDA</strong></p>
        <p>&nbsp;</p>
        <p>e-mail: contrato@arquiteturahumana.com.br</p>
        </Flex>
    </Body>
  )
}
