import { Avatar, Flex } from '@chakra-ui/react'
import { iColaboradorSquad } from '../../../../../../../../interfaces'
import { StyledContainer, StyledName, StyledText } from './styles'

interface iSquadCardProps {
  colaborador: iColaboradorSquad
  cor: string
  nomeTag: string
}

export const SquadCard: React.FC<iSquadCardProps> = ({ colaborador, cor, nomeTag }) => {
  return (
    <Flex flexDirection={'column'} alignItems={'center'} borderRadius={'.5rem'} padding={'.5rem'} flexDir={'column'}>
      <StyledContainer theme={cor}>
        <Avatar src={colaborador.avatar} size={'lg'} marginBottom={'.5rem'} name={colaborador.nome} />
      </StyledContainer>
      <StyledText theme={cor}>{nomeTag}</StyledText>
      <StyledName>{colaborador.nome}</StyledName>
    </Flex>
  )
}
