import { Grid } from '@chakra-ui/react'
import { ISquad } from '../../../../../../interfaces'
import { SquadContainer } from './Components/SquadContainer'

interface iSquadOrgProps{
  squads: ISquad[]
  reload: () => void
}

export const SquadOrgChart: React.FC<iSquadOrgProps> = ({ squads, reload }) => {
  return (
    <Grid marginTop={'1rem'} width={'100%'} gap={'1rem'} templateColumns='repeat(1, 1fr)'>
      {
        squads.filter(e => e.colaboradores !== null && e.colaboradores.length > 0).map((e, i) => {
          return (
            <SquadContainer reload={reload} squad={e} key={i}/>
          )
        })
      }
    </Grid>
  )
}
