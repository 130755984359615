/* eslint-disable @typescript-eslint/prefer-optional-chain */
import { Flex, FormControl, FormLabel, Select, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Button } from '../../../../components/Button'
import { WhiteBox } from '../../../../components/WhiteBox'
import { iNomeId } from '../../../../interfaces'
import { appApi } from '../../../../services/appApi'
import { Body } from '../../../Layouts/Body'
import { BarChart } from './Components/BarChart'
import { PieChart } from './Components/PieChart'
import { ComentariosContainer } from './styles'

interface iDiscursiva {
  pergunta: string
  respostas: string[]
}

interface iPieChartData {
  nome: string
  series: string[]
  labels: string[]
}

interface iModel {
  discursivas: iDiscursiva[]
  nome: string
  descricao: string
  graficos: iPieChartData[]
  filtroColaboradores: iNomeId[]
}

export const ResultadoPesquisaInterna: React.FC = () => {
  const { pesquisaId } = useParams<{ pesquisaId: string }>()
  const { pathname } = useLocation()
  const nav = useNavigate()
  const [Model, setModel] = useState<iModel>()
  const [ColabordorId, setColabordorId] = useState('')

  function Resultado(): void {
    appApi.get(`PesquisaInterna/Resultado/${pesquisaId as string}`)
      .then(res => {
        if (res.status === 200) {
          setModel(res.data)
        }
      })
      .catch(err => console.log(err))
  }

  function FiltrarResultado(id: string): void {
    appApi.get(`PesquisaInterna/Resultado/${pesquisaId as string}?colaboradorId=${id}`)
      .then(res => {
        setModel(undefined)
        setModel(res.data)
      })
      .catch(err => console.log(err))
  }

  useEffect(() => {
    Resultado()
  }, [])

  return (
    <Body>
      <WhiteBox>
        <Flex justifyContent={'space-between'}>
          <Button onClick={() => nav(-1)} VarColor="c4" size={'lg'}>Voltar</Button>
          <Button VarColor='Azul' onClick={() => nav(pathname.replace('Resultado', 'Convites'))} size={'lg'}>Gestão de convites</Button>
        </Flex>
        <Flex flexDir={'column'} margin={'2rem 0'}>
          <h1>Resultados {'>'} {Model?.nome}</h1>
          <span style={{ marginTop: '1rem' }}>{Model?.descricao}</span>
        </Flex>

        {
          (Model?.filtroColaboradores && Model?.filtroColaboradores?.length > 0) && (
            <FormControl marginBottom={'2rem'}>
              <FormLabel fontSize={'xl'} color={'var(--Gray1)'}>Colaborador</FormLabel>
              <Select value={ColabordorId} onChange={(e) => {
                FiltrarResultado(e.target.value)
                setColabordorId(e.target.value)
              }} borderColor={'var(--Gray4)'} width={'18rem'}>
                <option value={''}>Todos</option>
                {
                  Model?.filtroColaboradores.map((e) => {
                    return (
                      <option key={e.id} value={e.id}>{e.nome}</option>
                    )
                  })
                }
              </Select>
            </FormControl>
          )
        }

        <Tabs size='md' variant='enclosed'>
          <TabList marginBottom={'.01rem'}>
            <Tab _selected={{ borderTopColor: 'var(--Gray4)', borderLeftColor: 'var(--Gray4)', borderRightColor: 'var(--Gray4)', borderRadius: '0rem', borderBotto: 'white' }} bg={'var(--c1)'} color={'black'}>Resultados</Tab>
            {
              (Model?.discursivas !== undefined && Model?.discursivas.length > 0) && (
                <Tab _selected={{ borderTopColor: 'var(--Gray4)', borderLeftColor: 'var(--Gray4)', borderRightColor: 'var(--Gray4)', borderRadius: '0rem' }} bg={'var(--c1)'} color={'black'}>Questões discursivas</Tab>
              )
            }
          </TabList>
          <TabPanels border={'1px solid var(--Gray4)'}>
            <TabPanel>
              <Flex flexDir={'column'} gap='5rem'>
                {
                  (Model !== undefined) && (
                    Model?.graficos.map((e, i) => {
                      if (e.series.length <= 8 || ColabordorId.length > 3) {
                        return (
                          <Flex key={i} flexDir={'column'} width='100%'>
                            <h3>{e.nome}</h3>
                            <Flex justifyContent={'center'} alignItems={'center'} width={'100%'} paddingBottom={'1rem'} marginBottom={'1rem'}>
                              <PieChart data={e} id={`chart-${i}`} />
                            </Flex>
                          </Flex>
                        )
                      } else {
                        return (
                          <Flex key={i} flexDir={'column'} width='100%'>
                            <h3>{e.nome}</h3>
                            <Flex justifyContent={'center'} alignItems={'center'} width={'100%'} paddingBottom={'1rem'} marginBottom={'1rem'}>
                              <BarChart data={e} id={`chart-${i}`} />
                            </Flex>
                          </Flex>
                        )
                      }
                    })
                  )
                }
              </Flex>
            </TabPanel>

            {
              (Model?.discursivas !== undefined && Model?.discursivas.length > 0) && (
                <TabPanel>
                  {
                    Model.discursivas.map((e, i) => {
                      return (
                        <div key={i}>
                          <Flex flexDir={'column'}>
                            <h3 style={{ color: 'var(--Gray3)' }}>Pergunta:</h3>
                            <span
                              style={{ fontSize: '1.2rem', fontWeight: '400', marginTop: '.5rem' }}
                            >{e.pergunta}</span>
                          </Flex>
                          <ComentariosContainer>
                            <section>
                              <h3>Comentários: {e.respostas.length}</h3>
                                {
                                  e.respostas.map((e, i) => {
                                    return (
                                      <div>
                                        <span key={i}>{e}</span>
                                      </div>
                                    )
                                  })
                                }
                            </section>
                          </ComentariosContainer>
                        </div>
                      )
                    })
                  }
                </TabPanel>
              )
            }
          </TabPanels>
        </Tabs>
      </WhiteBox>
    </Body>
  )
}
