import { FaCopy, FaPen, FaTrashAlt } from 'react-icons/fa'
import { HeadContainer, ContainerFormulario, Btns, Main } from './styles'
import { useEffect, useState } from 'react'
import { iIntegracao, iEtapa } from '../../../../interfaces'
import { appApi } from '../../../../services/appApi'
import { TimeLineOnboard } from '../../../../components/TimeLineOnboard'
import { useNavigate, useParams } from 'react-router-dom'
import { useToast } from '@chakra-ui/react'
import { GreyButton } from '../../../../components/Buttons/GreyButton'
import { GreenButton } from '../../../../components/Buttons/GreenButton'
import { Body } from '../../../Layouts/Body'
import { ModalDelete } from '../../../../components/ModalDelete'
import { CreateEtapaBox } from '../../../../components/CreateEtapaBox'
import { ModalCreateForm } from '../../../../components/ModalCreateForm'
import { ValidarEtapa } from '../../../../Utils/Helper'

type LocationProps = {
  trilhaId: string
  processoId?: string
}

export const ConfigImersao: React.FC = () => {
  const toast = useToast()
  const navigate = useNavigate()

  const { trilhaId, processoId } = useParams<LocationProps>()

  const [Imersoes, setImersoes] = useState<iIntegracao[]>([])
  const [showModal, setshowModal] = useState(false)

  const [Selecionar, setSelecionar] = useState(false)

  const [ModalDeleteIsOpen, setModalDeleteIsOpen] = useState(false)
  const [ModalCopyIsOpen, setModalCopyIsOpen] = useState(false)
  const [Id, setId] = useState('')

  const [Imersao, setImersao] = useState<iIntegracao>({
    id: '',
    itensIntegracao: [],
    nome: ''
  })

  function onCloseModalDelete(): void {
    setModalDeleteIsOpen(false)
    setId('')
  }

  function onOpenModalDelete(id: string): void {
    setModalDeleteIsOpen(true)
    setId(id)
  }

  function onCloseModalCopy(): void {
    setModalCopyIsOpen(false)
    setId('')
  }

  function onOpenModalCopy(id: string): void {
    setModalCopyIsOpen(true)
    setId(id)
  }

  function DeletarImersao(): void {
    appApi.delete(`Imersao/${Id}`)
      .then(response => {
        if (response.status === 200) {
          setImersoes(Imersoes.filter(e => e.id !== Id))
          setModalDeleteIsOpen(false)
        }
      }).catch(err => console.error(err))
  }

  function getImersoes(): any {
    appApi.get('Imersao').then(response => { setImersoes(response.data) })
      .catch(err => console.log(err))
  }

  function AdicionarEtapa(form: iEtapa): void {
    if (!ValidarEtapa(form)) {
      toast({
        title: 'Preencha os campos de etapa',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return
    }
    const url = trilhaId ? `ImersaoEtapa/${trilhaId}` : processoId ? `ImersaoEtapaColaborador/${processoId}` : ''

    appApi.post(`${url}?imersaoId=${Imersao.id}`, form).then(() => {
      toast({
        title: 'Etapa cadastrada com sucesso',
        status: 'success',
        isClosable: false
      })
      navigate(-1)
    }).catch(() => {
      toast({
        title: 'Erro ao cadastrar a etapa',
        status: 'error',
        isClosable: false
      })
    })
  }

  function CopyImersao(Texto: string): void {
    const form = {
      texto: Texto
    }
    appApi.post(`Imersao/Copy/${Id}`, form)
      .then(response => {
        if (response.status === 200) {
          getImersoes()
          setModalCopyIsOpen(false)
        }
      })
      .catch(err => console.log(err))
  }

  function CriarImersao(Texto: string): void {
    appApi.post('Imersao', { nome: Texto }).then(response => {
      navigate(response.data)
    }).catch(e => console.log(e))
  }

  useEffect(() => {
    getImersoes()
  }, [])

  return (
    <Body>
      <ModalDelete
        onRequestClose={onCloseModalDelete}
        onConfirm={DeletarImersao}
        isOpen={ModalDeleteIsOpen}
        message='Realmente deseja excluir esta imersão?'
      />

      <ModalCreateForm
        onClose={() => setshowModal(false)}
        onCreate={CriarImersao}
        isOpen={showModal}
      />

      <ModalCreateForm
        onClose={onCloseModalCopy}
        onCreate={CopyImersao}
        isOpen={ModalCopyIsOpen}
      />
      <Main>

        <TimeLineOnboard hide={processoId !== undefined} />
        {
          !Selecionar
            ? <>
              <HeadContainer>
                <h1>Imersão</h1>
                <Btns>
                  <GreenButton Size='lg' isNav={false} OnClick={() => setshowModal(true)}>Criar nova lista de imersão</GreenButton>
                  <GreyButton
                    Size='lg'
                    isNav={true}
                  >Voltar</GreyButton>
                </Btns>
              </HeadContainer>

              <h2>Imersões salvas</h2>
              <section>
                {
                  Imersoes.map((e: iIntegracao, i) => {
                    return (
                      <ContainerFormulario key={i}>
                        <span>{e.nome}</span>
                        <div>
                          <button onClick={() => { setSelecionar(true); setImersao(e) }}>Selecionar</button>
                          <button onClick={() => onOpenModalCopy(e.id)}><FaCopy size={11} />Copiar imersão</button>
                          <button onClick={() => {
                            navigate(`${e.id}`)
                          }}><FaPen size={11} />Editar</button>
                          <button onClick={() => onOpenModalDelete(e.id)}><FaTrashAlt size={11} /></button>
                        </div>
                      </ContainerFormulario>
                    )
                  })
                }
              </section>
            </>
            : <>
              <h1>Imersão</h1>
              <CreateEtapaBox
                onGreenAction={AdicionarEtapa}
                onGreyAction={() => setSelecionar(false)}
                keyWord={'imersão'}
              />
            </>
        }
      </Main>
    </Body>
  )
}
