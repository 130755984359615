import { Avatar, Flex, Image } from '@chakra-ui/react'
import { iColaboradorReuniao } from '../../../../../../interfaces'
import { appApi } from '../../../../../../services/appApi'
import { useState, useEffect } from 'react'

interface iCardProps{
  colaborador: iColaboradorReuniao
}

export interface iPI {
  urlSelo: string
  perfil: string
  assessmentId: string
  hide: boolean
}

export const CardInformacao: React.FC<iCardProps> = ({ colaborador }) => {
  const [PI, setPI] = useState<iPI>()

  function getPerfilPI(): void {
    appApi.get(`https://gupy-pi.azurewebsites.net/api/integracao/sahpi/?email=${colaborador.email as string}`)
      .then(res => {
        setPI(res.data)
      }).catch(err => {
        console.log(err)
      })
  }

  useEffect(() => {
    getPerfilPI()
  }, [])
  return (
        <Flex width={'fit-content'} alignItems={'center'} padding={'.5rem'} borderRadius={'1rem'} bg='var(--c2)' flexDir={'column'}>
            <h4>Informações sobre o colaborador que está em onboarding</h4>
            <Flex gap='1rem' alignItems={'center'}>
                <Flex>
                    <Avatar marginRight={'.5rem'} src={colaborador.avatar} size={'md'} name='t'/>
                    <Flex flexDir={'column'}>
                        <h5>{colaborador.nome}</h5>
                        <span>{colaborador.nomeDepartamento}</span>
                    </Flex>
                </Flex>
                <Flex flexDir={'column'}>
                    <span>Perfil</span>
                    <Image height={'3rem'} src={`https://gupy-pi.azurewebsites.net/api/Files?id=${PI?.assessmentId as string}&tipo=2`}/>
                </Flex>
                <Flex justifyContent={'center'} alignItems={'center'}>
                    <h5>{colaborador.diasDeOnboarding} dias de onboarding</h5>
                </Flex>
            </Flex>
        </Flex>
  )
}
