import { Container } from './styles'

interface iAtribProps{
  url: string
  color: string
  column?: boolean
  fontSize?: string
  size: 'sm'|'md'|'lg'
}

export const AtributoContainer: React.FC<iAtribProps> = ({ url, color, children, column, fontSize, size }) => {
  return (
        <Container color={color} column={column} fontSize={fontSize} size={`${size}IconSize`}>
          <div>
            <img src={url}/>
          </div>
            <span>{children}</span>
        </Container>
  )
}
