import { MdClose } from 'react-icons/md'
import Modal from 'react-modal'
import { ModalBody, ModalFooter, ModalHeader, TextoDescricao } from './styles'
import { FaRegCalendarAlt, FaChevronLeft } from 'react-icons/fa'
import { Button } from '../../../../components/Button'
import { useEffect, useState } from 'react'
import { appApi } from '../../../../services/appApi'
import { Flex } from '@chakra-ui/react'

interface props {
  isOpen: boolean
  onRequestClose: () => void
  pesquisaId: string
}
interface iDataPesquisa {
  dataInicio: string
  dataFim: string
}

export const ModalPesquisaRespondida: React.FC<props> = ({ isOpen, onRequestClose, pesquisaId }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [Model, setModel] = useState<iDataPesquisa>({
    dataFim: '',
    dataInicio: ''
  })
  useEffect(() => {
    appApi.get(
      `PesquisaPercepcaoEtapaColaborador/Detalhe/${pesquisaId}`
    ).then(res => setModel(res.data)).catch(err => console.log(err))
  }, [])
  Modal.setAppElement('#root')
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName='react-modal-overlay'
      className='react-modal-content-default'
    >
      <ModalHeader>
        <h2>Pesquisa</h2>
        <MdClose size={24} onClick={onRequestClose} />
      </ModalHeader>

      <ModalBody>
        <h2>Você já respondeu esta pesquisa!</h2>
        <TextoDescricao>Estamos aguardando os demais participantes responderem!</TextoDescricao>
        </ModalBody>
        <Flex justifyContent={'center'} alignItems={'center'} padding={'.6rem'} fontWeight={'bold'}>
          <FaRegCalendarAlt style={{ marginRight: '.5rem' }}/>  {Model.dataInicio} a {Model.dataFim}
        </Flex>
        <ModalFooter>
        <Button onClick={onRequestClose} leftIcon={<FaChevronLeft/>} size={'md'} VarColor={'c6'} >Voltar</Button>
      </ModalFooter>
    </Modal>
  )
}
