import { Flex, FormControl, FormLabel, Input, Select, useToast } from '@chakra-ui/react'
import { WhiteBox } from '../../../../components/WhiteBox'
import { Body } from '../../../Layouts/Body'
import { CardInformacao } from './Components/CardInformacao'
import { useState, useEffect } from 'react'
import { Button } from '../../../../components/Button'
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa'
import { CardParticipante } from './Components/CardParticipante'
import { iCardAvaliador, iColaboradorReuniao } from '../../../../interfaces'
import { appApi } from '../../../../services/appApi'
import { useNavigate, useParams } from 'react-router-dom'
import { ModalDelete } from '../../../../components/ModalDelete'
import { useAuth } from '../../../../contexts/AuthContext'

interface iParticipanteReuniao extends iCardAvaliador {
  tipo: number
}

interface iModel {
  nomeReuniao: string
  descricaoReuniao?: string
  dataInicio?: string
  hInicio?: string
  duracao?: number
  local?: string
  colaborador: iColaboradorReuniao
  participantes: iParticipanteReuniao[]
  dataLimiteInicio?: string
  dataLimiteFim?: string
}

type iLocationProps = {
  reuniaoId: string
  processoId: string
}

export const ConfiguracaoReuniao: React.FC = () => {
  const { isAuth } = useAuth()
  const { processoId, reuniaoId } = useParams<iLocationProps>()
  const toast = useToast()
  const nav = useNavigate()
  const [Model, setModel] = useState<iModel>()
  const [View, setView] = useState('1')

  const [Email, setEmail] = useState('')

  const [DataInicio, setDataInicio] = useState('')
  const [HInicio, setHInicio] = useState('')
  const [Duracao, setDuracao] = useState('5')
  const [Local, setLocal] = useState('')

  const [ModaConfirmIsOpen, setModaConfirmIsOpen] = useState(false)

  const [Editado, setEditado] = useState(false)

  function AgendarReuniao(): void {
    const form = {
      dataInicio: `${DataInicio}T${HInicio}`,
      Duracao: parseInt(Duracao),
      local: Local
    }
    appApi.post(`ReuniaoEtapaColaborador/${processoId as string}/Configuracao/${reuniaoId as string}`, form)
      .then(() => {
        toast({
          title: 'Reunião agendada com sucesso',
          status: 'success',
          isClosable: false,
          position: 'top-right'
        })
        if (isAuth()) {
          nav('/Colaborador')
        } else {
          nav('/')
        }
      })
      .catch(err => console.log(err))
  }

  function ReenviarConvites(): void {
    const form = {
      dataInicio: `${DataInicio}T${HInicio}`,
      Duracao: parseInt(Duracao),
      local: Local
    }

    appApi.put(`ReuniaoEtapaColaborador/${reuniaoId as string}/Reenviar`, form)
      .then(() => {
        toast({
          title: 'Mensagens reenviadas com sucesso',
          status: 'success',
          isClosable: false,
          position: 'top-right'
        })
        nav('/Colaborador')
      })
      .catch(err => console.log(err))
  }

  function Validar(param: string): void {
    if (param === '2') {
      if (DataInicio === '') {
        toast({
          title: 'Escolha uma data válida para a reunião',
          status: 'error',
          isClosable: false,
          position: 'top-right'
        })
        return
      }
      setView('2')
    }

    if (param === '3') {
      if (HInicio === '') {
        toast({
          title: 'Escolha uma hora válida para a reunião',
          status: 'error',
          isClosable: false,
          position: 'top-right'
        })
        return
      }
      setView('3')
    }
  }

  function ObterReuniao(): void {
    appApi.get(`ReuniaoEtapaColaborador/${processoId as string}/Reuniao/${reuniaoId as string}`)
      .then(res => {
        const data: iModel = res.data
        setModel(data)
        if (data.dataInicio !== undefined && data.dataInicio !== null) {
          setDataInicio(data.dataInicio)
          setDuracao(data.duracao?.toString() ?? '5')
          setHInicio(data.hInicio ?? '')
          setLocal(data.local ?? '')
          setView('5')
        }
      })
      .catch(err => console.log(err))
  }

  function PostParticipantes(): void {
    const form = {
      texto: Email
    }
    appApi.post(`Reuniao/${reuniaoId as string}/Participante`, form)
      .then(() => {
        setEditado(true)
        setEmail('')
        ObterReuniao()
      })
      .catch(({ response }) => {
        console.log(response)
        toast({
          title: response.data,
          status: 'error',
          position: 'top-right',
          isClosable: false
        })
      })
  }

  function DeleteParticipantes(id: string): void {
    appApi.delete(`Reuniao/${reuniaoId as string}/Participante/${id}`)
      .then(() => {
        ObterReuniao()
      })
      .catch(err => console.log(err))
  }

  function ObterView(): any {
    if (View === '1') {
      return (
        <Flex flexDir={'column'}>
          <h3>Selecione a data da reunião</h3>
          <Flex margin={'1rem 0'} justifyContent={'center'}>
            <Input min={Model?.dataLimiteInicio} max={Model?.dataLimiteFim} onChange={(e) => setDataInicio(e.target.value)} type={'date'} width='15rem' borderColor={'var(--Gray4)'} />
          </Flex>
          <Flex justifyContent={'center'}>
            <Button onClick={() => Validar('2')} VarColor='v2' rightIcon={<FaAngleRight />}>Escolher horário</Button>
          </Flex>
        </Flex>
      )
    }

    if (View === '2') {
      return (
        <Flex flexDir={'column'} alignItems='center'>
          <h3>Escolha o horário</h3>
          <Flex margin='1rem 0' gap={'1rem'}>
            <FormControl>
              <FormLabel>Início</FormLabel>
              <Input onChange={(e) => setHInicio(e.target.value)} width={'10rem'} type={'time'} borderColor={'var(--Gray4)'} />
            </FormControl>

            <FormControl>
              <FormLabel>Duração</FormLabel>
              <Select onChange={(e) => setDuracao(e.target.value)} width={'10rem'} borderColor={'var(--Gray4)'}>
                <option value={'5'}>5 minutos</option>
                <option value={'10'}>10 minutos</option>
                <option value={'15'}>15 minutos</option>
                <option value={'30'}>30 minutos</option>
                <option value={'60'}>1 hora</option>
                <option value={'120'}>2 horas</option>
                <option value={'180'}>3 horas</option>
                <option value={'240'}>4 horas</option>
                <option value={'300'}>5 horas</option>
                <option value={'360'}>6 horas</option>
                <option value={'420'}>7 horas</option>
                <option value={'480'}>8 horas</option>
                <option value={'540'}>9 horas</option>
                <option value={'600'}>10 horas</option>
              </Select>
            </FormControl>
          </Flex>

          <Flex justifyContent={'center'} gap='1rem'>
            <Button onClick={() => setView('1')} VarColor='c5' leftIcon={<FaAngleLeft />}>Voltar</Button>
            <Button onClick={() => Validar('3')} VarColor='v2' rightIcon={<FaAngleRight />}>Escolher local</Button>
          </Flex>
        </Flex>
      )
    }

    if (View === '3') {
      return (
        <Flex flexDir={'column'} textAlign={'center'}>
          <h3>Informe o local da reunião</h3>
          <Flex margin={'1rem 0'} justifyContent={'center'}>
            <Input placeholder={'Digite o local da reunião'} onChange={(e) => setLocal(e.target.value)} type={'text'} width='25rem' borderColor={'var(--Gray4)'} />
          </Flex>
          <Flex justifyContent={'center'} gap='1rem'>
          <Button onClick={() => setView('2')} VarColor='c5' leftIcon={<FaAngleLeft />}>Voltar</Button>
            <Button onClick={() => setView('4')} VarColor='v2' rightIcon={<FaAngleRight />}>Escolher participantes</Button>
          </Flex>
        </Flex>
      )
    }

    if (View === '4') {
      return (
        <Flex flexDir={'column'}>
          <Flex justifyContent={'center'}>
            <h3>Selecione os participantes</h3>
          </Flex>

          <Flex alignItems='center' flexDir={'column'} margin='1rem 0' >
            <FormLabel>Selecione o e-mail dos participantes</FormLabel>
            <Flex>
              <Input onChange={(e) => setEmail(e.target.value)} placeholder='colaborador@sah.com.br' marginRight={'1rem'} type={'email'} borderColor={'var(--Gray4)'} />
              <Button VarColor='Azul' onClick={PostParticipantes}>Selecionar</Button>
            </Flex>
          </Flex>

          {
            (Model?.participantes !== undefined && Model?.participantes !== null) && (
              <Flex gap={'1rem'} flexWrap='wrap' marginBottom={'1rem'}>
                {
                  Model.participantes.map((e) => {
                    return (
                      <CardParticipante key={e.id} onDelete={DeleteParticipantes} participante={e} />
                    )
                  })
                }
              </Flex>
            )
          }

          <Flex justifyContent={'center'} gap='1rem'>
            <Button onClick={() => setView('3')} VarColor='c5' leftIcon={<FaAngleLeft />}>Voltar</Button>
            <Button VarColor='v2'onClick={() => setModaConfirmIsOpen(true)}>Agendar</Button>
          </Flex>
        </Flex>
      )
    }

    if (View === '5') {
      return (
        <Flex flexDir={'column'}>
          <Flex justifyContent={'center'}>
            <h3>Selecione os participantes</h3>
          </Flex>
          <Flex margin='1rem 0' gap={'1rem'}>
            <FormControl>
              <FormLabel>Data início</FormLabel>
              <Input value={DataInicio} onChange={(e) => setDataInicio(e.target.value)} width={'10rem'} type={'date'} borderColor={'var(--Gray4)'} />
            </FormControl>

            <FormControl>
              <FormLabel>Início</FormLabel>
              <Input value={HInicio} onChange={(e) => setHInicio(e.target.value)} width={'10rem'} type={'time'} borderColor={'var(--Gray4)'} />
            </FormControl>

            <FormControl>
              <FormLabel>Duração</FormLabel>
              <Select value={Duracao} onChange={(e) => setDuracao(e.target.value)} width={'10rem'} borderColor={'var(--Gray4)'}>
                <option value={'5'}>5 minutos</option>
                <option value={'10'}>10 minutos</option>
                <option value={'15'}>15 minutos</option>
                <option value={'30'}>30 minutos</option>
                <option value={'60'}>1 hora</option>
                <option value={'120'}>2 horas</option>
                <option value={'180'}>3 horas</option>
                <option value={'240'}>4 horas</option>
                <option value={'300'}>5 horas</option>
                <option value={'360'}>6 horas</option>
                <option value={'420'}>7 horas</option>
                <option value={'480'}>8 horas</option>
                <option value={'540'}>9 horas</option>
                <option value={'600'}>10 horas</option>
              </Select>
            </FormControl>
          </Flex>

          <FormControl>
              <FormLabel>Local</FormLabel>
              <Input placeholder={'Digite o local da reunião'} value={Local} onChange={(e) => setLocal(e.target.value)} type={'text'} borderColor={'var(--Gray4)'} />
          </FormControl>

          <Flex alignItems='center' flexDir={'column'} margin='1rem 0' >
            <FormLabel>Selecione o e-mail dos participantes</FormLabel>
            <Flex>
              <Input value={Email} onChange={(e) => setEmail(e.target.value)} placeholder='colaborador@sah.com.br' marginRight={'1rem'} type={'email'} borderColor={'var(--Gray4)'} />
              <Button VarColor='Azul' onClick={PostParticipantes}>Selecionar</Button>
            </Flex>
          </Flex>

          {
            (Model?.participantes !== undefined && Model?.participantes !== null) && (
              <Flex gap={'1rem'} flexWrap='wrap' marginBottom={'1rem'}>
                {
                  Model.participantes.map((e) => {
                    return (
                      <CardParticipante key={e.id} onDelete={DeleteParticipantes} participante={e} />
                    )
                  })
                }
              </Flex>
            )
          }

          <Flex justifyContent={'center'} gap='1rem'>
            <Button onClick={() => nav(-1)} VarColor='c5' leftIcon={<FaAngleLeft />}>Voltar</Button>
            {
              (Editado) && (
                <Button onClick={ReenviarConvites} VarColor='v2'>Editar e reenviar convites</Button>
              )
            }
          </Flex>
        </Flex>
      )
    }
  }

  useEffect(() => {
    ObterReuniao()
  }, [])

  useEffect(() => {
    if (View === '5') {
      setEditado(true)
    }
  }, [DataInicio, HInicio, Duracao, Local])

  return (
    <Body>
      <ModalDelete
        isOpen={ModaConfirmIsOpen}
        message='Deseja confirmar os dados e agendar a reunião?'
        onConfirm={AgendarReuniao}
        onRequestClose={() => setModaConfirmIsOpen(false)}
      />
      <WhiteBox>
        <h1>{Model?.nomeReuniao}</h1>

        <Flex padding={'1rem'} margin={'2rem'} justifyContent={'center'} border={'2px solid var(--c5)'} borderRadius='1rem'>
          {
            ObterView()
          }
        </Flex>
        {
          (Model) && (
            <Flex marginBottom={'1rem'} justifyContent={'center'}>
              <CardInformacao colaborador={Model?.colaborador} />
            </Flex>
          )
        }

        {
          (Model?.descricaoReuniao !== undefined) && (
            <Flex flexDir={'column'}>
              <h4>Descrição da reunião</h4>
              <strong>{Model?.descricaoReuniao}</strong>
            </Flex>
          )
        }
      </WhiteBox>
    </Body>
  )
}
