import styled from 'styled-components'

export const ActionButton = styled.div`
display: flex;
padding: 1rem;
border-radius: 15px;
align-items: center;
background-color:${props => props.theme};
color: white;
svg{
    margin-right: 1rem;
}

span{
    font-weight: 700;
    font-size: 18px;
}

&:hover{
    cursor: pointer;
    transition: ease .3 all;
    filter: brightness(.9);
}
`
