import { AiOutlinePlus } from 'react-icons/ai'
import { Container, HeadBox } from './styles'

interface TextBoxProps{
  onClick: () => void
}

export const TextBoxAdd: React.FC<TextBoxProps> = ({ onClick }) => {
  return (
    <Container>
        <HeadBox>
            <h4 onClick={onClick}>Clique para adicionar um tópico</h4>
            <AiOutlinePlus size={24} onClick={onClick}/>
        </HeadBox>
    </Container>
  )
}
