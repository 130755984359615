/* eslint-disable  @typescript-eslint/restrict-template-expressions */
import { useEffect, useState } from 'react'
import { Body } from '../../../Layouts/Body'
import { MetaButton } from '../../../../components/Buttons/MetaButton'
import { ModalDelete } from '../../../../components/ModalDelete'
import { SectionHeader } from '../Components/SectionHeader'
import { Flex } from '../../../../styles/styledGlobal'
import { CreateJobTitleModal } from './Components/CreateJobTitleModal'
import { UpdateJobTitleModal } from './Components/UpdateJobTitleModal'
import { JobTitleCard } from './Components/JobTitlesCard'
import { Main } from './styles'
import { NavBar } from '../Components/NavBar'
import { IJobTitle } from '../../../../interfaces'
import { appApi } from '../../../../services/appApi'
import { useAuth } from '../../../../contexts/AuthContext'
import { parseJwt } from '../../../../services/token'

interface INavLink {
  to: string
  linkTitle: string
  exact?: boolean
}

export const CargoDesenvolvimento: React.FC = () => {
  const { permitirFuncao } = useAuth()
  function ObterRotas(): INavLink[] {
    const rotas: INavLink[] = []
    if (parseJwt().adm === 'True' || permitirFuncao('Organograma', 'visao-geral')) {
      rotas.push({
        to: '/Organograma/Dashboard',
        linkTitle: 'Visão geral'
      })
    }

    if (parseJwt().adm === 'True' || permitirFuncao('Organograma', 'areas')) {
      rotas.push({
        to: '/Organograma/Departamentos',
        linkTitle: 'Áreas'
      })
    }

    if (parseJwt().adm === 'True' || permitirFuncao('Organograma', 'funcoes')) {
      rotas.push({
        to: '/Organograma/Cargos',
        linkTitle: 'Funções'
      })
    }

    if (parseJwt().adm === 'True' || permitirFuncao('Organograma', 'colaboradores')) {
      rotas.push({
        to: '/Organograma/Colaboradores',
        linkTitle: 'Colaboradores'
      })
    }
    return rotas
  }

  const [Cargos, setCargos] = useState<IJobTitle[]>([])
  const [EditCargo, setEditCargo] = useState<IJobTitle>({
    id: '',
    nome: ''
  })

  const [ModalDeleteIsOpen, setModalDeleteIsOpen] = useState(false)
  const [ModalCreateIsOpen, setModalCreateIsOpen] = useState(false)
  const [ModalUpdateIsOpen, setModalUpdateIsOpen] = useState(false)

  function getCargos(): void {
    appApi.get('Cargo').then(res => setCargos(res.data)).catch(err => console.log(err))
  }

  function onDeleteCargo(): void {
    appApi.delete(`Cargo/${EditCargo.id}`).then(getCargos).catch(err => console.log(err))
  }

  function onCreateCargo(obj: IJobTitle): void {
    const form = {
      nome: obj.nome
    }
    appApi.post('Cargo', form).then(() => {
      getCargos()
      onRequestModalCreateClose()
    }).catch(err => console.log(err))
  }

  function onUpdateCargo(obj: IJobTitle): void {
    const form = {
      Nome: obj.nome
    }
    appApi.put(`Cargo/${obj.id}`, form).then(res => {
      console.log(res)
      getCargos()
      onRequestModalUpdateClose()
    }).catch(err => console.log(err))
  }

  function onRequestModalCreateClose(): void {
    setModalCreateIsOpen(false)
  }

  function onRequestModalDeleteClose(): void {
    setModalDeleteIsOpen(false)
    setEditCargo({
      id: '',
      nome: ''
    })
  }

  function onRequestModalUpdateClose(): void {
    setModalUpdateIsOpen(false)
    setEditCargo({
      id: '',
      nome: ''
    })
  }

  function onOpenModalUpdate(obj: IJobTitle): void {
    setModalUpdateIsOpen(true)
    setEditCargo(obj)
  }

  function onOpenModalDelete(obj: IJobTitle): void {
    setModalDeleteIsOpen(true)
    setEditCargo(obj)
  }

  useEffect(() => {
    getCargos()
  }, [])

  return (
    <Body>
      <Main>
        <ModalDelete
          isOpen={ModalDeleteIsOpen}
          onRequestClose={onRequestModalDeleteClose}
          onConfirm={onDeleteCargo}
          message={'Deseja realmente excluir esta função?'}
        />

        <CreateJobTitleModal
          isOpen={ModalCreateIsOpen}
          onCreateJobTitle={onCreateCargo}
          onRequestClose={onRequestModalCreateClose}
        />

        <UpdateJobTitleModal
          isOpen={ModalUpdateIsOpen}
          onUpdateJobTittle={onUpdateCargo}
          onRequestClose={onRequestModalUpdateClose}
          initialData={EditCargo}
        />

        <NavBar
          title="Organograma"
          navLinks={ObterRotas()}
        />
        <Flex style={{ justifyContent: 'end' }}>
          <MetaButton onClick={() => setModalCreateIsOpen(true)} bg='v2' size='md' styles={{ margin: '1rem 0rem' }}>Nova função</MetaButton>
        </Flex>

        <SectionHeader title="Funções" />

        <section>
          {
            Cargos.map((e: IJobTitle, i) => {
              return (
                <JobTitleCard
                  key={i}
                  jobTitle={e}
                  onEdit={() => onOpenModalUpdate(e)}
                  onDelete={() => onOpenModalDelete(e)}
                />
              )
            })
          }
        </section>
      </Main>
  </Body>
  )
}
