import styled from 'styled-components'

export const Container = styled.div<{width?: string}>`
display: flex;
padding: .2rem;
border-radius: 24px;
background: var(--DegradeAzul);
width: ${props => props.width};
span{
    background-image: var(--DegradeAzul);
  background-clip: text;
  -webkit-background-clip: text; 
  -webkit-text-fill-color: transparent;
  color: black;
}
span:first-child{
  font-weight: 700;
  font-size: 15px;
}
span:last-child{
    font-weight: 400;
    font-size: 21px;
}
svg{
    color: var(--DegradeAzul);
    margin-right: .5rem;
}
`

export const ContainerButton = styled.div`
display: flex;
background-color: white;
border-radius: 22px;
padding: 1rem;
align-items: center;
width: 100%;
&:hover{
    background-color: var(--DegradeAzul);
    cursor: pointer;
    transition: all .4s ease;
    span{
        color: white !important;
        background-image: white;
        -webkit-background-clip: text; 
        -webkit-text-fill-color: white;
        color: white;
    }
}
`
