import styled from 'styled-components'

export const ColumnText = styled.div`
display: flex;
flex-direction: column;
margin-right:2rem;

span,h4{
    color: var(--a5);
}

h4{
    font-size: 12px;
}

span{
font-weight: 400;
font-size: 0.875rem;
}
`

export const PreViewParticipante = styled.div`
display: flex;
justify-content: space-between;
background: var(--c2);
border-radius: 0.625rem;
height: 6rem;
`

export const RadiusLeft = styled.div`
 background:#${props => props.theme};
 width: 1rem;
 height: 100%;
 border-top-left-radius:0.625rem ;
 border-bottom-left-radius:0.625rem ;
 margin-right: .5rem;
`

export const BackgroundCollapse = styled.div<{active: boolean}>`
background: var(--c1);
border-radius: 0.625rem;
box-shadow: var(--SombraBtns);
overflow-y: hidden;
max-height: 6rem;

${({ active }) => active && `
     transition: max-height 7.2s;
     max-height: 800rem;
`}
`
