import { useEffect, useState } from 'react'
import { Body } from '../../../Layouts/Body'
import { OutlineMetaButton } from '../../../../components/Buttons/OutlineMetaButton'
import { WhiteBox } from '../../../../components/WhiteBox'
import { iItemIntegracao } from '../../../../interfaces'
import { CheckTitulo, FlexItems, Titulo, ColumnItem } from './styles'
import { FormControl, FormLabel, Input } from '@chakra-ui/react'

interface iChecklistprops {
  item: iItemIntegracao
  isVisible: boolean
}

interface iChekIntegracao {
  texto: string
  check: Boolean
  observacaoIson: Boolean
  observacao?: string
}

export const Checklist: React.FC<iChecklistprops> = ({ item, isVisible }) => {
  if (!isVisible) {
    return null
  }
  const [ItensCheck, setItensCheck] = useState<iChekIntegracao[]>([])

  useEffect(() => {
    if (item.checklist !== undefined) {
      for (let i = 0; i < item.checklist.length; i++) {
        setItensCheck(oldArray => [...oldArray,
          {
            check: false,
            observacaoIson: false,
            texto: item.checklist !== undefined ? item.checklist[i]?.texto : ''
          }])
      }
    }
  }, [])

  function UpdateItemArray(index: number, check: Boolean, ObsIsOn: Boolean, Observacao?: string): void {
    const array = [...ItensCheck]
    array[index].check = check
    array[index].observacao = Observacao
    array[index].observacaoIson = ObsIsOn
    setItensCheck(array)
  }

  return (
    <Body>
      <h2 style={{ marginBottom: '1rem', color: 'var(--a3)', marginTop: '2rem' }}>{item.titulo}</h2>
      <WhiteBox>
        <Titulo>{item.descricao}</Titulo>
        {
          ItensCheck.map((r: iChekIntegracao, i) => {
            return (
              <ColumnItem key={i}>
                <FlexItems>
                  <input type={'checkbox'} />
                  <CheckTitulo>{r.texto}</CheckTitulo>
                  {
                    r.observacaoIson
                      ? <OutlineMetaButton
                        size='sm'
                        color='terc1'
                        onClick={() => UpdateItemArray(i, r.check, false)}
                      >Cancelar</OutlineMetaButton>
                      : <OutlineMetaButton
                        size='sm'
                        color='terc1'
                        onClick={() => UpdateItemArray(i, r.check, true)}
                      >Observação</OutlineMetaButton>
                  }
                </FlexItems>
                {
                  (r.observacaoIson) && (
                    <FormControl>
                      <FormLabel color={'var(--terc1)'}>Observação</FormLabel>
                      <Input placeholder='Digite sua observação' type={'text'} borderColor='var(--Gray4)' />
                    </FormControl>
                  )
                }
              </ColumnItem>
            )
          })
        }
      </WhiteBox>
    </Body>
  )
}
