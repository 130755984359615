import { Flex } from '@chakra-ui/react'
import { Button } from '../../../../../components/Button'
import { ModalBase } from '../../../../../components/ModalBase'
import { ModalBody } from './styles'

interface iModal {
  isOpen: boolean
  onRequestClose: () => void
}

export const ModalCadastroEmMassa: React.FC<iModal> = ({ onRequestClose, isOpen }) => {
  return (
        <ModalBase Titulo='Cadastrar participantes em massa' isOpen={isOpen} onClose={onRequestClose} Width='65rem'>
            <ModalBody>
                <span><strong>Instruções para gerar o arquivo no excel:</strong>
                    Baixe o arquivo de exemplo e preencha com os dados abaixo da linha de título.
                    Após isso, clique em "Salvar como..." e selecione o formato
                    <b>CSV UTF-8 (Delimitado por vírgulas).</b>
                    As colunas que estão marcadas com “*” são campos obrigatórios.
                    Por favor, realize o preenchimento completo para correto envio.
                    <small>Obrigatório a inserção do endereço de email</small>
                </span>

                <label htmlFor='Ifile'>Selecione um arquivo</label>
                <input id='Ifile' type={'file'} />
            </ModalBody>
            <Flex justifyContent={'end'} padding='1rem'>
                {/* <button>Baixar exemplo para preenchimento</button> */}
                <Button VarColor='Green2' size='md'>Enviar arquivo</Button>
            </Flex>
        </ModalBase>
  )
}
