/* eslint-disable no-return-assign */
import { Body } from '../../Layouts/Body'
import { GreenButton } from '../../../components/Buttons/GreenButton'
import { GreyButton } from '../../../components/Buttons/GreyButton'
import { WhiteBox } from '../../../components/WhiteBox'
import { Main } from './styles'
import { useState, useEffect } from 'react'
import { appApi } from '../../../services/appApi'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Checkbox, Divider, Flex, FormControl, FormLabel, Input, Radio, RadioGroup, Select, Stack, useToast } from '@chakra-ui/react'
import { Button } from '../../../components/Button'
import { EditorTexto } from '../../../components/EditorTexto/Index'
import { PropInvalida } from '../../../Utils/Helper'
import { ButtonTag } from './Components/ButtonTag'
import { ModalTag } from './Components/ModalTag'
import { FindUser } from '../../../components/FindBox'
import { iNomeId } from '../../../../src/interfaces'
import { AxiosResponse } from 'axios'

type iParamProps = {
  pesquisaId: string
  trilhaId: string
  processoId: string
  editar: string
}

interface iModel {
  dataInicio?: string
  dataFim?: string
  textoEmail: string
  textoAbertura: string
  textoEncerramento: string
  encerrarAutomaticamente: boolean
  tipo: number
  ciclo?: number
  validade?: number
  baseCiclo?: number
  reenviarDias: number[]
  pdi: iPDIPesquisa
  textosAbertura: iTextosPesquisa[]
  textosConvite: iTextosPesquisa[]
  textosEncerramento: iTextosPesquisa[]
}

interface iTextosPesquisa {
  texto: string
  participantes: number[]
}

interface iPDIPesquisa {
  dias: number
  destinatarioEmail: number
  notificacaoCadastro: number[]
  responsavelId?: string
  notificadoId?: string
  textoCriacao: string
  pdiGerado?: boolean
}

export const EnviarPesquisa: React.FC = () => {
  const toast = useToast()
  const navigate = useNavigate()
  const path = useLocation().pathname
  const { pesquisaId } = useParams<iParamProps>()

  const textPadraoEncerramento = '<strong>Obrigado por participar!</strong>'
  const textPadraoAbertura = `<p>Bem-vindo a pesquisa de ${path.includes('AnaliseDesempenho') ? 'análise de desempenho por competência' : 'percepção'} do [NOME DO AVALIADO].</p><br/> <p>A pesquisa é rápida e fácil, mas seja sincero e atento para o melhor desenvolvimento do avaliado.</p><br/> <p>Contamos com sua participação!</p>`
  const textPadraoEmail = `<p>Olá!</p><p>Você está recebendo o convite para o preenchimento da pesquisa de ${path.includes('AnaliseDesempenho') ? 'análise de desempenho por competência' : 'perpepção'}.</p><p>A pesquisa é simples e rápida.</p><p>O prazo para preenchimento é até o dia [DATA FIM]</p><p>[Link]</p><p>Qualquer problema no preenchimento da pesquisa, favor contatar o responsável.</p><p>A sua participação é fundamental para o sucesso dessa iniciativa.</p><p>Contamos com você!</p>`
  const textPadraoPDI = '<p>Olá, [NOME DO RESPONSAVEL]!</p><p>A pesquisa do(a) [NOME DO AVALIADO] foi concluída e você já pode iniciar o cadastro do PDI para o desenvolvimento dele!</p><p>É muito simples, basta acessa o link com os resultados do avaliado e Clicar no botão Adicionar PDI , criar as ações expecificando o que, por que e como ele deve trabalhar cada uma das competências definidas.</p><p>Você tem até o dia [DATA FIM] para cadastrar o PDI.</p><p>Link dos resultados da pesquisa do(a) [NOME DO AVALIADO]:</p><p>[LINK]</p><p>Se tiver qualquer dúvida pode entrar em contato com nosso RH.</p><p>Atenciosamente,</p><p>Equipe [NOME DA EMPRESA]</p>'

  const [isLoading, setIsLoading] = useState(true)
  const [Model, setModel] = useState<iModel>({
    encerrarAutomaticamente: false,
    textoAbertura: textPadraoAbertura,
    textoEmail: textPadraoEmail,
    textoEncerramento: textPadraoEncerramento,
    reenviarDias: [],
    tipo: 0,
    textosAbertura: [{ texto: textPadraoAbertura, participantes: [0, 1, 2, 3, 4, 6] }],
    textosEncerramento: [{ texto: textPadraoEncerramento, participantes: [0, 1, 2, 3, 4, 6] }],
    textosConvite: [{ texto: textPadraoEmail, participantes: [0, 1, 2, 3, 4, 6] }],
    pdi: {
      dias: 0,
      destinatarioEmail: 0,
      notificacaoCadastro: [0],
      textoCriacao: textPadraoPDI,
      pdiGerado: false
    }
  })

  const [DataInicioIsInvalid, setDataInicioIsInvalid] = useState(false)
  const [DataFimIsInvalid, setDataFimIsInvalid] = useState(false)
  const [ValidadeIsInvalid, setValidadeIsInvalid] = useState(false)
  const [CicloIsInvalid, setCicloIsInvalid] = useState(false)
  const [EnviarPdi, setEnviarPdi] = useState(false)
  const [ReenviarPesquisa, setReenviarPesquisa] = useState(false)
  const [ModalTagIsOpen, setModalTagIsOpen] = useState(false)
  const [Colaboradores, setColaboradores] = useState<iNomeId[]>([])
  const [OutroPDI, setOutroPDI] = useState(false)
  const [PesquisaNaoIniciada, setPesquisaNaoIniciada] = useState<boolean>(false)

  function SelecionarEixo(tipo: number, e: string, index: number): void {
    if (tipo === 0) {
      if (!Model.textosConvite[index].participantes.includes(parseInt(e))) {
        AdicionarNovoEixo(tipo, e, index)
      } else {
        if (index === 0) {
          CriarNovoEditor(tipo, e, index)
        } else if (Model.textosConvite[index].participantes.length > 0) {
          ExcluirNovoEixo(tipo, parseInt(e), index)
        }
      }
    } else if (tipo === 1) {
      if (!Model.textosAbertura[index].participantes.includes(parseInt(e))) {
        AdicionarNovoEixo(tipo, e, index)
      } else {
        if (index === 0) {
          CriarNovoEditor(tipo, e, index)
        } else if (Model.textosAbertura[index].participantes.length > 0) {
          ExcluirNovoEixo(tipo, parseInt(e), index)
        }
      }
    } else if (tipo === 2) {
      if (!Model.textosEncerramento[index].participantes.includes(parseInt(e))) {
        AdicionarNovoEixo(tipo, e, index)
      } else {
        if (index === 0) {
          CriarNovoEditor(tipo, e, index)
        } else if (Model.textosEncerramento[index].participantes.length > 0) {
          ExcluirNovoEixo(tipo, parseInt(e), index)
        }
      }
    }
  }

  function AdicionarNovoEixo(tipo: number, e: string, index: number): void {
    if (tipo === 0) {
      const copy = Model.textosConvite[index]
      copy.participantes.push(parseInt(e))
      const lista = Model.textosConvite
      lista.shift()
      lista.unshift(copy)
      for (let a = 1; a < lista.length; a++) {
        if (lista[a].participantes[0] === parseInt(e)) {
          lista.splice(a, 1)
        }
      }
      setModel({ ...Model, textosConvite: lista })
    } else if (tipo === 1) {
      const copy = Model.textosAbertura[index]
      copy.participantes.push(parseInt(e))
      const lista = Model.textosAbertura
      lista.shift()
      lista.unshift(copy)
      for (let a = 1; a < lista.length; a++) {
        if (lista[a].participantes[0] === parseInt(e)) {
          lista.splice(a, 1)
        }
      }
      setModel({ ...Model, textosAbertura: lista })
    } else if (tipo === 2) {
      const copy = Model.textosEncerramento[index]
      copy.participantes.push(parseInt(e))
      const lista = Model.textosEncerramento
      lista.shift()
      lista.unshift(copy)
      for (let a = 1; a < lista.length; a++) {
        if (lista[a].participantes[0] === parseInt(e)) {
          lista.splice(a, 1)
        }
      }
      setModel({ ...Model, textosEncerramento: lista })
    }
  }

  function CriarNovoEditor(tipo: number, e: string, index: number): void {
    if (tipo === 0) {
      const indexEixo = (ind: number): number => {
        return Model.textosConvite[ind].participantes.findIndex(r => r === parseInt(e))
      }
      setModel({
        ...Model,
        textosConvite: [...Model.textosConvite, {
          texto: textPadraoEmail,
          participantes: Model.textosConvite[index].participantes.splice(indexEixo(index), 1)
        }]
      })
    } else if (tipo === 1) {
      const indexEixo = (ind: number): number => {
        return Model.textosAbertura[ind].participantes.findIndex(r => r === parseInt(e))
      }
      setModel({
        ...Model,
        textosAbertura: [...Model.textosAbertura, {
          texto: textPadraoAbertura,
          participantes: Model.textosAbertura[index].participantes.splice(indexEixo(index), 1)
        }]
      })
    } else if (tipo === 2) {
      const indexEixo = (ind: number): number => {
        return Model.textosEncerramento[ind].participantes.findIndex(r => r === parseInt(e))
      }
      setModel({
        ...Model,
        textosEncerramento: [...Model.textosEncerramento, {
          texto: textPadraoEncerramento,
          participantes: Model.textosEncerramento[index].participantes.splice(indexEixo(index), 1)
        }]
      })
    }
  }

  function ExcluirNovoEixo(tipo: number, valorEixoAtual: number, index: number): void {
    if (tipo === 0) {
      const firstElement = Model.textosConvite[0]
      firstElement.participantes.push(valorEixoAtual)
      Model.textosConvite.shift()
      setModel({ ...Model, textosConvite: Model.textosConvite.splice(index - 1, 1) })
      const copy = [...Model.textosConvite]
      copy.unshift(firstElement)
      setModel({ ...Model, textosConvite: copy })
    } else if (tipo === 1) {
      const firstElement = Model.textosAbertura[0]
      firstElement.participantes.push(valorEixoAtual)
      Model.textosAbertura.shift()
      setModel({ ...Model, textosAbertura: Model.textosAbertura.splice(index - 1, 1) })
      const copy = [...Model.textosAbertura]
      copy.unshift(firstElement)
      setModel({ ...Model, textosAbertura: copy })
    } else if (tipo === 2) {
      const firstElement = Model.textosEncerramento[0]
      firstElement.participantes.push(valorEixoAtual)
      Model.textosEncerramento.shift()
      setModel({ ...Model, textosEncerramento: Model.textosEncerramento.splice(index - 1, 1) })
      const copy = [...Model.textosEncerramento]
      copy.unshift(firstElement)
      setModel({ ...Model, textosEncerramento: copy })
    }
  }

  function AtualizarTexto(tipo: number, value: string, index: number): void {
    if (tipo === 0) {
      const copy = [...Model.textosConvite]
      copy[index].texto = value
      setModel({ ...Model, textosConvite: copy })
    } else if (tipo === 1) {
      const copy = [...Model.textosAbertura]
      copy[index].texto = value
      setModel({ ...Model, textosAbertura: copy })
    } else if (tipo === 2) {
      const copy = [...Model.textosEncerramento]
      copy[index].texto = value
      setModel({ ...Model, textosEncerramento: copy })
    }
  }

  function EnviarPesquisa(): void {
    setDataInicioIsInvalid(false)
    setDataFimIsInvalid(false)
    setValidadeIsInvalid(false)
    setCicloIsInvalid(false)

    if (Model.tipo === 1 && PropInvalida(Model.ciclo)) {
      setCicloIsInvalid(true)
      toast({
        title: 'Selecione um ciclo',
        status: 'error',
        isClosable: false,
        position: 'top-right'
      })
      return
    }

    if (Model.tipo === 1 && PropInvalida(Model.validade)) {
      setValidadeIsInvalid(true)
      toast({
        title: 'Preencha a validade',
        status: 'error',
        isClosable: false,
        position: 'top-right'
      })
      return
    }

    if (Model.tipo === 1 && PropInvalida(Model.baseCiclo)) {
      toast({
        title: 'Escolha uma base para definir o ciclo da pesquisa',
        status: 'error',
        isClosable: false,
        position: 'top-right'
      })
      return
    }

    if ((Model.tipo === 0 && (Model.dataInicio === undefined || Model.dataInicio === '')) || (Model.tipo === 1 && Model.baseCiclo === 1 && PropInvalida(Model.dataInicio))) {
      setDataInicioIsInvalid(true)
      toast({
        title: 'Preencha a data de início',
        status: 'error',
        isClosable: false,
        position: 'top-right'
      })
      return
    }

    if (Model.tipo === 0 && (Model.dataFim === undefined || Model.dataFim === '')) {
      setDataFimIsInvalid(true)
      toast({
        title: 'Preencha a data de fim',
        status: 'error',
        isClosable: false,
        position: 'top-right'
      })
    }

    const form = {
      dataInicio: Model.dataInicio,
      dataFim: Model.dataFim,
      encerrarAutomaticamente: Model.encerrarAutomaticamente,
      textoAbertura: Model.textoAbertura,
      textoEmail: Model.textoEmail,
      textoEncerramento: Model.textoEncerramento,
      reenviarDias: Model.reenviarDias,
      tipo: Model.tipo,
      textosAbertura: Model.textosAbertura,
      textosEncerramento: Model.textosEncerramento,
      textosConvite: Model.textosConvite,
      pdi: EnviarPdi ? Model.pdi : undefined,
      baseCiclo: Model.baseCiclo,
      ciclo: Model.ciclo,
      validade: Model.validade
    }

    appApi.put(`PesquisaPercepcao/${pesquisaId as string}/Enviar`, form)
      .then(() => navigate(`${path.replace('EnviarPesquisa', 'ResumoPesquisa')}`))
      .catch(() => {
        toast({
          title: 'Ocorreu um erro, tente mais tarde',
          status: 'error',
          isClosable: false,
          position: 'top-right'
        })
      })
  }

  function onChangeTipo(value: string): void {
    if (value === '0') {
      setModel({
        ...Model,
        tipo: parseInt(value),
        baseCiclo: undefined,
        ciclo: undefined,
        validade: undefined
      })
    } else if (value === '1') {
      setModel({
        ...Model,
        encerrarAutomaticamente: false,
        tipo: parseInt(value),
        dataFim: undefined
      })
    }
  }

  function UpdateTexto(tipo: number): void {
    if (tipo === 0) {
      appApi.put(`PesquisaPercepcao/TextoEmail/${pesquisaId as string}`, Model.textosConvite)
        .then(response => {
          if (response.status === 200) {
            toast({
              title: 'Texto salvo com sucesso',
              status: 'success',
              isClosable: false,
              position: 'top-right'
            })
          } else {
            alert('Erro ao alterar o texto')
          }
        }).catch(err => console.log(err))
    } else if (tipo === 1) {
      appApi.put(`PesquisaPercepcao/TextoAbertura/${pesquisaId as string}`, Model.textosAbertura)
        .then(response => {
          if (response.status === 200) {
            toast({
              title: 'Texto salvo com sucesso',
              status: 'success',
              isClosable: false,
              position: 'top-right'
            })
          } else {
            alert('Erro ao alterar o texto')
          }
        }).catch(err => console.log(err))
    } else if (tipo === 2) {
      appApi.put(`PesquisaPercepcao/TextoEncerramento/${pesquisaId as string}`, Model.textosEncerramento).then(response => {
        if (response.status === 200) {
          toast({
            title: 'Texto salvo com sucesso',
            status: 'success',
            isClosable: false,
            position: 'top-right'
          })
        } else {
          alert('Erro ao alterar o texto')
        }
      }).catch(err => console.log(err))
    } else if (tipo === 3) {
      const data = {
        texto: Model.pdi.textoCriacao
      }
      appApi.put(`PesquisaPercepcao/TextoPDI/${pesquisaId as string}`, data).then(response => {
        if (response.status === 200) {
          toast({
            title: 'Texto salvo com sucesso',
            status: 'success',
            isClosable: false,
            position: 'top-right'
          })
        } else {
          alert('Erro ao alterar o texto')
        }
      }).catch(err => console.log(err))
    }
  }

  function UpdateDestinatarioPDI(value: string): void {
    setModel((prevModel) => ({
      ...prevModel,
      pdi: {
        ...prevModel.pdi,
        destinatarioEmail: parseInt(value)
      }
    }))
    if (value === '4') {
      setOutroPDI(true)
      return
    }
    setOutroPDI(false)
  }

  function handleNotificacaoCadastroChange(value: number, isChecked: boolean): void {
    setModel((prevState) => {
      const notificacaoCadastro = [...prevState.pdi.notificacaoCadastro]

      if (isChecked) {
        notificacaoCadastro.push(value)
      } else {
        const index = notificacaoCadastro.indexOf(value)
        if (index > -1) {
          notificacaoCadastro.splice(index, 1)
        }
      }

      return {
        ...prevState,
        pdi: {
          ...prevState.pdi,
          notificacaoCadastro
        }
      }
    })
  }

  function SelecionarEnvioDias(value: string): void {
    if (Model.reenviarDias.find(e => e.toString() === value) === undefined) {
      const copy = [...Model.reenviarDias]
      copy.push(parseInt(value))
      setModel({ ...Model, reenviarDias: copy })
    } else {
      const copy = [...Model.reenviarDias].filter(e => e.toString() !== value)
      setModel({ ...Model, reenviarDias: copy })
    }
  }

  function getPesquisa(): void {
    appApi.get(`PesquisaPercepcao/${pesquisaId as string}/Configuracao`).then((response: AxiosResponse<iModel>) => {
      setModel(response.data)

      const DataString = `${response?.data?.dataInicio ?? ''}`
      setPesquisaNaoIniciada(Date.parse(DataString) > new Date().getTime())
      if (!PropInvalida(response.data.pdi) && !response.data.pdi.pdiGerado) {
        setEnviarPdi(true)
      }
      setIsLoading(false)
    }).catch(err => console.log(err))
  }

  function getColaboradores(): void {
    appApi.get('Colaborador/Select')
      .then(res => setColaboradores(res.data))
      .catch(err => console.log(err))
  }

  function UpdateConfiguracaoPesquisaEtapa(): void {
    const form = {
      textosAbertura: Model.textosAbertura,
      textosEncerramento: Model.textosEncerramento,
      textosConvite: Model.textosConvite
    }
    const splRoute = path.split('/')
    appApi.put(`PesquisaPercepcaoEtapa/Pesquisa/${pesquisaId as string}`, form)
      .then(() => {
        if (path.includes('Trilha')) {
          navigate(`/Jornada/Configuracao/Trilha/${splRoute[splRoute.length - 1]}`)
        } else if (path.includes('Processo')) {
          navigate(`/Rh/VisualizarPerfil/${splRoute[splRoute.length - 1]}`)
        }
      })
      .catch(err => console.log(err))
  }

  function ReenviarPesquisaEtapaColaborador(): void {
    const form = {
      textosAbertura: Model.textosAbertura,
      textosEncerramento: Model.textosEncerramento,
      textosConvite: Model.textosConvite
    }
    appApi.put(`PesquisaPercepcaoEtapaColaborador/${pesquisaId as string}/Reenviar`, form)
      .then(() => {
        navigate(-1)
      })
      .catch(err => console.log(err))
  }

  function ReenviarNaoRespondidos(): void {
    const form = {
      dataInicio: Model.dataInicio,
      dataFim: Model.dataFim,
      textoEmail: Model.textoEmail,
      textoAbertura: Model.textoAbertura,
      textoEncerramento: Model.textoEncerramento,
      encerrarAutomaticamente: Model.encerrarAutomaticamente,
      pdi: Model.pdi
    }
    appApi.put(`PesquisaPercepcao/${pesquisaId as string}/Reenviar`, form).then(response => {
      if (response.status === 200) {
        navigate(-1)
      } else {
        alert('Erro')
      }
    }).catch(err => console.log(err))
  }

  function SalvarNovaDataFinal(): void {
    const form = {
      dataInicio: Model.dataInicio,
      dataFim: Model.dataFim,
      encerrarAutomaticamente: Model.encerrarAutomaticamente
    }
    appApi.put(`PesquisaPercepcao/${pesquisaId as string}/AlterarDataFim`, form).then(response => {
      if (response.status === 200) {
        toast({
          title: 'Data atualizada com sucesso',
          status: 'success',
          isClosable: false,
          position: 'top-right'
        })
        getPesquisa()
      } else {
        alert('Erro ao alterar data')
      }
    }).catch(err => console.log(err))
  }

  function VoltarPagina(): void {
    toast({
      title: 'Pesquisa atualizada com sucesso',
      status: 'success',
      isClosable: false,
      position: 'top-right'
    })
    navigate('/Rh/AnaliseDesempenho/Gestao')
  }

  function VoltarESalvarPagina(): void {
    const form = {
      dataInicio: Model.dataInicio,
      dataFim: Model.dataFim,
      encerrarAutomaticamente: Model.encerrarAutomaticamente,
      textoAbertura: Model.textoAbertura,
      textoEmail: Model.textoEmail,
      textoEncerramento: Model.textoEncerramento,
      reenviarDias: Model.reenviarDias,
      tipo: Model.tipo,
      textosAbertura: Model.textosAbertura,
      textosEncerramento: Model.textosEncerramento,
      textosConvite: Model.textosConvite,
      pdi: EnviarPdi ? Model.pdi : undefined,
      baseCiclo: Model.baseCiclo,
      ciclo: Model.ciclo,
      validade: Model.validade
    }

    appApi.put(`PesquisaPercepcao/${pesquisaId as string}/Salvar`, form)
      .then(() => {
        navigate(-1)
        toast({
          title: 'Dados da pesquisa salvos',
          status: 'success',
          isClosable: false,
          position: 'top-right'
        })
      })
      .catch(() => {
        toast({
          title: 'Ocorreu um erro, tente mais tarde',
          status: 'error',
          isClosable: false,
          position: 'top-right'
        })
      })
  }

  useEffect(() => {
    getColaboradores()
    getPesquisa()
  }, [])

  return (
    <Body>
      <ModalTag
        onClose={() => setModalTagIsOpen(false)}
        isOpen={ModalTagIsOpen}
      />
      <Main>
        <h1 style={{ margin: '2rem 0rem' }}>Enviar pesquisa</h1>
        {
          (!path.includes('Trilha') && !path.includes('Processo') && PropInvalida(Model.ciclo) && path.includes('Editar')) && (
            <WhiteBox>
              <Flex flexDir={'column'}>
                <h2 style={{ marginTop: '1rem', color: 'var(--a4)' }}>Configuração da pesquisa</h2>

                <FormControl alignItems={'center'} margin={'1rem 0'} as={Flex}>
                  <FormLabel width={'11rem'} marginRight={'1rem'} fontSize={'xl'}>Início da pesquisa</FormLabel>
                  <Input
                    width={'15rem'}
                    type={'datetime-local'}
                    min={new Date().toISOString().split('T')[0]}
                    value={Model?.dataInicio ?? ''}
                    onChange={(e) => setModel({ ...Model, dataInicio: e.target.value })}
                    borderColor='var(--Gray4)'
                    isInvalid={DataInicioIsInvalid}
                  />
                </FormControl>

                <FormControl alignItems={'center'} marginBottom={'1rem'} as={Flex}>
                  <FormLabel width={'11rem'} marginRight={'1rem'} fontSize={'xl'}>Final da pesquisa</FormLabel>
                  <Input
                    width={'15rem'}
                    type={'datetime-local'}
                    min={new Date().toISOString().split('T')[0]}
                    value={Model?.dataFim ?? ''}
                    onChange={(e) => setModel({ ...Model, dataFim: e.target.value })}
                    borderColor='var(--Gray4)'
                    isInvalid={DataFimIsInvalid}
                  />
                  {
                    (path.includes('Editar')) && (<Flex marginLeft={'1rem'}>
                      <GreenButton margin-left='5px' OnClick={() => SalvarNovaDataFinal()} Size='md' isNav={false}>Salvar</GreenButton>
                    </Flex>)
                  }
                </FormControl>

                <Flex marginBottom={'1rem'} alignItems={'center'} >
                  <input
                    checked={Model.encerrarAutomaticamente}
                    onChange={(e) => setModel({ ...Model, encerrarAutomaticamente: e.target.checked })}
                    type={'checkbox'}
                  />
                  <small>Encerrar automaticamente quando todas as pesquisas do avaliado forem respondidas</small>
                </Flex>
              </Flex>
              <Flex mt='1rem' direction={'column'}>
                <FormLabel marginRight={'.5rem'}>
                  Adicionar fluxo de PDI após a pesquisa encerrar?</FormLabel>
                <RadioGroup value={EnviarPdi ? '1' : '2'} defaultValue='1' onChange={() => setEnviarPdi(!EnviarPdi)}>
                  <Stack spacing={5} direction='row'>
                    <Radio value='1'>
                      Sim
                    </Radio>
                    <Radio value='2'>
                      Não
                    </Radio>
                  </Stack>
                </RadioGroup>
              </Flex>
              {(EnviarPdi) &&
                <Flex direction={'column'}>
                  <Divider my={'1rem'} />
                  <h3 style={{ marginTop: '1rem', color: 'var(--a4)' }}>PDI</h3>

                  <FormLabel my={'1rem'} fontSize={'lg'}>Tempo para preencher PDI após pesquisa encerrada</FormLabel>
                  <FormControl alignItems={'center'} marginBottom={'1rem'} as={Flex}>
                    <Input
                      type={'number'}
                      width={'5rem'}
                      value={Model?.pdi?.dias}
                      onChange={(e) => setModel((prevState) => ({
                        ...prevState,
                        pdi: {
                          ...prevState.pdi,
                          dias: e.target.valueAsNumber
                        }
                      }))}
                      borderColor='var(--Gray4)'
                    />
                    <FormLabel width={'11rem'} marginLeft={'1rem'} fontSize={'lg'}>Dias</FormLabel>
                  </FormControl>

                  <FormLabel my={'1rem'} marginRight={'.5rem'}>
                    Quem irá inserir o PDI do avaliado?</FormLabel>
                  <RadioGroup value={Model?.pdi?.destinatarioEmail.toString()} defaultValue='1'
                    onChange={UpdateDestinatarioPDI}>
                    <Stack spacing={5} direction='row'>
                      <Radio value='0'>Gestor</Radio>
                      <Radio value='1'>Colaborador</Radio>
                      <Radio value='2'>RH</Radio>
                      <Radio value='3'>Outro:</Radio>
                      {OutroPDI &&
                        <Flex flexDir={'column'}>
                          <FindUser
                            lista={Colaboradores}
                            onChoice={(e) => setModel((prevState) => ({
                              ...prevState,
                              pdi: {
                                ...prevState.pdi,
                                responsavelId: e
                              }
                            }))}
                            placeholder='Digite o nome do participante'
                            selecionado={Model.pdi.responsavelId}
                          />
                        </Flex>
                      }
                    </Stack>
                  </RadioGroup>

                  <FormLabel my={'1rem'} marginRight={'.5rem'}>
                    Quem deve receber notificações de cadastro de PDI em atraso?</FormLabel>
                  <Stack spacing={5} direction='row'>
                    <Checkbox
                      value={0}
                      isChecked={Model.pdi.notificacaoCadastro.includes(0)}
                      onChange={(e) => handleNotificacaoCadastroChange(0, e.target.checked)}
                    >
                      Gestor
                    </Checkbox>
                    <Checkbox
                      value={2}
                      isChecked={Model.pdi.notificacaoCadastro.includes(2)}
                      onChange={(e) => handleNotificacaoCadastroChange(2, e.target.checked)}
                    >
                      RH
                    </Checkbox>
                    <Checkbox
                      value={3}
                      isChecked={Model.pdi.notificacaoCadastro.includes(3)}
                      onChange={(e) => handleNotificacaoCadastroChange(3, e.target.checked)}
                    >
                      Outro:
                    </Checkbox>
                    {Model?.pdi?.notificacaoCadastro.includes(3) &&
                      <Flex flexDir={'column'}>
                        <FindUser
                          lista={Colaboradores}
                          onChoice={(e) => setModel((prevState) => ({
                            ...prevState,
                            pdi: {
                              ...prevState.pdi,
                              notificadoId: e
                            }
                          }))}
                          placeholder='Digite o nome do participante'
                          selecionado={Model.pdi.notificadoId}
                        />
                      </Flex>
                    }

                  </Stack>
                </Flex>
              }
            </WhiteBox>
          )
        }
        {
          (!path.includes('Trilha') && !path.includes('Processo') && !path.includes('Editar')) && (
            <WhiteBox>
              <FormControl as={Flex} alignItems='center'>
                <FormLabel margin={0} marginRight={'.5rem'}>Tipo da pesquisa</FormLabel>
                <Select
                  onChange={(e) => onChangeTipo(e.target.value)}
                  value={Model.tipo.toString()}
                  width={'20rem'}
                >
                  <option value={'0'}>Envio único</option>
                  <option value={'1'}>Ciclica</option>
                </Select>
              </FormControl>
              {
                (Model.tipo === 0) && (
                  <Flex flexDir={'column'}>
                    <h2 style={{ marginTop: '1rem', color: 'var(--a4)' }}>Configuração da pesquisa</h2>

                    <FormControl alignItems={'center'} margin={'1rem 0'} as={Flex}>
                      <FormLabel width={'11rem'} marginRight={'1rem'} fontSize={'xl'}>Início da pesquisa</FormLabel>
                      <Input
                        width={'15rem'}
                        type={'datetime-local'}
                        min={new Date().toISOString().split('T')[0]}
                        value={Model?.dataInicio ?? ''}
                        onChange={(e) => setModel({ ...Model, dataInicio: e.target.value })}
                        borderColor='var(--Gray4)'
                        isInvalid={DataInicioIsInvalid}
                      />
                    </FormControl>

                    <FormControl alignItems={'center'} marginBottom={'1rem'} as={Flex}>
                      <FormLabel width={'11rem'} marginRight={'1rem'} fontSize={'xl'}>Final da pesquisa</FormLabel>
                      <Input
                        type={'datetime-local'}
                        width={'15rem'}
                        min={new Date().toISOString().split('T')[0]}
                        value={Model?.dataFim ?? ''}
                        onChange={(e) => setModel({ ...Model, dataFim: e.target.value })}
                        borderColor='var(--Gray4)'
                        isInvalid={DataFimIsInvalid}
                      />
                      {
                        (path.includes('Editar')) && (<Flex marginLeft={'1rem'}>
                          <GreenButton margin-left='5px' OnClick={() => SalvarNovaDataFinal()} Size='md' isNav={false}>Salvar</GreenButton>
                        </Flex>)
                      }
                    </FormControl>

                    <Flex my={'.7rem'} alignItems={'center'} >
                      <input
                        checked={Model.encerrarAutomaticamente}
                        onChange={(e) => setModel({ ...Model, encerrarAutomaticamente: e.target.checked })}
                        type={'checkbox'}
                      />
                      <small>Encerrar automaticamente quando todas as pesquisas do avaliado forem respondidas</small>
                    </Flex>

                    <Flex mt='2rem' direction={'column'}>
                      <FormControl>
                        <FormLabel marginRight={'.5rem'}>Reenviar convites para quem não respondeu</FormLabel>
                        <RadioGroup value={ReenviarPesquisa ? '1' : '2'} defaultValue='1' onChange={() => setReenviarPesquisa(!ReenviarPesquisa)}>
                          <Stack spacing={5} direction='row'>
                            <Radio value='1'>
                              Sim
                            </Radio>
                            <Radio value='2'>
                              Não
                            </Radio>
                          </Stack>
                        </RadioGroup>
                      </FormControl>
                      {ReenviarPesquisa &&
                        <Stack mt={'1rem'} direction={'column'} gap='.5rem'>
                          <Flex alignItems={'center'} >
                            <input
                              checked={Model?.reenviarDias?.includes(5)}
                              value={'5'}
                              onChange={(e) => { SelecionarEnvioDias(e.target.value) }
                              }
                              type={'checkbox'}
                            />
                            <small>5 dias antes de encerrar a pesquisa</small>
                          </Flex>
                          <Flex alignItems={'center'} >
                            <input
                              checked={Model?.reenviarDias?.includes(3)}
                              value={'3'}
                              onChange={(e) => { SelecionarEnvioDias(e.target.value) }
                              }
                              type={'checkbox'}
                            />
                            <small>3 dias antes de encerrar a pesquisa</small>
                          </Flex>
                          <Flex alignItems={'center'} >
                            <input
                              checked={Model?.reenviarDias?.includes(2)}
                              value={'2'}
                              onChange={(e) => { SelecionarEnvioDias(e.target.value) }
                              }
                              type={'checkbox'}
                            />
                            <small>2 dias antes de encerrar a pesquisa</small>
                          </Flex>
                          <Flex alignItems={'center'} >
                            <input
                              checked={Model?.reenviarDias?.includes(1)}
                              value={'1'}
                              onChange={(e) => { SelecionarEnvioDias(e.target.value) }
                              }
                              type={'checkbox'}
                            />
                            <small>1 dia antes de encerrar a pesquisa</small>
                          </Flex>
                        </Stack>
                      }
                    </Flex>

                    <Flex mt='2rem' direction={'column'}>
                      <FormLabel marginRight={'.5rem'}>
                        Adicionar fluxo de PDI após a pesquisa encerrar?</FormLabel>
                      <RadioGroup value={EnviarPdi ? '1' : '2'} defaultValue='1' onChange={() => setEnviarPdi(!EnviarPdi)}>
                        <Stack spacing={5} direction='row'>
                          <Radio value='1'>
                            Sim
                          </Radio>
                          <Radio value='2'>
                            Não
                          </Radio>
                        </Stack>
                      </RadioGroup>
                    </Flex>
                    {EnviarPdi &&
                      <Flex direction={'column'}>
                        <Divider my={'1rem'} />
                        <h3 style={{ marginTop: '1rem', color: 'var(--a4)' }}>PDI</h3>

                        <FormLabel my={'1rem'} fontSize={'lg'}>Tempo para preencher PDI após pesquisa encerrada</FormLabel>
                        <FormControl alignItems={'center'} marginBottom={'1rem'} as={Flex}>
                          <Input
                            type={'number'}
                            width={'5rem'}
                            value={Model?.pdi?.dias}
                            onChange={(e) => setModel((prevState) => ({
                              ...prevState,
                              pdi: {
                                ...prevState.pdi,
                                dias: e.target.valueAsNumber
                              }
                            }))}
                            borderColor='var(--Gray4)'
                          />
                          <FormLabel width={'11rem'} marginLeft={'1rem'} fontSize={'lg'}>Dias</FormLabel>
                        </FormControl>

                        <FormLabel my={'1rem'} marginRight={'.5rem'}>
                          Quem irá inserir o PDI do avaliado?</FormLabel>
                        <RadioGroup value={Model?.pdi?.destinatarioEmail.toString()} defaultValue='1'
                          onChange={UpdateDestinatarioPDI}>
                          <Stack spacing={5} direction='row'>
                            <Radio value='0'>Gestor</Radio>
                            <Radio value='1'>Colaborador</Radio>
                            <Radio value='2'>RH</Radio>
                            <Radio value='3'>Outro:</Radio>
                            {OutroPDI &&
                              <Flex flexDir={'column'}>
                                <FindUser
                                  lista={Colaboradores}
                                  onChoice={(e) => setModel((prevState) => ({
                                    ...prevState,
                                    pdi: {
                                      ...prevState.pdi,
                                      responsavelId: e
                                    }
                                  }))}
                                  placeholder='Digite o nome do participante'
                                  selecionado={Model.pdi.responsavelId}
                                />
                              </Flex>
                            }
                          </Stack>
                        </RadioGroup>

                        <FormLabel my={'1rem'} marginRight={'.5rem'}>
                          Quem deve receber notificações de cadastro de PDI em atraso?</FormLabel>
                        <Stack spacing={5} direction='row'>
                          <Checkbox
                            value={0}
                            isChecked={Model.pdi.notificacaoCadastro.includes(0)}
                            onChange={(e) => handleNotificacaoCadastroChange(0, e.target.checked)}
                          >
                            Gestor
                          </Checkbox>
                          <Checkbox
                            value={2}
                            isChecked={Model.pdi.notificacaoCadastro.includes(2)}
                            onChange={(e) => handleNotificacaoCadastroChange(2, e.target.checked)}
                          >
                            RH
                          </Checkbox>
                          <Checkbox
                            value={3}
                            isChecked={Model.pdi.notificacaoCadastro.includes(3)}
                            onChange={(e) => handleNotificacaoCadastroChange(3, e.target.checked)}
                          >
                            Outro:
                          </Checkbox>
                          {Model?.pdi?.notificacaoCadastro.includes(3) &&
                            <Flex flexDir={'column'}>
                              <FindUser
                                lista={Colaboradores}
                                onChoice={(e) => setModel((prevState) => ({
                                  ...prevState,
                                  pdi: {
                                    ...prevState.pdi,
                                    notificadoId: e
                                  }
                                }))}
                                placeholder='Digite o nome do participante'
                                selecionado={Model.pdi.notificadoId}
                              />
                            </Flex>
                          }

                        </Stack>
                      </Flex>
                    }
                  </Flex>
                )
              }

              <Flex flexDir={'column'}>
                {
                  (Model.tipo === 1) && (
                    <Flex flexDir={'column'} gap='1rem'>
                      <FormControl marginTop={'1rem'} as={Flex} alignItems='center'>
                        <FormLabel margin={0} marginRight={'.5rem'}>Ciclo</FormLabel>
                        <Select
                          onChange={(e) => setModel({ ...Model, ciclo: parseInt(e.target.value) })}
                          value={Model.ciclo?.toString() ?? ''}
                          width={'20rem'}
                          placeholder='Selecione um ciclo'
                          isInvalid={CicloIsInvalid}
                        >
                          <option value={'0'}>Mensal</option>
                          <option value={'1'}>Trimestral</option>
                          <option value={'2'}>Semestral</option>
                          <option value={'3'}>Anual</option>
                          <option value={'4'}>Bianual</option>
                        </Select>
                      </FormControl>
                      <FormControl alignItems='center' as={Flex}>
                        <FormLabel margin={0} marginRight={'.5rem'}>Tempo limite para resposta</FormLabel>
                        <Input
                          width={'12rem'}
                          type={'number'}
                          placeholder={'Digite a validade'}
                          value={Model.validade?.toString() ?? ''}
                          onChange={(e) => setModel({ ...Model, validade: parseInt(e.target.value) })}
                          isInvalid={ValidadeIsInvalid}
                        />
                      </FormControl>

                      <FormControl flexDir={'column'} as={Flex}>
                        <FormLabel margin={0}>Definir o ciclo baseado na data de</FormLabel>
                        <RadioGroup
                          marginTop={'.5rem'}
                          onChange={(e) => setModel({ ...Model, baseCiclo: parseInt(e) })}
                          value={Model.baseCiclo?.toString() ?? ''}
                        >
                          <Stack direction='column'>
                            <Radio value='0'>ingresso do avaliado</Radio>
                            <Flex gap={'1rem'}>
                              <Radio value='1'>início da pesquisa</Radio>
                              {
                                (Model.baseCiclo === 1) && (
                                  <Input
                                    width={'15rem'}
                                    type={'datetime-local'}
                                    min={new Date().toISOString().split('T')[0]}
                                    value={Model?.dataInicio ?? ''}
                                    onChange={(e) => setModel({ ...Model, dataInicio: e.target.value })}
                                    borderColor='var(--Gray4)'
                                    isInvalid={DataInicioIsInvalid}
                                  />
                                )
                              }
                            </Flex>
                          </Stack>
                        </RadioGroup>
                      </FormControl>
                    </Flex>
                  )
                }
              </Flex>
            </WhiteBox>
          )
        }
        <WhiteBox margin='1rem 0rem'>
          {Model.textosConvite.map((elemento, index) => {
            return (
              <div key={index}>
                {index > 0 && (
                  <hr style={{ marginTop: '1rem', border: '1px solid var(--c3)' }} />
                )}
                <div>
                  {index === 0 && (
                    <Flex justifyContent={'space-between'}>
                      <Flex flexDir={'column'}>
                        <h3>Texto do convite para preenchimento da pesquisa</h3>
                        <small>Esse texto será apresentado como e-mail ou notificação antes do respondente abrir a pesquisa</small>
                      </Flex>
                      <ButtonTag onClick={() => setModalTagIsOpen(true)}>Saiba como utilizar as TAGs</ButtonTag>
                    </Flex>
                  )}
                  <Flex width={'100%'} alignItems='center' justifyContent={'space-between'}>
                    <Flex flexDir={'column'}>
                      <FormLabel my={'1rem'} marginRight={'.5rem'}>
                        {index === 0 ? 'Participantes que receberão essa mensagem:' : 'Participante que receberá essa mensagem:'}
                      </FormLabel>
                      <Stack my={'1rem'} spacing={5} direction='row'>
                        {elemento.participantes.includes(0) && (
                          <Checkbox isDisabled={index === 0 && elemento.participantes.length === 1} isChecked={elemento.participantes.includes(0)} value={'0'} onChange={(e) => SelecionarEixo(0, e.target.value, index)}>
                            Auto avaliação
                          </Checkbox>
                        )}

                        {elemento.participantes.includes(1) && (
                          <Checkbox isDisabled={index === 0 && elemento.participantes.length === 1} isChecked={elemento.participantes.includes(1)} value={'1'} onChange={(e) => SelecionarEixo(0, e.target.value, index)}>
                            Gestor
                          </Checkbox>
                        )}
                        {elemento.participantes.includes(2) && (
                          <Checkbox isDisabled={index === 0 && elemento.participantes.length === 1} isChecked={elemento.participantes.includes(2)} value={'2'} onChange={(e) => SelecionarEixo(0, e.target.value, index)}>
                            Pares
                          </Checkbox>
                        )}

                        {elemento.participantes.includes(3) && (
                          <Checkbox isDisabled={index === 0 && elemento.participantes.length === 1} isChecked={elemento.participantes.includes(3)} value={'3'} onChange={(e) => SelecionarEixo(0, e.target.value, index)}>
                            Liderados
                          </Checkbox>
                        )}

                        {elemento.participantes.includes(6) && (
                          <Checkbox isDisabled={index === 0 && elemento.participantes.length === 1} isChecked={elemento.participantes.includes(6)} value={'6'} onChange={(e) => SelecionarEixo(0, e.target.value, index)}>
                            Alta liderança
                          </Checkbox>
                        )}

                        {elemento.participantes.includes(4) && (
                          <Checkbox isDisabled={index === 0 && elemento.participantes.length === 1} isChecked={elemento.participantes.includes(4)} value={'4'} onChange={(e) => SelecionarEixo(0, e.target.value, index)}>
                            Outros
                          </Checkbox>
                        )}
                      </Stack>
                    </Flex>
                    {index > 0 && (
                      <Button onClick={() => SelecionarEixo(0, elemento.participantes[0].toString(), index)} VarColor='terc3'>Excluir mensagem</Button>
                    )}
                  </Flex>
                  <FormLabel my={'.2rem'} marginRight={'.5rem'} color={'var(--c7)'} fontSize={'10pt'} pb={'.6rem'} mt={'-.1rem'}>
                    Ao desselecionar um eixo é gerado um novo editor de texto personalizado para ele
                  </FormLabel>
                </div>
                <EditorTexto isLoading={isLoading} initialValue={elemento.texto} onChange={(e: string) => AtualizarTexto(0, e, index)} />
                <Flex justifyContent={'space-between'} marginTop='1rem'>
                  <Flex gap={'1rem'}>
                    <GreyButton
                      onClick={() => {
                        setModel(prevState => ({
                          ...prevState,
                          textosConvite: prevState.textosConvite.map((texto, i) => {
                            if (i === index) {
                              return { ...texto, texto: textPadraoEmail }
                            }
                            return texto
                          })
                        }))
                        setIsLoading(true)
                        setTimeout(() => setIsLoading(false), 1000)
                      }}
                      Size='md'
                      isNav={false}
                    >Restaurar texto padrão</GreyButton>

                    {
                      (path.includes('Editar')) && (<Flex marginLeft={'1rem'}>
                        <GreenButton OnClick={() => UpdateTexto(0)} Size='md' isNav={false}>Salvar</GreenButton>
                      </Flex>)
                    }
                  </Flex>
                </Flex>
              </div>
            )
          })}
        </WhiteBox>

        <WhiteBox margin='1rem 0rem'>
          {Model.textosAbertura.map((elemento, index) => {
            return (
              <div key={index}>
                {index > 0 && (
                  <hr style={{ marginTop: '1rem', border: '1px solid var(--c3)' }} />
                )}
                <div>
                  {index === 0 && (
                    <Flex justifyContent={'space-between'}>
                      <Flex flexDir={'column'}>
                        <h3>Texto de abertura da pesquisa</h3>
                        <small>O respondente irá visualizar este texto antes de começar a responder a pesquisa.</small>
                      </Flex>
                      <ButtonTag onClick={() => setModalTagIsOpen(true)}>Saiba como utilizar as TAGs</ButtonTag>
                    </Flex>
                  )}
                  <Flex width={'100%'} alignItems='center' justifyContent={'space-between'}>
                    <Flex flexDir={'column'}>
                      <FormLabel my={'1rem'} marginRight={'.5rem'}>
                        {index === 0 ? 'Participantes que receberão essa mensagem:' : 'Participante que receberá essa mensagem:'}
                      </FormLabel>
                      <Stack my={'1rem'} spacing={5} direction='row'>
                        {elemento.participantes.includes(0) && (
                          <Checkbox isDisabled={index === 0 && elemento.participantes.length === 1} isChecked={elemento.participantes.includes(0)} value={'0'} onChange={(e) => SelecionarEixo(1, e.target.value, index)}>
                            Auto avaliação
                          </Checkbox>
                        )}

                        {elemento.participantes.includes(1) && (
                          <Checkbox isDisabled={index === 0 && elemento.participantes.length === 1} isChecked={elemento.participantes.includes(1)} value={'1'} onChange={(e) => SelecionarEixo(1, e.target.value, index)}>
                            Gestor
                          </Checkbox>
                        )}
                        {elemento.participantes.includes(2) && (
                          <Checkbox isDisabled={index === 0 && elemento.participantes.length === 1} isChecked={elemento.participantes.includes(2)} value={'2'} onChange={(e) => SelecionarEixo(1, e.target.value, index)}>
                            Pares
                          </Checkbox>
                        )}

                        {elemento.participantes.includes(3) && (
                          <Checkbox isDisabled={index === 0 && elemento.participantes.length === 1} isChecked={elemento.participantes.includes(3)} value={'3'} onChange={(e) => SelecionarEixo(1, e.target.value, index)}>
                            Liderados
                          </Checkbox>
                        )}

                        {elemento.participantes.includes(6) && (
                          <Checkbox isDisabled={index === 0 && elemento.participantes.length === 1} isChecked={elemento.participantes.includes(6)} value={'6'} onChange={(e) => SelecionarEixo(1, e.target.value, index)}>
                            Alta liderança
                          </Checkbox>
                        )}

                        {elemento.participantes.includes(4) && (
                          <Checkbox isDisabled={index === 0 && elemento.participantes.length === 1} isChecked={elemento.participantes.includes(4)} value={'4'} onChange={(e) => SelecionarEixo(1, e.target.value, index)}>
                            Outros
                          </Checkbox>
                        )}
                      </Stack>
                    </Flex>
                    {index > 0 && (
                      <Button onClick={() => SelecionarEixo(1, elemento.participantes[0].toString(), index)} VarColor='terc3'>Excluir mensagem</Button>
                    )}
                  </Flex>
                  <FormLabel my={'.2rem'} marginRight={'.5rem'} color={'var(--c7)'} fontSize={'10pt'} pb={'.6rem'} mt={'-.1rem'}>
                    Ao desselecionar um eixo é gerado um novo editor de texto personalizado para ele
                  </FormLabel>
                </div>
                <EditorTexto isLoading={isLoading} initialValue={elemento.texto} onChange={(e: string) => AtualizarTexto(1, e, index)} />
                <Flex justifyContent={'space-between'} marginTop='1rem'>
                  <Flex gap={'1rem'}>
                    <GreyButton
                      onClick={() => {
                        setModel(prevState => ({
                          ...prevState,
                          textosAbertura: prevState.textosAbertura.map((texto, i) => {
                            if (i === index) {
                              return { ...texto, texto: textPadraoAbertura }
                            }
                            return texto
                          })
                        }))
                        setIsLoading(true)
                        setTimeout(() => setIsLoading(false), 1000)
                      }}
                      Size='md'
                      isNav={false}
                    >Restaurar texto padrão</GreyButton>
                    {
                      (path.includes('Editar')) && (<Flex marginLeft={'1rem'}>
                        {pesquisaId && <GreenButton OnClick={() => UpdateTexto(1)} Size='md' isNav={false}>Salvar</GreenButton>}
                      </Flex>)
                    }
                  </Flex>
                </Flex>
              </div>
            )
          })}
        </WhiteBox>

        <WhiteBox margin='1rem 0rem'>
          {Model.textosEncerramento.map((elemento, index) => {
            return (
              <div key={index}>
                {index > 0 && (
                  <hr style={{ marginTop: '1rem', border: '1px solid var(--c3)' }} />
                )}
                <div>
                  {index === 0 && (
                    <Flex justifyContent={'space-between'}>
                      <Flex flexDir={'column'}>
                        <h3>Texto de encerramento da pesquisa</h3>
                        <small>Esse texto será apresentado quando o respondente terminar de responder uma pesquisa.</small>
                      </Flex>
                      <ButtonTag onClick={() => setModalTagIsOpen(true)}>Saiba como utilizar as TAGs</ButtonTag>
                    </Flex>
                  )}
                  <Flex width={'100%'} alignItems='center' justifyContent={'space-between'}>
                    <Flex flexDir={'column'}>
                      <FormLabel my={'1rem'} marginRight={'.5rem'}>
                        {index === 0 ? 'Participantes que receberão essa mensagem:' : 'Participante que receberá essa mensagem:'}
                      </FormLabel>
                      <Stack my={'1rem'} spacing={5} direction='row'>
                        {elemento.participantes.includes(0) && (
                          <Checkbox isDisabled={index === 0 && elemento.participantes.length === 1} isChecked={elemento.participantes.includes(0)} value={'0'} onChange={(e) => SelecionarEixo(2, e.target.value, index)}>
                            Auto avaliação
                          </Checkbox>
                        )}

                        {elemento.participantes.includes(1) && (
                          <Checkbox isDisabled={index === 0 && elemento.participantes.length === 1} isChecked={elemento.participantes.includes(1)} value={'1'} onChange={(e) => SelecionarEixo(2, e.target.value, index)}>
                            Gestor
                          </Checkbox>
                        )}
                        {elemento.participantes.includes(2) && (
                          <Checkbox isDisabled={index === 0 && elemento.participantes.length === 1} isChecked={elemento.participantes.includes(2)} value={'2'} onChange={(e) => SelecionarEixo(2, e.target.value, index)}>
                            Pares
                          </Checkbox>
                        )}

                        {elemento.participantes.includes(3) && (
                          <Checkbox isDisabled={index === 0 && elemento.participantes.length === 1} isChecked={elemento.participantes.includes(3)} value={'3'} onChange={(e) => SelecionarEixo(2, e.target.value, index)}>
                            Liderados
                          </Checkbox>
                        )}

                        {elemento.participantes.includes(6) && (
                          <Checkbox isDisabled={index === 0 && elemento.participantes.length === 1} isChecked={elemento.participantes.includes(6)} value={'6'} onChange={(e) => SelecionarEixo(2, e.target.value, index)}>
                            Alta liderança
                          </Checkbox>
                        )}

                        {elemento.participantes.includes(4) && (
                          <Checkbox isDisabled={index === 0 && elemento.participantes.length === 1} isChecked={elemento.participantes.includes(4)} value={'4'} onChange={(e) => SelecionarEixo(2, e.target.value, index)}>
                            Outros
                          </Checkbox>
                        )}
                      </Stack>
                    </Flex>
                    {index > 0 && (
                      <Button onClick={() => SelecionarEixo(2, elemento.participantes[0].toString(), index)} VarColor='terc3'>Excluir mensagem</Button>
                    )}
                  </Flex>
                  <FormLabel my={'.2rem'} marginRight={'.5rem'} color={'var(--c7)'} fontSize={'10pt'} pb={'.6rem'} mt={'-.1rem'}>
                    Ao desselecionar um eixo é gerado um novo editor de texto personalizado para ele
                  </FormLabel>
                </div>
                <EditorTexto isLoading={isLoading} initialValue={elemento.texto} onChange={(e: string) => AtualizarTexto(2, e, index)} />
                <Flex justifyContent={'space-between'} marginTop='1rem'>
                  <Flex gap={'1rem'}>
                    <GreyButton
                      onClick={() => {
                        setModel(prevState => ({
                          ...prevState,
                          textosEncerramento: prevState.textosEncerramento.map((texto, i) => {
                            if (i === index) {
                              return { ...texto, texto: textPadraoEncerramento }
                            }
                            return texto
                          })
                        }))
                        setIsLoading(true)
                        setTimeout(() => setIsLoading(false), 1000)
                      }}
                      Size='md'
                      isNav={false}
                    >Restaurar texto padrão</GreyButton>
                    {
                      (path.includes('Editar')) && (<Flex marginLeft={'1rem'}>
                        {pesquisaId && <GreenButton OnClick={() => UpdateTexto(2)} Size='md' isNav={false}>Salvar</GreenButton>}
                      </Flex>)
                    }
                  </Flex>
                </Flex>
              </div>
            )
          })}
        </WhiteBox>
        {
          (EnviarPdi) && (
            <WhiteBox margin='1rem 0rem'>
              <Flex justifyContent={'space-between'}>
                <div style={{ maxWidth: '55rem', marginBottom: '1rem' }}>
                  <h3>Texto para criação do PDI</h3>
                  <small>Esse é o texto que será enviado ao e mail do responsável por subir o PDI do avaliado</small>
                </div>
                <ButtonTag onClick={() => setModalTagIsOpen(true)}>Saiba como utilizar as TAGs</ButtonTag>
              </Flex>

              <EditorTexto isLoading={isLoading} initialValue={Model.pdi.textoCriacao}
                onChange={(e: string) => setModel((prevState) => ({
                  ...prevState,
                  pdi: {
                    ...prevState.pdi,
                    textoCriacao: e
                  }
                }))} />
              <Flex justifyContent={'space-between'} marginTop='1rem'>
                <Flex gap={'1rem'}>
                  <GreyButton
                    onClick={() => {
                      setIsLoading(true)
                      setModel((prevState) => ({
                        ...prevState,
                        pdi: {
                          ...prevState.pdi,
                          textoCriacao: textPadraoPDI
                        }
                      }))
                      setTimeout(() => setIsLoading(false), 1000)
                    }}
                    Size='md'
                    isNav={false}
                  >Restaurar texto padrão</GreyButton>
                  {
                    (path.includes('Editar')) && (<Flex marginLeft={'1rem'}>
                      {EnviarPdi && <GreenButton OnClick={() => UpdateTexto(3)} Size='md' isNav={false}>Salvar</GreenButton>}
                    </Flex>)
                  }
                </Flex>
              </Flex>
            </WhiteBox>)
        }
      </Main>
      <Flex gap={'1rem'} justifyContent='end' marginTop={'1rem'}>
        {
          (path.includes('Editar')) && (<Flex marginLeft={'1rem'}>
            <Button size='lg' VarColor='c6' onClick={() => navigate(-1)}>Voltar</Button>
          </Flex>)
        }

        {
          (!path.includes('Trilha') && !path.includes('Processo') && !path.includes('Editar')) && (
          <Flex marginLeft={'1rem'}>
            <Button size='lg' VarColor='c6' onClick={() => VoltarESalvarPagina()}>Voltar e salvar</Button>
          </Flex>)
        }

        {
          (!path.includes('Trilha') && !path.includes('Processo') && !path.includes('Editar')) && (
            <Button
              size='lg'
              VarColor='v2'
              onClick={() => EnviarPesquisa()}
            >Programar disparo</Button>
          )
        }

        {
          (!path.includes('Trilha') && !path.includes('Processo') && path.includes('Editar') && (Model.ciclo === null)) && (
            <Button
              size='lg'
              VarColor='v2'
              onClick={PesquisaNaoIniciada ? VoltarPagina : ReenviarNaoRespondidos}
            >{PesquisaNaoIniciada ? 'Salvar' : 'Salvar e reenviar convites não respondidos'} </Button>
          )
        }

        {
          ((path.includes('Trilha') || path.includes('Processo')) && !path.includes('Processo/Editar')) && (
            <Button size={'lg'} onClick={UpdateConfiguracaoPesquisaEtapa} VarColor='v2'>Atualizar detalhes da etapa</Button>
          )
        }

        {
          (path.includes('Processo/Editar')) && (
            <Button size={'lg'} onClick={ReenviarPesquisaEtapaColaborador} VarColor='v2'>Reenviar convites não respondidos</Button>
          )
        }
      </Flex>
    </Body>
  )
}
