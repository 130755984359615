import { useEffect, useState } from 'react'
import { Body } from '../../Layouts/Body'
import { WhiteBox } from '../../../components/WhiteBox'
import { appApi } from '../../../services/appApi'
import { TextBox } from './Components/TextBox'
import { Main, FlexHead } from './styles'
import { iPerguntasFrequentes } from '../../../interfaces'
import { Flex, Input, InputGroup, InputLeftElement } from '@chakra-ui/react'
import { FaSearch } from 'react-icons/fa'
import { Button } from '../../../components/Button'
import { useNavigate } from 'react-router-dom'
import { parseJwt } from '../../../services/token'

export const PerguntasFrequentesColab: React.FC = () => {
  const nav = useNavigate()
  const [Perguntas, setPerguntas] = useState<iPerguntasFrequentes[]>([])
  const [Texto, setTexto] = useState('')

  function getPerguntas(): void {
    appApi.get('PerguntasFrequentes')
      .then(res => setPerguntas(res.data))
      .catch(err => console.log(err))
  }

  useEffect(() => {
    getPerguntas()
  }, [])
  return (
    <Body>
      {
        (parseJwt().adm === 'True') && (
          <Flex>
            <Button onClick={() => nav('/Usuario/PerguntasFrequentes/Editar')} size={'lg'} VarColor='Azul'>Editar perguntas</Button>
          </Flex>
        )
      }
      <WhiteBox styles={{ marginTop: '2rem' }}>
        <FlexHead>
          <h1>Perguntas frequentes</h1>
          <InputGroup width={'20rem'}>
            <InputLeftElement
              pointerEvents='none'
              children={<FaSearch color='var(--Gray4)' />}
            />
            <Input
              borderColor={'var(--Gray4)'}
              type='text'
              placeholder='Pesquisa'
              onChange={(e) => setTexto(e.target.value)}
            />
          </InputGroup>
        </FlexHead>
        <Main>
          <img src='https://sigah.blob.core.windows.net/onboard/93e163ff-dc29-4ffb-9c95-cd44d3a2d68d.png' />
          <section>
            {
              Perguntas.filter((e: iPerguntasFrequentes) => {
                if (Texto === '') {
                  return e
                } else if (e.titulo.toLowerCase().includes(Texto.toLowerCase())) {
                  return e
                }
                return null
              }).map((e: iPerguntasFrequentes, i) => {
                return (
                  <TextBox
                    key={i}
                    isFirst={i === 0}
                    Titulo={e.titulo}
                    Pergunta={e.descricao}
                  />
                )
              })
            }
          </section>
        </Main>
      </WhiteBox>
    </Body>
  )
}
