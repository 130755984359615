/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { useEffect, useState } from 'react'
import { FaHeadset } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import { Body } from '../../Layouts/Body'
import { MetaButton } from '../../../components/Buttons/MetaButton'
import { PerfilColaborador } from '../../../components/PerfilColaborador/indesx'
import { iEtapaColaborador, iPendencia, iPerfilColaborador } from '../../../interfaces'
import { appApi, urlSigah } from '../../../services/appApi'
import { CardEtapa } from './Components/CardEtapa'
import { ModalPesquisa } from './Components/ModalPesquisa'
import { ModalPrimeiroAcesso } from './Components/ModalPrimeiroAcesso'
import { ModalReuniao } from './Components/ModalReuniao'
import {
  MainColumn, ColumnTitle, MainContainer,
  PreviewEtapaContainer,
  HeadPreview
} from './styles'
import { WhiteBox } from '../../../components/WhiteBox'
import { Box, Button, Flex, Grid } from '@chakra-ui/react'
import { Pesquisaimg } from './Svg/Pesquisaimg'
import { ContainerPesquisa } from './Components/ContainerPesquisa'
import { ModalChamadoRh } from './Components/ModalChamadoRh'
import { parseJwt } from '../../../services/token'
import { ModalVideoIntroducao } from './Components/ModalVideoIntroducao'
import { ButtomInicial } from './Components/ButtomInicial'
import { ModalPesquisaRespondida } from './ModalPesquisaRespondida'
import { PropInvalida } from '../../../Utils/Helper'
import { ModalReuniaoOne } from './Components/ModalReuniaoOne'
import { TabelaAprovacao } from '../../RH/VisualizarTrilhaColaborador/Components/TabelaAprovacao'

interface iModelTrilha {
  id: string
  etapas: iEtapaColaborador[]
}

interface iConviteXPesquisa {
  tipo: number
  titulo: string
  link: string
  comunicacao?: iComunicacao
  dataInicio: string
  dataFim: string
}

interface iComunicacao {
  descricao: string
  uri: string
}

interface iDadoTabelaPDI {
  id: string
  dataFim: string
  origem: string
  totalAcoes: number
  colaboradorId?: string
}

interface iModel {
  convites: iConviteXPesquisa[]
  pendencias: iPendencia[]
  pdis: iDadoTabelaPDI[]
  processo?: iModelTrilha
}

export const Painel: React.FC = () => {
  const nav = useNavigate()
  const [Model, setModel] = useState<iModel>()
  const [ModalAcesso, setModalAcesso] = useState(false)
  const [Colaborador, setColaborador] = useState<iPerfilColaborador>()
  const [ModalReuniaoIsOpen, setModalReuniaoIsOpen] = useState(false)
  const [ModalReuniaoOneIsOpen, setModalReuniaoOneIsOpen] = useState(false)
  const [ModalPesquisaIsOpen, setModalPesquisaIsOpen] = useState(false)
  const [ModalPesquisaRespondidaIsOpen, setModalPesquisaRespondidaIsOpen] = useState(false)
  const [ModalIntroducaoIsOpen, setModalIntroducaoIsOpen] = useState(false)
  const [ModalChamadoIsOpen, setModalChamadoIsOpen] = useState(false)
  const [ViewTrilha, setViewTrilha] = useState(false)

  const [IsLoading, setIsLoading] = useState(false)

  const [RedirectId, setRedirectId] = useState<string>()
  const [Percepcao, setPercepcao] = useState<boolean>()

  const [Id, setId] = useState<string>()

  function onRequestCloseModalReuniao(): void {
    setModalReuniaoIsOpen(false)
    setModalReuniaoOneIsOpen(false)
    setId(undefined)
  }

  function onRequestCloseModalPesquisa(): void {
    setModalPesquisaIsOpen(false)
    setRedirectId(undefined)
    setPercepcao(undefined)
  }

  function getTrilha(): void {
    setIsLoading(true)
    appApi.get('ProcessoOnboard/Trilha/v3').then(response => {
      if (!PropInvalida(response.data)) {
        setModel(response.data)
      }
      setIsLoading(false)
    }).catch(err => console.log(err))
  }

  function ObterOnclickPeloTipo(obj: iEtapaColaborador): any {
    if (obj.tipo !== 2 && (obj.status !== 0 && obj.status !== 2 && obj.status !== 6 && obj.status !== 7)) {
      return () => { }
    }
    if (obj.tipo === 1) {
      return nav(`/Colaborador/Integracao/${obj.interacoes[0].idExterno}/Etapa/${obj.id}`)
    } else if (obj.tipo === 2) {
      setId(obj.interacoes[0].idExterno)
      return setModalReuniaoIsOpen(true)
    } else if (obj.tipo === 3) {
      return nav(`/Colaborador/Imersao/${obj.interacoes[0].idExterno}/Etapa/${obj.id}`)
    } else if (obj.tipo === 4) {
      setRedirectId(obj.conviteId)
      return setModalPesquisaIsOpen(true)
    } else if (obj.tipo === 5) {
      if (obj.status === 7) {
        setId(obj.interacoes[0].idExterno)
        console.log(obj.interacoes[0].idExterno)
        return setModalPesquisaRespondidaIsOpen(true)
      } else {
        setRedirectId(obj.conviteId)
        setPercepcao(true)
        return setModalPesquisaIsOpen(true)
      }
    } else if (obj.tipo === 6) {
      return window.open(`${urlSigah}/PesquisasPEC/f/${obj.conviteId}`, '_blank')
    } else if (obj.tipo === 7) {
      setId(obj.interacoes[0].idExterno)
      return setModalReuniaoOneIsOpen(true)
    } else {
      return () => { }
    }
  }

  function getColaboador(): void {
    appApi.get('Colaborador/Perfil')
      .then(response => setColaborador(response.data))
      .catch(err => console.log(err))
  }

  function Load(): void {
    getTrilha()
    getColaboador()
  }

  function PostChamado(texto: string): void {
    const form = {
      texto: texto
    }
    appApi.post('Chamado', form)
      .then(res => {
        setModalChamadoIsOpen(false)
        console.log(res.data)
      }
      ).catch(err => console.log(err))
  }

  function ConcluirPrimeiroAcesso(pronome: string, avatar?: string): void {
    const form = {
      avatar: avatar,
      pronome: pronome
    }

    appApi.patch('EtapaColaborador/PrimeiroAcesso', form)
      .then(res => {
        localStorage.setItem('token', res.data)
        if (Model?.processo) {
          getTrilha()
        }
        Load()
      })
      .catch(err => console.log(err))
  }

  function onCloseModalAcesso(): void {
    setModalAcesso(false)
    setModalIntroducaoIsOpen(true)
  }

  useEffect(() => {
    Load()

    const val = parseJwt().pa
    if (val === '' || val === 'False') {
      setModalAcesso(true)
    }
  }, [])

  const NenhumaPendenciaDisponivel = Model?.pendencias.map(e => e.nomePendencia === 'Não há atividades disponíveis no momento')

  return (
    <Body isLoading={IsLoading}>
      {
        Id && ModalReuniaoIsOpen &&
        <ModalReuniao
          isOpen={ModalReuniaoIsOpen}
          onRequestClose={onRequestCloseModalReuniao}
          reuniaoId={Id}
        />
      }

      {
        Id && ModalReuniaoOneIsOpen &&
        <ModalReuniaoOne
          isOpen={ModalReuniaoOneIsOpen}
          onRequestClose={onRequestCloseModalReuniao}
          reuniaoId={Id}
        />
      }
      {Id && ModalPesquisaRespondidaIsOpen &&
        <ModalPesquisaRespondida
          isOpen={ModalPesquisaRespondidaIsOpen}
          onRequestClose={() => setModalPesquisaRespondidaIsOpen(false)}
          pesquisaId={Id ?? ''}
        />
      }

      <ModalChamadoRh
        isOpen={ModalChamadoIsOpen}
        onConfirm={PostChamado}
        onRequestClose={() => setModalChamadoIsOpen(false)}
      />

      <ModalPesquisa
        isOpen={ModalPesquisaIsOpen}
        onRequestClose={onRequestCloseModalPesquisa}
        conviteId={RedirectId}
        Percepcao={Percepcao}
      />

      <ModalPrimeiroAcesso
        isOpen={ModalAcesso}
        OnCloseModal={onCloseModalAcesso}
        onConcluir={ConcluirPrimeiroAcesso}
      />

      <ModalVideoIntroducao
        isOpen={ModalIntroducaoIsOpen}
        onClose={() => setModalIntroducaoIsOpen(false)}
      />

      <Flex>
        <PerfilColaborador
          Colaborador={Colaborador ?? {} as iPerfilColaborador}
          margin='0 1rem 0 0'
        />
        {
          (!ViewTrilha && Model) && (
            <MainContainer>
              {(NenhumaPendenciaDisponivel?.includes(false))
                ? <WhiteBox>
                  <Grid templateColumns='repeat(2, 1fr)' gap={'.8rem'}>
                    {
                      Model?.pendencias.map((e, i) => {
                        return (
                          <ButtomInicial
                            key={i}
                            pendencia={e}
                            clickExterno={() => setViewTrilha(true)}
                          />
                        )
                      })
                    }
                  </Grid>
                </WhiteBox>
                : <WhiteBox styles={{ height: '24.5rem', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <Box as={'span'} fontWeight={'600'}>Sem atividades disponíveis no momento!</Box>
                </WhiteBox>
              }
              {
                (Model?.pdis?.length > 0) && (
                  <WhiteBox margin='1rem 0'>
                    <h3>PDI aguardando aprovação</h3>
                    <Flex marginTop={'.5rem'} flexDir={'column'}>
                      <TabelaAprovacao slice={4} dados={Model?.pdis} />
                    </Flex>
                  </WhiteBox>
                )
              }
              {
                (Model?.processo) && (
                  <WhiteBox margin='1rem 0rem'>
                    <HeadPreview>
                      <Pesquisaimg />
                      <span>Você tem uma etapa de onboarding a ser realizada...</span>
                    </HeadPreview>
                    <PreviewEtapaContainer>
                      {
                        Model.processo.etapas.filter(e => e.status !== 2).slice(0, 2).map((e: iEtapaColaborador, i) => {
                          return (
                            <CardEtapa
                              key={i}
                              Etapa={e}
                              Last={true}
                              onClick={() => ObterOnclickPeloTipo(e)}
                            />
                          )
                        })
                      }
                    </PreviewEtapaContainer>
                    <Button bg={'none'}
                      outline={0}
                      borderRadius={'1rem'}
                      color={'var(--Azul)'}
                      border={'1px solid var(--Azul)'}
                      _hover={{ bg: 'var(--Azul)', color: 'white' }}
                      size={'md'}
                      marginTop={'1rem'}
                      onClick={() => setViewTrilha(true)}
                    >Clique e veja toda sua linha do tempo</Button>
                  </WhiteBox>
                )
              }

              {
                (Model?.convites) && (
                  <>
                    {
                      Model.convites.map((e, i) => {
                        return (
                          <WhiteBox key={i} margin='1rem 0'>
                            <ContainerPesquisa key={i} convite={e} />
                          </WhiteBox>
                        )
                      })
                    }
                  </>
                )
              }

            </MainContainer>
          )
        }

        {
          (ViewTrilha) && (
            <MainColumn padding='1rem 1rem' width='60%'>
              <Flex justifyContent={'space-between'}>
                <MetaButton bg='c5' onClick={() => setViewTrilha(false)} size='md'>Voltar</MetaButton>
                <MetaButton onClick={() => setModalChamadoIsOpen(true)} bg='a1' size='md'><FaHeadset className='IconLeft' /> Abrir chamado com RH</MetaButton>
              </Flex>

              <ColumnTitle>
                <h3>Onboarding em andamento</h3>
                <span className='p2'>Acompanhe cada etapa a ser realizada e que você já realizou</span>
              </ColumnTitle>
              {
                (Model?.processo) && (
                  <Flex flexDir={'column'}>
                    {
                      Model?.processo.etapas.map((e: iEtapaColaborador, i) => {
                        return (
                          <CardEtapa
                            key={i}
                            Etapa={e}
                            Last={i + 1 === Model?.processo?.etapas?.length ?? 0}
                            onClick={() => ObterOnclickPeloTipo(e)}
                          />
                        )
                      })
                    }
                  </Flex>
                )
              }
            </MainColumn>
          )
        }
      </Flex>
    </Body>
  )
}
