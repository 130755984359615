import styled from 'styled-components'

interface NavBtnProps{
  size: string
  padding: string
  heigth: string
}

export const Btn = styled.a<NavBtnProps>`
 display: flex;
 align-items: center;
 justify-content: center;
 border-radius: 0.75rem;
 font-weight: 500;
 background: var(--c6);
 color: var(--c1);
 padding: var(--${props => props.padding});
 font-size:var(--${props => props.size});
 height:var(--${props => props.heigth}) ;
 line-height: 1px;

 &:hover{
    transition:var(--transitionBtns);
    box-shadow: var(--SombraBtns);
    filter: brightness(1.05);
}
`

export const StandBtn = styled.a<NavBtnProps>`
 display: flex;
 align-items: center;
 justify-content: center;
 border-radius: 0.75rem;
 font-weight: 500;
 background: var(--c6);
 color: var(--c1);
 padding: var(--${props => props.padding});
 font-size:var(--${props => props.size});
 height:var(--${props => props.heigth}) ;
 line-height: 1px;

 &:hover{
    transition:var(--transitionBtns);
    box-shadow: var(--SombraBtns);
    cursor: pointer;
    filter: brightness(1.05);
}
`
