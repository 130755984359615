import { Button, Flex, Input, Text, useToast } from '@chakra-ui/react'
import { useState } from 'react'
import { IJobTitle } from '../../interfaces'

interface ICreateJobTitleModalProps {
  onCreateJobTitle: (newJobTitle: IJobTitle) => void
}

export const CreateCargo: React.FC<ICreateJobTitleModalProps> = ({ onCreateJobTitle }) => {
  const toast = useToast()
  function valida(): void {
    if (nome.length < 2) {
      toast({
        title: 'O nome da função precisa ter no mínimo 2 caracteres',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return
    }
    onCreateJobTitle({ id: '', nome: nome })
  }

  const [nome, setNome] = useState('')
  return (
   <Flex mb={'1rem'} p={'0.8rem'} direction={'column'} backgroundColor={'gray.50'} borderRadius={'2xl'} border={'1px'}>
  <Text>Nova função</Text>
  <Flex>
  <Input backgroundColor={'white'} placeholder='Digite o nome da nova função' onChange={e => setNome(e.target.value)} />
  <Button onClick={() => valida()} backgroundColor={'var(--Green1)'} size={'md'} fontSize={'sm'} mx={'0.5rem'} >Criar nova função</Button>
  </Flex>
</Flex>)
}
