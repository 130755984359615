import styled from 'styled-components'
import { Link } from 'react-router-dom'

interface NavBtnProps{
  size: string
  heigth: string
  padding: string
}

export const NavBtn = styled(Link)<NavBtnProps>`
    background: var(--v2);
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--c1);
    font-size:var(--${props => props.size});
    padding:var(--${props => props.padding});
    border-radius: 0.75rem;
    font-weight: 500;
    height:var(--${props => props.heigth}) ;
    line-height: 1px;

    &:hover{
      transition:var(--transitionBtns);
      filter: brightness(1.05);
      box-shadow: var(--SombraBtns);
}
`

export const StandBtn = styled.a<NavBtnProps>`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.75rem;
    font-weight: 500;
    color: var(--c1);
    background: var(--v2);
    font-size:var(--${props => props.size});
    padding:var(--${props => props.padding});
    height:var(--${props => props.heigth}) ;
    line-height: 1px;

    &:hover{
      transition:var(--transitionBtns);
      filter: brightness(1.05);
      box-shadow: var(--SombraBtns);
    }
`
