import { HTMLAttributes, useState } from 'react'
import { Container, ContainerButton } from './styles'

interface ButtonProps extends HTMLAttributes<HTMLDivElement> {
  Icon?: any
  HoverIcon?: any
}

export const ButtonOption: React.FC<ButtonProps> = ({ Icon, HoverIcon, onClick, children }) => {
  const [Hover, setHover] = useState(false)
  return (
    <Container
      onMouseLeave={() => setHover(false)}
      onMouseOver={() => setHover(true)}
      onClick={onClick}
    >
      <ContainerButton>
        {
          !Hover ? Icon : HoverIcon
        }
        <span>{children}</span>
      </ContainerButton>
    </Container>
  )
}
