/* eslint-disable no-return-assign */
import { Button, Flex, Link } from '@chakra-ui/react'
import { FaCalendarAlt, FaChalkboardTeacher, FaClipboardList, FaPencilAlt, FaTachometerAlt } from 'react-icons/fa'
import { Body, Container, Header } from './styles'
import { useEffect, useRef } from 'react'
import LogoPec from '../../../../../assets/PEC.svg'
import LogoPulse from '../../../../../assets/Pulse.svg'
import CardImersao from '../../../../../assets/CardImersao.svg'
import CardNps from '../../../../../assets/CardNps.png'
import CardPI from '../../../../../assets/CardPI.png'
import CardPulse from '../../../../../assets/CardPulse.png'
import CardPec from '../../../../../assets/CardPec.png'

interface iConviteXPesquisa{
  tipo: number
  titulo: string
  link: string
  comunicacao?: iComunicacao
  dataInicio: string
  dataFim: string
  avaliado?: string
  avatarAvaliado?: string
}

interface iComunicacao{
  descricao: string
  uri: string
}

interface iContainerPesquisaProps{
  convite: iConviteXPesquisa
}

export const ContainerPesquisa: React.FC<iContainerPesquisaProps> = ({ convite }) => {
  // const redirectPage = (): any => window.location.href = convite.link
  const spanRef = useRef<HTMLSpanElement>(null)
  useEffect(() => {
    if (spanRef.current) {
      spanRef.current.innerHTML = convite.comunicacao?.descricao ?? ''
    }
  }, [convite.comunicacao?.descricao])

  function getIcon(): any {
    switch (convite.tipo) {
      case 4:
        return <img src={LogoPec}/>

      case 5:
        return <img src={LogoPulse}/>

      case 6:
        return <FaTachometerAlt size={35}/>

      case 1:
        return <FaChalkboardTeacher size={35}/>

      case 0:
        return <FaClipboardList size={35}/>

      default:
        break
    }
  }

  function getImageDefault(): any {
    if (convite.comunicacao?.uri !== undefined && convite.comunicacao?.uri !== '') {
      return convite.comunicacao.uri
    }
    switch (convite.tipo) {
      case 4:
        return CardPec

      case 5:
        return CardPulse

      case 6:
        return CardNps

      case 1:
        return CardImersao

      case 0:
        return CardPI

      default:
        return CardNps
    }
  }

  return (
        <Container>
            <Header>
                {getIcon()}
                <Flex justifyContent={'center'} flexDirection={'column'}>
                  <span>{convite.titulo}</span>
                </Flex>
            </Header>
            <Body>
                <img style={{ maxHeight: '300px' }} src={getImageDefault()}/>
                <Flex justifyContent={'start'} width={'100%'} margin={'1rem 0'}>
                  <span dangerouslySetInnerHTML={{ __html: convite.comunicacao?.descricao ?? '' }} />
                </Flex>
                {(convite.avatarAvaliado !== null && convite.avaliado !== null) && (
                <Flex alignItems={'center'} gap={'.7rem'} justifyContent={'center'}>
                  <img style={{ width: '2.1rem', height: '2.1rem', borderRadius: '50%' }} src={convite.avatarAvaliado}/>
                  <h5 style={{ color: 'var(--a4)', fontWeight: '500' }}>{convite.avaliado}</h5>
                </Flex>
                )}
                {
                  (convite.dataFim && convite.dataInicio) && (
                    <Flex color={'var(--a4)'}>
                        <FaCalendarAlt/>
                        <span>{convite.dataInicio} a {convite.dataFim}</span>
                    </Flex>
                  )
                }
            </Body>
            <Link href={convite.link} isExternal width='100%'>
            <Button width='100%'
            // onClick={redirectPage}
            _hover={{ bg: 'var(--v2)' }} bg={'var(--v2)'} display={'flex'} justifyContent={'space-between'} borderRadius={'.9rem'} alignItems={'center'}>
              <span></span>
              <span>{convite.tipo === 1 ? 'Visualizar imersão' : convite.tipo === 6 ? 'Responder Nps' : 'Responder pesquisa'}</span>
              <span><FaPencilAlt /></span>
            </Button>
            </Link>
        </Container>
  )
}
