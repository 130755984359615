import { useEffect, useState } from 'react'
import { Container, FlexIcon } from './styles'
import { appApi } from '../../../../../services/appApi'
import { FaClipboardList, FaMapMarkerAlt, FaUserAlt } from 'react-icons/fa'
interface iCardReuniao {
  reuniaoId: string
}

interface iReuniao {
  tipoReuniao: number
  participanteId?: string
  assunto: string
  descricao: string
  dataInicio?: string
  duracao?: number
  presencial: boolean
  local?: string
}

export const CardReuniaoOne: React.FC<iCardReuniao> = ({ reuniaoId }) => {
  const [Model, setModel] = useState<iReuniao>({
    tipoReuniao: 0,
    assunto: '',
    descricao: '',
    presencial: false,
    duracao: 5
  })
  function ObterEtapa(): void {
    appApi.get(`ReuniaoOne/${reuniaoId ?? ''}`)
      .then(res => {
        setModel(res.data)
      })
      .catch(err => console.log(err))
  }

  useEffect(() => {
    ObterEtapa()
  }, [])

  return (
    <Container>
      <FlexIcon>
        <FaUserAlt size={30} />
        <div>
          <h3>Tipo de reunião</h3>
          <strong>{Model.tipoReuniao === 0 ? 'Feedback com o gestor' : 'Feedback com o RH'}</strong>
        </div>
      </FlexIcon>

      <FlexIcon>
        <FaMapMarkerAlt size={30} />
        <div>
          <h3>Local da reunião</h3>
          <strong>{Model.local}</strong>
        </div>
      </FlexIcon>

      {
        (Model.descricao !== null) && (
          <>
            <FlexIcon>
              <FaClipboardList size={30} />
              <div>
                <h3>Descrição</h3>
                <small dangerouslySetInnerHTML={{ __html: Model.descricao }}></small>
              </div>
            </FlexIcon>
          </>
        )
      }
    </Container>
  )
}
