/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
import ApexCharts from 'apexcharts'

import { useEffect } from 'react'

interface iProps {
  id: string
  grafico: IGrafico
  fator: number
}

interface IDadoGrafico {
  medias: number[]
  nome: string
}

interface IGrafico {
  competencias: string[]
  dados: IDadoGrafico[]
}

export const ComparativoChart: React.FC<iProps> = ({ id, grafico, fator }) => {
  function AbreviarNomesGrandes(): string[] {
    return grafico.competencias.map((e) => {
      if (grafico.competencias.length === 4) {
        return e
      } else if (grafico.competencias.length > 4 && e.length > 20) {
        return e.substring(0, 20) + '...'
      } else {
        return e
      }
    })
  }

  function getSeries(): any {
    return grafico.dados.map((e) => {
      return {
        name: e.nome,
        data: e.medias
      }
    })
  }

  const colors = [
    'rgb(0, 227, 150)',
    'rgb(0, 143, 251)',
    '#fff'
  ]

  function GerarGrafico(): void {
    const options = {
      colors: colors,
      series: getSeries(),
      chart: {
        type: 'bar',
        height: 550,
        width: '100%'
      },
      plotOptions: {
        bar: {
          horizontal: false,
          endingShape: 'rounded',
          dataLabels: {
            position: 'top'
          }
        }
      },
      title: {
        text: '#  Essa questão foi alterada ou não existia',
        offsetX: 460,
        offsetY: 10,
        style: {
          fontSize: '12px',
          fontWeight: '400',
          fontFamily: 'Helvetica, Arial, sans-serif',
          color: 'rgb(55, 61, 63)'
        }
      },
      dataLabels: {
        enabled: true,
        offsetY: 15,
        style: {
          fontSize: '10px',
          colors: ['#000']
        },
        formatter: function(val: number) {
          if (val === -1) {
            return '#'
          } else {
            return val.toFixed(2)
          }
        }
      },
      legend: {
        position: 'top',
        onItemClick: {
          toggleDataSeries: false
        }
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: AbreviarNomesGrandes()
      },
      grid: {
        xaxis: {
          lines: {
            show: true
          }
        },
        yaxis: {
          lines: {
            show: false
          }
        }
      },
      yaxis: {
        min: 0,
        max: fator,
        // title: {
        //   text: 'Comparativo de competência'
        // },
        labels: {
          formatter: function(val: number) {
            return val.toFixed(0)
          }
        }
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        y: {
          formatter: function (val: number) {
            return val.toFixed(2)
          }
        }
      }
    }
    const chart = new ApexCharts(document.querySelector(`#chart-${id}`), options)
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    chart.render()

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    chart.updateSeries(getSeries())
  }

  useEffect(() => {
    GerarGrafico()
  }, [grafico])

  //   useEffect(() => {
  //     GerarGrafico()
  //   }, [])
  return (
        <div style={{ width: '100%' }} id={`chart-${id}`} />
  )
}
