import { Avatar, AvatarBadge, Flex, FormControl, FormLabel, Grid, Image, Select, Tab, TabList, TabPanel, TabPanels, Tabs, Tooltip } from '@chakra-ui/react'
import { FaAngleDown, FaAngleUp, FaClipboard, FaClipboardList, FaEye, FaEyeSlash, FaRegComment } from 'react-icons/fa'
import { Body } from '../../Layouts/Body'
import { ComentariosContainer, InfoBox, StyledTd, TableDiv, Titulo } from './styles'
import { RadarChartJs } from './components/RadarChart'
import { Recomendacao } from './components/Recomendacao'
import { Button } from '../../../components/Button'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { appApi } from '../../../services/appApi'
import { ReactNode, useEffect, useState } from 'react'
import { NavLine } from '../../../components/NavLine'
import { iColaboradorBase, iCompetencia, iCompetenciaPDI, iEscala, iNomeId } from '../../../interfaces'
import IconeAvaliadores from '../../../assets/IconeAvaliadores.svg'
import PDFRadarChart from './components/RelatorioPDF/Components/RadarChartJs'
import { PDFDownloadLink } from '@react-pdf/renderer'
import { RelatorioPDF } from './components/RelatorioPDF'
import { ModalPDI } from './components/ModalPDI'
import { ComparativoChart } from './components/ComparativoChart'
import { parseJwt } from '../../../services/token'
import { ModalComentarios } from './components/ModalComentarios'
import { DistinctString, PropInvalida } from '../../../Utils/Helper'

export interface iDiscursiva {
  tipoAvaliador: number
  titulo: string
  texto: string
}

export interface IDadoGrafico {
  medias: number[]
  nome: string
}

export interface IGrafico {
  competencias: string[]
  dados: IDadoGrafico[]
  definicoesCompetencias: string[]
}

export interface iCorTabela {
  eixo: string
  notasPositivas: number[]
  notasNegativas: number[]
  recomendacoes: number[]
}

export interface iLinhaTabela {
  competencia: string
  autoAvaliacao: string
  gestor?: string
  pares?: string
  outros?: string
  liderados?: string
  altalideranca?: string
  mediaSemAuto: string
  questoes: iLinhaTabela[]
}

export interface iTabela {
  linhas: iLinhaTabela[]
}

interface iPostPDI {
  pesquisaId: string
  colaboradorId: string
  competencias: string[]
}

interface iModel {
  pesquisaId: string
  avaliado: iColaboradorBase
  nomePesquisa: string
  notaMaximaEscala: number
  totalAvaliadores: number
  totalRespostas: number
  mediaGeral: number
  participantes: number[]
  graficoHierarquia: IGrafico
  graficoIndividual: IGrafico
  discursivas: iDiscursiva[]
  recomendacoes: iCompetenciaPDI[]
  tabela: iTabela
  graficoComparativo: IGrafico
  graficoComparativoAutoavaliacao: IGrafico
  filtro: iNomeId[]
  comparativoId?: string
  pdf?: iPDF
  pdi: boolean
  liberarResultado: number
  cores: iCorTabela[]
}

interface iPDF {
  escala: iEscala
  competencias: iCompetencia[]
  melhorCompetencia: iCompetencia
}

export interface iMediaAbsoluta {
  mediaAltaLideranca: number
  mediaPares: number
  mediaAutoAval: number
  mediaGestor: number
  mediaLiderados: number
  mediaOutros: number
  mediaSemAuto: number
}

export const ResultadoIndividual: React.FC = () => {
  const { avaliacaoId } = useParams<{ avaliacaoId: string }>()
  const { pathname } = useLocation()
  const [open, setOpen] = useState<number[]>([])
  const toggle = (e: number): void => {
    if (open.some(valor => valor === e)) {
      setOpen(open.filter(valor => valor !== e))
    } else {
      setOpen([...open, e])
    }
  }
  const [Grafico1, setGrafico1] = useState<string>()
  const [Grafico2, setGrafico2] = useState<string>()

  const nav = useNavigate()

  const [Model, setModel] = useState<iModel>({
    pdi: false,
    cores: [],
    liberarResultado: 1,
    avaliado: {
      id: '',
      nome: ''
    },
    pdf: {
      melhorCompetencia: {
        discursiva: false,
        doSistema: false,
        icone: '',
        id: '',
        nome: '',
        questoes: []
      },
      competencias: [],
      escala: {
        id: '',
        fator: 0,
        nome: '',
        respostas: []
      }
    },
    discursivas: [],
    filtro: [],
    graficoComparativo: {
      competencias: [],
      dados: [],
      definicoesCompetencias: []
    },
    graficoComparativoAutoavaliacao: {
      competencias: [],
      dados: [],
      definicoesCompetencias: []
    },
    graficoHierarquia: {
      competencias: [],
      dados: [],
      definicoesCompetencias: []
    },
    graficoIndividual: {
      competencias: [],
      dados: [],
      definicoesCompetencias: []
    },
    mediaGeral: 0,
    nomePesquisa: '',
    notaMaximaEscala: 0,
    participantes: [],
    pesquisaId: '',
    recomendacoes: [],
    tabela: {
      linhas: []
    },
    totalAvaliadores: 0,
    totalRespostas: 0
  })

  const [GraficoHierarquia, setGraficoHierarquia] = useState(Model.graficoHierarquia.dados.length === 2)
  const [GraficoComparativoAutoavaliacao, setGraficoComparativoAutoavaliacao] = useState(true)

  const [ModalPDIsOpen, setModalPDIsOpen] = useState(false)
  const [ModalComentariosIsOpen, setModalComentariosIsOpen] = useState(false)

  const [Competencia, setCompetencia] = useState<string>()

  const [FiltroCompetencia, setFiltroCompetencia] = useState<string>('')
  const [FiltroTipo, setFiltroTipo] = useState<string>('')

  const [IsLoading, SetIsLoading] = useState(false)

  function getResultado(): void {
    SetIsLoading(true)
    appApi.get(`AvaliacaoPesquisa/${avaliacaoId as string}/ResultadoIndividual`)
      .then(res => {
        setModel(res.data)
        SetIsLoading(false)
      })
      .catch(err => console.log(err))
  }

  function handleMedia(): iMediaAbsoluta[] {
    const medias = {
      mediaAltaLideranca: 0,
      mediaPares: 0,
      mediaAutoAval: 0,
      mediaGestor: 0,
      mediaLiderados: 0,
      mediaOutros: 0,
      mediaSemAuto: 0
    }
    Model.tabela.linhas.forEach((e): void => {
      const parse = parseFloat(e.pares ?? '')
      medias.mediaPares = medias.mediaPares + (isNaN(parse) ? 0 : parse)
    })
    Model.tabela.linhas.forEach((e): void => {
      const parse = parseFloat(e.autoAvaliacao ?? '')
      medias.mediaAutoAval = medias.mediaAutoAval + (isNaN(parse) ? 0 : parse)
    })
    Model.tabela.linhas.forEach((e): void => {
      const parse = parseFloat(e.gestor ?? '')
      medias.mediaGestor = medias.mediaGestor + (isNaN(parse) ? 0 : parse)
    })
    Model.tabela.linhas.forEach((e): void => {
      const parse = parseFloat(e.liderados ?? '')
      medias.mediaLiderados = medias.mediaLiderados + (isNaN(parse) ? 0 : parse)
    })
    Model.tabela.linhas.forEach((e): void => {
      const parse = parseFloat(e.mediaSemAuto ?? '')
      medias.mediaSemAuto = medias.mediaSemAuto + (isNaN(parse) ? 0 : parse)
    })
    Model.tabela.linhas.forEach((e): void => {
      const parse = parseFloat(e.outros ?? '')
      medias.mediaOutros = medias.mediaOutros + (isNaN(parse) ? 0 : parse)
    })
    Model.tabela.linhas.forEach((e): void => {
      const parse = parseFloat(e.altalideranca ?? '')
      medias.mediaAltaLideranca = medias.mediaAltaLideranca + (isNaN(parse) ? 0 : parse)
    })
    const mediasCalculado = [{
      mediaAltaLideranca: medias.mediaAltaLideranca / Model.tabela.linhas.filter(r => r.altalideranca).length,
      mediaPares: medias.mediaPares / Model.tabela.linhas.filter(r => r.pares).length,
      mediaAutoAval: medias.mediaAutoAval / Model.tabela.linhas.filter(r => r.autoAvaliacao).length,
      mediaGestor: medias.mediaGestor / Model.tabela.linhas.filter(r => r.gestor).length,
      mediaLiderados: medias.mediaLiderados / Model.tabela.linhas.filter(r => r.liderados).length,
      mediaOutros: medias.mediaOutros / Model.tabela.linhas.filter(r => r.outros).length,
      mediaSemAuto: medias.mediaSemAuto / Model.tabela.linhas.filter(r => r.mediaSemAuto).length
    }]
    return mediasCalculado
  }
  const Medias = handleMedia()

  function FiltrarComaparativo(id: string): void {
    appApi.get(`AvaliacaoPesquisa/${avaliacaoId as string}/Comparativo/?comparativoId=${id}`)
      .then(res => {
        const ar = { ...Model }
        ar.graficoComparativo = res.data.graficoComparativo
        ar.graficoComparativoAutoavaliacao = res.data.graficoComparativoAutoavaliacao
        ar.comparativoId = id
        setModel(ar)
      })
      .catch(err => console.log(err))
  }

  function TemColuna(tipo: string): boolean {
    if (Model?.graficoHierarquia !== undefined) {
      return Model?.graficoHierarquia.dados.find(e => e.nome === tipo) !== undefined
    } else {
      return false
    }
  }

  function CriarBasePDI(opcao: string): void {
    const form: iPostPDI = {
      colaboradorId: Model?.avaliado?.id ?? '',
      pesquisaId: Model?.pesquisaId ?? '',
      competencias: Model?.recomendacoes.map((e) => {
        return e.id
      }) ?? []
    }
    if (opcao === '1') {
      appApi.post('PDI/Pesquisa/Recomendacao', form).then(res => {
        nav(`/Desempenho/PDI/Editar/${res.data as string}`)
      }).catch(err => console.log(err))
    } else {
      appApi.post('PDI/Pesquisa/Continuar', form).then(res => {
        nav(`/Desempenho/PDI/Editar/${res.data as string}`)
      }).catch(err => console.log(err))
    }
  }

  function GravarGrafico1(blob: string): void {
    setGrafico1(blob)
  }

  function GravarGrafico2(blob: string): void {
    setGrafico2(blob)
  }

  function AlterarVisibiliade(visibilidade: boolean): void {
    appApi.patch(`AvaliacaoPesquisa/${avaliacaoId ?? ''}/AlterarVisibilidade?visibilidade=${visibilidade ? 0 : 1}`)
      .then(() => {
        setModel({ ...Model, liberarResultado: Model.liberarResultado === 0 ? 1 : 0 })
      })
      .catch(err => console.log(err))
  }

  function onOpenModalCompetencia(nome: string): void {
    setCompetencia(nome)
    setModalComentariosIsOpen(true)
  }

  function onCloseModalCompetencia(): void {
    setCompetencia(undefined)
    setModalComentariosIsOpen(false)
  }

  function TooltipLabel(e: iLinhaTabela): string {
    if (e.questoes.length > 1) {
      return `${e.questoes.length} Questões`
    } else {
      return e.questoes[0].competencia ?? 'Sem descrição'
    }
  }

  function VerificarCorV2(eixo: string, nota: string): string {
    const dado = Model.cores.find(e => e.eixo === eixo)
    if (dado) {
      return dado.notasPositivas.some(r => r.toFixed(2) === nota?.replace(',', '.')) ? '#00AA00' : dado.notasNegativas.some(r => r.toFixed(2) === nota?.replace(',', '.')) ? 'var(--Orange)' : 'black'
    }
    return 'black'
  }

  function VerificarCorRecomendacao(nota: string, index: number): string {
    const dado = Model.cores.find(e => e.eixo === 'semAutoavaliacao')
    if (dado) {
      return dado.notasPositivas.some(r => r.toFixed(2) === nota?.replace(',', '.')) ? '#00AA00' : dado.recomendacoes.some(e => e === index) ? 'var(--Orange)' : 'black'
    }
    return 'black'
  }

  function CriaDropQuestoes(e: iLinhaTabela[], nomeCompetencia: string): ReactNode {
    return (
      <>
        {e.map((questao, index) => {
          const temDiscursiva = Model.discursivas.filter(r => r.titulo.includes(questao.competencia)).length > 0
          return (
            <tr key={index}>
              <td className='dropDownTr'>
                <Flex alignItems={'center'}>
                  <Flex flex={'1'} marginRight={'4px'}>
                    <Tooltip hasArrow label={questao.competencia} bg={'var(--Preto)'} color={'var(--c1)'} placement={'top'} padding={'.9rem'}>
                      {nomeCompetencia + ` ${index + 1}`}
                    </Tooltip>
                  </Flex>
                  {temDiscursiva &&
                    <Avatar
                      onClick={() => temDiscursiva ? onOpenModalCompetencia(questao.competencia) : {}}
                      _hover={{ backgroundColor: 'var(--Gray4)' }}
                      bg={'var(--c3)'}
                      width={'2rem'}
                      height={'2rem'} icon={<FaRegComment size={20} />}
                    >
                      <AvatarBadge
                        boxSize='1.75em'
                        bg='#b2c6ea'
                        fontSize={'.6rem'}
                        fontWeight='bold'
                      >
                        {Model.discursivas.filter(d => d.titulo.includes(questao.competencia)).length}
                      </AvatarBadge>
                    </Avatar>
                  }
                  {!temDiscursiva && <Flex width={'1.25rem'}></Flex>}
                </Flex>
              </td>
              {TemColuna('Autoavaliação') && <StyledTd >{questao.autoAvaliacao}</StyledTd>}
              {TemColuna('Gestor') && <StyledTd >{questao.gestor}</StyledTd>}
              {TemColuna('Pares') && <StyledTd >{questao.pares}</StyledTd>}
              {TemColuna('Liderados') && <StyledTd >{questao.liderados}</StyledTd>}
              {TemColuna('Outros') && <StyledTd >{questao.outros}</StyledTd>}
              {TemColuna('Alta liderança') && <StyledTd >{questao.altalideranca}</StyledTd>}
              <StyledTd >{questao.mediaSemAuto}</StyledTd>
            </tr>
          )
        })}
      </>
    )
  }

  function MostrarComentario(e: iLinhaTabela): ReactNode {
    const temDiscursiva = Model.discursivas.filter(r => r.titulo.includes(e.competencia)).length > 0
    return (
      <>
        <Flex justifyContent='flex-end'>
          {temDiscursiva && (
            <Avatar
              onClick={() => onOpenModalCompetencia(e.competencia)}
              _hover={{ backgroundColor: 'var(--Gray4)' }}
              bg={'var(--c3)'}
              width={'2rem'}
              height={'2rem'} icon={<FaRegComment size={20} />}
            >
              <AvatarBadge
                boxSize='1.75em'
                bg='#b2c6ea'
                fontSize={'.6rem'}
                fontWeight='bold'
              >
                {Model.discursivas.filter(d => d.titulo.includes(e.competencia)).length}
              </AvatarBadge>
            </Avatar>
          )}
        </Flex>
      </>
    )
  }

  function ObterFiltroQuestao(): string[] {
    return DistinctString(Model.discursivas.map((e) => {
      if (e.titulo.split('-').length > 1) {
        return e.titulo.split('-')[1].trim()
      } else {
        return e.titulo.split('-')[0].trim()
      }
    }))
  }

  useEffect(() => {
    getResultado()
  }, [])

  return (
    <Body isLoading={IsLoading}>
      <ModalPDI
        recomendacoes={Model.recomendacoes.length}
        isOpen={ModalPDIsOpen}
        onCreate={CriarBasePDI}
        onRequestClose={() => setModalPDIsOpen(false)}
      />

      <ModalComentarios
        isOpen={ModalComentariosIsOpen}
        onRequestClose={onCloseModalCompetencia}
        comentarios={Model.discursivas.filter(r => r.titulo.includes(Competencia ?? ''))}
      />

      <Flex margin={'1rem 0'}>
        <NavLine options={[
          {
            nome: 'Gestão de pesquisas',
            path: `${pathname.replace(`ResultadoIndividual/${avaliacaoId as string}`, 'Gestao')}`
          },
          {
            nome: Model?.nomePesquisa ?? '',
            path: -1
          }, {
            nome: Model?.avaliado?.nome ?? '',
            path: ''
          }
        ]} />
      </Flex>

      <Flex marginBottom={'2rem'} justifyContent={'space-between'} flexWrap='wrap'>
        <Flex flexDir={'column'} marginBottom='1rem'>
          <Flex>
            <Avatar
              marginRight={'1rem'}
              size={'2xl'}
              name={Model?.avaliado.nome}
              src={Model?.avaliado.avatar}
            />
            <div>
              <h3>Avaliado</h3>
              <h1>{Model?.avaliado.nome}</h1>
              <strong>{Model?.avaliado?.nomeDepartamento}</strong>
            </div>
          </Flex>
        </Flex>
        <Flex gap={'1rem'}>
          <InfoBox style={{ flexDirection: 'column' }}>
            <span>Média geral</span>
            <h3>{Model?.mediaGeral.toFixed(2)}</h3>
          </InfoBox>

          <InfoBox>
            <Flex width={'2rem'}>
              <Image src={IconeAvaliadores} />
            </Flex>
            <div>
              <h3>{Model?.totalAvaliadores}</h3>
              <span>Avaliadores</span>
            </div>
          </InfoBox>

          <InfoBox>
            <FaClipboardList size={40} />
            <div>
              <h3>{Model?.totalRespostas}</h3>
              <span>Respostas</span>
            </div>
          </InfoBox>
        </Flex>
      </Flex>

      {
        (Model.recomendacoes.length > 0) && (
          <>
            <Flex justifyContent={'start'}>
              <h3>Potencial para melhorias</h3>
            </Flex>

            <Grid templateColumns='repeat(2, 1fr)' gap={10} margin={'1rem'} bg={'white'} padding='2rem' borderRadius={'1rem'}>
              {
                Model?.recomendacoes.map((e, i) => {
                  return (
                    <Recomendacao pos={i + 1} recomendacao={e} key={i} />
                  )
                })
              }
            </Grid>
          </>
        )
      }

      <Flex gap={'1rem'} marginTop={'1rem'}>
        <PDFDownloadLink
          document={
            <RelatorioPDF
              temColuna={TemColuna}
              temWorkShop={false}
              Grafico1={Grafico1}
              Grafico2={Grafico2}
              model={Model}
              medias={Medias}
              tipoPesquisa={pathname.includes('AnaliseDesempenho') ? 'Pesquisa de análise e desempenho' : 'Pesquisa de percepção'}
            />
          }
          fileName={`Relatorio_Pesquisa_Percepcao_${Model.avaliado.nome.replace(/\s/g, '_')}.pdf`}
        >
          {({ loading }) =>
            loading
              ? (
                <Button VarColor='Azul' isLoading>Baixar relatório</Button>
                )
              : (
                <Button VarColor='Azul'>Baixar relatório</Button>
                )
          }
        </PDFDownloadLink>
        {
          (pathname.includes('AnaliseDesempenho')) && (
            <Button
              fontWeight={'500'}
              onClick={() => {
                Model.pdi ? setModalPDIsOpen(true) : CriarBasePDI('1')
              }}
              leftIcon={<FaClipboard />} VarColor='Azul'
            >Adicionar PDI</Button>
          )
        }

        {
          (Model.liberarResultado === 0 && (parseJwt().adm === 'True' || parseJwt().cid === Model.avaliado.gestorId)) && (
            <Button
              borderRadius={'.5rem'}
              VarColor='Orange'
              leftIcon={<FaEyeSlash />}
              onClick={() => AlterarVisibiliade(false)}
            >Ocultar resultado</Button>
          )
        }

        {
          (Model.liberarResultado === 1 && (parseJwt().adm === 'True' || parseJwt().cid === Model.avaliado.gestorId)) && (
            <Button
              leftIcon={<FaEye />}
              borderRadius={'.5rem'}
              VarColor='Orange'
              onClick={() => AlterarVisibiliade(true)}
            >Liberar resultado</Button>
          )
        }
      </Flex>

      <Tabs marginTop={'1rem'} variant='enclosed'>
        <TabList >
          <Tab backgroundColor={'white'} color={'var(--c6)'}>Gráfico</Tab>
          <Tab backgroundColor={'white'} color={'var(--c6)'}>Tabela</Tab>
          <Tab backgroundColor={'white'} color={'var(--c6)'}>Comparativo</Tab>
          {(Model && Model?.discursivas.length > 0) && <Tab backgroundColor={'white'} color={'var(--c6)'}>Comentários</Tab>}
        </TabList>
        <TabPanels background={'white'} borderBottomLeftRadius='1rem' borderBottomRightRadius='1rem'>
          <TabPanel>
            <Flex flexDir={'column'} width={'100%'}>
              {
                (GraficoHierarquia && Model.graficoHierarquia.dados.length !== 2) && (
                  <Flex flexDir={'column'} width='100%'>
                    <Flex justifyContent={'space-between'}>
                      <Titulo>Gráfico de hierarquia</Titulo>
                      <Button onClick={() => setGraficoHierarquia(!GraficoHierarquia)} VarColor='Azul'>Alterar tipo do gráfico</Button>
                    </Flex>
                    <Flex justifyContent={'center'} width='100%'>
                      <Flex width={'60%'}>
                        <RadarChartJs
                          competencias={Model?.graficoHierarquia.competencias ?? []}
                          dados={Model?.graficoHierarquia.dados ?? []}
                          notaMaximaEscala={Model?.notaMaximaEscala ?? 0}
                          stepSize={((Model.pdf?.escala?.respostas[0]?.peso ?? 0) - (Model.pdf?.escala?.respostas[1]?.peso ?? 0)) ?? 0}
                        />
                      </Flex>
                    </Flex>
                  </Flex>
                )
              }

              {
                (!GraficoHierarquia) && (
                  <Flex flexDir={'column'} width='100%'>
                    <Flex justifyContent={'space-between'}>
                      <Titulo>Gráfico individual</Titulo>
                      {
                        (Model.graficoHierarquia.dados.length !== 2) && (
                          <Button
                            onClick={() => setGraficoHierarquia(!GraficoHierarquia)}
                            VarColor='Azul'
                          >Alterar tipo do gráfico</Button>
                        )
                      }
                    </Flex>
                    <Flex justifyContent={'center'} width='100%'>
                      <Flex width={'60%'}>
                        <RadarChartJs
                          competencias={Model?.graficoIndividual.competencias ?? []}
                          dados={Model?.graficoIndividual.dados ?? []}
                          notaMaximaEscala={Model?.notaMaximaEscala ?? 0}
                          stepSize={((Model.pdf?.escala?.respostas[0]?.peso ?? 0) - (Model.pdf?.escala?.respostas[1]?.peso ?? 0)) ?? 0}
                        />
                      </Flex>
                    </Flex>
                  </Flex>
                )
              }
              <Flex position={'absolute'} zIndex={-1} justifyContent={'center'} width={'100%'} maxWidth='1168px'>
                <Flex width={'60%'}>
                  <PDFRadarChart
                    setGrafico={GravarGrafico1}
                    notaMaximaEscala={Model?.notaMaximaEscala ?? 0}
                    stepSize={((Model.pdf?.escala?.respostas[0]?.peso ?? 0) - (Model.pdf?.escala?.respostas[1]?.peso ?? 0)) ?? 0}
                    competencias={Model.graficoIndividual.competencias}
                    dados={Model.graficoIndividual.dados}
                  />
                </Flex>
              </Flex>

              <Flex position={'absolute'} zIndex={-1} justifyContent={'center'} width={'100%'} maxWidth='1168px'>
                <Flex width={'60%'}>
                  <PDFRadarChart
                    setGrafico={GravarGrafico2}
                    notaMaximaEscala={Model?.notaMaximaEscala ?? 0}
                    stepSize={((Model.pdf?.escala?.respostas[0]?.peso ?? 0) - (Model.pdf?.escala?.respostas[1]?.peso ?? 0)) ?? 0}
                    competencias={Model.graficoHierarquia.competencias}
                    dados={Model.graficoHierarquia.dados}
                  />
                </Flex>
              </Flex>

            </Flex>
          </TabPanel>

          <TabPanel>
            <Titulo>Tabela dos resultados por hierarquia</Titulo>
            <TableDiv>
              <table>
                <thead>
                  <tr>
                    <th>
                      <Flex alignItems='center' justifyContent={'space-between'}>
                        <Flex width={'100%'} justifyContent='center'>
                          Competência
                        </Flex>
                        <Flex width={'1.25rem'}></Flex>
                      </Flex></th>
                    {TemColuna('Autoavaliação') && <th>Autoavaliação </th>}
                    {TemColuna('Gestor') && <th>Gestor</th>}
                    {TemColuna('Pares') && <th>Pares</th>}
                    {TemColuna('Liderados') && <th>Liderados</th>}
                    {TemColuna('Gestores') && <th>Gestores</th>}
                    {TemColuna('Outros') && <th>Outros</th>}
                    {TemColuna('Alta liderança') && <th>Alta liderança</th>}
                    <th>Média do avaliado sem autoavaliação</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    Model?.tabela.linhas.map((e, i) => {
                      return (
                        <>
                          <tr onClick={() => toggle(i)} key={i}>
                            <td>
                              <Flex width={'100%'} alignItems='center' justifyContent={'center'}>
                                <Flex width={'100%'} justifyContent={'center'}>
                                  <Tooltip hasArrow label={TooltipLabel(e)} bg={'var(--Preto)'} color={'var(--c1)'} placement={'top'} padding={'.9rem'}>
                                    {e.competencia}
                                  </Tooltip>
                                </Flex>
                                {e.questoes.length === 1 ? MostrarComentario(e.questoes[0]) : ''}
                                {e.questoes.length === 1 ? '' : !open.some(valor => valor === i) ? <FaAngleDown /> : <FaAngleUp />}
                              </Flex>
                            </td>
                            {TemColuna('Autoavaliação') && <StyledTd theme={VerificarCorV2('autoavaliacao', e.autoAvaliacao)}>{e.autoAvaliacao}</StyledTd>}
                            {TemColuna('Gestor') && <StyledTd theme={VerificarCorV2('gestor', e.gestor ?? '')}>{e.gestor}</StyledTd>}
                            {TemColuna('Pares') && <StyledTd theme={VerificarCorV2('par', e.pares ?? '')} >{e.pares}</StyledTd>}
                            {TemColuna('Liderados') && <StyledTd theme={VerificarCorV2('subordinado', e.liderados ?? '')}>{e.liderados}</StyledTd>}
                            {TemColuna('Outros') && <StyledTd theme={VerificarCorV2('outro', e.outros ?? '')}>{e.outros}</StyledTd>}
                            {TemColuna('Alta liderança') && <StyledTd theme={VerificarCorV2('altaLideranca', e.altalideranca ?? '')}>{e.altalideranca}</StyledTd>}
                            <StyledTd theme={VerificarCorRecomendacao(e.mediaSemAuto ?? '', i)}>{e.mediaSemAuto}</StyledTd>
                          </tr>
                          {open.some(valor => valor === i) && e.questoes.length > 1 && CriaDropQuestoes(e.questoes, e.competencia)}
                        </>
                      )
                    })
                  }
                  {
                    Medias.map((e, i) => {
                      return (
                        <tr key={i}>
                          <td>
                            Média geral
                          </td>
                          {TemColuna('Autoavaliação') && <StyledTd>{e.mediaAutoAval.toFixed(2)}</StyledTd>}
                          {TemColuna('Gestor') && <StyledTd>{e.mediaGestor.toFixed(2)}</StyledTd>}
                          {TemColuna('Pares') && <StyledTd>{e.mediaPares.toFixed(2)}</StyledTd>}
                          {TemColuna('Liderados') && <StyledTd>{e.mediaLiderados.toFixed(2)}</StyledTd>}
                          {TemColuna('Outros') && <StyledTd>{e.mediaOutros.toFixed(2)}</StyledTd>}
                          {TemColuna('Alta liderança') && <StyledTd>{e.mediaAltaLideranca.toFixed(2)}</StyledTd>}
                          <StyledTd>{e.mediaSemAuto.toFixed(2)}</StyledTd>
                        </tr>
                      )
                    })
                  }
                </tbody>

              </table>
            </TableDiv>
          </TabPanel>

          <TabPanel>
            <Flex width={'100%'} flexDir='column'>
              {
                (Model?.graficoComparativo) &&
                <Flex justifyContent={'space-between'}>
                  <Titulo>{GraficoComparativoAutoavaliacao ? 'Gráfico de comparativo das médias sem autoavaliação' : 'Gráfico comparativo - autoavaliacão'}</Titulo>
                  <Button
                    onClick={() => setGraficoComparativoAutoavaliacao(!GraficoComparativoAutoavaliacao)}
                    VarColor='Azul'
                  >Alterar tipo do gráfico</Button>
                </Flex>
              }
              <Flex flexDir={'column'} width='100%'>
                <FormControl marginBottom={'1rem'}>
                  <FormLabel>Pesquisa comparada</FormLabel>
                  <Select value={Model?.comparativoId ?? ''} onChange={(e) => FiltrarComaparativo(e.target.value)}>
                    <option value={''}>Mais recente</option>
                    {
                      Model?.filtro.filter(e => e.id !== avaliacaoId).map((e) => {
                        return (
                          <option key={e.id} value={e.id}>{e.nome}</option>
                        )
                      })
                    }
                  </Select>
                </FormControl>
              </Flex>
              {
                (Model?.graficoComparativo && GraficoComparativoAutoavaliacao) && (
                  <Flex justifyContent={'center'} width='100%'>
                    <Flex width={'100%'}>
                      <ComparativoChart fator={Model.notaMaximaEscala} grafico={{
                        competencias: Model?.graficoComparativo.competencias,
                        dados: Model?.graficoComparativo.dados
                      }} id='g-1' />
                    </Flex>
                  </Flex>
                )
              }

              {
                (Model?.graficoComparativoAutoavaliacao && !GraficoComparativoAutoavaliacao) && (
                  <Flex justifyContent={'center'} width='100%'>
                    <Flex width={'100%'}>
                      <ComparativoChart fator={Model.notaMaximaEscala} grafico={{
                        competencias: Model?.graficoComparativoAutoavaliacao.competencias,
                        dados: Model?.graficoComparativoAutoavaliacao.dados
                      }} id='g-0' />
                    </Flex>
                  </Flex>
                )
              }
            </Flex>
          </TabPanel>

          {
            (Model && Model.discursivas.length > 0) && (
              <TabPanel>
                {!Model.discursivas.every(e => PropInvalida(e.tipoAvaliador)) && <Flex gap={'1rem'} marginBottom='1rem' width='50%'>
                  <FormControl>
                    <FormLabel>Questões</FormLabel>
                    <Select onChange={(e) => setFiltroCompetencia(e.target.value)}>
                      <option value={''}>Todos</option>
                      {
                        ObterFiltroQuestao().map((e, i) => {
                          return (
                            <option value={e} key={i}>{e}</option>
                          )
                        })
                      }
                    </Select>
                  </FormControl>

                  <FormControl>
                    <FormLabel>Tipo do avaliador</FormLabel>
                    <Select onChange={(e) => setFiltroTipo(e.target.value)}>
                      <option value={''}>Todos</option>
                      {TemColuna('Autoavaliação') &&
                        <option value={'0'}>Autoavaliação</option>
                      }
                      {TemColuna('Gestor') &&
                        <option value={'1'}>Líder</option>
                      }
                      {TemColuna('Pares') && <option value={'2'}>Par</option>}
                      {TemColuna('Liderados') && <option value={'3'}>Liderado</option>}
                      {TemColuna('Alta liderança') && <option value={'6'}>Alta liderança</option>}
                      {TemColuna('Outros') && <option value={'4'}>Outro</option>}
                    </Select>
                  </FormControl>
                </Flex>}
                <h3>Comentários: {Model.discursivas.filter((e) => {
                  if (FiltroCompetencia === '' && FiltroTipo === '') {
                    return e
                  } else if (!PropInvalida(e.tipoAvaliador) && FiltroCompetencia !== '' && FiltroTipo !== '') {
                    if (e.titulo.includes(FiltroCompetencia) && FiltroTipo === e.tipoAvaliador.toString()) {
                      return e
                    }
                    return null
                  } else if (!PropInvalida(e.tipoAvaliador) && ((FiltroCompetencia !== '' && e.titulo.includes(FiltroCompetencia)) || (FiltroTipo !== '' && FiltroTipo === e.tipoAvaliador.toString()))) {
                    return e
                  } else if (PropInvalida(e.tipoAvaliador) && FiltroCompetencia === 'Comentários') {
                    return e
                  }
                  return null
                }).length}</h3>
                <Flex marginTop={'1rem'} flexDir={'column'} gap='1rem'>
                  {
                    Model.discursivas.filter((e) => {
                      if (FiltroCompetencia === '' && FiltroTipo === '') {
                        return e
                      } else if (!PropInvalida(e.tipoAvaliador) && FiltroCompetencia !== '' && FiltroTipo !== '') {
                        if (e.titulo.includes(FiltroCompetencia) && FiltroTipo === e.tipoAvaliador.toString()) {
                          return e
                        }
                        return null
                      } else if (!PropInvalida(e.tipoAvaliador) && ((FiltroCompetencia !== '' && e.titulo.includes(FiltroCompetencia)) || (FiltroTipo !== '' && FiltroTipo === e.tipoAvaliador.toString()))) {
                        return e
                      } else if (PropInvalida(e.tipoAvaliador) && FiltroCompetencia === 'Comentários') {
                        return e
                      }
                      return null
                    }).map((e, i) => {
                      return (
                        <ComentariosContainer key={i}>
                          <strong>{e.titulo}</strong>
                          <span>{e.texto}</span>
                        </ComentariosContainer>
                      )
                    })
                  }
                </Flex>
              </TabPanel>
            )
          }
        </TabPanels>
      </Tabs>
    </Body>
  )
}
