import styled from 'styled-components'

export const Main = styled.div`
padding: 2rem;
border: 1px solid var(--Azul);
border-radius: 1rem;
display: flex;
justify-content: space-around;
margin: 2rem 0rem;

span,h3{
    color: var(--Azul);
}

strong{
    color: var(--Red);
}

h3{
    font-size:1.75rem;
    margin-bottom: .5rem;
}

span{
    font-weight: 500;
    font-size: 1.2rem;
}

div{
    display: flex;
    flex-direction: column;

    span:not(:last-child){
        margin-bottom: .3rem;
    }
}

div:last-child{
    border-left: 1px solid var(--Gray4);
    padding-left: 1rem;
    //background: red;
    min-width: 30%;
}

div:first-child{
    //background: red;
    max-width: 60%;
}

`
