import { FaTrashAlt, FaPlus } from 'react-icons/fa'
import { HeadContainer, ContainerFormulario, Main, Btns } from './styles'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { iFormulario } from '../../../interfaces'
import { useEffect, useState } from 'react'
import { appApi } from '../../../services/appApi'
import { Body } from '../../Layouts/Body'
import { GreenButton } from '../../../components/Buttons/GreenButton'
import { GreyButton } from '../../../components/Buttons/GreyButton'
import { ModalCreateForm } from '../../../components/ModalCreateForm'
import { ModalDelete } from '../../../components/ModalDelete'
import { useToast } from '@chakra-ui/react'

type navProps = {
  pesquisaId: string
  trilhaId: string
  processoId: string
}

export const Formularios: React.FC = () => {
  const toast = useToast()
  const { pesquisaId } = useParams<navProps>()
  const [Formularios, setFormularios] = useState<iFormulario[]>([])
  const [isOpen, setisOpen] = useState(false)
  const [ModalDeleteIsOpen, setModalDeleteIsOpen] = useState(false)
  const [Id, setId] = useState<string>()
  const nav = useNavigate()

  const path = useLocation().pathname

  function getFormularios(): void {
    appApi.get('Formulario').then(response => setFormularios(response.data)).catch(err => console.log(err))
  }

  function DelteFormulario(): void {
    appApi.delete(`Formulario/${Id ?? ''}`).then(() => getFormularios()).catch(err => console.log(err))
  }

  function onOpenModalDelete(id: string): void {
    setModalDeleteIsOpen(true)
    setId(id)
  }

  function onCloseModalDelete(): void {
    setModalDeleteIsOpen(false)
    setId(undefined)
  }

  function SelectFormulario(FormId: string): void {
    appApi.put(`FormularioPesquisa/${pesquisaId as string}/Formulario/${FormId}`)
      .then(() => nav(`${path.replace('Formularios', 'VisualizarFormulario')}`))
      .catch(err => console.log(err))
  }

  function CriarFormulario(texto: string): void {
    if (texto === '') {
      toast({
        title: 'Informe um nome para o formulário',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return
    }
    appApi.post(`FormularioPesquisa/${pesquisaId as string}`, { nome: texto }).then(() => {
      nav(`${path.replace('Formularios', 'Formulario')}`)
    }).catch(err => console.log(err))
  }

  useEffect(() => {
    getFormularios()
  }, [])

  return (
    <Body>
      <ModalDelete
        isOpen={ModalDeleteIsOpen}
        message='Tem certeza que deseja excluir este formulário?'
        onRequestClose={onCloseModalDelete}
        onConfirm={DelteFormulario}
      />

      <ModalCreateForm
        isOpen={isOpen}
        onCreate={CriarFormulario}
        onClose={() => setisOpen(false)}
      />
      <Main>
        <HeadContainer>
          <h1>Selecione um formulário já criado ou elabore um novo</h1>
          <Btns>
            <GreenButton isNav={false} Size='lg' OnClick={() => setisOpen(true)}><FaPlus className='IconLeft' size={13} />Criar novo formulário</GreenButton>
            <GreyButton Size='lg' isNav={true}>Voltar</GreyButton>
          </Btns>
        </HeadContainer>

        <h2>Formulários salvos</h2>
        <section>
          {
            Formularios.map((e: iFormulario, i) => {
              return (
                <ContainerFormulario key={i}>
                  <span>{e.nome}</span>
                  <div>
                    <button onClick={() => SelectFormulario(e.id)}>Selecionar</button>
                    <button onClick={() => onOpenModalDelete(e.id)}><FaTrashAlt size={11} /></button>
                  </div>
                </ContainerFormulario>
              )
            })
          }
        </section>
      </Main>
    </Body>
  )
}
