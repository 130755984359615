import { Flex } from '@chakra-ui/react'
import { iDiscursiva } from '../..'
import { ModalBase } from '../../../../../components/ModalBase'
import { ComentariosContainer } from '../../styles'
import { Container } from './styles'

interface iModal {
  comentarios: iDiscursiva[]
  isOpen: boolean
  onRequestClose: () => void
}

export const ModalComentarios: React.FC<iModal> = ({ comentarios, isOpen, onRequestClose }) => {
  return (
        <ModalBase isOpen={isOpen} onClose={onRequestClose} Titulo={`Comentários: ${comentarios.length}`} Width='50rem'>
            <Container>
                <Flex flexDir={'column'} gap='1rem'>
                    {
                        comentarios.map((e, i) => {
                          return (
                                <ComentariosContainer key={i}>
                                    <strong>{e.titulo}</strong>
                                    <span>{e.texto}</span>
                                </ComentariosContainer>
                          )
                        })
                    }
                </Flex>
            </Container>
        </ModalBase>
  )
}
