import axios from 'axios'
// Produção
// export const urlLocal = 'https://sah.arquiteturahumana.com.br'
// export const urlSigah = 'https://sigah.app'
// export const urlApi = 'https://api-onboard.azurewebsites.net'

export const urlLocal = 'https://pre-sah.arquiteturahumana.com.br'
export const urlSigah = 'https://pre.sigah.app'
export const urlApi = 'https://api-onboard-pre.azurewebsites.net'

// HML
// export const urlApi = 'https://api-onboard-hml.azurewebsites.net'
// export const urlLocal = 'https://hml2.sigah.cloud'
// export const urlSigah = 'https://hml.sigah.cloud'
// export const urlSigah = 'https://localhost:44392'
// export const urlSigah = 'https://localhost:5001'
// export const urlLocal = 'http://localhost:3000'
// export const urlApi = 'https://localhost:7212'

export const appApi = axios.create({
  baseURL: urlApi
})

export const SigahApi = axios.create({
  baseURL: urlSigah
})
export const TestApi = axios.create({
  baseURL: 'https://sigah.requestcatcher.com'
})
