import { useState } from 'react'
import Modal from 'react-modal'
import { MetaButton } from '../../../../../../components/Buttons/MetaButton'
import { InputFormControl } from '../../../../../../components/InputFormControl'
import { SelectFormControl } from '../../../../../../components/SelectFormControl'
import { IDepartment } from '../../../../../../interfaces'
import { ModalBody, ModalContent, ModalFooter } from './styles'

Modal.setAppElement('#root')

interface ICreateDepartmentModalProps {
  isOpen: boolean
  departmentList: IDepartment[]
  onRequestClose: () => void
  onCreateDepartment: (newDepartment: IDepartment) => void
}

export const CreateDepartmentModal: React.FC<ICreateDepartmentModalProps> = ({
  isOpen,
  departmentList,
  onRequestClose,
  onCreateDepartment
}) => {
  const [Nome, setNome] = useState('')
  const [IdPai, setIdPai] = useState<string>()

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName="react-modal-overlay"
      className="react-modal-content-default"
    >
      <ModalContent>
        <h1>Novo departamento</h1>
        <ModalBody>
          <InputFormControl
            label='Nome da área'
            onChange={(e) => setNome(e.target.value)}
            placeholder='Digite um nome'
          />

          <SelectFormControl label='Área superior' onChange={(e) => setIdPai(e.target.value)}>
            <option value={undefined}>Selecione um</option>
            {
              departmentList.map((e: IDepartment, i) => {
                return (
                  <option value={e.id} key={i}>{e.nome}</option>
                )
              })
            }
          </SelectFormControl>
        </ModalBody>
        <ModalFooter>
          <MetaButton bg='c4' size='md' onClick={onRequestClose}>Fechar</MetaButton>
          <MetaButton bg='v2' size='md' onClick={() => onCreateDepartment({ id: '', nome: Nome, departamentoPaiId: IdPai })}>Salvar</MetaButton>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
