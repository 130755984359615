import styled, { css } from 'styled-components'

interface iBoxProps{
  margin?: string
}

export const Container = styled.div<iBoxProps>`

${props => props.margin && css<iBoxProps>`
    margin: ${props => props.margin};`
}

background: var(--c1);
display: flex;
flex-direction: column;
padding: 1rem;
border-radius: 1.25rem;
box-shadow: var(--SombraBackground);
`
