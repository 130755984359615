import { Container, LeftContainer, NavButton, RightContainer } from './styles'

interface INavLink {
  to: string
  linkTitle: string
  exact?: boolean
}

interface INavBarProps {
  title: string
  navLinks: INavLink[]
}

export const NavBar: React.FC<INavBarProps> = ({ title, navLinks }) => {
  return (
    <Container>
      <LeftContainer>
        <img src={'https://sigah.blob.core.windows.net/onboard/252b9d42-fd65-47c9-b6c3-6ee280bd7dc2.png'} alt="icone" />

        <span>{title}</span>
      </LeftContainer>

      <RightContainer>
        {navLinks.map((navLink) => (
          <NavButton key={navLink.to} to={navLink.to} >
            <span>{navLink.linkTitle}</span>
          </NavButton>
        ))}
      </RightContainer>
    </Container>
  )
}
