import styled from 'styled-components'

export const Container = styled.div`
display: flex;
align-items: center;
box-sizing: border-box;
border:solid var(--Iris80);
border-width:0.1rem;
border-radius: 0.5rem;
font-family: 'Roboto', sans-serif;
width: fit-content;
color: var(--Iris80);
height: 2.5rem;
padding-right: 1rem;
 svg{
     margin-right: 1rem;
     margin-left: 1rem;
 }
 &:hover{
     color: var(--c1);
     background: var(--Iris80);
     cursor: pointer;
 }
`
