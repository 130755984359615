import styled from 'styled-components'

export const Container = styled.div`
background: white;
border-radius: var(--RadiusModal);
padding: 1rem;
box-shadow: var(--SombraBackground);
width: 50rem;
`

export const ModalHeader = styled.div`
display: flex;
margin-bottom:1rem;
justify-content: space-between;

h2{
    color: var(--a3);
}
`

export const ModalBody = styled.div`
display: flex;
flex-direction: column;
padding: 0.375rem;
margin-bottom: 1rem;
label{
    color: var(--a4);
    font-weight: bold;
    font-size: 1.4rem;
}
input{
    border: 0.063rem solid var(--c4);
    border-radius:1rem;
}
`

export const ModalFooter = styled.div`
display: flex;
justify-content:end;
`
