import PesquisaImg from '../../../../../assets/Pesquisa.svg'
import { Container } from './styles'
import { FaCog, FaEdit } from 'react-icons/fa'
import React, { useEffect, useState } from 'react'
import { appApi } from '../../../../../services/appApi'
import { Flex } from '@chakra-ui/react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Button } from '../../../../../components/Button'

interface ICardPesquisa {
  pesquisaId: string
  Quando?: number
  EtapaAnterior?: string
}

interface iPesquisaInternaStatus {
  respondente: string
  totalQuestoes: number
  formularioAprovado: boolean
}

export const CardPesquisa: React.FC<ICardPesquisa> = ({ EtapaAnterior, pesquisaId, Quando }) => {
  const { pathname } = useLocation()
  const [Pesquisa, setPesquisa] = useState<iPesquisaInternaStatus>()
  useEffect(() => {
    ObterPesquisaPorId()
  }, [])

  const nav = useNavigate()

  function ObterPesquisaPorId(): void {
    appApi.get(`PesquisaInterna/${pesquisaId}/Status`)
      .then(response => { setPesquisa(response.data) }).catch(err => console.error(err))
  }

  return (
    <Flex flexDir={'column'} gap={'1rem'}>
      <Container>
        <Flex onClick={() => nav(`PesquisaInterna/Formulario/${pesquisaId}`)} className={Pesquisa?.formularioAprovado ? 'disabled' : ''}>
          <img src={PesquisaImg} />
          <div>
            <FaEdit color='var(--terc10)' size={45} />
          </div>
        </Flex>
        <div>
          <span>Respondente: <strong>{Pesquisa?.respondente}</strong></span>
          {
            Quando !== null
              ? <span>Quando: <strong>{Quando} dias de onboarding</strong></span>
              : <span>Após a etapa: <strong>{EtapaAnterior}</strong></span>
          }
          <span>Total de questões:<strong>{Pesquisa?.totalQuestoes}</strong></span>
        </div>

      </Container>
      <Flex justifyContent={'center'}>
        <Button
          VarColor='Azul'
          leftIcon={<FaCog/>}
          onClick={() => nav(`${pathname}/PesquisaInterna/Configuracao/${pesquisaId}`)}
        >Configurações de envio</Button>
      </Flex>
    </Flex>
  )
}
