import { Checkbox, Flex, FormControl, FormLabel, Input, Select, Switch, Textarea, useToast } from '@chakra-ui/react'
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Button } from '../../../../components/Button'
import { WhiteBox } from '../../../../components/WhiteBox'
import { appApi } from '../../../../services/appApi'
import { Body } from '../../../Layouts/Body'
import { useState, useEffect } from 'react'
import { iEtapa } from '../../../../interfaces'
import { ValidarEtapa } from '../../../../Utils/Helper'
import { CreateEtapaBox } from '../../../../components/CreateEtapaBox'

interface iPostPesquisa {
  titulo: string
  descricao: string
  respondente: number
  respostaGestor: boolean
  anonima: boolean
}

type iParams = {
  trilhaId: string
  processoId: string
  etapaId: string
}
export const CriarPesquisaInterna: React.FC = () => {
  const toast = useToast()
  const { pathname } = useLocation()
  const { trilhaId, etapaId, processoId } = useParams<iParams>()
  const nav = useNavigate()
  const urlPai = processoId ? 'PesquisaInternaEtapaColaborador' : 'PesquisaInternaEtapa'

  const [Pesquisa, setPesquisa] = useState<iPostPesquisa>({
    descricao: '',
    titulo: '',
    respondente: 0,
    respostaGestor: true,
    anonima: false
  })

  const [NomeIsInvalid, setNomeIsInvalid] = useState(false)
  const [Etapa, setEtapa] = useState<iEtapa>({})

  function CriarPesquisa(): void {
    setNomeIsInvalid(false)

    if (Pesquisa.titulo.length < 5) {
      setNomeIsInvalid(true)
      toast({
        title: 'O nome da pesquisa deve conter no mínimo 5 caracteres',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return
    }
    appApi.post('PesquisaInterna', Pesquisa)
      .then(res => {
        nav(`/Jornada/PesquisaInterna/Formulario/${res.data as string}`)
      })
      .catch(err => console.log(err))
  }

  function CreateEtapaComposta(): void {
    setNomeIsInvalid(false)
    if (Pesquisa.titulo.length < 5) {
      setNomeIsInvalid(true)
      toast({
        title: 'O nome da pesquisa deve conter no mínimo 5 caracteres',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return
    }

    if (!ValidarEtapa(Etapa)) {
      toast({
        title: 'Preencha os campos de etapa',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return
    }

    const form = {
      pesquisa: Pesquisa,
      etapa: Etapa
    }

    appApi.post(`${urlPai}/${trilhaId ?? processoId ?? ''}`, form)
      .then(res => nav(`${pathname.replace('Criar', 'Formulario')}/${res.data as string}`))
      .catch(err => console.log(err))
  }

  function getById(): void {
    const param = processoId ? `${processoId}/Etapa/${etapaId as string}` : `${etapaId as string}`
    appApi.get(`${urlPai}/${param}`)
      .then(res => {
        setPesquisa(res.data.pesquisa)
        setEtapa(res.data.etapa)
      })
      .catch(err => console.log(err))
  }

  function UpdateEtapaComposta(): void {
    if (Pesquisa.titulo.length < 5) {
      setNomeIsInvalid(true)
      toast({
        title: 'O nome da pesquisa deve conter no mínimo 5 caracteres',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return
    }

    if (!ValidarEtapa(Etapa)) {
      toast({
        title: 'Preencha os campos de etapa',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return
    }

    const form = {
      pesquisa: Pesquisa,
      etapa: Etapa
    }

    const param = processoId ? `${processoId}/Etapa/${etapaId as string}` : `${etapaId as string}`
    appApi.put(`${urlPai}/${param}`, form)
      .then(() => nav(-1))
      .catch(err => console.log(err))
  }

  function AtualizarEtapa(etapa: iEtapa): void {
    setEtapa(etapa)
  }

  useEffect(() => {
    if (trilhaId ?? processoId ?? etapaId) {
      if (etapaId) {
        getById()
      }
    }
  }, [])

  return (
    <Body>
      <WhiteBox>
        <Flex marginBottom={'2rem'}>
          <h1>Jornada {'>'} Pesquisas internas {'>'} Nova pesquisa</h1>
        </Flex>
        <Flex flexDir={'column'} gap='1rem' marginBottom={'2rem'}>
          <FormControl>
            <FormLabel fontSize={'xl'}>Nome da pesquisa</FormLabel>
            <Input
              isInvalid={NomeIsInvalid}
              placeholder='Digite o nome da pesquisa'
              value={Pesquisa.titulo}
              type={'text'}
              onChange={(e) => setPesquisa({ ...Pesquisa, titulo: e.target.value })}
              borderColor={'var(--Gray4)'}
            />
          </FormControl>

          {
            (trilhaId ?? etapaId ?? processoId) && (
              <>
                <Flex flexDir={'column'}>
                  <FormControl marginBottom={'1rem'}>
                    <FormLabel fontSize={'xl'}>Para quem será enviada a pesquisa</FormLabel>
                    <Select value={Pesquisa.respondente} onChange={(e) => setPesquisa({ ...Pesquisa, respondente: parseInt(e.target.value) })} width={'15rem'} borderColor={'var(--Gray4)'}>
                      <option value={'0'}>Gestor</option>
                      <option value={'1'}>Colaborador</option>
                    </Select>
                  </FormControl>

                  <Flex alignItems={'center'}>
                    <Checkbox onChange={(e) => setPesquisa({ ...Pesquisa, respostaGestor: !e.target.checked })} isChecked={!Pesquisa.respostaGestor} borderColor={'var(--Gray4)'} marginRight={'.5rem'} />
                    <FormLabel margin={0} fontSize={'sm'}>Selecione caso não queira disponibilizar as respostas ao gestor</FormLabel>
                  </Flex>
                </Flex>
                <CreateEtapaBox
                  EditEtapa={Etapa}
                  ignoreId={etapaId}
                  variant='Etapa'
                  onModelChange={AtualizarEtapa}
                  keyWord='pesquisa interna'
                />
              </>
            )
          }

          <FormControl>
            <FormLabel fontSize={'xl'}>Descrição da pesquisa</FormLabel>
            <Textarea placeholder='Digite a descrição da pesquisa' value={Pesquisa.descricao}
              onChange={(e) => setPesquisa({ ...Pesquisa, descricao: e.target.value })}
              borderColor={'var(--Gray4)'}
            />
          </FormControl>

          {
            (!pathname.includes('Editar')) && (
              <FormControl gap={'1rem'} alignItems='center' as={Flex}>
                <FormLabel margin={0} fontSize={'xl'}>Pesquisa anônima</FormLabel>
                <Switch isChecked={Pesquisa.anonima} onChange={() => setPesquisa({ ...Pesquisa, anonima: !Pesquisa.anonima })} size={'lg'} />
              </FormControl>
            )
          }
        </Flex>
        <Flex padding={'0 1rem 0 0'} justifyContent={'end'}>
          <Button leftIcon={<FaAngleLeft />} onClick={() => nav(-1)} VarColor='c5' size={'lg'}>Voltar</Button>
          {
            (etapaId === undefined && (trilhaId ?? processoId)) && (
              <Button marginLeft={'1rem'} rightIcon={<FaAngleRight />} VarColor='Green2' size={'lg'} onClick={CreateEtapaComposta}>Criar etapa</Button>
            )
          }

          {
            (etapaId) && (
              <Button marginLeft={'1rem'} VarColor='Green2' size={'lg'} onClick={UpdateEtapaComposta}>Atualizar</Button>
            )
          }

          {
            (trilhaId === undefined && etapaId === undefined && processoId === undefined) && (
              <Button marginLeft={'1rem'} rightIcon={<FaAngleRight />} VarColor='Green2' size={'lg'} onClick={CriarPesquisa}>Próximo</Button>
            )
          }
        </Flex>
      </WhiteBox>
    </Body>
  )
}
