import styled from 'styled-components'

export const ComentariosContainer = styled.div`
display: flex;
justify-content: start;
width: 100%;
margin-top: 2rem;
margin-bottom: 1rem;

div{
background: var(--c2);
padding: .5rem;
width: 100%;
}

div:not(:last-child){
    margin-bottom: 1rem;
}

section{
    max-width: 70rem;
    width: 100%;
}

h3{
    color: var(--Gray3);
    margin-bottom: 1rem;
}
`
