/* eslint-disable no-return-assign */
import { Menu, MenuButton, Button, Flex, Badge, AlertIcon, Alert, Link } from '@chakra-ui/react'
import { FaBriefcase, FaChalkboardTeacher, FaChevronDown, FaClipboardList, FaCog, FaColumns, FaCopy, FaDoorOpen, FaHeadset, FaHome, FaIdCard, FaPeopleArrows, FaPlane, FaPlusSquare, FaQuestionCircle, FaSitemap, FaStreetView, FaTachometerAlt, FaUserClock, FaUsersCog } from 'react-icons/fa'
import { BsCalendarCheck } from 'react-icons/bs'
import { MdLogout, MdMenu, MdPassword } from 'react-icons/md'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAuth } from '../../contexts/AuthContext'
import { StyledMenuItem } from './Components/StyledMenuItem'
import { Container, FlexIcon, LogoContainer } from './styles'

import LogoPec from '../../assets/PEC.svg'
import LogoPulse from '../../assets/Pulse.svg'
import LogoFO from '../../assets/FO.svg'
// import LogoTurnOver from '../../assets/TurnOver.svg'
import { urlSigah } from '../../services/appApi'
import { parseJwt } from '../../services/token'
import { StyledMenuList } from './Components/StyledMenuList'
import { useEffect } from 'react'
import { ObterToken, PropInvalida } from '../../Utils/Helper'
import { StyledMenuButton } from './Components/StyledMenuButton'

export const NavBar: React.FC = () => {
  const { Permissoes, permitirFuncao, isAuth, logOut, refreshToken, obterVersao, recarregarPermissoes, modoFree } = useAuth()
  const BlackList = ['/', 'RespostaPesquisa', 'Abertura', 'RespostaNps', 'VisualizarImersao', 'EsqueciSenha', 'Preenchimento']
  const { pathname } = useLocation()

  function NaoTemNavBar(): boolean {
    let temAcesso = true
    if (pathname === '/') { return true }
    pathname.split('/').forEach((e) => {
      const res = BlackList.find(r => r === e)
      if (res !== undefined) {
        temAcesso = false
      }
    })

    if (temAcesso) {
      return false
    }

    return true
  }

  if (NaoTemNavBar() || !isAuth()) {
    return null
  }

  const nav = useNavigate()
  const Route = `${urlSigah}/Home/ExtLogin/?token=${ObterToken()}`
  const redirectPage = (ct: string, at: string): any => window.location.href = `${Route}&ct=${ct}&at=${at}`

  useEffect(() => {
    obterVersao()
    refreshToken()
    if (Permissoes.permissoes.length === 0) {
      recarregarPermissoes()
    }
  }, [pathname])
  return (<>
        <Container>
            <Flex alignItems={'center'} gap={'1rem'}>
                <LogoContainer>
                    <img onClick={() => nav('/Colaborador')} src={localStorage.getItem('logo') === 'https://sigah.arquiteturahumana.com.br/css/logoBranco.png' || PropInvalida(localStorage.getItem('logo')) ? 'https://sigah.blob.core.windows.net/onboard/f99445d7-d98d-48ad-9cdb-a443a9f636a2.png' : localStorage.getItem('logo') ?? ''} />
                    <hr />
                </LogoContainer>
                <Button
                    px={4}
                    height={'2.5rem'}
                    borderRadius='lg'
                    transition='all 0.2s'
                    _hover={{ bg: 'var(--Rosa)' }}
                    _focus={{ bg: 'var(--Rosa)' }}
                    bg={'none'}
                    fontWeight={'700'}
                    _active={{ bg: 'var(--Rosa)' }}
                    onClick={() => nav('/Colaborador')}
                >
                    Início
                </Button>

                {
                    (Permissoes.jornada) && (
                        <Menu>
                            <StyledMenuButton rightIcon={<FaChevronDown />}>
                                Jornada
                            </StyledMenuButton>
                            <StyledMenuList>
                                {
                                    ((parseJwt().adm === 'True') || permitirFuncao('Jornada', 'dash')) && (
                                        <StyledMenuItem
                                            onClick={() => nav('/Jornada/Dashboard')}
                                        >
                                            <FaColumns style={{ marginRight: '1rem' }} />
                                            Dashboard
                                        </StyledMenuItem>
                                    )
                                }

                                {(parseJwt().adm === 'True' || permitirFuncao('Jornada', 'trilha-onboard')) && (
                                    <StyledMenuItem
                                        onClick={() => nav('Jornada/Onboard?tipo=0')}
                                    >
                                        <FaUserClock style={{ marginRight: '1rem' }} />
                                        Trilha onboarding
                                    </StyledMenuItem>
                                )}

                                {(parseJwt().adm === 'True' || permitirFuncao('Jornada', 'trilha-desenvolvimento')) && (
                                    <StyledMenuItem
                                        onClick={() => nav('Jornada/Onboard?tipo=1')}
                                    >
                                        <FaPlane style={{ marginRight: '1rem' }} />
                                        Trilha de desenvolvimento
                                    </StyledMenuItem>
                                )}

                                {(parseJwt().adm === 'True' || permitirFuncao('Jornada', 'trilha-offboard')) && (
                                    <StyledMenuItem
                                        onClick={() => nav('Jornada/Onboard?tipo=3')}
                                    >
                                        <FaDoorOpen style={{ marginRight: '1rem' }} />
                                        Offboarding
                                    </StyledMenuItem>
                                )}

                                {
                                    (parseJwt().adm === 'True' || permitirFuncao('Jornada', '1on1')) && (
                                        <StyledMenuItem
                                            onClick={() => nav('Jornada/ReuniaoOne')}
                                        >
                                            <FaPeopleArrows style={{ marginRight: '1rem' }} />
                                            Reunião 1:1
                                        </StyledMenuItem>
                                    )
                                }

                                {
                                    (parseJwt().adm === 'True' || permitirFuncao('Jornada', 'pesquisa-percepcao')) && (
                                        <StyledMenuItem
                                            onClick={() => nav('Rh/PesquisaPercepcao/Gestao')}
                                        >
                                            <FaStreetView style={{ marginRight: '1rem' }} />
                                            Pesquisa de percepção
                                        </StyledMenuItem>
                                    )
                                }

                                {
                                    (parseJwt().adm === 'True' || permitirFuncao('Jornada', 'pesquisa-interna')) && (
                                        <StyledMenuItem
                                            onClick={() => nav('/Jornada/PesquisaInterna/Gestao')}
                                        >
                                            <FaClipboardList style={{ marginRight: '1rem' }} />
                                            Pesquisa interna
                                        </StyledMenuItem>
                                    )
                                }

                                {
                                    (parseJwt().adm === 'True' || permitirFuncao('Jornada', 'imersão-cultural')) && (
                                        <StyledMenuItem
                                            onClick={() => nav('/Jornada/Imersao')}
                                        >
                                            <FaChalkboardTeacher style={{ marginRight: '1rem' }} />
                                            Imersão cultural
                                        </StyledMenuItem>
                                    )
                                }

                                {
                                    (parseJwt().adm === 'True' || permitirFuncao('Jornada', 'enps')) && (
                                        <StyledMenuItem
                                            onClick={() => nav('/Jornada/eNps')}
                                        >
                                            <FaTachometerAlt style={{ marginRight: '1rem' }} />
                                            eNPS
                                        </StyledMenuItem>
                                    )
                                }

                                {/* {
                                    (parseJwt().adm === 'True' || permitirFuncao('Jornada', 'turnover')) && (
                                        <StyledMenuItem
                                            onClick={() => nav('/Jornada/TurnOver')}
                                        >
                                            <img src={LogoTurnOver} style={{ marginRight: '1rem', height: '1rem' }} />
                                            Turnover
                                        </StyledMenuItem>
                                    )
                                } */}

                            </StyledMenuList>
                        </Menu>
                    )
                }

                {
                    (!Permissoes.jornada) && (
                        <Menu>
                            <StyledMenuButton rightIcon={<FaChevronDown />}>
                                Jornada
                            </StyledMenuButton>
                            <StyledMenuList>
                                <StyledMenuItem>
                                    <FaColumns style={{ marginRight: '1rem' }} />
                                    Módulo bloqueado
                                </StyledMenuItem>

                                <StyledMenuItem
                                    disabled
                                >
                                    <FaColumns style={{ marginRight: '1rem' }} />
                                    Dashboard
                                </StyledMenuItem>

                                <StyledMenuItem
                                    disabled
                                >
                                    <FaUserClock style={{ marginRight: '1rem' }} />
                                    Trilha onboarding
                                </StyledMenuItem>

                                <StyledMenuItem
                                    disabled
                                >
                                    <FaPlane style={{ marginRight: '1rem' }} />
                                    Trilha de desenvolvimento
                                </StyledMenuItem>

                                <StyledMenuItem
                                    disabled
                                >
                                    <FaDoorOpen style={{ marginRight: '1rem' }} />
                                    Trilha offboarding
                                </StyledMenuItem>

                                <StyledMenuItem
                                    disabled
                                >
                                    <FaPeopleArrows style={{ marginRight: '1rem' }} />
                                    Reunião 1:1
                                </StyledMenuItem>

                                <StyledMenuItem
                                    disabled
                                >
                                    <FaStreetView style={{ marginRight: '1rem' }} />
                                    Pesquisa de percepção
                                </StyledMenuItem>

                                <StyledMenuItem
                                    disabled
                                >
                                    <FaClipboardList style={{ marginRight: '1rem' }} />
                                    Pesquisa interna
                                </StyledMenuItem>

                                <StyledMenuItem
                                    disabled
                                >
                                    <FaChalkboardTeacher style={{ marginRight: '1rem' }} />
                                    Imersão cultural
                                </StyledMenuItem>
                                <StyledMenuItem
                                    disabled
                                >
                                    <FaTachometerAlt style={{ marginRight: '1rem' }} />
                                    eNPS
                                </StyledMenuItem>
                                {/* <StyledMenuItem
                                    disabled
                                >
                                    <img src={LogoTurnOver} style={{ marginRight: '1rem', height: '1rem' }} />
                                    Turnover
                                </StyledMenuItem> */}
                            </StyledMenuList>
                        </Menu>
                    )
                }

                {
                    (Permissoes.desempenho) && (
                        <Menu>
                            <StyledMenuButton rightIcon={<FaChevronDown />}>
                                Desempenho
                            </StyledMenuButton>
                            <StyledMenuList>
                                {
                                    ((parseJwt().adm === 'True') || permitirFuncao('Desempenho', 'dash')) && (
                                        <StyledMenuItem
                                            onClick={() => nav('/Desempenho/Dashboard')}
                                        >
                                            <FaColumns style={{ marginRight: '1rem' }} />
                                            Dashboard
                                        </StyledMenuItem>
                                    )
                                }

                                {
                                    (parseJwt().adm === 'True') && (
                                        <StyledMenuItem
                                            onClick={() => nav('/Rh/AnaliseDesempenho/Criar')}
                                        >
                                            <FaPlusSquare style={{ marginRight: '1rem' }} />
                                            Criar pesquisa de desempenho por competência
                                        </StyledMenuItem>
                                    )
                                }

                                {
                                    (parseJwt().adm === 'True' || permitirFuncao('Desempenho', 'pesquisa-desempenho')) && (
                                        <StyledMenuItem
                                            onClick={() => nav('/Rh/AnaliseDesempenho/Gestao')}
                                        >
                                            <FaCopy style={{ marginRight: '1rem' }} />
                                            Gestão de pesquisas de desempenho por competência
                                        </StyledMenuItem>
                                    )
                                }

                                {
                                    (parseJwt().adm === 'True' || permitirFuncao('Desempenho', '1on1')) && (
                                        <StyledMenuItem
                                            onClick={() => nav('Desempenho/ReuniaoOne')}
                                        >
                                            <FaPeopleArrows style={{ marginRight: '1rem' }} />
                                            Reunião 1:1
                                        </StyledMenuItem>
                                    )
                                }

                                {
                                    (parseJwt().adm === 'True' || permitirFuncao('Desempenho', 'pdi')) && (
                                        <StyledMenuItem
                                            onClick={() => nav('/Desempenho/PDI/Dashboard/Individual')}
                                        >
                                            <BsCalendarCheck style={{ marginRight: '1rem' }} />
                                            PDI
                                        </StyledMenuItem>
                                    )
                                }

                                {
                                    (parseJwt().adm === 'True' || permitirFuncao('Desempenho', 'imersao-cultural')) && (
                                        <StyledMenuItem
                                            onClick={() => nav('/Jornada/Imersao')}
                                        >
                                            <FaChalkboardTeacher style={{ marginRight: '1rem' }} />
                                            Imersão cultural
                                        </StyledMenuItem>
                                    )
                                }
                            </StyledMenuList>
                        </Menu>
                    )
                }

                {
                    (!Permissoes.desempenho) && (
                        <Menu>
                            <StyledMenuButton rightIcon={<FaChevronDown />}>
                                Desempenho
                            </StyledMenuButton>
                            <StyledMenuList>
                                <StyledMenuItem>
                                    <FaColumns style={{ marginRight: '1rem' }} />
                                    Módulo bloqueado
                                </StyledMenuItem>

                                <StyledMenuItem
                                    disabled
                                >
                                    <FaColumns style={{ marginRight: '1rem' }} />
                                    Dashboard
                                </StyledMenuItem>

                                <StyledMenuItem
                                    disabled
                                >
                                    <FaPlusSquare style={{ marginRight: '1rem' }} />
                                    Criar pesquisa de desempenho por competência
                                </StyledMenuItem>

                                <StyledMenuItem
                                    disabled
                                >
                                    <FaCopy style={{ marginRight: '1rem' }} />
                                    Gestão de pesquisas de desempenho por competência
                                </StyledMenuItem>

                                <StyledMenuItem
                                    disabled
                                >
                                    <FaPeopleArrows style={{ marginRight: '1rem' }} />
                                    Reunião 1:1
                                </StyledMenuItem>

                                <StyledMenuItem
                                    disabled
                                >
                                    <BsCalendarCheck style={{ marginRight: '1rem' }} />
                                    PDI
                                </StyledMenuItem>

                                <StyledMenuItem
                                    disabled
                                >
                                    <FaChalkboardTeacher style={{ marginRight: '1rem' }} />
                                    Imersão cultural
                                </StyledMenuItem>
                            </StyledMenuList>
                        </Menu>
                    )
                }

                {
                    (Permissoes.engajamento && parseJwt()?.adm === 'True') && (
                        <Menu>
                            <StyledMenuButton rightIcon={<FaChevronDown />}>
                                Engajamento
                            </StyledMenuButton>
                            <StyledMenuList>
                                {
                                    (parseJwt().adm === 'True' || permitirFuncao('Engajamento', 'dash')) && (
                                        <StyledMenuItem
                                            onClick={() => nav('/Engajamento')}
                                        >
                                            <FaColumns style={{ marginRight: '1rem' }} />
                                            Dashboard
                                        </StyledMenuItem>
                                    )
                                }

                                {
                                    (parseJwt().adm === 'True' || permitirFuncao('Engajamento', 'pec')) && (
                                        <StyledMenuItem
                                            onClick={() => redirectPage('PesquisasPEC', 'Index')}
                                        >
                                            <img src={LogoPec} style={{ marginRight: '1rem', height: '1rem' }} />
                                            Pesquisa de engajamento
                                        </StyledMenuItem>
                                    )
                                }

                                {
                                    (parseJwt().adm === 'True' || permitirFuncao('Engajamento', 'pulse')) && (
                                        <StyledMenuItem
                                            onClick={() => redirectPage('PesquisaPulse', 'Index')}
                                        >
                                            <img src={LogoPulse} style={{ marginRight: '1rem', height: '1rem' }} />
                                            Pesquisa Pulse
                                        </StyledMenuItem>
                                    )
                                }

                                {
                                    (parseJwt().adm === 'True' || permitirFuncao('Engajamento', 'feedback')) && (
                                        <StyledMenuItem
                                            onClick={() => redirectPage('FeedbackOrganizacao', 'Index')}
                                        >
                                            <img src={LogoFO} style={{ marginRight: '1rem', height: '1rem' }} />
                                            Feedback organização
                                        </StyledMenuItem>
                                    )
                                }

                                {
                                    (parseJwt().adm === 'True' || permitirFuncao('Engajamento', 'enps')) && (
                                        <StyledMenuItem
                                            onClick={() => nav('/Engajamento/eNPS')}
                                        >
                                            <FaTachometerAlt style={{ marginRight: '1rem' }} />
                                            eNPS
                                        </StyledMenuItem>
                                    )
                                }

                            </StyledMenuList>
                        </Menu>
                    )
                }

                {
                    (!Permissoes.engajamento) && (
                        <Menu>
                            <StyledMenuButton rightIcon={<FaChevronDown />}>
                                Engajamento
                            </StyledMenuButton>
                            <StyledMenuList>
                                <StyledMenuItem>
                                    <FaColumns style={{ marginRight: '1rem' }} />
                                    Módulo bloqueado
                                </StyledMenuItem>

                                <StyledMenuItem
                                    disabled
                                >
                                    <FaColumns style={{ marginRight: '1rem' }} />
                                    Dashboard
                                </StyledMenuItem>

                                <StyledMenuItem
                                    disabled
                                >
                                    <img src={LogoPec} style={{ marginRight: '1rem', height: '1rem' }} />
                                    Pesquisa de engajamento
                                </StyledMenuItem>

                                <StyledMenuItem
                                    disabled
                                >
                                    <img src={LogoPulse} style={{ marginRight: '1rem', height: '1rem' }} />
                                    Pesquisa Pulse
                                </StyledMenuItem>

                                <StyledMenuItem
                                    disabled
                                >
                                    <img src={LogoFO} style={{ marginRight: '1rem', height: '1rem' }} />
                                    Feedback organização
                                </StyledMenuItem>

                                <StyledMenuItem
                                    disabled
                                >
                                    <FaTachometerAlt style={{ marginRight: '1rem' }} />
                                    eNPS
                                </StyledMenuItem>

                            </StyledMenuList>
                        </Menu>
                    )
                }

                {
                    (Permissoes.organograma) && (
                        <Menu>
                            <StyledMenuButton rightIcon={<FaChevronDown />}
                            >Organograma
                            </StyledMenuButton>
                            <StyledMenuList>
                                {
                                    (parseJwt().adm === 'True' || permitirFuncao('Organograma', 'visao-geral')) && (
                                        <StyledMenuItem
                                            onClick={() => nav('/Organograma/Dashboard')}
                                        >
                                            <FaSitemap style={{ marginRight: '1rem' }} />
                                            Visão geral
                                        </StyledMenuItem>
                                    )
                                }

                                {
                                    (parseJwt().adm === 'True' || permitirFuncao('Organograma', 'areas')) && (
                                        <StyledMenuItem
                                            onClick={() => nav('/Organograma/Departamentos')}
                                        >
                                            <FaIdCard style={{ marginRight: '1rem' }} />
                                            Áreas
                                        </StyledMenuItem>
                                    )
                                }

                                {
                                    (parseJwt().adm === 'True' || permitirFuncao('Organograma', 'funcoes')) && (
                                        <StyledMenuItem
                                            onClick={() => nav('/Organograma/Cargos')}
                                        >
                                            <FaBriefcase style={{ marginRight: '1rem' }} />
                                            Funções
                                        </StyledMenuItem>
                                    )
                                }

                                {
                                    (parseJwt().adm === 'True' || permitirFuncao('Organograma', 'colaboradores')) && (
                                        <StyledMenuItem
                                            onClick={() => nav('/Organograma/Colaboradores')}
                                        >
                                            <FaUsersCog style={{ marginRight: '1rem' }} />
                                            Colaboradores
                                        </StyledMenuItem>
                                    )
                                }
                            </StyledMenuList>
                        </Menu>
                    )
                }

                {
                    (!Permissoes.organograma) && (
                        <Menu>
                            <StyledMenuButton rightIcon={<FaChevronDown />}>
                                Organograma
                            </StyledMenuButton>
                            <StyledMenuList>
                                <StyledMenuItem>
                                    <FaColumns style={{ marginRight: '1rem' }} />
                                    Módulo bloqueado
                                </StyledMenuItem>

                                <StyledMenuItem
                                    disabled
                                >
                                    <FaSitemap style={{ marginRight: '1rem' }} />
                                    Visão geral
                                </StyledMenuItem>

                                <StyledMenuItem
                                    disabled
                                >
                                    <FaIdCard style={{ marginRight: '1rem' }} />
                                    Áreas
                                </StyledMenuItem>

                                <StyledMenuItem
                                    disabled
                                >
                                    <FaBriefcase style={{ marginRight: '1rem' }} />
                                    Funções
                                </StyledMenuItem>

                                <StyledMenuItem
                                    disabled
                                >
                                    <FaUsersCog style={{ marginRight: '1rem' }} />
                                    Colaboradores
                                </StyledMenuItem>
                            </StyledMenuList>
                        </Menu>
                    )
                }
                {
                    (parseJwt().eid === 'acbf6a22-154e-480d-a465-61b990faa8bd' || parseJwt().eid === 'c5d7423c-5fbc-4917-a667-c2a2ec9693d7') && (
                        <>
                            <Menu>
                                <StyledMenuButton rightIcon={<FaChevronDown />}>
                                    Assessment PI &reg;
                                </StyledMenuButton>
                                <StyledMenuList>
                                    {
                                        (parseJwt().adm === 'True' || permitirFuncao('Organograma', 'visao-geral')) && (
                                            <StyledMenuItem
                                                onClick={() => nav('/AssessmentPi/Dashboard')}
                                            >
                                                <FaColumns style={{ marginRight: '1rem' }} />
                                                Dashboard
                                            </StyledMenuItem>
                                        )
                                    }

                                    {
                                        (parseJwt().adm === 'True' || permitirFuncao('Organograma', 'areas')) && (
                                            <StyledMenuItem
                                                onClick={() => nav('/AssessmentPi/JobTarget/Create')}
                                            >
                                                <FaIdCard style={{ marginRight: '1rem' }} />
                                                Novo job target
                                            </StyledMenuItem>
                                        )
                                    }

                                </StyledMenuList>
                            </Menu>

                            <Menu>
                                <StyledMenuButton onClick={() => nav('/CentralComando')}>
                                    Central de comando
                                </StyledMenuButton>
                            </Menu>
                        </>
                    )
                }

            </Flex>

            <FlexIcon>
                <Menu>
                    <MenuButton
                        px={0}
                        _hover={{ bg: 'none' }}
                        bg={'none'}
                        as={Button}
                        rightIcon={<MdMenu size={'1.5rem'} />}
                        _focus={{ bg: 'none' }}
                        _active={{ bg: 'none' }}
                    ></MenuButton>
                    <StyledMenuList>

                        {modoFree() && (<StyledMenuItem

                        >
                            <FaBriefcase style={{ marginRight: '1rem' }} />
                            <Badge colorScheme='red'>Modo FREE </Badge>
                        </StyledMenuItem>)}
                        {
                            (parseJwt().adm === 'True') && (
                                <StyledMenuItem
                                    onClick={() => nav('/Usuario/Permissoes')}
                                >
                                    <FaHome style={{ marginRight: '1rem' }} />
                                    Gerenciar permissões
                                </StyledMenuItem>
                            )
                        }

                        {
                            (parseJwt().adm === 'True') && (
                                <StyledMenuItem
                                    onClick={() => nav('/Usuario/Empresa')}
                                >
                                    <FaCog style={{ marginRight: '1rem' }} />
                                    Perfil da empresa
                                </StyledMenuItem>
                            )
                        }

                        <StyledMenuItem
                            onClick={() => nav('Usuario/AlterarSenha')}
                        >
                            <MdPassword style={{ marginRight: '1rem' }} />
                            Alterar senha
                        </StyledMenuItem>

                        <StyledMenuItem
                            onClick={() => nav('/Usuario/PerguntasFrequentes')}
                        >
                            <FaQuestionCircle style={{ marginRight: '1rem' }} />
                            Perguntas frequentes
                        </StyledMenuItem>

                        <StyledMenuItem
                            onClick={() => nav('/Usuario/ChamadoRh')}
                        >
                            <FaHeadset style={{ marginRight: '1rem' }} />
                            Chamado com RH
                        </StyledMenuItem>

                        <StyledMenuItem
                            onClick={() => logOut()}
                        >
                            <MdLogout style={{ marginRight: '1rem' }} />
                            Sair
                        </StyledMenuItem>
                    </StyledMenuList>
                </Menu>
            </FlexIcon>
        </Container>
        {modoFree() && (
            <Alert status='warning'>
                <AlertIcon />
                <Link href='mailto:comercial@arquiteturahumana.com.br'>Sua empresa utiliza o plano gratuito do SAH limitado a 5 colaboradores, para contratar os planos com funcionalidades completas entre em contato com seu consultor de conta</Link>.
            </Alert>)}
    </>
  )
}
