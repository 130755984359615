import styled from 'styled-components'
import { Column } from '../../../../../styles/styledGlobal'

export const PreViewParticipante = styled.div`
display: flex;
justify-content: space-between;
background: var(--c2);
border-radius: 0.625rem;
box-shadow: var(--SombraBtns);
min-height: 5rem;
max-height: 10rem;

.OpacityEfect{
    transition: all .5s ease;
    opacity: 100%;
    pointer-events: inherit;
}

.disable{
    cursor: not-allowed;
    li:not(:nth-child(6),:nth-child(7)){
        opacity: 50%;
        pointer-events: none;
    }
}
`

export const RadiusAmarelo = styled.div`
 background:var(--terc5);
 width: 1rem;
 height: 100%;
 border-top-left-radius:0.625rem ;
 border-bottom-left-radius:0.625rem ;
 margin-right: .6rem;
`

export const ColumnText = styled(Column)`
align-items: flex-start;
justify-content: center;
max-width: 12.938rem;
margin-right:.5rem;
margin-top: 0.700rem;
margin-bottom: 0.700rem;

span,h4{
    color: var(--a5);
}
`

export const GridContainer = styled.div`
display: grid;
grid-template-columns: repeat(4,1fr);
gap: 0rem 1rem;
width: 100%;
`
