import styled from 'styled-components'

export const Container = styled.table`
margin-top: 1rem;
border-spacing: 0 .5rem;
font-family: 'Roboto','sans-serif';
thead{
    tr{
        color: var(--a2);
        font-weight: 600;
        font-size: 1rem;
    }
}

thead{
    th:first-child{
        padding-left: 4rem;
        text-align: start;
    }
}

tbody{

    tr{
        background-color: var(--c2);
    }

    tr,th{
        border-collapse: collapse;
        padding: .5rem;
    }
}
`
