import { useEffect, useState } from 'react'
import { Container } from './styles'
import { iItemIntegracao } from '../../../../../interfaces'
import { appApi } from '../../../../../services/appApi'
interface ICardIntegracao {
  IntegracaoId: string
}

export const CardIntegracao: React.FC<ICardIntegracao> = ({ IntegracaoId }) => {
  const [Itens, setItem] = useState<iItemIntegracao[]>([])

  useEffect(() => {
    ObterInformacoesCheck()
  }, [])

  function ObterInformacoesCheck(): void {
    appApi.get(`Integracao/${IntegracaoId}`)
      .then(response => { setItem(response.data.itensIntegracao) }).catch(err => console.error(err))
  }

  return (
    <Container>
      <h3>Informações a serem verificadas</h3>
      {
        Itens.map((e: iItemIntegracao, i): any => {
          return (
            <span key={i}>{i + 1}.  {e.titulo}</span>
          )
        })
      }
    </Container>
  )
}
