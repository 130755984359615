import styled from 'styled-components'

export const Container = styled.div`
display: flex;
margin: 0 0 1rem 0;
align-items: center;

span{
font-size: 1rem;
color: var(--a4);
cursor: pointer;
}

svg,span{
    margin-right:.5rem;
}

svg{
    color: var(--a1);
}

span:hover{
    text-decoration: underline;
}

span:last-child{
    font-weight: bold;
}
`
