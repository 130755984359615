import { Flex, Textarea } from '@chakra-ui/react'
import { useState } from 'react'
import { Button } from '../../../../../../components/Button'
import { ModalBase } from '../../../../../../components/ModalBase'

interface iModalProps {
  isOpen: boolean
  onClose: () => void
  onSend: (texto: string) => void
}
export const ModalComentario: React.FC<iModalProps> = ({ isOpen, onClose, onSend }) => {
  const [Texto, setTexto] = useState('')

  return (
        <ModalBase
            Titulo="Deixe seu feedback para esta reunião"
            isOpen={isOpen}
            onClose={onClose}
            Width='35rem'
        >
            <Flex padding={'1rem 1rem 0 1rem'}>
                <Textarea
                    borderColor={'var(--Gray4)'}
                    onChange={(e) => setTexto(e.target.value)}
                    height={'10rem'}
                />
            </Flex>
            <Flex justifyContent={'end'} padding={'1rem'}>
                <Button onClick={onClose} marginRight={'1rem'} VarColor='c4'>Cancelar</Button>
                <Button VarColor='v2' onClick={() => onSend(Texto)}>Enviar e concluir</Button>
            </Flex>
        </ModalBase>
  )
}
