import styled from 'styled-components'
import { Flex } from '../../../../../../styles/styledGlobal'

export const ModalBody = styled.div`
width: 40rem;
label{
  margin-bottom:.5rem;
  margin-top: 2rem;
}


input{
  background-color: var(--c2);
}
`

export const ModalContent = styled.div`
padding: 48px;
`

export const ModalFooter = styled(Flex)`
margin-top: 2rem;
justify-content: end;

a:first-child{
  margin-right: 1rem;
}
`
