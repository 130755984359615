import { Container, TextIcon, Icone, WidthContainer } from './styles'
import { FaClipboardList, FaUserClock, FaDoorOpen, FaComments, FaUsers, FaChalkboardTeacher, FaPeopleArrows } from 'react-icons/fa'
import { iCardEtapa } from '../../interfaces'
import { ObterCorEtapaPeloTipo } from '../../Utils/Helper'
import IconeTrilha from '../../assets/IconeTrilha.svg'
import { Img } from '@chakra-ui/react'
import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { appApi } from '../../services/appApi'
import { MdBlock } from 'react-icons/md'
import { BsCalendarCheck } from 'react-icons/bs'

interface iTimeLine {
  hide?: boolean
}

export async function IsOffboarding(isTrilha: boolean, id: string): Promise<boolean> {
  const url = isTrilha ? `GrupoTrilha/${id}/Tipo` : `ProcessoOnboard/${id}/Tipo`
  const promisse = appApi.get(url)
    .then(response => {
      if (response.status === 200) {
        return response.data === 3
      } else {
        return false
      }
    }).catch(() => {
      return false
    })
  return await promisse
}

export const TimeLineOnboard: React.FC<iTimeLine> = ({ hide }) => {
  if (hide) {
    return null
  }

  const { trilhaId } = useParams<{ trilhaId: string }>()
  const [Etapas, setEtapas] = useState<iCardEtapa[]>([])
  const [NomeGrupo, setNomeGrupo] = useState('')

  useEffect(() => {
    if (trilhaId) {
      ObterEtapas(trilhaId)
    }
  }, [trilhaId])

  function ObterEtapas(trilhaId: string): void {
    appApi.get(`Etapa?idTrilha=${trilhaId}`)
      .then(response => setEtapas(response.data))
      .catch(e => console.log(e))

    appApi.get(`GrupoTrilha/${trilhaId}`)
      .then(response => { setNomeGrupo(response.data.nome) })
      .catch(err => console.error(err))
  }

  function ObterIconePeloTipo(Tipo: number): any {
    if (Tipo === -1) {
      return <FaUserClock size={40} />
    } else if (Tipo === 0) {
      return <FaDoorOpen size={40} />
    } else if (Tipo === 1) {
      return <FaUsers size={40} />
    } else if (Tipo === 2) {
      return <FaComments size={40} />
    } else if (Tipo === 3) {
      return <FaChalkboardTeacher size={40} />
    } else if (Tipo === 4) {
      return <FaClipboardList size={40} />
    } else if (Tipo === 5 || Tipo === 6) {
      return <Img src={IconeTrilha} width='40px' height='40px' />
    } else if (Tipo === 7) {
      return <FaPeopleArrows size={40} />
    } else if (Tipo === 8) {
      return <MdBlock size={40} />
    } else if (Tipo === 9) {
      return <BsCalendarCheck size={40} />
    }
  }

  return (
    <Container data-cy="TimeLineTrilha">
      <WidthContainer width='100%'>
        <TextIcon>
          <Icone theme={'00B45A'}>
            <FaUserClock size={40} />
          </Icone>
          <span>{NomeGrupo}</span>
        </TextIcon>
        <hr />
      </WidthContainer>

      {
        Etapas.map((e: iCardEtapa, i) => {
          return (
            <WidthContainer key={i} width='100%'>
              <TextIcon>
                <Icone theme={ObterCorEtapaPeloTipo(e.tipo)}>
                  {
                    ObterIconePeloTipo(e.tipo)
                  }
                </Icone>
                <span>{e.tipo === 0 ? 'Primeiro acesso' : e.descricao}</span>
              </TextIcon>
              <hr />
            </WidthContainer>
          )
        })
      }

      <WidthContainer>
        <TextIcon>
          <Icone theme={'C4C4C4'}>
            <svg width={'40'} height={'40'} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M416 32h-64c-17.67 0-32 14.33-32 32s14.33 32 32 32h64c17.67 0 32 14.33 32 32v256c0 17.67-14.33 32-32 32h-64c-17.67 0-32 14.33-32 32s14.33 32 32 32h64c53.02 0 96-42.98 96-96V128C512 74.98 469 32 416 32zM342.6 233.4l-128-128c-12.51-12.51-32.76-12.49-45.25 0c-12.5 12.5-12.5 32.75 0 45.25L242.8 224H32C14.31 224 0 238.3 0 256s14.31 32 32 32h210.8l-73.38 73.38c-12.5 12.5-12.5 32.75 0 45.25s32.75 12.5 45.25 0l128-128C355.1 266.1 355.1 245.9 342.6 233.4z" fill='white' /></svg>
          </Icone>
          <span>Fim do processo</span>
        </TextIcon>
      </WidthContainer>
    </Container>
  )
}
