/* eslint-disable @typescript-eslint/no-floating-promises */
import ApexCharts from 'apexcharts'
import { Flex } from '@chakra-ui/react'
import { useEffect } from 'react'

interface iPieChartData{
  nome: string
  series: string[]
  labels: string[]
}

interface BarChartProps {
  id: string
  data: iPieChartData
}

export const BarChart: React.FC<BarChartProps> = ({ id, data }) => {
  function GerarGrafico(): void {
    const options = {
      series: [{
        data: data.series
      }],
      chart: {
        type: 'bar',
        height: 350,
        width: 1000
      },
      tooltip: {
        enabled: false
      },
      grid: {
        show: false
      },
      plotOptions: {
        bar: {
          horizontal: true
        }
      },
      dataLabels: {
        enabled: false
      },
      xaxis: {
        categories: data.labels,
        title: {
          style: {
            color: '#000',
            fontSize: '20px',
            fontWeight: 600
          }
        },
        labels: {
          rotate: 0,
          rotateAlways: false,
          formatter: function (val: number) {
            return val.toFixed(0)
          }
        }
      }
    }
    const chart = new ApexCharts(document.querySelector(`#chart-${id}`), options)
    chart.render()
  }

  useEffect(() => {
    GerarGrafico()
  }, [])
  return (
      <Flex id={`chart-${id}`} />
  )
}
