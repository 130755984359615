import styled from 'styled-components'

export const Container = styled.div`
display: flex;
flex-direction: column;
`

export const Header = styled.div`
display: flex;
span{
    font-size: 18px;
    font-weight: 500;
}
svg{
    margin-right: 1rem;
}
`

export const Body = styled.div`
margin: 1rem 0rem;
max-width: 687.766px;
img{
    width: 100%;
    object-fit: scale-down;
}

div{
    display: flex;
    justify-content: center;
    margin-top: 1rem;
    svg{
        margin-right: .5rem;
    }
    span{
        color: #153962;
        font-size: 14px;
        font-weight: 300;
    }
}
`
