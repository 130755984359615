import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

export const Container = styled.nav`
  background: var(--c1);
  border-radius: 0.25rem;
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
  box-shadow: var(--SombraBackground);
`

export const LeftContainer = styled.div`
  display: flex;

  align-items: center;

  img {
    height: 5rem;

    margin-right: 1rem;
  }

  span {
    font-size: 1.5rem;

    color: var(--a4);
  }
`

export const RightContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`

export const NavButton = styled(NavLink)`
  padding: 1rem;
  border: 0;
  border-radius: 0.25rem;

  color: var(--a4);
  background: var(--c1);
  text-decoration: none;

  display: flex;
  align-items: center;
  justify-content: center;

  span {
    display: block;
    font-size: 1.5rem;

    transition: filter 0.2s;

    &:hover {
      filter: brightness(1.6);
    }
  }
`
