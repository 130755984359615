import { CSSProperties } from 'react'
import { Container } from './styles'

interface BoxProps{
  styles?: CSSProperties
  margin?: string
}

export const WhiteBox: React.FC<BoxProps> = ({ children, margin, styles }) => {
  return (
    <Container margin={margin} style={styles}>{children}</Container>
  )
}
