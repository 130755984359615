import { Flex, Text } from '@chakra-ui/react'
import Stonks from '../../../../../assets/Stonks.svg'
import { iCompetenciaPDI } from '../../../../../interfaces'

interface iRecomendacaoProps {
  pos: number
  recomendacao: iCompetenciaPDI
}

export const Recomendacao: React.FC<iRecomendacaoProps> = ({ recomendacao, pos }) => {
  return (
        <Flex width={'100%'} height={'100%'}>
            <Flex borderBottomLeftRadius={'1.875rem'} borderTopLeftRadius={'1.875rem'} background={'var(--c2)'}>
                <h3 style={{ padding: '.5rem 0 0 .5rem' }}>{pos}°</h3>
                <Flex width={'10rem'} textAlign={'center'} justifyContent={'center'} alignItems='center' flexDir={'column'} padding={'1rem 1rem 1rem 0'}>
                    <Flex width={'6rem'}>
                        <img style={{ width: '100%', height: '100%' }} src={recomendacao.avatar} />
                    </Flex>
                    <strong>{recomendacao.nome}</strong>
                </Flex>
            </Flex>
            <Flex flexDir={'column'} gap='1rem' borderBottomRightRadius={'1.875rem'} borderTopRightRadius={'1.875rem'} padding={'1rem'} background={'#9B51E0'} width={'-webkit-fill-available'}>
                <Text fontSize={'0.75rem'} lineHeight={'115%'} fontWeight={'400'} color='white'>{recomendacao.pergunta}</Text>
                <Flex height={'100%'} alignItems={'end'} justifyContent='space-between'>
                    <Flex color={'white'} flexDir={'column'}>
                        <h2>{recomendacao.colaboradores}</h2>
                        <Text fontWeight={'400'}>Colaboradores</Text>
                    </Flex>
                    <Flex height='1rem'>
                        <img src={Stonks} />
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
  )
}
