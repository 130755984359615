import styled from 'styled-components'

export const Main = styled.div` 
h1{
  margin: 1rem 0rem;
}
`

export const ContainerFlex = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  
  span{
      display: flex;
      white-space: nowrap;
      font-weight: bold; 
      align-items: center;
      margin-bottom: 1rem;
      input{
          margin: 0rem .5rem 0rem .5rem 
  
      }
  }
  `

export const StyledLabel = styled.label`
  color: var(--a4);
  font-weight: 500;
  margin-bottom: 0.25rem;
  font-size:1.5rem;
  line-height: 1.75rem;
  font-weight: bold;
`
