import styled from 'styled-components'

export const Container = styled.div`
display: flex;
justify-content: space-between;
border-radius: 6px;
width: 15rem;
background: var(--c2);
border: 0.125rem solid var(--v3);
`

export const EndIcon = styled.div`
display: flex;
align-items: start;

svg{
    color: var(--terc3);
    cursor: pointer;
    margin: .3rem;
}
`

export const ColumnText = styled.div`
display: flex;
flex-direction: column;
margin-left: 12px;
span,h4{
    color: var(--a5);
}

span{
font-weight: 400;
font-size: 0.875rem;
}
`

export const AvatarTitle = styled.div`
display: flex;
align-items: center;
margin: 9px 0px 9px 13px;
`
